import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardBlueTag = React.forwardRef<HTMLDivElement, CardProps>(({ highlighted, barcode }, ref) => {
  const referenceData: ReferenceDataType[] = [
    {
      title: '',
      data: {
        left: [
          { name: '', title: 'Гвозди' },
          { name: 'end_diameter', title: 'Диаметр, мм' },
          { name: 'steel_grade', title: 'Марка' },
          { name: 'aggregate', title: '№ стана' },
          { name: '', title: '№ Катушки' },
          { name: '', title: 'Масса, тн' },
          { name: 'date', title: 'Дата' },
        ],
        right: [
          { name: 'order_code', title: '№ Плавки' },
          { name: '', title: 'Оператор' },
          { name: '', title: 'Тип смазки' },
          { name: 'brigade', title: 'Бригада' },
          { name: '', title: 'ОТК' },
        ],
      },
    },
  ]

  const renderBlock = (data?: Block[]) => {
    if (!data) return null

    return data
      .map(({ name, title }) => {
        const newDescription: string =
          // @ts-ignore
          highlighted[0][name] !== undefined ? highlighted[0][name] : '____'
        return { title, description: newDescription }
      })
      .map(({ title, description }) => (
        <Box style={{ paddingBottom: '0px' }} justifyContent="space-between" key={title}>
          <Box>
            <Typography style={{ maxWidth: '150px' }} variant="Heading3">
              {title}
            </Typography>
          </Box>

          <Typography variant="Heading3">{description}</Typography>
        </Box>
      ))
  }

  return (
    <div className="print-block print-block_blue-tag" ref={ref}>
      <Box
        flexDirection="column"
        alignItems="start"
        style={{
          position: 'relative',
          marginLeft: '10px',
          paddingLeft: '15px',
        }}
      >
        {referenceData.map(({ title, data }, index) => (
          <div
            style={{
              padding: '10px 5px 29px 5px',
              paddingBottom: '15px',
              position: 'relative',
              width: '420px',
              marginLeft: '20px',
              marginRight: '20px',
            }}
            key={`title${index}`}
          >
            <Typography variant="Heading3">{title}</Typography>
            <Box justifyContent="space-between">
              {Object.values(data).map((value, i) => (
                <Box
                  style={{
                    width: '220px',
                    maxHeight: '210px',
                    paddingRight: '5px',
                    marginRight: '10px',
                    borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                    lineHeight: '600',
                    fontSize: '20px',
                  }}
                  flexDirection="column"
                  key={i}
                >
                  <div
                    style={{
                      lineHeight: '600',
                      fontSize: '20px',
                    }}
                  >
                    {renderBlock(value)}
                  </div>
                </Box>
              ))}
            </Box>
          </div>
        ))}

        <div className={styles.barcodeContainer}>
          <Barcode height={45} width={3} fontSize={22} value={barcode} />
        </div>
        <div className={styles.qrCodeContainer}>
          <QRCode size={100} value={String(barcode)} />
        </div>
      </Box>
    </div>
  )
})

export default CardBlueTag
