import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  RefreshSvgIcon,
  Select,
  TableColumn,
} from '../../../../ui-kit/components'
import LoaderWrapper from '../../../../ui-kit/frontend-components/LoaderWrapper'
import { useAsyncTableManager } from '../../../../ui-kit/hooks/useAsyncTableManager'
import { useAllDictionaryTypes } from '../hooks/useAllDictionaryTypes'
import { dictionaryWorkStatus } from './constants'
import { AllWithNodes, Column, useDictionaryValues } from './hooks/useDictionaryValues'
import { useGetDependentDictionaries } from './hooks/useGetDependentDictionaries'
import { getReducerKey } from './utils'
import { FormControlLabel, makeStyles } from '@material-ui/core'
import service from 'services/service'
import DICTIONARY_WORK_STATUS from 'shared/enums/DictionaryWorkStatus'

import EditTable from './components/EditTable'

const styles = makeStyles({
  contentContainer: {
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const columnOrder: Record<string, number> = {
  startDateTime: 1000,
  finishDateTime: 1000,
  setDateTime: 1000,
  updateDateTime: 1000,
  approveDateTime: 1000,
}

const getColumnOrder = (name: string): number => columnOrder?.[name] ?? 0

const sortColumns = <R,>(columns: TableColumn<R>[]): TableColumn<R>[] => {
  return columns.sort((a, b) => getColumnOrder(a.name) - getColumnOrder(b.name))
}

const EditDictionaries: FC = () => {
  const classes = styles()
  // const alert = useAlert();

  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [readOnly, setReadOnly] = useState(true)
  const [selectedWorkStatus, setSelectedWorkStatus] = useState(DICTIONARY_WORK_STATUS.ACTUAL)
  const [isFullXlsx, setIsFullXlsx] = useState(false)

  const { dictionariesTypesInfo, request: allDictionaryTypesRequest } = useAllDictionaryTypes()

  const {
    dictionaryValues,
    setDictionaryValues,
    columns: dictionaryColumns,
    request: dictionaryValuesRequest,
    dependentColumns,
    dictionaryInfo,
    pageable,
  } = useDictionaryValues(selectedType, selectedWorkStatus)

  const { dependentDictionaries, getDependentDictionaries } = useGetDependentDictionaries()

  useEffect(() => {
    if (Object.keys(dependentColumns).length > 0) {
      getDependentDictionaries(dependentColumns)
    }
  }, [getDependentDictionaries, dependentColumns])

  useEffect(() => {
    if (selectedType !== '') {
      dictionaryValuesRequest.execute()
    }
  }, [dictionaryValuesRequest.execute])

  const tableManagerColumns = useMemo(
    () =>
      dictionaryColumns.map(({ name, title, dataType, customMethods }) => ({
        name,
        title,
        dataType,
        customMethods,
      })),
    [dictionaryColumns],
  )

  const { manager, columns: reduxManagerColumns } = useAsyncTableManager(
    getReducerKey(selectedType),
    tableManagerColumns,
  )

  const managerColumns = useMemo(() => sortColumns(reduxManagerColumns), [reduxManagerColumns])

  const columns = useMemo(
    () =>
      managerColumns.map((managerColumn) => {
        const column = (dictionaryColumns.find(({ name }) => name === managerColumn.name) ??
          {}) as Column
        return {
          ...managerColumn,
          ...column,
        }
      }),
    [managerColumns, dictionaryColumns],
  )

  const dispatch = useDispatch()

  const handleChangeCurrentPage = useCallback(
    (page) => {
      if (manager) {
        dispatch(manager.changePage(page - 1))
      }
    },
    [manager],
  )

  const resetManagerParams = useCallback(() => {
    if (manager) {
      dispatch(manager.resetParams())
    }
  }, [manager])

  useEffect(() => resetManagerParams, [resetManagerParams])

  useEffect(() => {
    if (selectedType) {
      resetManagerParams()
    }
  }, [selectedType, selectedWorkStatus])

  const handleAddDictionaryValue = useCallback((value: AllWithNodes[number]) => {
    setDictionaryValues((prevValues) => [value, ...prevValues])
    setSelectedWorkStatus(DICTIONARY_WORK_STATUS.OPEN)
  }, [])

  const handleEditDictionaryValue = useCallback((value: AllWithNodes[number]) => {
    setDictionaryValues((prevValues) => {
      return prevValues.map((prevValue) => {
        if (prevValue.id === value.id) {
          return {
            ...prevValue,
            ...value,
          }
        }
        return prevValue
      })
    })
  }, [])

  const handleDeleteDictionaryValue = useCallback((id: number) => {
    setDictionaryValues((prevValues) => prevValues.filter((prevValue) => prevValue.id !== id))
  }, [])

  const handleCloseDictionaryValue = useCallback((id: number, dateTime: string) => {
    setDictionaryValues((prevValues) =>
      prevValues.map((prevValue) => {
        if (prevValue.id === id) {
          return {
            ...prevValue,
            finishDateTime: dateTime,
          } as AllWithNodes[number]
        }
        return prevValue
      }),
    )
  }, [])

  async function downloadDictionary(
    selectedType: string,
    selectedWorkStatus: string,
    dateOper: boolean,
  ) {
    try {
      setLoading(true)
      await service.downloadDictionary(selectedType, selectedWorkStatus, dateOper, selectedType)
    } catch (e) {
      // alert.error(String(e));
    } finally {
      setLoading(false)
    }
  }

  const handleApproveDictionaryValue = useCallback((id: number, dateTime: string) => {
    setDictionaryValues((prevValues) =>
      prevValues.map((prevValue) => {
        if (prevValue.id === id) {
          return {
            ...prevValue,
            approveDateTime: dateTime,
          } as AllWithNodes[number]
        }
        return prevValue
      }),
    )
  }, [])

  return (
    <Box className={classes.contentContainer} spacing={2}>
      <LoaderWrapper
        rootStyles={{ flexShrink: 0 }}
        isLoad={allDictionaryTypesRequest.status === 'pending'}
      >
        <Box spacing={2}>
          <Select
            formControlStyle={{ width: '350px' }}
            label="Справочник"
            defaultValue=""
            value={selectedType}
            onChange={({ target }) => {
              setSelectedType(String(target.value))
              resetManagerParams()
            }}
          >
            {dictionariesTypesInfo.map(({ readOnly, dictionaryType, typeAlias, infoName }) => (
              <MenuItem
                key={dictionaryType}
                value={dictionaryType}
                onClick={() => setReadOnly(readOnly)}
              >
                {`${typeAlias} - ${infoName}`}
              </MenuItem>
            ))}
          </Select>
          <Select
            formControlStyle={{ width: '350px' }}
            label="Статус справочных значений"
            defaultValue=""
            value={selectedWorkStatus}
            onChange={({ target }) => {
              setSelectedWorkStatus(target.value as DICTIONARY_WORK_STATUS)
            }}
          >
            {Object.entries(dictionaryWorkStatus).map(([workStatus, title]) => (
              <MenuItem key={workStatus} value={workStatus}>
                {title}
              </MenuItem>
            ))}
          </Select>
          <Button
            disabled={selectedType === '' || loading}
            variant="contained"
            color="primary"
            onClick={dictionaryValuesRequest.execute}
          >
            <RefreshSvgIcon />
          </Button>
          <Box marginLeft="auto" spacing={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFullXlsx}
                  onChange={(e) => {
                    setIsFullXlsx(e.target.checked)
                  }}
                />
              }
              label="Расширенный формат"
            />
            <Button
              disabled={selectedType === ''}
              color="primary"
              variant="contained"
              onClick={() => downloadDictionary(selectedType, selectedWorkStatus, isFullXlsx)}
            >
              Скачать xlsx
            </Button>
          </Box>
        </Box>
      </LoaderWrapper>
      {Boolean(selectedType) && manager && (
        <EditTable
          dictionaryType={selectedType}
          readOnly={readOnly}
          rows={dictionaryValues ?? []}
          columns={columns}
          totalPages={pageable.totalPages}
          currentPage={pageable.currentPage + 1}
          onChangeCurrentPage={handleChangeCurrentPage}
          isLoading={dictionaryValuesRequest.status === 'pending'}
          onAdd={handleAddDictionaryValue}
          onEdit={handleEditDictionaryValue}
          onDelete={handleDeleteDictionaryValue}
          onRefresh={dictionaryValuesRequest.execute}
          onClose={handleCloseDictionaryValue}
          onApprove={handleApproveDictionaryValue}
          dictionaryInfo={dictionaryInfo}
          manager={manager}
          dependentDictionaries={dependentDictionaries}
        />
      )}
    </Box>
  )
}

export default EditDictionaries
