import { makeStyles } from '@material-ui/core'

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    height: '40px',
    padding: '9px 12px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textTransform: 'none',
    minWidth: 30,
  },
  text: {
    color: theme.palette.text.primary,
  },
  sizeSmall: {
    height: '31px',
    padding: '4px 12px',
  },
  iconSizeSmall: { marginLeft: '12px', marginRight: '12px' },
  iconSizeMedium: { marginLeft: '14px', marginRight: '14px' },
  containerPrimary: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    },
    '&$disabled': {
      opacity: 0.5,
      backgroundColor: theme.palette.primary.main,
      color: theme.neutral.noChangeWhite,
    },
    '&:active': {
      transition: 'all 200ms ease',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    background: theme.palette.secondary.main,
    color: theme.buttonTextColor,
    '&:hover': {
      background: theme.palette.secondary.light,
      boxShadow: 'none',
    },
    '&$disabled': {
      opacity: 0.5,
      background: theme.palette.secondary.main,
      color: theme.buttonTextColor,
    },
  },
  outlinedPrimary: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&$disabled': {
      opacity: 0.5,
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  textPrimary: {
    color: theme.palette.primary.main,
    '&$disabled': {
      opacity: 0.5,
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  disabled: {
    background: theme.neutral.noChangeWhite,
    opacity: 0.5,
    color: theme.neutral.noChangeWhite,
  },
  label: {
    fontSize: theme.textStyles.body1medium.fontSize,
    fontWeight: theme.textStyles.body1bold.fontWeight,
  },
  inherit: {
    backgroundColor: theme.palette.grey['50'],
  },
}))
