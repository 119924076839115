import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const PenSvgIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3685 3.29L20.7085 5.63C21.0985 6.02 21.0985 6.65 20.7085 7.04L18.8785 8.87L15.1285 5.12L16.9585 3.29C17.1485 3.1 17.3985 3 17.6585 3C17.9185 3 18.1685 3.09 18.3685 3.29ZM2.99854 17.25V21H6.74854L17.8085 9.94L14.0585 6.19L2.99854 17.25ZM5.91854 19H4.99854V18.08L14.0585 9.02L14.9785 9.94L5.91854 19Z"
      />
    </SvgIcon>
  )
}
