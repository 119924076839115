function getValidDiameter(value: string | undefined): string {
  if (!value) return ''
  let diameter = value.split(' ').join('')
  if (diameter?.includes(',')) diameter = String(Number(diameter?.replace(',', '.')))

  if (!diameter.includes('.')) {
    return diameter.concat('.00')
  }

  if (diameter.substring(diameter.indexOf('.')).length === 2) return diameter.concat('0')

  if (diameter.substring(diameter.indexOf('.')).length >= 3)
    return diameter.substring(0, diameter.indexOf('.') + 3)

  return diameter
}

export default getValidDiameter
