import { makeStyles, SwitchProps, Theme } from '@material-ui/core'

export const useSwitchStyles = makeStyles<Theme, SwitchProps>((theme) => ({
  switchBase: {
    margin: '3px 0 0 3px',
    padding: '6px',
  },
  track: ({ disabled }) => ({
    backgroundColor: disabled ? theme.palette.action.selected : theme.neutral.grey200,
    opacity: 1,
  }),
  thumb: ({ disabled }) => ({
    background: disabled ? theme.neutral.grey200 : theme.neutral.noChangeWhite,
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
  }),
  '@global': {
    '.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.secondary.dark,
    },
    '.MuiSwitch-colorSecondary.Mui-checked  .MuiSwitch-thumb': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.main,
      boxShadow:
        '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    '.MuiSwitch-colorSecondary.Mui-checked:hover': {
      background: 'rgba(22, 127, 251, 0.08)',
    },
    '.MuiSwitch-colorSecondary:hover': {
      background: 'rgba(0, 23, 41, 0.04)',
    },
  },
}))
