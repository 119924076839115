import PRINT_PAGE_STYLE from '../config/printConfig'

export const tagSizes = {
  small: 'small',
  medium: 'medium',
}

export const CARD = ['white', 'white_blue_stripe', 'yellow', 'green', 'blue', 'red']

export const CARD_TAGS = [
  'white_tag',
  'white_blue_stripe_tag',
  'yellow_tag',
  'green_tag',
  'blue_tag',
  'red_tag',
]

export const CARD_MEDIUM_TAGS = [
  'white_medium_tag',
  'white_blue_stripe_medium_tag',
  'yellow_medium_tag',
  'green_medium_tag',
  'blue_medium_tag',
  'red_medium_tag',
]

export function getTagSizeByColor(color: string): string | null {
  if (CARD_TAGS.includes(color)) return tagSizes.small
  if (CARD_MEDIUM_TAGS.includes(color)) return tagSizes.medium
  return null
}

function getPageStyle(currentRegion: string | undefined, tagSize?: string | null): string {
  if (tagSize === 'small') return PRINT_PAGE_STYLE.tagPrint
  if (tagSize === 'medium') return PRINT_PAGE_STYLE.tagPrint
  if (!currentRegion) return PRINT_PAGE_STYLE.tablePrint
  if (currentRegion.includes('wire_nailing_machine')) return PRINT_PAGE_STYLE.tablePrint
  if (currentRegion.includes('ugv')) return PRINT_PAGE_STYLE.labPrintPortrait
  return PRINT_PAGE_STYLE.tablePrint
}

export default getPageStyle
