import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconDowntimeAnalysis24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M12 19.4326C16.0869 19.4326 19.4707 16.0488 19.4707 11.9619C19.4707 10.2554 18.8774 8.67334 17.8887 7.40625L18.46 6.82764C18.6284 6.65918 18.7236 6.43213 18.7236 6.23438C18.7236 5.82422 18.394 5.49463 17.9692 5.49463C17.7202 5.49463 17.5444 5.56787 17.3687 5.73633L16.812 6.28564C15.6914 5.3335 14.2998 4.69629 12.7764 4.53516V3.81738C12.7764 3.37793 12.4321 3.01172 11.9927 3.01172C11.5679 3.01172 11.209 3.37793 11.209 3.81738V4.53516C7.48096 4.93799 4.5293 8.146 4.5293 11.9619C4.5293 16.0488 7.92041 19.4326 12 19.4326ZM12 18.1875C8.54297 18.1875 5.78174 15.4189 5.78174 11.9619C5.78174 8.51221 8.53564 5.73633 11.9927 5.73633C15.4497 5.73633 18.2183 8.51221 18.2256 11.9619C18.2329 15.4189 15.457 18.1875 12 18.1875ZM12 13.1484C12.6519 13.1484 13.1792 12.6211 13.1792 11.9692C13.1792 11.5078 12.9155 11.105 12.5054 10.9072V7.26709C12.5054 6.98145 12.2783 6.76172 11.9927 6.76172C11.707 6.76172 11.4873 6.98145 11.4873 7.26709V10.8999C11.0845 11.1123 10.8208 11.5078 10.8208 11.9692C10.8208 12.6211 11.3408 13.1484 12 13.1484Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconDowntimeAnalysis24
