// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import service from 'services/service'

export type UseCloseDictionaryValueReturn = {
  isLoading: boolean
  closeDictionaryValue: (value: number[], dateTimeForClose: Date) => Promise<void>
}

export const useCloseDictionaryValue = (onSuccess: (id: number, dateTime: string) => void) => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const closeDictionaryValue = useCallback(
    async (value: number[], dateTimeForClose?: Date) => {
      try {
        setIsLoading(true)

        const response = await service.closeDictionaryValues(value, dateTimeForClose)

        const { listIds, dateTime } = response.content
        onSuccess(listIds[0], dateTime)
        // alert.success('Значение успешно закрыто');
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess],
  )

  return {
    isLoading,
    closeDictionaryValue,
  }
}
