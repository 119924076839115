// import { useKeyCloak } from '@nlmk/ds';
import { useCallback } from 'react'

import { PERMISSION } from '../../shared/enums/Permission'

export type UsePermissionParams = {
  can: (permissions: PERMISSION | PERMISSION[]) => boolean
}

export const usePermission = (): UsePermissionParams => {
  // const roles = useKeyCloak()?.realmAccess?.roles ?? [];
  const roles = ['admin']

  const can = useCallback<UsePermissionParams['can']>(
    (permissions) => {
      if (roles.includes(PERMISSION.ADMIN)) return true

      if (Array.isArray(permissions)) {
        return permissions.some((permission) => roles.includes(permission))
      }

      return roles.includes(permissions)
    },
    [roles],
  )

  return { can }
}
