import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import 'normalize.css'

import './scss/style.scss'

export * from '../src/ui-kit/components'
export * from '../src/ui-kit/helpers'
export * from '../src/ui-kit/hooks'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
