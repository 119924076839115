import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const Icon2Bunker32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C9.3828 4 4 9.3828 4 16V26.049C4 27.125 4.87495 28 5.95098 28H26.049C27.125 28 28 27.125 28 26.049V16C28 9.3828 22.6172 4 16 4ZM5.94118 16C5.94118 10.4535 10.4535 5.94118 16 5.94118C21.5465 5.94118 26.0588 10.4535 26.0588 16V26.049C26.0588 26.0492 26.0588 26.0497 26.0584 26.0507L26.0573 26.0527L26.0552 26.0552C26.0542 26.0562 26.0532 26.0569 26.0523 26.0575C26.0517 26.0579 26.0511 26.0582 26.0507 26.0584C26.0497 26.0588 26.0492 26.0588 26.049 26.0588H23.5882V21.6373C23.5882 21.1014 23.1535 20.6667 22.6176 20.6667H20.1569V15.7549C20.1569 15.2191 19.7221 14.7843 19.1863 14.7843H12.8137C12.2779 14.7843 11.8431 15.2191 11.8431 15.7549V20.6667H9.38235C8.84652 20.6667 8.41176 21.1014 8.41176 21.6373V26.0588H5.95098C5.95089 26.0588 5.95119 26.0589 5.95098 26.0588C5.95071 26.0588 5.9499 26.0586 5.94934 26.0584C5.94817 26.0579 5.9465 26.0569 5.94481 26.0552C5.94312 26.0535 5.94211 26.0518 5.9416 26.0507C5.9414 26.0502 5.94166 26.0509 5.9416 26.0507C5.94154 26.0504 5.94118 26.0491 5.94118 26.049V16ZM13.7843 20.6667V16.7255H15.0294V17.2255C15.0294 17.7613 15.4642 18.1961 16 18.1961C16.5358 18.1961 16.9706 17.7613 16.9706 17.2255V16.7255H18.2157V20.6667H13.7843ZM10.3529 26.0588V22.6078H11.8431V23.1078C11.8431 23.6437 12.2779 24.0784 12.8137 24.0784C13.3496 24.0784 13.7843 23.6437 13.7843 23.1078V22.6078H15.0294V26.0588H10.3529ZM16.9706 26.0588V22.6078L18.2157 22.6078V23.1078C18.2157 23.6437 18.6504 24.0784 19.1863 24.0784C19.7221 24.0784 20.1569 23.6437 20.1569 23.1078V22.6078H21.6471V26.0588H16.9706Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon2Bunker32
