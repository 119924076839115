import React, { FC, ReactNode } from 'react'
import InputMask from 'react-input-mask'

import { TInputMaskCorrect } from './types'

// Это костыль для нормальной работы компонента InputMask
// TODO: заменить или разобраться с этой ошибкой корректно (https://github.com/sanniassin/react-input-mask/issues/139)
export const InputMaskCorrect: FC<TInputMaskCorrect> = ({ children, ...props }) => {
  const child = children as ReactNode
  return <InputMask children={child} {...props} />
}
