import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { checkInFavorite } from '../../../../helpers/navigation'
import { FavoriteStar } from '../../../Icon/FavoriteStar'
import NavigationContext from '../../context/NavigationContext'
import { RenderLinkProps } from '../../types'

import { useNavigationStyles } from '../../styles'

export const DefaultRenderLink = ({
  href,
  children,
  id,
  onClick,
}: RenderLinkProps): JSX.Element => {
  const { useFavorites, onChangeFavorites, setSelectedFirstLevel } = useContext(NavigationContext)
  const [updateView, setUpdateView] = useState(false)
  const { linkWrapper, activeFavoriteIcon, favoriteIcon } = useNavigationStyles()

  const inFavorite = checkInFavorite(href || id)

  const onUpdateView = useCallback(() => {
    setUpdateView(!updateView)
    if (onChangeFavorites) {
      onChangeFavorites()
    }
  }, [updateView, onChangeFavorites])

  const handleLinkClick = useCallback(() => {
    if (setSelectedFirstLevel) {
      setSelectedFirstLevel(null)
    }
  }, [setSelectedFirstLevel])

  return (
    <div className={linkWrapper}>
      {useFavorites && (href || onClick) && (
        <FavoriteStar
          className={inFavorite ? activeFavoriteIcon : favoriteIcon}
          id={id}
          href={href}
          updateView={onUpdateView}
        />
      )}
      {href && (
        <Link
          style={{ color: 'inherit', textDecoration: 'none' }}
          to={href}
          onClick={handleLinkClick}
        >
          {children}
        </Link>
      )}
      {onClick && !href && <>{children}</>}
    </div>
  )
}
