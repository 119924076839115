import React, { ReactElement, useState } from 'react'

import { Box } from '../../../../ui-kit/components'
import { Tab, Tabs } from '../../../../ui-kit/components/Tabs'
import TabPanel from '../../../../ui-kit/frontend-components/TabPanel'
import { NodesToAdd, NodesToRemove } from './components'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const ADD_TAB_INDEX = 0
const REMOVE_TAB_INDEX = 1

export const NodeManagment = (): ReactElement => {
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState(ADD_TAB_INDEX)
  return (
    <Box className={classes.flexGrow} spacing={2}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={(e, tab) => setCurrentTab(tab)}
      >
        <Tab label="Добавить в отображение" />
        <Tab label="Удалить из отображения" />
      </Tabs>
      <TabPanel
        className={currentTab === ADD_TAB_INDEX ? classes.flexGrow : undefined}
        index={ADD_TAB_INDEX}
        value={currentTab}
      >
        <NodesToAdd />
      </TabPanel>
      <TabPanel
        className={currentTab === REMOVE_TAB_INDEX ? classes.flexGrow : undefined}
        index={REMOVE_TAB_INDEX}
        value={currentTab}
      >
        <NodesToRemove />
      </TabPanel>
    </Box>
  )
}
