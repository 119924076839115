import React, { FC, PropsWithChildren } from 'react'

import { CircularProgress } from '@material-ui/core'

import { LoaderProps } from './types'

import { useLoaderStyles } from './styles'

export const Loader: FC<LoaderProps & PropsWithChildren> = ({
  useAsWrapper,
  children,
  ...props
}): JSX.Element => {
  const classes = useLoaderStyles()

  if (useAsWrapper) {
    return (
      <div className={classes.loaderWrapper}>
        <div className={classes.loaderWrapper__loader}>
          <CircularProgress {...props} />
        </div>
        {children}
      </div>
    )
  }
  return <CircularProgress {...props} />
}
