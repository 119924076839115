import React, { useMemo } from 'react'

import { Box, Card, Pagination } from '../../../../../../ui-kit/components'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import Table from '../../../../../../ui-kit/frontend-components/Table'
import TableManager from '../../../../../../ui-kit/managers/TableManager'
import { DictionaryColumn } from '../../hooks/useDictionary'
import { makeStyles } from '@material-ui/core'
import { DictionaryContent } from 'interfaces/responses/Dictionary'
import getLocalDate from 'utils/getLocalDate'

type Props = {
  rows: DictionaryContent[]
  columns: DictionaryColumn[]
  totalPages: number
  currentPage: number
  onChangeCurrentPage: (page: number) => void
  isLoading: boolean
  manager: TableManager
}

const styles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const dateTimeColumnNames = [
  'setDateTime',
  'startDateTime',
  'updateDateTime',
  'finishDateTime',
  'approveDateTime',
]

const tableStyle = {
  root: {
    style: {
      flexGrow: 1,
      overflow: 'auto',
      maxHeight: '620px',
    },
  },
}

const DictionaryTable = ({
  rows,
  columns,
  currentPage,
  onChangeCurrentPage,
  totalPages,
  isLoading,
  manager,
}: Props): JSX.Element => {
  const classes = styles()

  const columnsConfig = useMemo(
    () =>
      columns.map((item) => {
        if (dateTimeColumnNames.includes(item.name)) {
          return {
            ...item,
            render: (row: any) => {
              if (!row[item.name]) return '-'
              return getLocalDate(row[item.name])
            },
          }
        }
        return {
          ...item,
          render: (row: any) => {
            return row?.[item.name] ?? '-'
          },
        }
      }),
    [columns],
  )

  return (
    <LoaderWrapper
      rootClassName={classes.flexGrow}
      contentClassName={classes.flexGrow}
      isLoad={isLoading}
    >
      <Card className={classes.flexGrow}>
        <Box className={classes.flexGrow} spacing={2} paddingX={2} paddingY={2}>
          <Table
            narrowColumns
            fixHeader
            noDataMessage="Нет данных"
            style={tableStyle}
            enableColumnResizing
            columns={columnsConfig}
            rows={rows}
            withFilter
            manager={manager}
            excludeColumnNames={dateTimeColumnNames}
          />
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => onChangeCurrentPage(page)}
          />
        </Box>
      </Card>
    </LoaderWrapper>
  )
}

export default DictionaryTable
