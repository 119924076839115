import { makeStyles } from '@material-ui/core'

export const useFooterStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    paddingBottom: '8px',
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.neutral.grey100}`,
  },
  button: {
    marginRight: '8px',
  },
}))
