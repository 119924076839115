import { getColor } from './helpers/getColor'
import { makeStyles, Theme } from '@material-ui/core'

import { IUseChipClassesProps } from './types'

export const useChipStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 15,
    border: ({ color }: IUseChipClassesProps) =>
      color === 'outline' ? `1px ${theme.neutral.black} solid` : 'none',
    backgroundColor: ({ color }: IUseChipClassesProps) =>
      getColor(theme, color || 'default').backgroundColor,
  },
  sizeSmall: {
    borderRadius: 100,
  },
  label: {
    fontSize: theme.textStyles.body1bold.fontSize,
    fontWeight: theme.textStyles.body1bold.fontWeight,
    color: theme.palette.text.primary,
  },
  labelSmall: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}))
