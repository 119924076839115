import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconDocFile24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M15.7031 0H6.06368C4.65541 0 3.50927 1.14694 3.50927 2.55436V12H3.25973C2.69136 12 2.23047 12.4604 2.23047 13.0293V19.2717C2.23047 19.8405 2.69136 20.3009 3.25973 20.3009H3.50927V21.4456C3.50927 22.8546 4.65541 24 6.06363 24H19.216C20.6235 24 21.7696 22.8545 21.7696 21.4456V6.0455L15.7031 0ZM3.93328 13.8867C4.29302 13.8295 4.76168 13.7973 5.25643 13.7973C6.07856 13.7973 6.61169 13.9448 7.02942 14.2594C7.47897 14.5936 7.76136 15.1271 7.76136 15.8913C7.76136 16.7197 7.4597 17.2916 7.04197 17.6446C6.58615 18.0234 5.89229 18.2032 5.04462 18.2032C4.53708 18.2032 4.17734 18.171 3.93333 18.1389V13.8867H3.93328ZM19.2161 22.4356H6.06368C5.51836 22.4356 5.07434 21.9916 5.07434 21.4456V20.3009H17.3352C17.9035 20.3009 18.3645 19.8405 18.3645 19.2717V13.0293C18.3645 12.4604 17.9036 12 17.3352 12H5.07434V2.55436C5.07434 2.00989 5.51836 1.56587 6.06368 1.56587L15.1178 1.55641V4.90314C15.1178 5.88068 15.911 6.67459 16.8892 6.67459L20.1677 6.66518L20.2046 21.4455C20.2046 21.9916 19.7613 22.4356 19.2161 22.4356ZM8.20692 16.0261C8.20692 14.7286 9.03543 13.7589 10.3138 13.7589C11.6436 13.7589 12.3692 14.7544 12.3692 15.9492C12.3692 17.3684 11.5086 18.229 10.2431 18.229C8.95857 18.229 8.20692 17.2595 8.20692 16.0261ZM15.23 17.4132C15.5253 17.4132 15.8528 17.3488 16.0458 17.2719L16.1933 18.0361C16.0137 18.1263 15.6089 18.2228 15.0825 18.2228C13.5856 18.2228 12.8148 17.2916 12.8148 16.0584C12.8148 14.581 13.8684 13.7589 15.1785 13.7589C15.6861 13.7589 16.0713 13.8617 16.2447 13.9511L16.0458 14.7286C15.8465 14.6446 15.5703 14.5678 15.2236 14.5678C14.4462 14.5678 13.8425 15.0369 13.8425 16.0003C13.8426 16.8672 14.3564 17.4132 15.23 17.4132Z"
        fill="currentColor"
      />
      <path
        d="M6.72098 15.9234C6.72725 15.0236 6.2005 14.549 5.35905 14.549C5.14052 14.549 4.9993 14.5678 4.91577 14.5873V17.4195C4.9993 17.4391 5.1343 17.4391 5.2563 17.4391C6.1428 17.4453 6.72098 16.9574 6.72098 15.9234Z"
        fill="currentColor"
      />
      <path
        d="M11.3353 15.9808C11.3353 15.2041 10.9626 14.5358 10.2883 14.5358C9.6266 14.5358 9.24097 15.1656 9.24097 16.0067C9.24097 16.8548 9.63949 17.4517 10.2946 17.4517C10.9564 17.4517 11.3353 16.8225 11.3353 15.9808Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconDocFile24
