import React, { FC } from 'react'

import { Chip as MUChip } from '@material-ui/core'

import { IChipProps } from './types'

import { useChipStyles } from './styles'

export const Chip: FC<IChipProps> = ({ color = 'default', style, ...restProps }) => {
  const classes = useChipStyles({ color: color })

  return <MUChip style={{ ...style }} {...restProps} classes={classes} />
}
