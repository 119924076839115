import TableManager from './manager.interface'

import { TableState, ThunkAction } from './types'

export abstract class ThunkTableManager<TS extends TableState = TableState>
  implements TableManager<TS> {
  public key: string

  constructor(props: { key: string }) {
    this.key = props.key
  }

  public actions = {
    resetParams: () => ({
      type: `[TABLE-${this.key}.PARAMS] reset`,
    }),
    changeParams: (payload: Partial<TS['params']>) => ({
      type: `[TABLE-${this.key}.PARAMS] change`,
      payload,
    }),
    changeVisual: (payload: Partial<TS['visual']>) => ({
      type: `[TABLE-${this.key}.VISUAL] change`,
      payload,
    }),
    resetVisual: () => ({
      type: `[TABLE-${this.key}.VISUAL] reset`,
    }),
  }

  public updateState = (newState: Partial<TS['params']>, fetch = true): ThunkAction => (
    dispatch,
    getState,
  ) => {
    const newParams = { ...getState()[this.key].params, ...newState }
    dispatch(this.actions.changeParams(newParams))
    if (fetch) {
      this.onChangeParams(newParams)(dispatch, getState)
    }
  }

  public update(): ThunkAction {
    return (dispatch, getState) => {
      const params = getState()[this.key].params

      this.onChangeParams(params)(dispatch, getState)
    }
  }

  public abstract onChangeParams(params: TS['params']): ThunkAction

  public changeQuery(query: Partial<TS['params']['query']>): ThunkAction {
    return (dispatch, getState) => {
      const newState = { ...getState()[this.key].params, query }

      dispatch(this.actions.changeParams(newState))
      this.onChangeParams(newState)(dispatch, getState)
    }
  }

  public changePage(page: Partial<TS['params']['page']>): ThunkAction {
    return (dispatch, getState) => {
      const newState = { ...getState()[this.key].params, page }

      dispatch(this.actions.changeParams(newState))
      this.onChangeParams(newState)(dispatch, getState)
    }
  }

  public changeViewTable(viewTable: TS['params']['viewTable']): ThunkAction {
    return (dispatch, getState) => {
      const newState = {
        ...getState()[this.key].params,
        viewTable,
      }

      dispatch(this.actions.changeParams(newState))
      // Пока что вся логика у нас на фронте
      // this.onChangeParams(newState)(dispatch, getState);
    }
  }

  public changeFilterTable(filter: Partial<TS['params']['filter']>): ThunkAction {
    return (dispatch, getState) => {
      const newState = { ...getState()[this.key].params, filter, page: 0 }

      dispatch(this.actions.changeParams(newState))
      this.onChangeParams(newState)(dispatch, getState)
    }
  }

  public changeSortTable(sort: Partial<TS['params']['sort']>): ThunkAction {
    return (dispatch, getState) => {
      const newState = { ...getState()[this.key].params, sort }

      dispatch(this.actions.changeParams(newState))
      this.onChangeParams(newState)(dispatch, getState)
    }
  }

  public resetVisual(): ThunkAction {
    return (dispatch) => {
      dispatch(this.actions.resetVisual())
    }
  }

  public resetParams(): ThunkAction {
    return (dispatch) => {
      dispatch(this.actions.resetParams())
    }
  }

  public showLoading(loading: TS['visual']['loading']): ThunkAction {
    return (dispatch, getState) => {
      dispatch(this.actions.changeVisual({ ...getState()[this.key].visual, loading }))
    }
  }
}
