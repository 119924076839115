// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import { ResponseAllWithNodes } from 'interfaces/responses/Dictionary'
import { isEqual } from 'lodash'
import service from 'services/service'

export type UseUpdateDictionaryValueReturn = {
  isLoading: boolean
  updateDictionaryValue: (args: {
    value: Record<string, unknown> & {
      id: number
    }
    newValue: Record<string, unknown>
    start: boolean
    finish?: boolean
  }) => Promise<void>
}

export const useUpdateDictionaryValue = (
  dictionaryType: string,
  onSuccess: (value: ResponseAllWithNodes['content']['content'][number]) => void,
): UseUpdateDictionaryValueReturn => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const updateDictionaryValue = useCallback<
    UseUpdateDictionaryValueReturn['updateDictionaryValue']
  >(
    async ({ value, newValue, start, finish = false }) => {
      try {
        setIsLoading(true)

        const payloadData = Object.entries(newValue).reduce((acc, [key, newVal]) => {
          if (newVal === '') {
            return {
              ...acc,
              [key]: null,
            }
          }
          return {
            ...acc,
            [key]: isEqual(String(value[key]), String(newVal)) ? undefined : newVal,
          }
        }, {} as Record<string, unknown>)

        if (start) {
          payloadData.startDateTime = null
        }

        if (finish) {
          payloadData.finishDateTime = null
        }

        const payloadDataWithDate = {
          ...payloadData,
          start,
          finish,
          type: dictionaryType,
        }

        const updatedDictionaryValue = await service.updateDictionaryValue(
          value.id,
          payloadDataWithDate,
        )

        onSuccess(updatedDictionaryValue.content)
        // alert.success('Значение успешно обновлено');
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess, dictionaryType],
  )

  return {
    isLoading,
    updateDictionaryValue,
  }
}
