import React, { ReactElement, useContext } from 'react'

import { useGetCurrentTheme } from '../../CommonWrapper'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'

import { useTableStyles } from '../styles'

// any использован в силу необходимости соответствовать свойству tableComponent в Table из @devexpress/dx-react-grid-material-ui
type TableBaseProps = any & StyledProps

export function TableBase(props: TableBaseProps): ReactElement<TableBaseProps> {
  const context = useContext(CustomFieldsCtx)
  const theme = useGetCurrentTheme()
  const classes = useTableStyles({ theme })

  const [className, style] = useStyleProps(props, useContext(CustomFieldsCtx).style?.table)
  return (
    <DevexpressTable.Table
      {...props}
      className={clsx(className, classes.base, {
        ['Base_columns_narrow']: context.narrowColumns,
        ['Base_rows_narrow']: context.narrowRows,
        ['Base_columns_super_narrow']: context.superNarrowColumns,
        ['Base_rows_super_narrow']: context.superNarrowRows,
        ['Base_rows_ultra_narrow']: context.ultraNarrowRows,
      })}
      style={style}
    />
  )
}
