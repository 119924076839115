import React, { useContext, useState } from 'react'

import { Button } from '../../../../'
import { Drawer } from '../../../../Drawer'
import { SortAscendingSvgIcon } from '../../../../Icon'
import { SortDescendingSvgIcon } from '../../../../Icon'
import { prepareColumnOptions } from '../../../utils'
import { SortContext } from '../context'
import { ISortContext, SortItem } from '../typings'
import { initialValues } from '../utils/enums'
import { hashRenderTitle } from '../utils/helpers'
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface CreateSortTabProps {
  values?: SortItem
}

export const CreateSortTab: React.FC<CreateSortTabProps> = ({ values }) => {
  const {
    /** уже добавленные значения сортировки */
    sort,
    visible,
    onClose,
    setSort,
    setTab,
    /** список колонок таблицы as-is */
    header,
  } = useContext<ISortContext>(SortContext)

  const [form, setForm] = useState<SortItem>(values || initialValues)

  function handler() {
    const newSort: SortItem[] = [...sort]
    if (values) {
      const index = newSort.findIndex((e) => e.label === values.label)
      newSort[index] = form
      setSort([...newSort])
    } else {
      setSort([...newSort, form])
    }
    setTab({ tab: 0 })
  }

  return (
    <Drawer
      header={{
        title: 'Добавление сортировки',
        onBack: () => setTab({ tab: 0 }),
      }}
      footer={{
        onSubmit: {
          handler,
          label: values ? 'Сохранить' : 'Применить',
          disabled: !form.label,
        },
        onCancel: { handler: () => setTab({ tab: 0 }), label: 'Отмена' },
      }}
      open={visible}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Autocomplete<any>
            value={values?.label}
            options={prepareColumnOptions(header, sort)}
            onChange={(_, v) => {
              const newValue = v ? v.value : ''
              setForm({ ...form, label: newValue as string })
            }}
            getOptionLabel={(option) => option.label || hashRenderTitle(form, header)}
            renderInput={(params) => (
              <TextField {...params} label="Столбец" variant="outlined" size="small" />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              if (form.method === 'ASC') {
                setForm({ ...form, method: 'DESC' })
              } else {
                setForm({ ...form, method: 'ASC' })
              }
            }}
          >
            {form.method === 'ASC' ? <SortAscendingSvgIcon /> : <SortDescendingSvgIcon />}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}
