import { Theme, Tooltip, withStyles } from '@material-ui/core'

export const TooltipStyled = withStyles((theme: Theme) => ({
  tooltip: {
    padding: '8px 12px',
    backgroundColor: theme.palette.text.primary,
    borderRadius: 4,
    color: theme.palette.background.paper,
    fontSize: '0.875rem',
  },
}))(Tooltip)
