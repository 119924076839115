import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconLightningOutline32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M13.8647 3.5L8.5 16.9118H13.8647L12.9706 26.7471L23.7 12.4412H17.4412L21.9118 3.5H13.8647Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconLightningOutline32
