import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardRedTag = React.forwardRef<HTMLDivElement, CardProps>(({ highlighted, barcode }, ref) => {
  const referenceData: ReferenceDataType[] = [
    {
      title: '',
      data: {
        left: [
          { name: 'symbol', title: 'Условное обозначение' },
          { name: '', title: '№ розетты' },
          { name: 'steel_grade', title: 'Марка стали' },
          { name: 'swim_number', title: '№ плавки' },
          { name: 'brigade', title: 'Бригада' },
          { name: 'order_code', title: 'Шифр заказа' },
          { name: 'beg_diameter', title: 'Диаметер' },
          { name: 'tu_gost', title: 'ТУ, ГОСТ' },
        ],
        right: [
          { name: '', title: 'Предел прочности Н/мм2' },
          { name: '', title: 'Плотность цинка Н/мм2' },
          { name: '', title: 'дата изготовления' },
          { name: '', title: 'Штамп ОТК' },
        ],
      },
    },
  ]

  const renderBlock = (data?: Block[]) => {
    if (!data) return null
    return data
      .map(({ name, title }) => {
        const newDescription: string =
          // @ts-ignore
          highlighted[0][name] !== undefined ? highlighted[0][name] : '______'
        return { title, description: newDescription }
      })
      .map(({ title, description }) => (
        <Box justifyContent="space-between" key={title}>
          <Typography variant="Heading3">{title}</Typography>
          <Typography variant="Heading3">{description}</Typography>
        </Box>
      ))
  }

  return (
    <div className="print-block print-block_red-tag" ref={ref}>
      <Box
        flexDirection="column"
        alignItems="start"
        style={{
          position: 'relative',
        }}
      >
        {referenceData.map(({ title, data }, index) => (
          <div key={`title${index}`}>
            <Typography variant="Heading3">{title}</Typography>
            <Box justifyContent="space-between">
              {Object.values(data).map((value, i) => (
                <Box
                  style={{
                    width: '240px',
                    maxHeight: '210px',
                    paddingRight: '10px',
                    marginRight: '10px',
                    borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                    lineHeight: '600',
                    fontSize: '20px',
                  }}
                  flexDirection="column"
                  key={i}
                >
                  <div
                    style={{
                      lineHeight: '600',
                      fontSize: '20px',
                    }}
                  >
                    {renderBlock(value)}
                  </div>
                </Box>
              ))}
            </Box>
          </div>
        ))}

        <div className={styles.barcodeContainer}>
          <Barcode height={45} width={3} fontSize={22} value={barcode} />
        </div>
        <div className={styles.qrCodeContainer}>
          <QRCode size={100} value={String(barcode)} />
        </div>
      </Box>
    </div>
  )
})

export default CardRedTag
