import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconDowntimeAnalysis32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M16 29C22.5647 29 28 23.8483 28 17.6262C28 15.0281 27.0471 12.6195 25.4588 10.6905L26.3765 9.80954C26.6471 9.55308 26.8 9.2074 26.8 8.90633C26.8 8.28189 26.2706 7.78011 25.5882 7.78011C25.1882 7.78011 24.9059 7.89161 24.6235 8.14808L23.7294 8.98439C21.9294 7.53479 19.6941 6.56467 17.2471 6.31936V5.22658C17.2471 4.55754 16.6941 4 15.9882 4C15.3059 4 14.7294 4.55754 14.7294 5.22658V6.31936C8.74118 6.93265 4 11.8167 4 17.6262C4 23.8483 9.44706 29 16 29ZM16 27.1044C10.4471 27.1044 6.01176 22.8894 6.01176 17.6262C6.01176 12.3742 10.4353 8.14808 15.9882 8.14808C21.5412 8.14808 25.9882 12.3742 26 17.6262C26.0118 22.8894 21.5529 27.1044 16 27.1044ZM16 19.4326C17.0471 19.4326 17.8941 18.6298 17.8941 17.6374C17.8941 16.9349 17.4706 16.3216 16.8118 16.0205V10.4786C16.8118 10.0437 16.4471 9.70919 15.9882 9.70919C15.5294 9.70919 15.1765 10.0437 15.1765 10.4786V16.0094C14.5294 16.3327 14.1059 16.9349 14.1059 17.6374C14.1059 18.6298 14.9412 19.4326 16 19.4326Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconDowntimeAnalysis32
