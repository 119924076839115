import React, { FC, ReactElement, useEffect, useState } from 'react'

import { useGetCurrentTheme } from '../CommonWrapper'
import { SelectSvgIcon } from '../Icon'
import IconDone24 from '../Icon/icons/IconDone24'
import { MenuItem } from '../MenuItem'
import { PseudoInput } from '../PseudoInput'
import { FormControl, FormHelperText, InputLabel, Select as MUSelect } from '@material-ui/core'
import clsx from 'clsx'

import { SelectProps } from './types'

import { useSelectStyles } from './styles'

// TODO: можно улучшить, также необходимо учесть все минусы при написании своего компонента
export const Select: FC<SelectProps> = (props) => {
  const {
    pseudo = false,
    colored = false,
    multiple = false,
    value,
    children,
    label,
    pseudoChildren,
    formControlClassName,
    disabled,
    labelClassName,
    error,
    compact = false,
    helperText = '',
  } = props
  const classes = useSelectStyles({ compact, disabled })
  const theme = useGetCurrentTheme()

  const [selectedMenuIds, setSelectedMenuIds] = useState([])

  const multipleIcon = (item: ReactElement) => {
    return (value as any).indexOf(item.props.value) > -1 ? (
      <IconDone24 color="primary" />
    ) : undefined
  }

  const renderElements = () => {
    return (children as any) ? (
      (children as any).map((item) => {
        return (
          <MenuItem
            key={item.props.value}
            value={item.props.value}
            disabled={item.props.disabled}
            className={classes.menuItem}
          >
            {item.props.children}
            {multiple ? multipleIcon(item) : undefined}
          </MenuItem>
        )
      })
    ) : (
      <MenuItem key="1" className={classes.emptyMessage}>
        Ничего не найдено
      </MenuItem>
    )
  }

  useEffect(() => {
    if (children && multiple) {
      setSelectedMenuIds((children as any).map((item) => item.props))
    }
  }, [children, multiple])

  return pseudo ? (
    <PseudoInput label={label as string}>{pseudoChildren}</PseudoInput>
  ) : (
    <FormControl
      variant="outlined"
      className={clsx(classes.formControl, formControlClassName, colored && classes.colored)}
      style={props.formControlStyle}
    >
      {label && (
        <InputLabel
          id="demo-simple-select-outlined-label"
          className={clsx(
            classes.inputLabelRoot,
            labelClassName,
            error && classes.selectClassErrorLabel,
          )}
          style={props.labelStyle}
        >
          {label}
        </InputLabel>
      )}
      {multiple ? (
        <MUSelect
          IconComponent={SelectSvgIcon}
          classes={{
            ...props.classes,
            root: clsx(props.classes?.root, classes.root, {
              [classes.light]: theme === 'light',
              [classes.dark]: theme === 'dark',
              [classes.colored]: colored,
            }),
            icon: clsx(classes.icon, props.classes?.icon),
          }}
          renderValue={(value: any) => {
            const items = value.map((val) => {
              const time: any = selectedMenuIds.find((el: any) => el.value === val)
              if (!!time) {
                return time.children
              }
            })
            return items.join(', ')
          }}
          {...props}
        >
          {renderElements()}
        </MUSelect>
      ) : (
        <MUSelect
          IconComponent={SelectSvgIcon}
          classes={{
            ...props.classes,
            root: clsx(props.classes?.root, classes.root, {
              [classes.light]: theme === 'light',
              [classes.dark]: theme === 'dark',
              [classes.colored]: colored,
            }),
            icon: clsx(classes.icon, props.classes?.icon),
          }}
          {...props}
        >
          {renderElements()}
        </MUSelect>
      )}
      {helperText.length ? (
        <FormHelperText
          classes={{
            ...props.classes,
            root: classes.helperTextRoot,
            error: classes.error,
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}
