import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const Icon3Bunker24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 3.75C5.38324 3.75 0 9.13324 0 15.75V19.35C0 19.5708 0.178782 19.75 0.399986 19.75H7.60005C7.82123 19.75 8.00002 19.5708 8.00002 19.35V15.75C8.00002 13.5444 9.7944 11.75 12.0001 11.75C14.2057 11.75 16 13.5444 16 15.75V17.75C16 17.9708 16.1789 18.15 16.4 18.15C16.6212 18.15 16.8001 17.9708 16.8001 17.75V15.75C16.8001 13.1032 14.6468 10.95 12.0001 10.95C9.35325 10.95 7.20003 13.1032 7.20003 15.75V18.95H0.800017V15.75C0.800017 9.57438 5.824 4.55002 12.0001 4.55002C18.1761 4.55002 23.2001 9.57438 23.2001 15.75V18.95H15.2001V15.75C15.2001 13.9856 13.7644 12.55 12.0001 12.55C10.2356 12.55 8.80005 13.9856 8.80005 15.75V19.35C8.80005 19.5708 8.97878 19.75 9.20002 19.75C9.4212 19.75 9.6 19.5708 9.6 19.35V15.75C9.6 14.4264 10.6764 13.35 12 13.35C13.3236 13.35 14.4 14.4264 14.4 15.75V19.35C14.4 19.5708 14.5788 19.75 14.8 19.75H23.6C23.8212 19.75 24 19.5708 24 19.35V15.75C24.0001 9.13324 18.6169 3.75 12.0001 3.75ZM2.3999 14.15C2.3999 15.0324 3.11752 15.75 3.99989 15.75C4.88232 15.75 5.59988 15.0324 5.59988 14.15C5.59988 13.2676 4.88228 12.5501 3.99989 12.5501C3.11752 12.5501 2.3999 13.2676 2.3999 14.15ZM4.79992 14.15C4.79992 14.5912 4.44071 14.9501 3.99989 14.9501C3.5587 14.9501 3.19987 14.5913 3.19987 14.15C3.19987 13.7088 3.55866 13.35 3.99989 13.35C4.44071 13.35 4.79992 13.7088 4.79992 14.15ZM20.0001 12.5501C20.8825 12.5501 21.6001 13.2676 21.6001 14.15C21.6001 15.0324 20.8825 15.75 20.0001 15.75C19.1177 15.75 18.4001 15.0324 18.4001 14.15C18.4001 13.2676 19.1177 12.5501 20.0001 12.5501ZM20.0001 13.35C19.5589 13.35 19.2001 13.7088 19.2001 14.15C19.2001 14.5912 19.5589 14.9501 20.0001 14.9501C20.4409 14.9501 20.8001 14.5913 20.8001 14.15C20.8001 13.7088 20.4409 13.35 20.0001 13.35Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon3Bunker24
