import React, { FC } from 'react'

import { DialogContentProps, DialogContent as MUIDialogContent } from '@material-ui/core'

import { useDialogContentStyles } from './styles'

export const DialogContent: FC<DialogContentProps> = ({ ...props }) => {
  const classes = useDialogContentStyles()

  return (
    <MUIDialogContent
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  )
}
