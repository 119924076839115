import React, { FC } from 'react'

import { ISvgProps } from '../types'

export const SunSvgIcon: FC<ISvgProps> = (): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 13C17.65 13 19 14.35 19 16C19 17.65 17.65 19 16 19C14.35 19 13 17.65 13 16C13 14.35 14.35 13 16 13ZM16 11C13.24 11 11 13.24 11 16C11 18.76 13.24 21 16 21C18.76 21 21 18.76 21 16C21 13.24 18.76 11 16 11ZM6 17H8C8.55 17 9 16.55 9 16C9 15.45 8.55 15 8 15H6C5.45 15 5 15.45 5 16C5 16.55 5.45 17 6 17ZM24 17H26C26.55 17 27 16.55 27 16C27 15.45 26.55 15 26 15H24C23.45 15 23 15.45 23 16C23 16.55 23.45 17 24 17ZM15 6V8C15 8.55 15.45 9 16 9C16.55 9 17 8.55 17 8V6C17 5.45 16.55 5 16 5C15.45 5 15 5.45 15 6ZM15 24V26C15 26.55 15.45 27 16 27C16.55 27 17 26.55 17 26V24C17 23.45 16.55 23 16 23C15.45 23 15 23.45 15 24ZM9.99 8.58C9.6 8.19 8.96 8.19 8.58 8.58C8.19 8.97 8.19 9.61 8.58 9.99L9.64 11.05C10.03 11.44 10.67 11.44 11.05 11.05C11.43 10.66 11.44 10.02 11.05 9.64L9.99 8.58ZM22.36 20.95C21.97 20.56 21.33 20.56 20.95 20.95C20.56 21.34 20.56 21.98 20.95 22.36L22.01 23.42C22.4 23.81 23.04 23.81 23.42 23.42C23.81 23.03 23.81 22.39 23.42 22.01L22.36 20.95ZM23.42 9.99C23.81 9.6 23.81 8.96 23.42 8.58C23.03 8.19 22.39 8.19 22.01 8.58L20.95 9.64C20.56 10.03 20.56 10.67 20.95 11.05C21.34 11.43 21.98 11.44 22.36 11.05L23.42 9.99ZM11.05 22.36C11.44 21.97 11.44 21.33 11.05 20.95C10.66 20.56 10.02 20.56 9.64 20.95L8.58 22.01C8.19 22.4 8.19 23.04 8.58 23.42C8.97 23.8 9.61 23.81 9.99 23.42L11.05 22.36Z"
        fill="currentColor"
      />
    </svg>
  )
}
