import React, { FC, Suspense } from 'react'

import { iconsMap } from './helpers/isonsMap'
import { IconContainer } from './subcomponents/IconContainer'
import { IconFont } from './subcomponents/IconFont'

import { TIconProps } from './types'

export const Icon: FC<TIconProps> = ({ ...props }) => {
  const { color = 'inherit', name, containerSize = 24, htmlColor } = props
  const isLazy = iconsMap[name]

  let IconComponent, iconSize
  if (isLazy) {
    IconComponent = React.lazy(() => import(`../Icon/icons/${name}.tsx`))
    iconSize = Number(name.substr(-2))
  }

  return isLazy ? (
    <IconContainer containerSize={containerSize || iconSize}>
      <Suspense fallback={<></>}>
        <IconComponent htmlColor={htmlColor} color={color} />
      </Suspense>
    </IconContainer>
  ) : (
    <IconFont {...props} />
  )
}
;(() => {
  return (
    <>
      <Icon name="IconZoomOut24" />
      <Icon name="zoom" containerSize={24} size={24} />
      <Icon name="man" />
      <Icon name="child" />
      <Icon name="IconWIFI24" />
    </>
  )
})()
