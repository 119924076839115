import React from 'react'

import { Box } from '../../Box'
import { MenuItem } from '../../MenuItem'
import { Select } from '../../Select'
import { Typography } from '../../Typography'

interface TablePageSelectProps {
  pageSize?: number
  onPageSizeChange?: (pageSize: number) => void
  pageSizeList?: { name: string; value: number }[]
}

export const TablePageSelect: React.FC<TablePageSelectProps> = (props) => {
  const { pageSize, onPageSizeChange, pageSizeList } = props
  return (
    <Box alignItems="center">
      <Typography style={{ padding: '0 12px 0 0' }} variant="Body1-Medium">
        Отображать
      </Typography>
      <Select
        value={pageSize}
        formControlStyle={{ width: '164px' }}
        onChange={(e) =>
          onPageSizeChange && e.target.value && onPageSizeChange(e.target.value as number)
        }
      >
        {pageSizeList?.map((item) => (
          <MenuItem value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
    </Box>
  )
}
