import React from 'react'

import { FilterTable, SortTable, ViewTable } from '.'
import { hiddenColumnName } from '../Table/utils/getHoverColumn'
import Grid from '@material-ui/core/Grid'

import { ExcludeColumnNames, TableToolbarProps } from './types'

export function TableToolbar<Row extends Record<string, unknown>>({
  filter = true,
  sort = true,
  view = true,
  ...props
}: TableToolbarProps<Row>): JSX.Element {
  const defaultExcludeColumnNames: ExcludeColumnNames<Row> = [hiddenColumnName]
  const excludeColumnNames = defaultExcludeColumnNames.concat(props.excludeColumnNames || [])

  const componentProps = {
    ...props,
    excludeColumnNames,
  }

  return (
    <Grid container spacing={3} justify="space-between">
      {filter && (
        <Grid item>
          <FilterTable<Row> {...componentProps} />
        </Grid>
      )}
      {sort && (
        <Grid item>
          <SortTable<Row> {...componentProps} />
        </Grid>
      )}
      {view && (
        <Grid item>
          <ViewTable<Row> {...componentProps} />
        </Grid>
      )}
    </Grid>
  )
}
