import { makeStyles } from '@material-ui/core'

export const useDatePickerInputStyles = makeStyles(() => ({
  calendar: {
    display: 'flex',
    zIndex: 5,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
  },
}))
