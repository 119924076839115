import { makeStyles } from '@material-ui/core'

export const useHomeStyles = () =>
  makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    logo: {
      width: 298,
      height: 161,
      marginBottom: 71,
    },
    title: {
      marginBottom: 32,
    },
  }))()

export default useHomeStyles
