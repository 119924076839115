import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CustomCheckBoxOutlineBlankIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <rect x="3.2" y="3.1" rx="4" ry="4" width="17.5" height="17.5" fill="currentColor" />
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    </SvgIcon>
  )
}
