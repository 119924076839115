import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Button, PrintSvgIcon, Typography } from '../../../../ui-kit/components'
import ModalPrintCards from '../../../../ui-kit/frontend-components/ModalPrintCards'
import useToggle from '../../../../ui-kit/hooks/useToggle'
import NewModalPrintCards from '../Table'

type Props = {
  shopSchedule?: { id: number; start: string; end: string }[] | null
  title: string
  shiftId?: number
  reportActive?: boolean
  mainShiftId?: number
  printCardActive?: boolean
  currentRegion: string
  subSection?: string
  permissions?: {
    sample?: boolean
    request?: boolean
  }
  rows?: any
  page: number
  setPage: (isGet: number) => void
}

const ControlButtons: FC<Props> = ({
  title,
  mainShiftId = 0,
  printCardActive,
  subSection,
  rows,
  page,
  setPage,
}) => {
  const [isOpen, setIsOpen] = useToggle(false)

  const { pathname }: { pathname: string } = useLocation()
  const pathnameRoot = pathname.split('/')[1]

  return (
    <>
      <Box>
        <Typography variant="Heading4">{title}</Typography>
        <Box alignItems="center" spacing={3}>
          <Button onClick={setIsOpen} variant="contained" color="primary">
            <Box spacing={1} alignItems="center">
              <PrintSvgIcon />
              Печать сопр. карт
            </Box>
          </Button>
        </Box>
      </Box>
      {isOpen && (
        <NewModalPrintCards
          shiftId={mainShiftId}
          subSection={subSection}
          isOpen={isOpen}
          onClose={setIsOpen}
          tableRows={rows}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default ControlButtons
