import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconStackExpanded24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path d="M19.0771 7L5.07715 7L12.0771 18L19.0771 7Z" fill="currentColor" />
    </svg>
  )
}

export default IconStackExpanded24
