export const iconsMap = {
  CollectionAttributeMapping24: true,
  DocumentLinkAttributeMapping24: true,
  Icon2Bunker24: true,
  Icon2Dvr24: true,
  Icon2Education24: true,
  Icon2Notifications24: true,
  Icon2Search24: true,
  Icon2Settings24: true,
  IconAdd24: true,
  IconAddCircleOutline24: true,
  IconAnalysisNP24: true,
  IconAnalyticalUI24: true,
  IconAnalytics24: true,
  IconApplications24: true,
  IconArrowBack24: true,
  IconArrowBack32: true,
  IconArrowDownward24: true,
  IconArrowForward24: true,
  IconArrowUpward24: true,
  IconArticle24: true,
  IconAttachment24: true,
  IconAutorenew24: true,
  IconBackspace24: true,
  IconBalance24: true,
  IconBarChart24: true,
  IconBarcode24: true,
  IconVideoCamOn24: true,
  IconBlock24: true,
  IconBunker24: true,
  IconCalendarPause24: true,
  IconCalendarToday24: true,
  IconCancel24: true,
  IconChart24: true,
  IconChat24: true,
  IconChatBubbleOutline24: true,
  IconChemical24: true,
  IconChevronLeft24: true,
  IconChevronLeft32: true,
  IconChevronRight24: true,
  IconCircle24: true,
  IconClose24: true,
  IconClose32: true,
  IconCloudDone24: true,
  IconCoins24: true,
  IconContains24: true,
  IconContentCopy24: true,
  IconControlMaps24: true,
  IconCrane24: true,
  IconDarkMode24: true,
  IconData24: true,
  IconDelete24: true,
  IconDerictories24: true,
  IconDesktop24: true,
  IconDesktopOff24: true,
  IconDirectionsTransit24: true,
  IconDivide24: true,
  IconDNS24: true,
  IconDocs24: true,
  IconDocService24: true,
  IconDoesNotContain24: true,
  IconDoesNotEquals24: true,
  IconDone24: true,
  IconDoneAll24: true,
  IconDotIndicator24: true,
  IconDownload24: true,
  IconDowntimeAnalysis24: true,
  IconDragIndicator24: true,
  IconDvr24: true,
  IconDynamicDown16: true,
  IconDynamicDown24: true,
  IconDynamicUp16: true,
  IconDynamicUp24: true,
  IconEdit24: true,
  IconEducation24: true,
  IconEndsWith24: true,
  IconEquals24: true,
  IconErrorOutline16: true,
  IconErrorOutline24: true,
  IconErrorOutline32: true,
  IconExit24: true,
  IconExpand24: true,
  IconExpandLess24: true,
  IconExpandMore24: true,
  IconExternalLink24: true,
  IconExternalLink16: true,
  IconFavorite24: true,
  IconFeedback24: true,
  IconFeedback32: true,
  IconFilterList24: true,
  IconFilterPanel24: true,
  IconFolder24: true,
  IconFolderClose24: true,
  IconFolderTopic24: true,
  IconForbidden24: true,
  IconFormatListNumbered24: true,
  IconFullscreen24: true,
  IconFullscreenExit24: true,
  IconGreaterThan24: true,
  IconGreaterThanOrEqualTo24: true,
  IconHelmet24: true,
  IconHelp24: true,
  IconHowToReg24: true,
  IconImportExport24: true,
  IconInfo16: true,
  IconInfo24: true,
  IconInput24: true,
  IconInsertDriveFile24: true,
  IconInteraction24: true,
  IconKeyboardDoubleArrowRight24: true,
  IconKovsh24: true,
  IconKPE24: true,
  IconLessThan24: true,
  IconLessThanOrEqualTo24: true,
  IconLightMode24: true,
  IconListAlt24: true,
  IconLock24: true,
  IconLockOpen24: true,
  IconLotok24: true,
  IconMap24: true,
  IconMaxOrMin24: true,
  IconMenu24: true,
  IconMetal24: true,
  IconMetalTrackingSystem24: true,
  IconMinus24: true,
  IconMixer24: true,
  IconMoreVert24: true,
  IconMultipleChoice24: true,
  IconMultipleStop24: true,
  IconNotifications24: true,
  IconNumberOne16: true,
  IconNumberOne24: true,
  IconPanTool24: true,
  IconPechiVTO24: true,
  IconPermIdentity24: true,
  IconPlaylistAdd24: true,
  IconPoll24: true,
  IconPrint24: true,
  IconPushPin24: true,
  IconPushPinFilled24: true,
  IconQuality24: true,
  IconQuestionMark24: true,
  IconRedo24: true,
  IconRoll24: true,
  IconSave24: true,
  IconSaveAlt24: true,
  IconScales24: true,
  IconScanCode24: true,
  IconSchedule24: true,
  IconSearch24: true,
  IconSend24: true,
  IconSettings24: true,
  IconSettingsAlt24: true,
  IconSlab24: true,
  IconSort24: true,
  IconSortAscended24: true,
  IconStackCollapsed24: true,
  IconStackExpanded24: true,
  IconStar16: true,
  IconStar24: true,
  IconStarBorder24: true,
  IconStartWith24: true,
  IconStop24: true,
  IconTableChart24: true,
  IconTablet24: true,
  IconTabletOff24: true,
  IconTask24: true,
  IconTick24: true,
  IconTrendIndicatorDown24: true,
  IconTrendIndicatorNormal24: true,
  IconTrendIndicatorUp24: true,
  IconTrolley24: true,
  IconTune24: true,
  IconTurn24: true,
  IconUndo24: true,
  IconUpload24: true,
  IconVerticalAlignTop24: true,
  IconVisibility24: true,
  IconVisibilityFilled24: true,
  IconVisibilityOff24: true,
  IconVisibilityOffFilled24: true,
  IconWhatshot24: true,
  IconWIFI24: true,
  IconZoomIn24: true,
  IconZoomOut24: true,
  SimpleAttributeMapping24: true,
  TableAttributeMapping24: true,
  ViewMapping24: true,
  IconVideoCamOff24: true,
  IconPhotoCamOn24: true,
  IconMnemo24: true,
  IconMnemo32: true,
  IconSchedule16: true,
  IconStackCollapsed16: true,
  IconStackExpanded16: true,
  IconInsertVideoFile24: true,
  IconMetalQualityCheck32: true,
  IconDotPlot32: true,
  IconMetalQualityCheck24: true,
  IconDotPlot24: true,
  IconAdd16: true,
  IconAdd32: true,
  IconLightning16: true,
  IconLightning32: true,
  IconLightning24: true,
  IconLightningOutline16: true,
  IconLightningOutline24: true,
  IconLightningOutline32: true,
  IconListSheets24: true,
  IconListSheets16: true,
  IconMetallalomScrap24: true,
  IconMetallalomScrap16: true,
  IconMetallalomScrap32: true,
  IconMetallalomScrapFilled16: true,
  IconMetallalomScrapFilled24: true,
  IconMetallalomScrapFilled32: true,
  IconTemperatureFilled16: true,
  IconTemperatureFilled24: true,
  IconTemperatureFilled32: true,
  IconTemperatureStroke16: true,
  IconTemperatureStroke24: true,
  IconTemperatureStroke32: true,
  IconBugReportFilled16: true,
  IconBugReportFilled24: true,
  IconBugReportFilled32: true,
  IconPlayOutlined16: true,
  IconPlayOutlined24: true,
  IconPlayOutlined32: true,
  IconNotificationsBellOffFilled16: true,
  IconNotificationsBellOffFilled24: true,
  IconNotificationsBellOffFilled32: true,
  IconTelegramFilled16: true,
  IconTelegramFilled24: true,
  IconTelegramFilled32: true,
  IconBusinessOutlined16: true,
  IconBusinessOutlined24: true,
  IconBusinessOutlined32: true,
  IconFormatLineSpacingOutlined16: true,
  IconFormatLineSpacingOutlined24: true,
  IconFormatLineSpacingOutlined32: true,
  IconHomeOutlined16: true,
  IconHomeOutlined24: true,
  IconHomeOutlined32: true,
  IconHourglassEmptyOutlined16: true,
  IconHourglassEmptyOutlined24: true,
  IconHourglassEmptyOutlined32: true,
  IconMarkUnreadMailboxOutlined16: true,
  IconMarkUnreadMailboxOutlined24: true,
  IconMarkUnreadMailboxOutlined32: true,
  IconPauseFilled16: true,
  IconPauseFilled24: true,
  IconPauseFilled32: true,
  IconReplayOutlined16: true,
  IconReplayOutlined24: true,
  IconReplayOutlined32: true,
  IconWarningFilled16: true,
  IconWarningFilled24: true,
  IconWarningFilled32: true,
  IconAutorenewSystemOutlined16: true,
  IconAutorenewSystemOutlined24: true,
  IconAutorenewSystemOutlined32: true,
  IconHelperMailOutlined16: true,
  IconHelperMailOutlined24: true,
  IconHelperMailOutlined32: true,
  IconJsonOutlined16: true,
  IconJsonOutlined24: true,
  IconJsonOutlined32: true,
  IconRepairWrenchFrom16: true,
  IconRepairWrenchFrom24: true,
  IconRepairWrenchFrom32: true,
  IconRepairWrenchTo16: true,
  IconRepairWrenchTo24: true,
  IconRepairWrenchTo32: true,
  IconReservedFrom16: true,
  IconReservedFrom24: true,
  IconReservedFrom32: true,
  IconReservedTo16: true,
  IconReservedTo24: true,
  IconReservedTo32: true,
  IconPhotoCameraOutlined16: true,
  IconPhotoCameraOutlined24: true,
  IconPhotoCameraOutlined32: true,
}
