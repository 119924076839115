import { makeStyles } from '@material-ui/core'
import { Theme } from '@storybook/theming'

import { SelectWithSearchProps } from './types'

export const useSelectWithSearchStyles = makeStyles<Theme, SelectWithSearchProps<any>>((theme) => ({
  root: ({ disabled }) => ({
    '& .MuiSvgIcon-root': {
      cursor: disabled ? 'default' : 'pointer',
      zIndex: 2,
      color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
    },
  }),
  rootDefault: {
    position: 'relative',
  },
  notchedOutline: {
    '& span': {
      padding: 0,
      fontSize: '14px',
      margin: '0 3px',
    },
  },
  scroll: ({ scrollingItems }) => ({
    maxHeight: scrollingItems && 48 * scrollingItems,
    overflow: 'scroll',
  }),
  overflow: {
    maxHeight: 278,
    overflow: 'auto',
  },
  card: {
    padding: '8px 0',
    boxShadow: '0px 2px 8px #bfbfbf',
    zIndex: 100,
  },
  wrapper: {
    zIndex: 10000,
    marginTop: '8px',
  },
  cardDefault: {
    position: 'absolute',
    top: 48,
    left: 0,
    right: 0,
  },
  menuItem: {
    minHeight: 40,
    whiteSpace: 'initial',
    borderRadius: 4,
  },
}))
