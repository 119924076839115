import { makeStyles } from '@material-ui/core'

export const useWeekdaysStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.disabled,
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    background: theme.palette.background.paper,
  },
  weekday: {
    width: 36,
    minWidth: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.textStyles.body1medium.fontWeight,
    fontSize: theme.textStyles.body1medium.fontSize,
    lineHeight: theme.textStyles.body1medium.lineHeight,
  },
}))
