import { makeStyles, Theme } from '@material-ui/core'

export const useIconStyles = makeStyles<Theme>((theme) => ({
  userColor: {
    // @ts-ignore
    color: ({ htmlColor }) => htmlColor,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container16: {
    width: 16,
    height: 16,
  },
  container24: {
    width: 24,
    height: 24,
  },
  container32: {
    width: 32,
    height: 32,
  },
  action: {
    color: theme.palette.action.active,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  error: {
    color: theme.palette.error.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  inherit: {
    color: 'inherit',
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  size: {
    // @ts-ignore
    fontSize: ({ size }) => size,
    // @ts-ignore
    fontVariationSettings: ({ size }) => `OPSZ ${size}`,
  },
  mask: {
    mask: 'url(#masked)',
    maskType: 'alpha',
  },
}))
