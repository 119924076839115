import React, { FC } from 'react'

import {
  checkInFavorite,
  Favorites,
  getFavoriteItems,
  getUserName,
} from '../../../helpers/navigation'
import StarSvgIcon from '../icons_internal/StarSvgIcon'
import clsx from 'clsx'

import { FavoriteStarProps } from './types'

import { useNavigationItemStyles } from './styles'

export const FavoriteStar: FC<FavoriteStarProps> = ({
  id,
  href,
  updateView,
  className,
}: FavoriteStarProps) => {
  const classes = useNavigationItemStyles()
  const inFavorite = checkInFavorite(href || id)

  return (
    <StarSvgIcon
      className={clsx(classes.favoriteIcon, className)}
      active={inFavorite}
      onClick={(e) => {
        e.preventDefault()
        toggleFavorites(id, href, updateView)
      }}
    />
  )
}

const toggleFavorites = (id, href, updateView) => {
  const favoriteItems = getFavoriteItems()
  const userName = getUserName()
  let newFavorites: Favorites[] = []
  if (favoriteItems.userFavorites) {
    const inFavorite =
      favoriteItems.userFavorites.favorites.filter(
        (favorite) => favorite === href || favorite === id,
      ).length > 0
    if (inFavorite) {
      favoriteItems.userFavorites.favorites = favoriteItems.userFavorites.favorites.filter(
        (favorite) => favorite !== href && favorite !== id,
      )
    } else {
      favoriteItems.userFavorites.favorites.push(href || id)
    }
    newFavorites.push(favoriteItems.userFavorites)
  }
  if (favoriteItems.otherUsersFavorites) {
    newFavorites = newFavorites.concat(favoriteItems.otherUsersFavorites)
  }
  if (newFavorites.length === 0 || favoriteItems.userFavorites === null) {
    newFavorites.push({
      userName: userName,
      favorites: [href || id],
    })
  }

  localStorage.setItem('newFavorites', JSON.stringify(newFavorites))
  updateView()
}
