import { WarehouseAliasEnum } from '../../../../interfaces/enums/Warehouse'
import { Attributes } from '../../../../interfaces/Warehouse'
import WAREHOUSE from '../../../../shared/constants/Warehouse'

export const COLUMNS = [
  { name: 'swim_number', title: 'Плавка' },
  { name: 'coil_number', title: '№ бунта', width: '160px' },
  { name: 'weight', title: 'Вес, тн' },
  { name: 'barcode', title: '№ Штрихкода', width: '240px' },
  { name: 'status', title: 'Оприходование', width: '200px' },
  { name: 'controls', title: ' ', width: '1px' },
  { name: 'steel_grade', title: 'Марка стали' },
  { name: 'deliveryNum', title: 'Номер накладной', width: '150px' },
  { name: 'diameter', title: '⌀' },
  { name: 'startStoreTime', title: 'Дата отгрузки из ПЦ-4', width: '300px' },
  { name: 'borating', title: 'Признак борирования', width: '300px' },
  { name: 'no_those_cards', title: '№ Техкарты', width: '300px' },
]

export const weightFields = ['weight'] as Array<keyof Attributes>

export const reducerKey = 'WAREHOUSE-TABLE-virtual'

export const WAREHOUSE_DATA = WAREHOUSE[WarehouseAliasEnum.WAREHOUSE_UGV_VIRTUAL_INPUT]
