import TableManager from '../../../managers/TableManager'
import { COLUMNS, ReducerKey, reducerKeyPrefix } from '../constants'

type Managers = Record<ReducerKey, TableManager>

export function managers() {
  return Object.entries(COLUMNS).reduce((acc, [abbreviation]) => {
    const key = `${reducerKeyPrefix}-${abbreviation}`
    return {
      ...acc,
      [key]: new TableManager({ key }),
    }
  }, {} as Managers)
}
