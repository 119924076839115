export function getCalendar(
  fromYear: number,
  toYear: number = fromYear,
): { [key: string]: string[][] } {
  const res = {}
  for (let y = fromYear; y <= toYear; y++) {
    res[y] = getYearArray(y)
  }
  return res
}

function getYearArray(year: number) {
  const res: string[][] = []
  for (let month = 1; month <= 12; month++) {
    res.push(getMonthArray(year, month))
  }
  return res
}

function getMonthArray(year: number, month: number): string[] {
  return [
    ...new Array(getNumOfFirstDay(year, month, true)).fill(''),
    ...new Array(getDayCountInMonth(year, month)).fill(null).map((_el, i) => (i + 1).toString()),
  ]
}

// January - 1, December - 12
function getDayCountInMonth(year: number, month: number) {
  year = month === 12 ? year + 1 : year
  month = month === 12 ? 1 : month + 1
  const formattedMonth = month.toString().length === 1 ? `0${month}` : `${month}`
  const d = new Date(Number(new Date(`${year}-${formattedMonth}-01`)) - 1000 * 60 * 60 * 24)
  return d.getDate()
}

// January - 1, December - 12
// isFirstDayMonday: boolean
//   true - first day is monday
//   false - first day is sunday (default)
function getNumOfFirstDay(year: number, month: number, isFirstDayMonday: boolean) {
  const formattedMonth = month.toString().length === 1 ? `0${month}` : `${month}`
  const day = new Date(`${year}-${formattedMonth}-01`).getDay()
  return isFirstDayMonday ? (day ? day - 1 : 6) : day
}

/*
getCalendar(2020);
{
  '2020': [
    [
      '', '', 1,  2,  3,  4,  5,  6,  7,
      8,  9,  10, 11, 12, 13, 14, 15, 16,
      17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30, 31
    ],
    [
      '', '', '', '', '', 1,  2,  3,  4,
      5,  6,  7,  8,  9,  10, 11, 12, 13,
      14, 15, 16, 17, 18, 19, 20, 21, 22,
      23, 24, 25, 26, 27, 28, 29
    ],
    [
      '', '', '', '', '', '', 1,  2,  3,  4,
      5,  6,  7,  8,  9,  10, 11, 12, 13, 14,
      15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28, 29, 30, 31
    ],
    [
      '', '', 1,  2,  3,  4,  5,  6,  7,
      8,  9,  10, 11, 12, 13, 14, 15, 16,
      17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30
    ],
    [
      '', '', '', '', 1,  2,  3,  4,  5,
      6,  7,  8,  9,  10, 11, 12, 13, 14,
      15, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 25, 26, 27, 28, 29, 30, 31
    ],
    [
       1,  2,  3,  4,  5,  6,  7,  8,  9,
      10, 11, 12, 13, 14, 15, 16, 17, 18,
      19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30
    ],
    [
      '', '', 1,  2,  3,  4,  5,  6,  7,
      8,  9,  10, 11, 12, 13, 14, 15, 16,
      17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30, 31
    ],
    [
      '', '', '', '', '', 1,  2,  3,  4,
      5,  6,  7,  8,  9,  10, 11, 12, 13,
      14, 15, 16, 17, 18, 19, 20, 21, 22,
      23, 24, 25, 26, 27, 28, 29, 30, 31
    ],
    [
      '', 1,  2,  3,  4,  5,  6,  7,  8,
      9,  10, 11, 12, 13, 14, 15, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30
    ],
    [
      '', '', '', 1,  2,  3,  4,  5,  6,
      7,  8,  9,  10, 11, 12, 13, 14, 15,
      16, 17, 18, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28, 29, 30, 31
    ],
    [
      '', '', '', '', '', '', 1,  2,  3,
      4,  5,  6,  7,  8,  9,  10, 11, 12,
      13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30
    ],
    [
      '', 1,  2,  3,  4,  5,  6,  7,  8,
      9,  10, 11, 12, 13, 14, 15, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31
    ]
  ]
}
*/
