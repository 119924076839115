import React, { ReactElement, useMemo } from 'react'

import {
  Box,
  Card,
  IconAdd24,
  IconButton,
  Pagination,
  Tooltip,
} from '../../../../../../ui-kit/components'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import Table from '../../../../../../ui-kit/frontend-components/Table'
import { COLUMNS } from '../../constants'
import { NotAddNode, useNotAddNodes } from '../../hooks'
import { useAddNode } from '../../hooks/useAddNode'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loaderWrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

export const NodesToAdd = (): ReactElement => {
  const classes = useStyles()

  const { nodes, request, pageable } = useNotAddNodes()

  const { addNode } = useAddNode({ onSuccess: request.execute })

  const columnsConfig = useMemo(
    () =>
      COLUMNS.map((item) => {
        const name = item.name as keyof NotAddNode & 'controls'
        if (name === 'controls') {
          return {
            ...item,
            render: (row: NotAddNode) => {
              return (
                <Tooltip title="Добавить к отображению">
                  <span>
                    <IconButton variant="primary" onClick={() => addNode(row)}>
                      <IconAdd24 />
                    </IconButton>
                  </span>
                </Tooltip>
              )
            },
          }
        }

        return {
          ...item,
          render: (row: NotAddNode) => {
            return row?.[name] ?? '-'
          },
        }
      }),
    [addNode],
  )

  const TableMemo = useMemo(
    () => (
      <Table
        narrowColumns
        fixHeader
        noDataMessage="Нет данных"
        style={{
          root: {
            style: {
              flexGrow: 1,
            },
          },
        }}
        enableScroll
        enableColumnResizing
        columns={columnsConfig}
        rows={nodes}
      />
    ),
    [nodes, columnsConfig],
  )

  return (
    <Card className={classes.flexGrow}>
      <Box className={classes.flexGrow} spacing={2} paddingX={2} paddingY={2}>
        <LoaderWrapper
          isLoad={request.status === 'pending'}
          rootClassName={classes.loaderWrapperContainer}
          contentClassName={classes.loaderWrapper}
        >
          {TableMemo}
        </LoaderWrapper>
        <Pagination
          count={pageable.totalPages}
          page={pageable.currentPage}
          onChange={(_, page) => pageable.setCurrentPage(page)}
        />
      </Box>
    </Card>
  )
}
