import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Dialog } from '../../../../../../ui-kit/components'
import { Tab, Tabs } from '../../../../../../ui-kit/components/Tabs'
import ButtonLoader from '../../../../../../ui-kit/frontend-components/ButtonLoader'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import Table from '../../../../../../ui-kit/frontend-components/Table'
import TabPanel from '../../../../../../ui-kit/frontend-components/TabPanel'
import { AllNodes, useDictionaryNodes } from '../../hooks/useDictionaryNodes'
import { BIND_TAB_INDEX, columnsConfig, UNBIND_TAB_INDEX } from './constants'
import { useBindNode } from './hooks/useBindNode'
import { useUnbindNode } from './hooks/useUnbindNode'

import { BindNodes } from './types'

type BindNodeTableProps = {
  isOpen: boolean
  onClose: () => void
  dictionaryId: number
  currentIds: number[]
}

const tableStyle = {
  root: {
    style: {
      flexGrow: 1,
      overflow: 'auto',
      maxHeight: '720px',
    },
  },
}

const BindNodeTable = ({
  isOpen,
  onClose,
  dictionaryId,
  currentIds,
}: BindNodeTableProps): JSX.Element => {
  const [ids, setIds] = useState(currentIds)

  const [currentTab, setCurrentTab] = useState(0)

  const { dictionariesNodes, request } = useDictionaryNodes()

  const [bindRows, setBindRows] = useState<AllNodes>([])

  const [unbindRows, setUnbindRows] = useState<AllNodes>([])

  useEffect(() => {
    setIds(currentIds)
  }, [currentIds])

  const handleBindSuccess = useCallback(
    ({ nodeIds }: BindNodes) => {
      setBindRows((prev) => prev.filter(({ id }) => !nodeIds.includes(id)))
      setIds((prev) => [...prev, ...nodeIds])
    },
    [dictionariesNodes],
  )

  const handleUnbindSuccess = useCallback(
    ({ nodeIds }: BindNodes) => {
      setUnbindRows((prev) => prev.filter(({ id }) => !nodeIds.includes(id)))
      setIds((prev) => prev.filter((id) => !nodeIds.includes(id)))
    },
    [dictionariesNodes],
  )

  const { isLoading: isBindNodeLoading, bindNode } = useBindNode(handleBindSuccess)
  const { isLoading: isUnbindNodeLoading, unbindNode } = useUnbindNode(handleUnbindSuccess)

  const actions = useMemo(() => {
    if (currentTab === BIND_TAB_INDEX) {
      return [
        <ButtonLoader
          progressLoading={isBindNodeLoading}
          disabled={isBindNodeLoading || bindRows.length === 0}
          key={1}
          color="primary"
          variant="contained"
          onClick={() =>
            bindNode({
              dictionaryIds: [dictionaryId],
              nodeIds: bindRows.map(({ id }) => id),
            })
          }
        >
          Связать
        </ButtonLoader>,
        <Button onClick={onClose} key={2} color="secondary" variant="contained">
          Закрыть
        </Button>,
      ]
    }
    if (currentTab === UNBIND_TAB_INDEX) {
      return [
        <ButtonLoader
          progressLoading={isUnbindNodeLoading}
          disabled={isUnbindNodeLoading || unbindRows.length === 0}
          key={1}
          color="primary"
          variant="contained"
          onClick={() =>
            unbindNode({
              dictionaryIds: [dictionaryId],
              nodeIds: unbindRows.map(({ id }) => id),
            })
          }
        >
          Отвязать
        </ButtonLoader>,
        <Button onClick={onClose} key={2} color="secondary" variant="contained">
          Закрыть
        </Button>,
      ]
    }
    return []
  }, [currentTab, isBindNodeLoading, isUnbindNodeLoading, dictionaryId, bindRows, unbindRows])

  const bindTableRows = useMemo(
    () => dictionariesNodes.filter(({ id }) => !ids.includes(id)).sort((a, b) => b.id - a.id),
    [dictionariesNodes, ids],
  )

  const bindTableRowsSelection = useMemo(
    () => ({
      checkEnabled: true,
      checked: bindRows,
      onCheckedChange: setBindRows,
    }),
    [bindRows],
  )

  const unbindTableRows = useMemo(
    () => dictionariesNodes.filter(({ id }) => ids.includes(id)).sort((a, b) => b.id - a.id),
    [dictionariesNodes, ids],
  )

  const unbindTableRowsSelection = useMemo(
    () => ({
      checkEnabled: true,
      checked: unbindRows,
      onCheckedChange: setUnbindRows,
    }),
    [unbindRows],
  )

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      actions={actions}
      title="Связывание значения со справочниками"
      open={isOpen}
    >
      <Tabs
        // className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={(e, tab) => setCurrentTab(tab)}
      >
        <Tab label="Связать" />
        <Tab label="Отвязать" />
      </Tabs>
      <TabPanel index={BIND_TAB_INDEX} value={currentTab}>
        <LoaderWrapper isLoad={request.status === 'pending'}>
          <Table
            narrowColumns
            fixHeader
            noDataMessage="Нет справочников для связывания"
            rowsSelection={bindTableRowsSelection}
            style={tableStyle}
            enableColumnResizing
            columns={columnsConfig}
            rows={bindTableRows}
          />
        </LoaderWrapper>
      </TabPanel>

      <TabPanel index={UNBIND_TAB_INDEX} value={currentTab}>
        <LoaderWrapper isLoad={request.status === 'pending'}>
          <Table
            narrowColumns
            fixHeader
            noDataMessage="Нет связанных справочников"
            rowsSelection={unbindTableRowsSelection}
            style={tableStyle}
            enableColumnResizing
            columns={columnsConfig}
            rows={unbindTableRows}
          />
        </LoaderWrapper>
      </TabPanel>
    </Dialog>
  )
}

export default BindNodeTable
