import React, { FC } from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const SortAscendingSvgIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="swap_vert_24px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 18V16H21V18H3ZM3 6H9V8H3V6ZM15 11H3V13H15V11Z"
        />
      </g>
    </SvgIcon>
  )
}
