import { CheckboxProps, makeStyles, Theme } from '@material-ui/core'

export const useCheckboxStyles = makeStyles<Theme, CheckboxProps>((theme) => ({
  root: ({ disabled, checked }) => ({
    color: theme.palette.action.disabledBackground,
    fill: disabled && checked ? theme.palette.action.selected : theme.neutral.white,
    padding: 4,
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill: disabled && !checked ? theme.palette.action.selected : theme.neutral.white,
    },
    '& + .MuiFormControlLabel-label': {
      padding: '0 5px',
    },
  }),
  colorSecondary: ({ disabled }) => ({
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$checked': {
      color: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
      '& .MuiIconButton-label .MuiSvgIcon-root path': {
        fill: disabled ? theme.palette.action.selected : theme.palette.primary.main,
      },
    },
    '&$checked:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  checked: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: ({ size }: { size?: 'small' | 'medium' }) => (size === 'small' ? '15px' : '18px'),
      height: ({ size }: { size?: 'small' | 'medium' }) => (size === 'small' ? '15px' : '18px'),
      border: '1px solid rgba(255, 255, 255, 0.4)',
      borderRadius: ({ size }: { size?: 'small' | 'medium' }) =>
        size === 'small' ? '2.8px' : '3.2px',
    },
    '&$disabled::after': {
      border: 'none',
      color: theme.neutral.grey100,
    },
  },
  indeterminate: ({ disabled }) => ({
    outline: 'none',
    color: disabled
      ? `${theme.palette.action.disabledBackground} !important`
      : `${theme.palette.text.disabled} !important`,
    fill: disabled
      ? `${theme.palette.action.disabledBackground} !important`
      : `${theme.palette.text.disabled} !important`,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&$disabled': {
      color: theme.palette.action.disabledBackground,
    },
  }),
  disabled: {},
  error: ({ disabled, indeterminate }) => ({
    color: disabled
      ? `${theme.tableErrorBG.dark} !important`
      : `${theme.palette.error.main} !important`,
    '&:hover': {
      background: theme.errorLight.light,
    },
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill:
        indeterminate && disabled
          ? theme.neutral.white
          : disabled
          ? `${theme.tableErrorBG.light}`
          : `${theme.errorLight.light}`,
    },
    '&$checked': {
      '& .MuiIconButton-label .MuiSvgIcon-root path': {
        fill: disabled ? theme.tableErrorBG.main : theme.palette.error.main,
      },
      '& .MuiIconButton-label .MuiSvgIcon-root rect': {
        fill: `${theme.neutral.white} !important`,
      },
    },
  }),
  success: ({ disabled, indeterminate }) => ({
    color: disabled
      ? `${theme.tableSuccessBG.dark} !important`
      : `${theme.palette.success.main} !important`,
    '&:hover': {
      background: theme.successLight.light,
    },
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill:
        indeterminate && disabled
          ? theme.neutral.white
          : disabled
          ? `${theme.tableSuccessBG.light} !important`
          : `${theme.successLight.light} !important`,
    },
    '&$checked': {
      '& .MuiIconButton-label .MuiSvgIcon-root path': {
        fill: disabled ? theme.tableSuccessBG.main : theme.palette.success.main,
      },
      '& .MuiIconButton-label .MuiSvgIcon-root rect': {
        fill: `${theme.neutral.white} !important`,
      },
    },
  }),
  warning: ({ disabled, indeterminate }) => ({
    color: disabled
      ? `${theme.tableWarningBG.dark} !important`
      : `${theme.palette.warning.main} !important`,
    '&:hover': {
      background: theme.warningLight.light,
    },
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill:
        indeterminate && disabled
          ? theme.neutral.white
          : disabled
          ? `${theme.tableWarningBG.light} !important`
          : `${theme.warningLight.light} !important`,
    },
    '&$checked': {
      '& .MuiIconButton-label .MuiSvgIcon-root path': {
        fill: disabled ? theme.tableWarningBG.main : theme.palette.warning.main,
      },
      '& .MuiIconButton-label .MuiSvgIcon-root rect': {
        fill: `${theme.neutral.white} !important`,
      },
    },
  }),
}))
