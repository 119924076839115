import { Attributes, TEventReason, TWorkshopName } from '../../../interfaces/Warehouse'

export const weightFields = ['weight', 'remaining_weight'] as Array<keyof Attributes>

export var types = [
  { name: 'barcode', title: '№ Штрихкода', width: '240px' },
  { name: 'date_create_barcode', title: 'Дата штрихкода', width: '300px' },
]
// export var [ change, setIsChange ] = useState(0)

// const getTableColumns = useCallback(
//   () => service.getTableColumns('pipe'),
//   [],
// )
// const { execute, status, value, error } = useAsync<{
//   content: [{name: string, alias: string}]
// }>(getTableColumns, false, false)

// value?.content.map((row) => {
//   types.push({name: row.alias, title: row.name, width: '170px'})
// })

// service.getTableColumns('pipe').then(value => {
//   console.log(value)
//   var types: Array<any> = []
//   types.slice(0, types.length)
//   value.content.forEach(element => {
//     types.push({name: element.alias, title: element.name, width: '170px'})
//   })
//   console.log(types)
// })

export const COLUMNS = {
  'warehouse.inplast.output': [
    { name: 'barcode', title: '№ Штрихкода', width: '240px' },
    { name: 'date_create_barcode', title: 'Дата штрихкода', width: '300px' },
  ],
  'warehouse.inplast.virtual': [
    { name: 'barcode', title: '№ Штрихкода', width: '240px' },
    { name: 'date_create_barcode', title: 'Дата штрихкода', width: '300px' },
    { name: 'external_diameter', title: 'Внешний ⌀' },
    { name: 'inner_diameter', title: 'Внутренний ⌀' },
    { name: 'material_type', title: 'Тип материала', width: '150px' },
    { name: 'tu_gost', title: 'ГОСТ', width: '200px' },
    { name: 'length', title: 'Длина' },
    { name: 'status_otk', title: 'Статус ОТК' },
    { name: 'cause_otk', title: 'Причина ОТК', width: '150px' },
    { name: 'controls', title: ' ', width: '1px' },
  ],
}

export const reducerKeyPrefix = 'WAREHOUSE-TABLE'

export type ReducerKey = `${typeof reducerKeyPrefix}-${keyof typeof COLUMNS}`

export const getReducerKey = (abbreviation: keyof typeof COLUMNS): ReducerKey =>
  `${reducerKeyPrefix}-${abbreviation}` as ReducerKey

export const STATUS_INVENTORY = {
  lost: 'Потеряна',
  compliant: 'Соответствует учёту',
  found: 'Найдена',
}

export const DEFAULT_STATUS_INVENTORY = {
  lost: {
    value: 'Потеряна',
    id: 311,
  },
  compliant: {
    value: 'Соответствует учёту',
    id: 314,
  },
  found: {
    value: 'Найдена',
    id: 312,
  },
}

export const statusInventoryByShopMapper: Record<string, string> = {
  cki: 'status_inventory.wire.cki',
  gvc: 'status_inventory.wire.gvc',
  ugv: 'status_inventory.wire.ugv.spc',
  ugv_wire_rod: 'status_inventory.wire_rod.ugv.spc',
}

// export const getStatusInventoryDictionaryKey = (shop: TWorkshopName): string => {
//   const postfix = shop === 'ugv' ? '_wire_rod' : '';
//   return statusInventoryByShopMapper[`${shop}${postfix}`];
// };

export const EVENT_REASON: Record<string, TEventReason> = {
  MOVING: 'MOVING',
  INVENTORY: 'INVENTORY',
}
