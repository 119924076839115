import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const Icon3Bunker16: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00005 3C3.58882 3 0 6.70098 0 11.25V13.725C0 13.8768 0.119188 14 0.266658 14H5.0667C5.21415 14 5.33335 13.8768 5.33335 13.725V11.25C5.33335 9.73368 6.5296 8.5 8.00005 8.5C9.47045 8.5 10.6667 9.73368 10.6667 11.25V12.625C10.6667 12.7768 10.7859 12.9 10.9333 12.9C11.0808 12.9 11.2001 12.7768 11.2001 12.625V11.25C11.2001 9.43034 9.76455 7.95 8.00005 7.95C6.2355 7.95 4.80002 9.43034 4.80002 11.25V13.45H0.533345V11.25C0.533345 7.00426 3.88267 3.55001 8.00005 3.55001C12.1174 3.55001 15.4667 7.00426 15.4667 11.25V13.45H10.1334V11.25C10.1334 10.037 9.1763 9.05 8.00005 9.05C6.82375 9.05 5.8667 10.037 5.8667 11.25V13.725C5.8667 13.8768 5.98585 14 6.13335 14C6.2808 14 6.4 13.8768 6.4 13.725V11.25C6.4 10.34 7.1176 9.6 8 9.6C8.8824 9.6 9.6 10.34 9.6 11.25V13.725C9.6 13.8768 9.7192 14 9.86665 14H15.7333C15.8808 14 16 13.8768 16 13.725V11.25C16 6.70098 12.4112 3 8.00005 3ZM1.6001 10.15C1.6001 10.7567 2.07851 11.25 2.66676 11.25C3.25504 11.25 3.73342 10.7567 3.73342 10.15C3.73342 9.54338 3.25501 9.05006 2.66676 9.05006C2.07851 9.05006 1.6001 9.54338 1.6001 10.15ZM3.20011 10.15C3.20011 10.4533 2.96064 10.7001 2.66676 10.7001C2.37263 10.7001 2.13341 10.4534 2.13341 10.15C2.13341 9.84667 2.3726 9.60001 2.66676 9.60001C2.96064 9.60001 3.20011 9.84672 3.20011 10.15ZM13.3333 9.05006C13.9215 9.05006 14.3999 9.54338 14.3999 10.15C14.3999 10.7567 13.9215 11.25 13.3333 11.25C12.745 11.25 12.2666 10.7567 12.2666 10.15C12.2666 9.54338 12.745 9.05006 13.3333 9.05006ZM13.3333 9.60001C13.0391 9.60001 12.7999 9.84672 12.7999 10.15C12.7999 10.4533 13.0391 10.7001 13.3333 10.7001C13.6271 10.7001 13.8666 10.4534 13.8666 10.15C13.8666 9.84667 13.6271 9.60001 13.3333 9.60001Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon3Bunker16
