import React, { FC, useContext } from 'react'

import getWindowLocation from '../../../utils/getWindowLocation'
import { Box, Typography } from '../../components'
import ClockHeader from '../ClockHaeder'
import { UserNavigationContext } from '../UserNavigation/context/UserNavigationContext'
import { makeStyles } from '@material-ui/core'

const style = (isNavigationOpen = true) =>
  makeStyles({
    article: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: `calc(100% - ${isNavigationOpen ? 256 : 64}px)`,
      height: '100%',
      overflowY: 'auto',
      padding: '16px 48px 32px',
    },
    contentContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      flexShrink: 0,
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  })

type Props = {
  title: string
  children: React.ReactNode
}

function getStandInfo() {
  const locationAlias = getWindowLocation(window.location.href)
  if (locationAlias === 'PROD_LOCATION') return `v${process.env.REACT_APP_VERSION}`
  if (locationAlias === 'TEST_LOCATION') return `test-stand v${process.env.REACT_APP_VERSION}`
  return `dev-stand v${process.env.REACT_APP_VERSION}`
}

const UserContent: FC<Props> = ({ title, children }) => {
  const { isOpen } = useContext(UserNavigationContext)
  const classes = style(isOpen)()
  return (
    <article className={classes.article}>
      <Box spacing={2} className={classes.contentContainer}>
        <header className={classes.headerContainer}>
          <Box justifyContent="space-between">
            <Typography variant="Heading3">{title}</Typography>
            <Box flexDirection="column" alignItems="flex-end">
              <ClockHeader />
              <Typography variant="Body1">{getStandInfo()}</Typography>
            </Box>
          </Box>
        </header>
        <main className={classes.mainContainer}>{children}</main>
      </Box>
    </article>
  )
}

export default UserContent
