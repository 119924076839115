import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconHelp24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M12 19.4326C16.0869 19.4326 19.4707 16.0488 19.4707 11.9619C19.4707 7.88232 16.0796 4.49121 11.9927 4.49121C7.91309 4.49121 4.5293 7.88232 4.5293 11.9619C4.5293 16.0488 7.92041 19.4326 12 19.4326ZM12 18.1875C8.54297 18.1875 5.78174 15.4189 5.78174 11.9619C5.78174 8.51221 8.53564 5.73633 11.9927 5.73633C15.4497 5.73633 18.2183 8.51221 18.2256 11.9619C18.2329 15.4189 15.457 18.1875 12 18.1875ZM11.8462 13.478C12.2197 13.478 12.4395 13.251 12.4395 12.958V12.8701C12.4395 12.46 12.6812 12.2036 13.1865 11.8667C13.897 11.3979 14.4023 10.9658 14.4023 10.0942C14.4023 8.87109 13.3184 8.21924 12.0806 8.21924C10.8208 8.21924 9.99316 8.8125 9.78809 9.48633C9.75146 9.61084 9.72949 9.72803 9.72949 9.85254C9.72949 10.1748 9.98584 10.3579 10.2275 10.3579C10.645 10.3579 10.7109 10.1309 10.9453 9.85986C11.187 9.45703 11.5386 9.21533 12.0293 9.21533C12.6958 9.21533 13.1353 9.58887 13.1353 10.1528C13.1353 10.6509 12.8203 10.8926 12.1831 11.332C11.6558 11.6982 11.2676 12.0864 11.2676 12.8042V12.8994C11.2676 13.2876 11.4727 13.478 11.8462 13.478ZM11.8315 15.668C12.2563 15.668 12.6226 15.3311 12.6226 14.9062C12.6226 14.4741 12.2637 14.1445 11.8315 14.1445C11.3994 14.1445 11.0405 14.4814 11.0405 14.9062C11.0405 15.3237 11.4067 15.668 11.8315 15.668Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconHelp24
