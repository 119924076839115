import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    '& label:not(.Mui-focused)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - (66px + 9px))',
      fontWeight: 400,
    },
  },
  inputBaseRoot: {
    padding: '0px 65px 0px 0px',
    fontWeight: 400,
  },
  indicatorOpen: {
    marginRight: '7px',
  },
  icon: ({ error = false }: { error?: boolean }) => ({
    marginRight: '7px',
    '& > path': {
      fill: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  }),
}))
