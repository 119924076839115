export const COLUMNS = {
  ugv: [
    { name: 'aggregate', title: 'Наименование и номер стана', width: '150px' },
    { name: 'status', title: 'Статус', width: '90px' },
    { name: 'steel_grade', title: 'Марка стали', width: '90px' },
    { name: 'appointment', title: 'Назначение', width: '100px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '70px' },
    { name: 'beg_diameter', title: 'Начальный диаметр, мм', width: '90px' },
    { name: 'end_diameter', title: 'Конечный диаметр, мм', width: '90px' },
    { name: 'limit', title: 'Допуск', width: '90px' },
    { name: 'order_code', title: 'Шифр заказа', width: '100px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2', width: '120px' },
    { name: 'direction', title: 'Направление', width: '90px' },
    { name: 'no_those_cards', title: '№ тех. карты' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  ugv2: [
    { name: 'aggregate', title: 'Наименование и номер стана', width: '150px' },
    { name: 'status', title: 'Статус', width: '90px' },
    { name: 'steel_grade', title: 'Марка стали', width: '90px' },
    { name: 'appointment', title: 'Назначение', width: '100px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '70px' },
    { name: 'beg_diameter', title: 'Начальный диаметр, мм', width: '90px' },
    { name: 'end_diameter', title: 'Конечный диаметр, мм', width: '90px' },
    { name: 'limit', title: 'Допуск', width: '90px' },
    { name: 'order_code', title: 'Шифр заказа', width: '100px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2', width: '120px' },
    { name: 'direction', title: 'Направление', width: '90px' },
    { name: 'no_those_cards', title: '№ тех. карты' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  upp: [
    { name: 'aggregate', title: 'Наименование и номер стана', width: '150px' },
    { name: 'status', title: 'Статус', width: '90px' },
    { name: 'steel_grade', title: 'Марка стали', width: '90px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'limit', title: 'Допуск', width: '90px' },
    { name: 'no_those_cards', title: '№ тех. карты', width: '100px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'appointment', title: 'Назначение', width: '100px' },
    { name: 'order_code', title: 'Шифр заказа', width: '100px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '90px' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн' },
    { name: 'direction', title: 'Направление' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  ebner: [
    { name: 'aggregate', title: 'Наименование и номер печи', width: '150px' },
    { name: 'status', title: 'Статус', width: '90px' },
    { name: 'processing_mode', title: 'Режим обработки', width: '120px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ, ТК', width: '70px' },
    { name: 'steel_grade', title: 'Марка стали', width: '100px' },
    { name: 'appointment', title: 'Назначение', width: '100px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'order_code', title: 'Шифр заказа', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  uef: [
    { name: 'aggregate', title: '№ нити', width: '150px' },
    { name: 'status', title: 'Статус', width: '90px' },
    { name: 'steel_grade', title: 'Марка стали' },
    { name: 'beg_diameter', title: 'Начальный диаметр, мм', width: '80px' },
    { name: 'end_diameter', title: 'Конечный диаметр, мм', width: '110px' },
    { name: 'limit', title: 'Допуск', width: '110px' },
    { name: 'appointment', title: 'Назначение' },
    { name: 'no_those_cards', title: '№ тех. карты' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '90px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  ukp: [
    { name: 'aggregate', title: 'Наименование и номер автомата', width: '150px' },
    { name: 'status', title: 'Статус', width: '100px' },
    { name: 'symbol', title: 'Условное обозначение' },
    { name: 'steel_grade', title: 'Марка стали' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '90px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'order_code', title: 'Шифр заказа' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'no_those_cards', title: '№ тех. карты' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '160px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн' },
    { name: 'appointment', title: 'Назначение' },
    { name: 'direction', title: 'Направление' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  danielli: [
    { name: 'aggregate', title: 'Наименование и номер печи', width: '150px' },
    { name: 'status', title: 'Статус' },
    { name: 'processing_mode', title: 'Режим обработки', width: '90px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '70px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ, ТК', width: '90px' },
    { name: 'steel_grade', title: 'Марка стали', width: '120px' },
    { name: 'appointment', title: 'Назначение', width: '120px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '90px' },
    { name: 'order_code', title: 'Шифр заказа', width: '90px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  fib: [
    { name: 'aggregate', title: '№ нити', width: '150px' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'note', title: 'Примечание', wordWrapEnabled: false, width: '100px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'symbol', title: 'Условное обозначение', width: '100px' },
    { name: 'order_code', title: 'Шифр заказа', width: '90px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '90px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '80px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  fib1: [
    { name: 'aggregate', title: '№ нити', width: '150px' },
    { name: 'steel_grade', title: 'Марка стали' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'symbol', title: 'Условное обозначение', width: '100px' },
    { name: 'order_code', title: 'Шифр заказа', width: '90px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '90px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '80px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  fib2: [
    { name: 'aggregate', title: '№ нити', width: '150px' },
    { name: 'steel_grade', title: 'Марка стали' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'symbol', title: 'Условное обозначение', width: '100px' },
    { name: 'order_code', title: 'Шифр заказа', width: '90px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '90px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '80px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  fib3: [
    { name: 'aggregate', title: '№ нити', width: '150px' },
    { name: 'steel_grade', title: 'Марка стали' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'beg_diameter', title: 'Диаметр, мм', width: '90px' },
    { name: 'symbol', title: 'Условное обозначение', width: '100px' },
    { name: 'order_code', title: 'Шифр заказа', width: '90px' },
    { name: 'times_resistance', title: 'Временное сопротивление, Н/мм2' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '90px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '80px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  cto: [
    { name: 'aggregate', title: 'Линия', width: '150px' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '100px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '120px' },
    { name: 'appointment', title: 'Назначение', width: '120px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  coating: [
    { name: 'aggregate', title: 'Линия', width: '150px' },
    { name: 'status', title: 'Статус', width: '100px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '100px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '120px' },
    { name: 'appointment', title: 'Назначение', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '90px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  washing: [
    { name: 'aggregate', title: 'Машина', width: '150px' },
    { name: 'status', title: 'Статус', width: '110px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '110px' },
    { name: 'speed', title: 'Скорость, шт/мин', width: '110px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '120px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'appointment', title: 'Назначение', width: '120px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  nails: [
    { name: 'aggregate', title: 'Автомат', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_type', title: '№ гвоздя', width: '80px' },
    { name: 'speed', title: 'об/мин', width: '80px' },
    { name: 'nail_standard', title: 'Сортамент', width: '100px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  nail: [
    { name: 'aggregate', title: 'Линия', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_type', title: '№ гвоздя', width: '80px' },
    { name: 'nail_standard', title: 'Сортамент', width: '100px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  sharpening: [
    { name: 'aggregate', title: 'Станок', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '100px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  pressing: [
    { name: 'aggregate', title: 'Тип и номер пресса', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '120px' },
    { name: 'workpiece_length', title: 'Длина заготовки, мм', width: '100px' },
    { name: 'end_diameter', title: 'Диаметр проволоки, мм', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  rolling: [
    { name: 'aggregate', title: 'Станок', width: '150px' },
    { name: 'status', title: 'Статус', width: '100px' },
    { name: 'nail_standard', title: 'Типоразмер', width: '100px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'note', title: 'Примечание', width: '100px' },
    { name: 'one_weight', title: 'Плановый вес кюбеля, тн', width: '100px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  wire: [
    { name: 'aggregate', title: 'Тип и номер пресса', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'speed', title: 'Скорость, шт/мин', width: '120px' },
    { name: 'appointment', title: 'Назначение', width: '120px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '120px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'direction', title: 'Направление', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  polishing: [
    { name: 'aggregate', title: 'Тип и номер пресса', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'speed', title: 'Скорость, шт/мин', width: '100px' },
    { name: 'appointment', title: 'Назначение', width: '120px' },
    { name: 'one_weight', title: 'Плановый вес ЕП, тн', width: '120px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'direction', title: 'Направление', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  packing: [
    { name: 'aggregate', title: 'Линия', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_type', title: '№ гвоздя', width: '120px' },
    { name: 'nail_standard', title: 'Сортамент', width: '120px' },
    { name: 'multiple', title: 'Кол-во коробок на паллету, шт.', width: '120px' },
    { name: 'packaging_weight', title: 'Вес коробки, кг', width: '120px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  packing_line: [
    { name: 'aggregate', title: 'Линия', width: '150px' },
    { name: 'status', title: 'Статус', width: '120px' },
    { name: 'nail_type', title: '№ гвоздя', width: '120px' },
    { name: 'nail_standard', title: 'Сортамент', width: '120px' },
    { name: 'multiple', title: 'Кол-во коробок на паллету, шт.', width: '120px' },
    { name: 'packaging_weight', title: 'Вес коробки, кг', width: '120px' },
    { name: 'fact_weight_plan_weight', title: 'факт / план, тн', width: '250px' },
    { name: 'plan_weight', title: 'План, тн', width: '110px' },
    { name: 'note', title: 'Примечание', width: '120px' },
    { name: 'operator', title: 'Создал', width: '110px' },
    { name: 'change_operator', title: 'Изменил', width: '110px' },
  ],
  default: [],
}

export const reducerKeyPrefix = 'OTK-SHIFT-TABLE'

export type TableReducerKey = `${typeof reducerKeyPrefix}-${keyof typeof COLUMNS}`

export const getReducerKey = (abbreviation: keyof typeof COLUMNS): TableReducerKey =>
  `${reducerKeyPrefix}-${abbreviation}` as TableReducerKey
