import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 256

export const useNavigationStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listItemDisabled: {
    '&:hover': {
      cursor: 'default',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.menu.main,
    zIndex: 1300,
  },
  paper: {
    backgroundColor: theme.menu.main,
    color: '#fff',
    zIndex: 1300,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '52px 1fr 64px',
    gridGap: 0,
    alignItems: 'center',
    padding: theme.spacing(0, 0, 0, 1.5),
    background: theme.menu.dark,
    color: 'rgba(255,255,255,0.4)',
    overflow: 'hidden',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    backgroundColor: theme.menu.light,
  },
  listItemSelected: {
    backgroundColor: theme.menu.light,
    '&:hover': {
      backgroundColor: theme.menu.light,
    },
  },
  username: {
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    '& span': {
      fontWeight: '500 !important',
    },
  },
  point: {
    '& span': {
      fontWeight: '500 !important',
    },
  },
  mainContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    height: '100%',
  },
  listItemGutters: {
    'div&, li&': {
      padding: 0,
      height: 64,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& span': {
      lineHeight: '1.125rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&:hover': {
      background: theme.menu.light,
    },
  },
  listItemIcon: {
    'div&': {
      minWidth: 32,
    },
    marginRight: 16,
    marginLeft: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    transition: 'transform 0.3s',
    color: 'white',
  },
  listItemIconClosed: {
    transform: 'rotate(180deg)',
  },
  listItemIconNotOpen: {
    'div&': {
      minWidth: 32,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  listItemIconNotOpenRoot: {
    margin: 0,
  },
  firstModalPaper: {
    boxSizing: 'content-box',
    left: '255px',
  },
  secondModalPaper: {
    left: '668px',
  },
  favoriteIcon: {
    position: 'absolute',
    left: '-10%',
    display: 'none',
    cursor: 'pointer',
    paddingRight: '100%',
    top: '13px',
  },
  activeFavoriteIcon: {
    position: 'absolute',
    left: '-10%',
    display: 'block',
    cursor: 'pointer',
    paddingRight: '100%',
    top: '13px',
  },
  linkWrapper: {
    position: 'relative',
    '&:hover svg': {
      display: 'block',
    },
  },
  iconAvatarSmall: {
    'div&': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  list: {
    padding: 0,
  },
  sizeOfLogOut: {
    padding: 8,
    margin: 8,
  },
  tooltipLogOut: {
    '&:hover': {
      background: 'rgba(41, 0, 31, 0.3)',
    },
  },
}))
