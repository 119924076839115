export const COLUMNS = [
  {
    name: 'id',
    title: 'Выбрать все цвета сопр. карт',
    width: '420px',
  },
  {
    name: 'description',
    title: ' ',
  },
]

export const CARDS_INTERVAL_INITIAL_STATE = {
  white: 0,
  yellow: 0,
  green: 0,
  blue: 0,
  red: 0,
  red_stripe: 0,
  white_blue_stripe: 0,
  packing_line: 0,
  wire_nailing_machine: 0,
}

export const CARDS_PRINT_COUNT = 30

export default { COLUMNS }
