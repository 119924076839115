import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconTableAttributeMapping24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7H8V11H5V7ZM10 5V13H3V5H10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7H13V11H10V7ZM15 5V13H8V5H15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13H13V17H10V13ZM15 11V19H8V11H15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13H8V17H5V13ZM10 11V19H3V11H10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7H18V11H15V7ZM20 5V13H13V5H20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13H18V17H15V13ZM20 11V19H13V11H20Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconTableAttributeMapping24
