import React, { FC } from 'react'

import { fullnessGray, fullnessOrange, fullnessRed, mainGreen } from '../../../colors'
import { TWorkshopName } from '../../../interfaces/Warehouse'
import { Box } from '../../components/Box'
import { Button } from '../../components/Button'
import { Grid } from '../../components/Grid'
import { Typography } from '../../components/Typography'

type Props = {
  onClickZone: () => void
  zone: string
  current: number
  max: number
  workshopName: TWorkshopName
  selected?: boolean
}

const getColor = (percentFullness: number) => {
  if (percentFullness < 30) return { backgroundColor: mainGreen }
  if (percentFullness >= 70) return { backgroundColor: fullnessRed }
  return { backgroundColor: fullnessOrange }
}

const TEXT_ELLIPSIS = { overflow: 'hidden', textOverflow: 'ellipsis' }

const getPosition = (percentFullness: number) => {
  if (percentFullness <= 30) return { marginTop: '-28px', ...TEXT_ELLIPSIS }
  return { marginTop: '4px', ...TEXT_ELLIPSIS }
}

const FullnessItem: FC<Props> = ({ zone, current, max, onClickZone, selected = false }) => {
  const percentFullness = (current / max) * 100

  return (
    <Grid item sm key={zone}>
      <Box flexDirection="column" alignItems="center" spacing={1}>
        <Typography variant="Body2">{max || '-'}</Typography>
        <Button
          onClick={onClickZone}
          style={{
            height: '125px',
            backgroundColor: fullnessGray,
            border: selected ? '2px solid #167ffb' : '',
          }}
          fullWidth
        >
          <div
            style={{
              height: `${percentFullness}%`,
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              borderRadius: '4px 4px 0 0',
              // padding: '0 5px',
              ...getColor(percentFullness),
            }}
          >
            <Typography
              style={getPosition(percentFullness)}
              title={String(current)}
              variant="Subheading3-Medium"
            >
              {current}
            </Typography>
          </div>
        </Button>
        <Typography variant="Body2">{zone}</Typography>
      </Box>
    </Grid>
  )
}

export default FullnessItem
