import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconPhotoCameraOutlined32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 4H19.9L22.279 6.6H26.4C27.83 6.6 29 7.77 29 9.2V24.8C29 26.23 27.83 27.4 26.4 27.4H5.6C4.17 27.4 3 26.23 3 24.8V9.2C3 7.77 4.17 6.6 5.6 6.6H9.721L12.1 4ZM21.135 9.2L18.756 6.6H13.244L10.865 9.2H5.6V24.8H26.4V9.2H21.135ZM16 13.1C18.145 13.1 19.9 14.855 19.9 17C19.9 19.145 18.145 20.9 16 20.9C13.855 20.9 12.1 19.145 12.1 17C12.1 14.855 13.855 13.1 16 13.1ZM9.5 17C9.5 13.412 12.412 10.5 16 10.5C19.588 10.5 22.5 13.412 22.5 17C22.5 20.588 19.588 23.5 16 23.5C12.412 23.5 9.5 20.588 9.5 17Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconPhotoCameraOutlined32
