import React, { FC } from 'react'

import QRCode from 'qrcode.react'

type TProps = {
  value: string | number
  size?: number
}

const QRCodes: FC<TProps> = ({ value, size = 60 }) => {
  return <QRCode value={String(value)} size={size} />
}

export default QRCodes
