import React from 'react'

import { CardNailsProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'

const cardField = [
  'Отсутствие неопавшей обсечки',
  'Длина гвоздя',
  'Диаметр головки',
  'Сплющенное острие',
  'Соосность головки (смещение)',
  'Прогиб стержня',
  'Овальность головки',
]

const CardNails = React.forwardRef<HTMLDivElement, CardNailsProps>(
  ({ highlighted, barcode }, ref) => {
    const aggregateNumber = highlighted.aggregate.split('№')[1]
    return (
      <div className="print-block  print-ugv-production" ref={ref}>
        <div>
          <Box marginBottom={1} marginTop={1} spacing={2} width={700} alignItems="flex-end">
            <Typography variant="Subheading1">Сопроводительная карта продукции ГвЦ</Typography>
            <Barcode height={80} width={2} fontSize={30} value={barcode} />
          </Box>
          <Box flexDirection="column">
            <Box border={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Тип оборудования № ХВА</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">{aggregateNumber}</Typography>
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">
                  Нормативная документация на продукцию (ГОСТ, ТУ, ТТ)
                </Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading3-Medium">ГОСТ 4028-63</Typography>
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Типоразмер гвоздей</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                {highlighted.nail_standard}
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">№ кюбеля</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}></Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Фамилия авт.ХВА / дата</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}></Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Нетто кюбеля</Typography>
              </Box>
              <Box flexShrink={1} flexBasis={300}></Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Весовщик/роспись</Typography>
              </Box>
              <Box flexShrink={1} flexBasis={300}></Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">проверено по параметрам:</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1"></Typography>
              </Box>
            </Box>
            {cardField.map((item) => (
              <Box key={item} borderBottom={1} borderRight={1} borderLeft={1}>
                <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                  <Typography variant="Subheading1">{item}</Typography>
                </Box>
                <Box padding="2px 0" flexShrink={1} flexBasis={300} alignItems="center">
                  <Box marginLeft={5} border={1} width={20} height={20}></Box>
                </Box>
              </Box>
            ))}
            <Box padding="2px 5px" borderBottom={1} borderRight={1} borderLeft={1}>
              <Typography variant="Subheading1">Фамилия полировщика________________</Typography>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Соответствие качества продукции</Typography>
              </Box>
              <Box padding="2px 0" flexShrink={1} flexBasis={300} alignItems="center">
                <Box marginLeft={5} border={1} width={20} height={20}></Box>
              </Box>
            </Box>
            <Box padding="2px 5px" borderBottom={1} borderRight={1} borderLeft={1}>
              <Typography variant="Subheading1">Фамилия машиниста РУМ________________</Typography>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading1">Соответствие качества продукции</Typography>
              </Box>
              <Box padding="2px 0" flexShrink={1} flexBasis={300} alignItems="center">
                <Box marginLeft={5} border={1} width={20} height={20}></Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    )
  },
)

CardNails.displayName = 'CardNails'

export default CardNails
