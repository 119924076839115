import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconBalance32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7409 11.6771L16.7409 3.71932C16.7409 3.32205 16.4314 3 16.0496 3C15.6677 3 15.3582 3.32205 15.3582 3.71932L15.3582 11.4318L9.21947 10.3423C8.68178 10.2468 8.17154 10.623 8.07982 11.1824C8.0362 11.4485 8.0951 11.7081 8.22669 11.9175C8.22199 11.9269 8.21747 11.9364 8.21312 11.9461L4.38515 20.4808C4.151 20.6687 4.00016 20.9635 4.00016 21.295C4.00016 21.3437 4.00086 21.3923 4.00224 21.4406C3.9974 21.5028 4.00046 21.5649 4.01091 21.6254C4.19269 24.4052 6.6537 26.433 9.43219 26.433C12.3196 26.433 14.8642 24.2431 14.8642 21.295C14.8642 20.9251 14.6764 20.6008 14.3945 20.4198L10.8581 12.718L21.322 14.5751L17.5223 23.0467C17.2874 23.2345 17.1359 23.5298 17.1359 23.862C17.1359 23.9112 17.1366 23.9602 17.1381 24.0091C17.1331 24.0719 17.1362 24.1346 17.1469 24.1956C17.3304 26.9738 19.7905 29 22.568 29C25.3459 29 27.8064 26.9731 27.9891 24.1941C27.9995 24.134 28.0026 24.0723 27.9978 24.0105C27.9993 23.9612 28 23.9117 28 23.862C28 23.5186 27.8382 23.2146 27.5895 23.028L23.5841 14.5026C23.5713 14.4754 23.5571 14.4494 23.5416 14.4247C23.6405 14.2942 23.7104 14.1369 23.7391 13.9616C23.8308 13.4022 23.4693 12.8713 22.9316 12.7759L16.7409 11.6771ZM12.6871 22.3226H6.17731C6.63972 23.4742 7.85816 24.3778 9.43219 24.3778C11.0062 24.3778 12.2247 23.4742 12.6871 22.3226ZM9.35962 12.7919L12.7921 20.2674H6.00672L9.35962 12.7919ZM19.3131 24.8896C19.7755 26.0412 20.9939 26.9448 22.568 26.9448C24.142 26.9448 25.3604 26.0412 25.8228 24.8896H19.3131ZM22.4724 15.4122L25.9595 22.8344H19.1434L22.4724 15.4122Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconBalance32
