export const getWindowLocation = (
  location: string,
): 'PROD_LOCATION' | 'TEST_LOCATION' | 'DEV_LOCATION' | null => {
  if (location.includes('mes-metiz.nlmk.com/')) return 'PROD_LOCATION'

  if (location.includes('metiz-frontend-mes-nlmk-metiz-test.app-test.nlmk.com/'))
    return 'TEST_LOCATION'

  if (
    location.includes('metiz-frontend-mes-nlmk-metiz-dev.app-test.nlmk.com/') ||
    location.includes('localhost')
  )
    return 'DEV_LOCATION'

  return null
}

export default getWindowLocation
