import { Button } from '../'
import Box from '@material-ui/core/Box'

import withStyles from '@material-ui/core/styles/withStyles'

export const TableToolbarButton = withStyles({
  root: {
    padding: '9px 16px 9px 12px',
    textTransform: 'none',
  },
  startIcon: {
    margin: '0 12px 0 0',
  },
  endIcon: {
    margin: '0 0 0 8px',
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: '24px',
    },
  },
})(Button)

export const TableToolbarContainer = withStyles({
  root: {
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
  },
})(Box)
