import React, { FC } from 'react'

import { ISvgProps } from '../types'

export const LogoSvgIcon: FC<ISvgProps> = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H24V24H0V0ZM21.624 15.8742C19.8806 16.5282 18.0439 16.8969 16.184 16.9662C14.224 17.0947 10.016 16.9662 8.816 14.8183C6.84743 15.0534 4.85634 15.0224 2.896 14.726L6.06 10.5266C6.828 9.50284 8.984 9.9806 8.396 10.7876L6.728 13.0639H8.008C8.192 13.0639 8.36 13.0639 8.52 13.0639C8.544 12.9354 8.552 12.811 8.588 12.6865C8.824 11.5945 10.152 10.5828 11.604 9.85213C11.9528 9.66939 12.3169 9.51759 12.692 9.39846C12.692 9.39846 12.668 9.39846 12.668 9.37437C12.2938 9.05515 11.8519 8.82584 11.376 8.70391C10.2099 8.36688 8.98732 8.27396 7.784 8.43091C6.72382 8.52993 5.67359 8.71658 4.644 8.98896C3.952 9.18568 3.216 9.42255 2.364 9.73168V21.6112H21.624V15.8742ZM2.38 7.87688C4.35119 7.22155 6.39648 6.81692 8.468 6.67247C10.376 6.5721 12.36 6.82101 13.888 7.69622C14.2816 7.90981 14.6285 8.20061 14.908 8.55135C14.9763 8.64588 15.0378 8.74516 15.092 8.84844C17.1094 8.57778 19.1547 8.59264 21.168 8.89261L17.968 13.1281C17.568 13.6942 16.536 13.7183 16.14 13.6501C15.744 13.5818 15.268 13.2486 15.644 12.7628L15.992 12.3051L17.296 10.623H16C15.7757 10.6189 15.5514 10.627 15.328 10.647C14.2377 10.6983 13.181 11.0422 12.268 11.6427C11.612 12.0964 11.112 12.6905 11.068 13.3489C11.0521 13.5119 11.071 13.6764 11.1234 13.8315C11.1758 13.9866 11.2606 14.1286 11.372 14.2482C11.782 14.6445 12.2998 14.9099 12.86 15.011C15.944 15.6494 18.8 14.8745 21.636 13.8066V2.40883H2.38V7.87688ZM15.228 11.0405C15.0284 11.6348 14.6848 12.1701 14.228 12.5982C13.7222 13.0623 13.154 13.4529 12.54 13.7584C12.154 13.9542 11.7526 14.1181 11.34 14.2482C11.1915 14.0114 11.1205 13.7339 11.137 13.4546C11.1535 13.1752 11.2566 12.9081 11.432 12.6905C11.8879 12.1259 12.4983 11.7073 13.188 11.4861C13.8304 11.2444 14.5038 11.0957 15.188 11.0445L15.228 11.0405Z"
        fill="white"
      />
    </svg>
  )
}
