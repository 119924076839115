import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconPechiVTO32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.934 24.8814H12.1042V9.5624H19.934V24.8814ZM21.6945 24.8814L21.6923 23.7465H20.969L20.9519 20.0663H21.9816L21.9698 10.042L18.0907 6.42931L17.0438 6.41694L16.3943 5.45608L16.0573 5.45456L15.7144 5.4561L15.0613 6.41705L14.0083 6.42938L10.117 10.0426V19.9861H11.1106V23.7165H10.3833V24.8814H8.5883V25.6086H6.1579C6.0526 26.035 5.83885 26.3591 5.70233 26.5455H26.2977C26.1612 26.3591 25.9474 26.035 25.8421 25.6086H23.5375L23.5319 24.8814H21.6945ZM24.9809 24.1541H27.6269C27.6269 24.1541 27.333 24.6354 27.2589 24.9136C27.1881 25.1793 27.2792 25.3982 27.4188 25.6086C27.4725 25.6897 27.5335 25.7695 27.5951 25.8502C27.7779 26.0895 27.9671 26.3372 27.9948 26.6507C28.0515 27.2938 27.6269 28 27.6269 28H4.37315C4.37315 28 3.94846 27.2938 4.00522 26.6507C4.03289 26.3372 4.22211 26.0895 4.40486 25.8502C4.46654 25.7695 4.52749 25.6897 4.58124 25.6086C4.72084 25.3982 4.81187 25.1793 4.74107 24.9136C4.66696 24.6354 4.37315 24.1541 4.37315 24.1541H7.13376V22.2619H9.65605V21.4407H8.66242V9.40832L13.4299 4.98151L14.285 4.97149L14.9419 4.00501L16.0573 4L17.1692 4.00501L17.8225 4.97149L18.6704 4.98151L23.4236 9.40832L23.4379 21.516V21.5208H22.4132L22.4168 22.292H24.9666L24.9809 24.1541ZM5.62634 27.2617C5.62634 27.2617 5.6257 27.2606 5.6245 27.2584L5.62634 27.2617ZM18.4794 23.4268V11.017H13.5588V23.4268H18.4794Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconPechiVTO32
