import React, { FC } from 'react'

import getServerDateTime from '../../../utils/getServerDateTime'
import { Clock } from '../../components/Clock'

const ClockHeader: FC = () => {
  const time = getServerDateTime().getTime()

  return <Clock timeDifference={time - new Date().getTime()} />
}

export default ClockHeader
