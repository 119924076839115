import React, { useCallback, useMemo } from 'react'

import {
  CardDataInterfaceAttributes,
  CardDataInterfaceAttributes2,
} from '../../interfaces/TaskMills'
import CardBlue from '../../ui-kit/frontend-components/Card/CardBlue'
import CardBlueMediumTag from '../../ui-kit/frontend-components/Card/CardBlueMediumTag/CardBlueMediumTag'
import CardBlueNails from '../../ui-kit/frontend-components/Card/CardBlueNails'
import CardBlueTag from '../../ui-kit/frontend-components/Card/CardBlueTag'
import CardCkiCto from '../../ui-kit/frontend-components/Card/CardCkiCto'
import CardCkiWhitePressingMachine from '../../ui-kit/frontend-components/Card/CardCkiWhitePressingMachine'
import CardCkiWhiteRollingMachine from '../../ui-kit/frontend-components/Card/CardCkiWhiteRollingMachine'
import CardCkiWhiteSharpeningMachine from '../../ui-kit/frontend-components/Card/CardCkiWhiteSharpeningMachine'
import CardCoatingLine from '../../ui-kit/frontend-components/Card/CardCoatingLine'
import CardGreen from '../../ui-kit/frontend-components/Card/CardGreen'
import CardGreenMediumTag from '../../ui-kit/frontend-components/Card/CardGreenMediumTag'
import CardGreenNails from '../../ui-kit/frontend-components/Card/CardGreenNails'
import CardGreenTag from '../../ui-kit/frontend-components/Card/CardGreenTag'
import CardNails from '../../ui-kit/frontend-components/Card/CardNails'
import CardPackaging from '../../ui-kit/frontend-components/Card/CardPackaging'
import CardRed from '../../ui-kit/frontend-components/Card/CardRed'
import CardRedMediumTag from '../../ui-kit/frontend-components/Card/CardRedMediumTag'
import CardRedTag from '../../ui-kit/frontend-components/Card/CardRedTag'
import CardWhite from '../../ui-kit/frontend-components/Card/CardWhite'
import CardWhiteBlue from '../../ui-kit/frontend-components/Card/CardWhiteBlue'
import CardWhiteMediumTag from '../../ui-kit/frontend-components/Card/CardWhiteMediumTag'
import CardWhiteTag from '../../ui-kit/frontend-components/Card/CardWhiteTag/CardWhiteTag'
import CardYellow from '../../ui-kit/frontend-components/Card/CardYellow'
import CardYellowMediumTag from '../../ui-kit/frontend-components/Card/CardYellowMediumTag'
import CardYellowTag from '../../ui-kit/frontend-components/Card/CardYellowTag'

type TGetCard = (
  card: CardDataInterfaceAttributes,
  index: number,
  ref?: React.RefObject<HTMLDivElement>,
) => JSX.Element

const useCards = ({
  cards,
  currentRegion,
}: {
  cards: Array<{
    card: CardDataInterfaceAttributes | CardDataInterfaceAttributes2 | null
    ref?: React.RefObject<HTMLDivElement>
  }>
  currentRegion: string
}): JSX.Element[] => {
  const getCard: TGetCard = useCallback(
    (card, index, ref) => {
      const { color } = card

      const highlightedCard = card
        ? [
            Object.entries(card).reduce((acc, [key, value]) => {
              const modValue = value

              return { ...acc, [key]: modValue }
            }, {} as CardDataInterfaceAttributes),
          ]
        : []

      if (currentRegion.includes('coating_lines')) {
        return <CardCoatingLine key={index} ref={ref} highlighted={card} />
      }

      if (currentRegion.includes('cto')) {
        return <CardCkiCto key={index} ref={ref} highlighted={card} />
      }

      if (currentRegion.includes('rolling_machine')) {
        return <CardCkiWhiteRollingMachine key={index} ref={ref} highlighted={card} />
      }

      if (currentRegion.includes('pressing_machine')) {
        return <CardCkiWhitePressingMachine key={index} ref={ref} highlighted={card} />
      }
      if (currentRegion.includes('sharpening_machines')) {
        return <CardCkiWhiteSharpeningMachine key={index} ref={ref} highlighted={card} />
      }

      if (currentRegion.includes('ebner')) {
        return (
          <CardRedMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (currentRegion.split('.')[0] === 'wire_nailing_machine') {
        return <CardNails key={index} ref={ref} highlighted={card} barcode={card.idCard} />
      }

      if (currentRegion === 'packing_line') {
        return <CardPackaging key={index} ref={ref} highlighted={card} barcode={card.idCard} />
      }

      if (color === 'green' && currentRegion === 'nail_packing_line') {
        return (
          <CardGreenNails
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'green') {
        return (
          <CardGreen key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'green_tag') {
        return (
          <CardGreenTag key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }
      if (color === 'green_medium_tag') {
        return (
          <CardGreenMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'blue' && currentRegion === 'nail_packing_line') {
        return (
          <CardBlue key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'blue_tag') {
        return (
          <CardBlueTag key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }
      if (color === 'blue_medium_tag') {
        return (
          <CardBlueMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }
      if (color === 'blue') {
        return (
          <CardBlueNails
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'white_tag') {
        return (
          <CardWhiteTag key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'white_medium_tag') {
        return (
          <CardWhiteMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'red_tag') {
        return (
          <CardRedTag key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'red_medium_tag') {
        return (
          <CardRedMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'white') {
        return (
          <CardWhite key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'yellow') {
        return (
          <CardYellow key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }

      if (color === 'yellow_tag') {
        return (
          <CardYellowTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'yellow_medium_tag') {
        return (
          <CardYellowMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }
      if (color === 'white_blue_stripe') {
        return (
          <CardWhiteBlue
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }

      if (color === 'white_blue_stripe_tag') {
        return (
          <CardWhiteTag key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
        )
      }
      if (color === 'white_blue_stripe_medium_tag') {
        return (
          <CardWhiteMediumTag
            key={index}
            ref={ref}
            highlighted={highlightedCard}
            barcode={card.idCard}
          />
        )
      }
      if (color === 'red') {
        return <CardRed key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
      }

      if (color === 'red_stripe') {
        return <CardRed key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
      }

      if (color === 'red_blue_stripe') {
        return <CardRed key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
      }

      return <CardGreen key={index} ref={ref} highlighted={highlightedCard} barcode={card.idCard} />
    },
    [currentRegion],
  )

  const cardsItems = useMemo(
    () =>
      cards
        .filter(({ card }) => card !== null)
        .map(({ card, ref }, index) => getCard(card as CardDataInterfaceAttributes, index, ref)),
    [getCard, cards],
  )
  return cardsItems
}

export default useCards
