// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import { ResponseAllWithNodes } from 'interfaces/responses/Dictionary'
import service from 'services/service'

export type AddDictionaryValueParams = Record<string, string | number | Date | undefined>

export type UseAddDictionaryValueReturn = {
  isLoading: boolean
  addDictionaryValue: (value: AddDictionaryValueParams, start: boolean) => Promise<void>
}

export const useAddDictionaryValue = (
  dictionaryType: string,
  onSuccess: (value: ResponseAllWithNodes['content']['content'][number]) => void,
): UseAddDictionaryValueReturn => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const addDictionaryValue = useCallback<UseAddDictionaryValueReturn['addDictionaryValue']>(
    async (value, start) => {
      try {
        setIsLoading(true)

        const payloadData = Object.entries(value)
          .filter(([, value]) => value !== null && value !== '')
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {} as AddDictionaryValueParams,
          )

        const addedDictionaryValue = await service.addDictionaryValue(
          dictionaryType,
          payloadData,
          start,
        )

        addedDictionaryValue.content.nodesBinding = []

        onSuccess(addedDictionaryValue.content)
        // alert.success('Значение успешно добавлено');
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess, dictionaryType],
  )

  return {
    isLoading,
    addDictionaryValue,
  }
}
