import React, { useContext } from 'react'

import { Checkbox } from '../../../../Checkbox'
import { FormDatePicker, FormTextField } from '../../../../Formik'
import { FilterContext } from '../context'
import { ICreateFilter, labels } from '../form'
import { IFilterContext } from '../typings'
import { withoutValueRegex } from '../utils/enums'
import { filterCreator } from '../utils/helpers'
import { FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import { FieldArray, useField } from 'formik'

function FilterValues(): JSX.Element {
  const [{ value: method }] = useField<ICreateFilter['method']>('method')
  const [{ value: label }] = useField<ICreateFilter['label']>('label')
  const [{ value: values }] = useField<ICreateFilter['values']>('values')
  const { header } = useContext<IFilterContext>(FilterContext)

  const { dataType, filterValues } = filterCreator(header, label.value)
  const datePickerCondition = method.value && method.value.includes('date')
  const betweenCondition = method.value && method.value.includes('between')
  const valueInputCondition = !withoutValueRegex.exec(method.value) && !!method.value
  const customCondition = method.value.includes('custom')
  const customDateType = filterValues[0]?.type
  return (
    <>
      {valueInputCondition &&
        (datePickerCondition || customDateType ? (
          <Grid item xs={12}>
            <FormDatePicker
              name="value"
              label={labels.value}
              picker={
                dataType === 'datetime' || customDateType === 'datetime' ? 'datetime' : 'date'
              }
            />
          </Grid>
        ) : (
          <Grid item xs={betweenCondition ? 6 : 12}>
            <FormTextField
              name="value"
              label={`Значение ${betweenCondition ? 'до' : ''}`}
              type={dataType === 'numeric' ? 'number' : dataType} // Пофиксить потом во всех проектах (заменить на number)
            />
          </Grid>
        ))}
      {betweenCondition &&
        (datePickerCondition || customDateType ? (
          <Grid item xs={12}>
            <FormDatePicker
              name="secondValue"
              label={labels.secondValue}
              picker={
                dataType === 'datetime' || customDateType === 'datetime' ? 'datetime' : 'date'
              }
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <FormTextField
              name="secondValue"
              label="Значение после"
              type={dataType === 'numeric' ? 'number' : dataType} // Пофиксить потом во всех проектах (заменить на number)
            />
          </Grid>
        ))}
      {customCondition && (
        <Grid item xs={12}>
          <FieldArray
            name="values"
            render={(arrayHelpers) => (
              <FormGroup>
                {filterValues.map(
                  ({ value, title }, index) =>
                    value && (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={value}
                            name={value}
                            checked={values?.includes(value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push(value)
                              } else {
                                const idx = values?.indexOf(value)
                                if (idx || idx === 0) {
                                  arrayHelpers.remove(idx)
                                }
                              }
                            }}
                          />
                        }
                        label={title}
                      />
                    ),
                )}
              </FormGroup>
            )}
          />
        </Grid>
      )}
    </>
  )
}

export default FilterValues
