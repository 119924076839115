import { makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  shadow: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.05)',
  },
}))
