import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const SwapSvgIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.99L9 3L13 6.99H10V14H8V6.99H5ZM16 10V17.01H19L15 21L11 17.01H14V10H16Z"
      />
    </SvgIcon>
  )
}
