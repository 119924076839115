import { TableColumn } from '../../../../../../ui-kit/components'
import { AllNodes } from '../../hooks/useDictionaryNodes'

export const COLUMNS: TableColumn<AllNodes[number]>[] = [
  { name: 'id', title: 'Идентификатор' },
  { name: 'shop', title: 'Цех' },
  { name: 'area', title: 'Участок' },
  { name: 'section', title: 'Подучасток' },
  { name: 'aggregate', title: 'Агрегат' },
]

export const BIND_TAB_INDEX = 0

export const UNBIND_TAB_INDEX = 1

export const columnsConfig = COLUMNS.map((item) => {
  return {
    ...item,
    render: (row: any) => {
      return row?.[item.name] ?? '-'
    },
  }
})
