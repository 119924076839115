import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconLightMode24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M12 9.75C13.2375 9.75 14.25 10.7625 14.25 12C14.25 13.2375 13.2375 14.25 12 14.25C10.7625 14.25 9.75 13.2375 9.75 12C9.75 10.7625 10.7625 9.75 12 9.75ZM12 8.25C9.93 8.25 8.25 9.93 8.25 12C8.25 14.07 9.93 15.75 12 15.75C14.07 15.75 15.75 14.07 15.75 12C15.75 9.93 14.07 8.25 12 8.25ZM4.5 12.75H6C6.4125 12.75 6.75 12.4125 6.75 12C6.75 11.5875 6.4125 11.25 6 11.25H4.5C4.0875 11.25 3.75 11.5875 3.75 12C3.75 12.4125 4.0875 12.75 4.5 12.75ZM18 12.75H19.5C19.9125 12.75 20.25 12.4125 20.25 12C20.25 11.5875 19.9125 11.25 19.5 11.25H18C17.5875 11.25 17.25 11.5875 17.25 12C17.25 12.4125 17.5875 12.75 18 12.75ZM11.25 4.5V6C11.25 6.4125 11.5875 6.75 12 6.75C12.4125 6.75 12.75 6.4125 12.75 6V4.5C12.75 4.0875 12.4125 3.75 12 3.75C11.5875 3.75 11.25 4.0875 11.25 4.5ZM11.25 18V19.5C11.25 19.9125 11.5875 20.25 12 20.25C12.4125 20.25 12.75 19.9125 12.75 19.5V18C12.75 17.5875 12.4125 17.25 12 17.25C11.5875 17.25 11.25 17.5875 11.25 18ZM7.4925 6.435C7.2 6.1425 6.72 6.1425 6.435 6.435C6.1425 6.7275 6.1425 7.2075 6.435 7.4925L7.23 8.2875C7.5225 8.58 8.0025 8.58 8.2875 8.2875C8.5725 7.995 8.58 7.515 8.2875 7.23L7.4925 6.435ZM16.77 15.7125C16.4775 15.42 15.9975 15.42 15.7125 15.7125C15.42 16.005 15.42 16.485 15.7125 16.77L16.5075 17.565C16.8 17.8575 17.28 17.8575 17.565 17.565C17.8575 17.2725 17.8575 16.7925 17.565 16.5075L16.77 15.7125ZM17.565 7.4925C17.8575 7.2 17.8575 6.72 17.565 6.435C17.2725 6.1425 16.7925 6.1425 16.5075 6.435L15.7125 7.23C15.42 7.5225 15.42 8.0025 15.7125 8.2875C16.005 8.5725 16.485 8.58 16.77 8.2875L17.565 7.4925ZM8.2875 16.77C8.58 16.4775 8.58 15.9975 8.2875 15.7125C7.995 15.42 7.515 15.42 7.23 15.7125L6.435 16.5075C6.1425 16.8 6.1425 17.28 6.435 17.565C6.7275 17.85 7.2075 17.8575 7.4925 17.565L8.2875 16.77Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconLightMode24
