import React, { FC } from 'react'

import { useGetCurrentTheme } from '../CommonWrapper'
import { PseudoInput } from '../PseudoInput'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'

import { InputProps } from './types'

import { useInputStyles } from './styles'

export const Input: FC<InputProps> = ({
  leftIcon,
  rightIcon,
  height,
  colored,
  disabled = false,
  fullWidth = false,
  pseudo = false,
  value,
  label,
  resize = false,
  ...props
}) => {
  const heightBool = Boolean(height === 32)
  const rightIconBool = Boolean(rightIcon)
  const classes = useInputStyles({
    heightBool,
    rightIconBool,
    fullWidth,
    disabled,
    resize,
  })
  const theme = useGetCurrentTheme()
  return pseudo ? (
    <PseudoInput label={label as string}>{value as string}</PseudoInput> // закомменитить чтобы работало
  ) : (
    <TextField
      value={value}
      label={label}
      disabled={disabled}
      variant="outlined"
      {...props}
      fullWidth={fullWidth}
      className={clsx(
        Boolean(leftIcon) && classes.leftIcon,
        Boolean(rightIcon) && classes.rightIcon,
        Boolean(colored) && classes.colored,
        heightBool && classes.smallHeight,
        Boolean(props.multiline) && classes.multiline,
        props.className,
      )}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          ...props.InputLabelProps?.classes,
          root: clsx(classes.inputLabelRoot, props.InputLabelProps?.classes?.root),
          outlined: clsx(classes.MuiInputLabelOutlined, props.InputLabelProps?.classes?.outlined),
        },
      }}
      InputProps={{
        ...props.InputProps,
        startAdornment: leftIcon || props.InputProps?.startAdornment,
        endAdornment: rightIcon || props.InputProps?.endAdornment,
        classes: {
          ...props.InputProps?.classes,
          root: clsx(classes.inputRoot, props.InputProps?.classes?.root),
          input: clsx(classes.input, props.InputProps?.classes?.input),
          error: clsx(classes.error, props.InputProps?.classes?.error),
          notchedOutline: classes.notchedOutline,
          disabled: clsx(classes.disabled, props.InputProps?.classes?.disabled),
        } as any,
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          ...props.FormHelperTextProps?.classes,
          root: clsx(classes.helperTextRoot, props.FormHelperTextProps?.classes?.root),
        },
      }}
      classes={{
        ...props.classes,
        root: clsx(props.classes?.root, {
          [classes.light]: theme === 'light',
          [classes.dark]: theme === 'dark',
          [classes.colored]: colored,
        }),
      }}
    />
  )
}
