import React, { useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import {
  Box,
  Checkbox,
  DatePicker,
  IconAutorenew24,
  IconButton,
  IconClose24,
  Input,
  MenuItem,
  Select,
  Tooltip,
} from '../../../../../../ui-kit/components'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import { Column } from '../../hooks/useDictionaryValues'
import { DependentDictionaries } from '../../hooks/useGetDependentDictionaries'
import { DictionaryForm } from '../EditTable/hooks/useDictionaryForm'
import { FormControlLabel, FormHelperText } from '@material-ui/core'
import getServerDateTime from 'utils/getServerDateTime'

type DictionaryValuesFormProps = {
  form: UseFormReturn<DictionaryForm>
  columns: Column[]
  onSubmit: (values: DictionaryForm, start: boolean) => void
  mode: 'add' | 'edit'
  dependentDictionaries: DependentDictionaries
}

const getEnabledFrom = (
  name: keyof DictionaryForm,
  formValues: DictionaryForm,
): Date | undefined => {
  if (name === 'startDateTime') return getServerDateTime()

  if (name === 'finishDateTime') {
    const value = formValues?.startDateTime
    return value ? new Date(value) : undefined
  }

  return undefined
}

const fieldsOrders: Record<string, number> = {
  value: 0,
  code: 1,
  startDateTime: 1000,
  finishDateTime: 1000,
}

const getFieldOrder = (name: string): number => {
  return fieldsOrders?.[name] ?? 100
}

const DictionaryValuesForm = ({
  onSubmit,
  form,
  columns,
  mode,
  dependentDictionaries,
}: DictionaryValuesFormProps): JSX.Element => {
  const [start, setStart] = useState(mode === 'add')

  const fields = useMemo(
    () =>
      Object.keys(form.getValues())
        .filter((item) => {
          if (start && item === 'startDateTime') return false
          return true
        })
        .sort((a, b) => getFieldOrder(a) - getFieldOrder(b)),
    [form, start],
  )

  useEffect(() => {
    if (start) form.setValue('startDateTime', String(getServerDateTime()))
  }, [start])

  return (
    <form id="dictionary-values-form" onSubmit={form.handleSubmit((data) => onSubmit(data, start))}>
      <Box flexDirection="column" spacing={2}>
        {fields.map((key, index) => {
          const name = key as keyof DictionaryForm
          const column = columns.find((column) => column.name === name)
          const title = column?.title ?? name

          if (column?.type === 'Date') {
            return (
              <Controller
                key={key}
                name={name}
                control={form.control}
                render={({ field }) => {
                  return (
                    <Box spacing={1}>
                      <Box flexDirection="column" width="100%">
                        <DatePicker
                          {...field}
                          withPortal
                          type="time"
                          label={title}
                          value={field.value ? new Date(field.value) : undefined}
                          error={Boolean(form.formState.errors[name])}
                          enabledFrom={getEnabledFrom(name, form.watch())}
                        />
                        {form.formState.errors[name]?.message && (
                          <FormHelperText error>
                            {form.formState.errors[name]?.message || ' '}
                          </FormHelperText>
                        )}
                      </Box>
                      {name === 'finishDateTime' && (
                        <Tooltip title="Очистить дату окончания" placement="top">
                          <span>
                            <IconButton variant="primary" onClick={() => field.onChange(undefined)}>
                              <IconClose24 />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  )
                }}
              />
            )
          }

          if (dependentDictionaries?.[name]) {
            const { isLoading, refresh, error, value } = dependentDictionaries?.[name]
            const isError = Boolean(form.formState.errors?.[name]) || Boolean(error)
            const errorText = form.formState.errors?.[name]?.message ?? error ?? ''
            return (
              <Controller
                key={key}
                name={name}
                control={form.control}
                render={({ field }) => (
                  <Box spacing={1} alignItems="flex-end">
                    <Box flexDirection="column" spacing={1} width="100%">
                      <LoaderWrapper isLoad={isLoading}>
                        <Select
                          {...field}
                          disabled={isLoading}
                          formControlStyle={{ width: '100%' }}
                          fullWidth
                          label={title}
                          autoFocus={index === 0}
                          error={isError}
                        >
                          {value.map(({ id, value }) => (
                            <MenuItem key={id} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </LoaderWrapper>
                      {isError && <FormHelperText error>{errorText}</FormHelperText>}
                    </Box>
                    <Tooltip title="Перезагрузить словарь" placement="top">
                      <span>
                        <IconButton variant="primary" onClick={refresh}>
                          <IconAutorenew24 />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                )}
              />
            )
          }

          return (
            <Controller
              key={key}
              name={name}
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={title}
                  autoFocus={index === 0}
                  fullWidth
                  size="small"
                  helperText={form.formState.errors[name]?.message}
                  error={Boolean(form.formState.errors[name])}
                />
              )}
            />
          )
        })}

        <FormControlLabel
          value="hidden"
          style={{ marginLeft: 0, marginRight: 0 }}
          control={<Checkbox checked={start} onChange={(e, checked) => setStart(checked)} />}
          label="Использовать текущую дату для начала действия значения"
        />
      </Box>
    </form>
  )
}

export default DictionaryValuesForm
