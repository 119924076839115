import { useEffect, useState } from 'react'

import useAsync from '../../../../ui-kit/hooks/useAsync'
import { ResponseAllTypesInfo } from 'interfaces/responses/Dictionary'
import { Request } from 'interfaces/responses/Request'
import sortBy from 'lodash/sortBy'
// import { useAlert } from 'react-alert';
import service from 'services/service'

export type AllTypeInfo = ResponseAllTypesInfo['content']

type UseAllDictionaryReturn = {
  dictionariesTypesInfo: AllTypeInfo
  setDictionariesTypesInfo: (value: AllTypeInfo) => void
  request: Request<ResponseAllTypesInfo>
}

export const sortDictionariesTypesInfo = (dictionariesTypes: AllTypeInfo): AllTypeInfo => {
  return sortBy(dictionariesTypes, ['typeAlias', 'infoName'])
}

export const useAllDictionaryTypes = (): UseAllDictionaryReturn => {
  // const alert = useAlert();

  const [dictionariesTypesInfo, setDictionariesTypesInfo] = useState<AllTypeInfo>([])

  const request = useAsync(service.getAllTypesInfo)

  useEffect(() => {
    if (request.value) {
      setDictionariesTypesInfo(sortDictionariesTypesInfo(request.value.content))
    }
  }, [request.value])

  useEffect(() => {
    if (request.error) {
      // alert.error(request.error);
    }
  }, [request.error])

  return {
    dictionariesTypesInfo,
    setDictionariesTypesInfo,
    request,
  }
}
