import React, { FC } from 'react'

import { DialogTitleProps, DialogTitle as MUIDialogTitle } from '@material-ui/core'

import { useDialogTitleStyles } from './styles'

export const DialogTitle: FC<DialogTitleProps> = ({ ...props }) => {
  const classes = useDialogTitleStyles()

  return (
    <MUIDialogTitle
      classes={{
        root: classes.root,
      }}
      disableTypography
      {...props}
    />
  )
}
