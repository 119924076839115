import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconInsertDriveFile24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 20V8L14 2L6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20ZM18 20H5.99L5.99 4L13 4V9H18L18 20Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconInsertDriveFile24
