import { makeStyles } from '@material-ui/core'
import { Theme } from '@storybook/theming'

import { SelectProps } from './types'

export const useSelectStyles = makeStyles<Theme, SelectProps>((theme) => ({
  root: ({ disabled, compact }) => ({
    height: compact ? 32 : 40,
    boxSizing: 'border-box',
    color: disabled ? theme.palette.text.disabled : theme.neutral.grey900,
    fontWeight: 500,
    position: 'relative',
    backgroundColor: disabled ? theme.neutral.grey50 : theme.neutral.white,
    '$light&': {
      zIndex: 20,
    },
    '&.MuiSelect-select': {
      '&:focus': {
        backgroundColor: theme.neutral.white,
      },
    },
  }),
  formControl: ({ compact }) => ({
    '& legend': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '& .MuiSelect-icon path': {
        fill: `${theme.palette.error.main} !important`,
      },
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      zIndex: 30,
      borderColor: theme.neutral.grey200,
      color: theme.palette.text.disabled,
    },
    '& .MuiOutlinedInput-input': {
      padding: compact ? '6px 16px' : '11px 14px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink[class]': {
      transform: 'translate(15px, -6px) scale(0.875)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      zIndex: 20,
      borderWidth: '1px !important',
      borderColor: theme.neutral.grey200,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      zIndex: 20,
    },
  }),
  inputLabelRoot: ({ compact }) => ({
    fontSize: '16px',
    fontWeight: 500,
    '&.MuiFormLabel-root': {
      zIndex: 40,
      color: theme.palette.grey['700'],
    },
    '&.MuiFormLabel-root.Mui-focused[class]': {
      color: theme.palette.primary.main,
    },
    '&.MuiInputLabel-outlined': {
      transform: compact ? 'translate(14px, 8px) scale(1)' : 'translate(14px, 13px) scale(1)',
    },
  }),
  colored: {
    backgroundColor: `${theme.palette.warning.A100} !important`,
    borderRadius: '4px !important',
  },
  selectClassErrorLabel: {
    '&.MuiFormLabel-root.Mui-focused[class], &.MuiFormLabel-filled': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  menuItem: {
    justifyContent: 'space-between',
    padding: '9px 8px 9px 16px',
  },
  emptyMessage: {
    fontWeight: theme.textStyles.body1medium.fontWeight,
    fontSize: theme.textStyles.body1medium.fontSize,
    lineHeight: theme.textStyles.body1medium.lineHeight,
    color: theme.palette.text.disabled,
  },
  icon: {
    '&.MuiSelect-icon': {
      zIndex: 30,
      color: theme.palette.primary.main,
    },
    '&.MuiSelect-icon.Mui-disabled': {
      zIndex: 30,
      color: theme.palette.text.disabled,
    },
  },
  error: {
    '&.Mui-error': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  helperTextRoot: {
    '&.MuiFormHelperText-root': {
      fontSize: theme.textStyles.captionMedium.fontSize,
      lineHeight: theme.textStyles.captionMedium.lineHeight,
      fontWeight: theme.textStyles.captionMedium.fontWeight,
      color: 'inherit',
    },
  },
  light: {},
  dark: {},
}))
