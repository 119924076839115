import { makeStyles } from '@material-ui/core'

export const usePaginationStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPagination-ul': {
      gap: 4,
      '& li:first-child button, & li:last-child button': {
        height: 27,
        width: 27,
      },
    },
  },
  page: {
    width: 36,
    height: 36,
    borderRadius: 100,
    fontSize: '1rem',
    fontWeight: 500,
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  icon: {
    fill: theme.neutral.grey500,
    width: 24,
    height: 24,
  },
}))
