import { makeStyles, Theme } from '@material-ui/core'
import { Color } from '@material-ui/lab'
import { AlertProps as MUAlertProps } from '@material-ui/lab/Alert/Alert'

export const useAlertStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: theme.textStyles.body2medium.fontSize,
    lineHeight: theme.textStyles.body2medium.lineHeight,
    fontWeight: theme.textStyles.body2medium.fontWeight,
    color: theme.palette.text.secondary,
    borderRadius: '4px',
    padding: '12px 16px',
  },
  icon: (props: MUAlertProps) => ({
    marginRight: '12px',
    padding: 0,
    // TODO Когда появится нужная иконка, переработать - убрать стили и добавить нужную иконку в Alert.ts
    '& > svg': {
      fill: getIconColor(theme, props.severity, props.variant),
    },
  }),
  message: {
    padding: 0,
  },
  standardInfo: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  standardError: {
    backgroundColor: theme.errorLight.light,
    color: theme.palette.error.main,
  },
  standardWarning: {
    backgroundColor: theme.warningLight.light,
    color: theme.palette.warning.dark,
  },
  standardSuccess: {
    backgroundColor: theme.tableSuccessBG.light,
  },
  action: {
    // TODO Когда появится нужна иконка переработать - убрать стили и изменить иконку в Alert.stories.tsx
    '& > svg': {
      fill: theme.neutral.grey300,
    },
    alignItems: 'flex-start',
  },
}))

export const useAlertTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: theme.textStyles.body2bold.fontSize,
    lineHeight: theme.textStyles.body2bold.lineHeight,
    fontWeight: theme.textStyles.body2bold.fontWeight,
    color: 'inherit',
    backgroundColor: 'inherit',
    padding: '2.5px 0',
  },
}))

const getIconColor = (
  theme: Theme,
  severity?: Color,
  variant?: 'standard' | 'filled' | 'outlined',
): string => {
  switch (severity) {
    case 'error':
      return variant !== 'filled' ? theme.palette.error.main : theme.palette.text.primary
    case 'warning':
      return variant !== 'filled' ? theme.palette.warning.main : theme.palette.text.primary
    case 'success':
      return variant !== 'filled' ? theme.palette.success.main : theme.palette.text.primary
    default:
      return theme.neutral.grey900
  }
}
