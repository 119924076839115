import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { KeyCloakContext, PassportState, TokenInfoContext } from './passportState'
import Keycloak, { KeycloakInstance } from 'keycloak-js'

import { PassportProps } from './types'

const useUpdateToken = (keyCloak: KeycloakInstance, expires?: number | null) => {
  useEffect(() => {
    if (expires) {
      const timeoutTime = expires * 0.9
      const timeoutId = setTimeout(() => {
        keyCloak.updateToken(-1)
      }, timeoutTime)
      return () => clearTimeout(timeoutId)
    }
    return () => void 0
  }, [keyCloak, expires])
}

export const Passport: React.FC<PassportProps & PropsWithChildren> = ({
  children,
  loadingElement = null,
  onTokenChange,
  settings,
}) => {
  const keyCloak = useMemo(
    () =>
      Keycloak({
        url: settings?.url || process.env.REACT_APP_KEYCLOAK_URL || '',
        realm: settings?.realm || process.env.REACT_APP_KEYCLOAK_REALM || '',
        clientId: settings?.clientId || process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
      }),
    [settings?.clientId, settings?.realm, settings?.url],
  )
  const [tokenInfo, setTokenInfo] = useState<PassportState | null>(null)
  const [isAuthLoading, setAuthLoading] = useState(false)
  useEffect(() => {
    setAuthLoading(true)
    keyCloak
      .init({
        onLoad: 'login-required',
      })
      .then((authenticated) => {
        keyCloak
          .loadUserProfile()
          .then((res) => {
            setTokenInfo((prev) => {
              if (prev === null) {
                return null
              }
              return { ...prev, user: res }
            })
          })
          .catch(() => {
            keyCloak.login()
          })
        if (authenticated) {
          if (keyCloak.token && keyCloak.refreshToken) {
            sessionStorage.setItem('token', keyCloak.token)
            onTokenChange?.(keyCloak.token)
          }

          setTokenInfo((prev) => ({
            user: prev?.user ? prev.user : {},
            token: keyCloak.token ?? null,
            expires: keyCloak.tokenParsed?.exp ?? null,
            refreshExpires: keyCloak.refreshTokenParsed?.exp ?? null,
            dateCreateToken: Date.now(),
            authenticated,
            exists: true,
          }))
        } else {
          keyCloak.login()
        }
      })
      .finally(() => setAuthLoading(false))
  }, [keyCloak, onTokenChange])

  useUpdateToken(keyCloak, tokenInfo?.expires)

  if (!tokenInfo || !tokenInfo.authenticated) {
    return null
  }
  if (isAuthLoading) {
    return loadingElement
  }
  return (
    <TokenInfoContext.Provider value={tokenInfo}>
      <KeyCloakContext.Provider value={keyCloak}>{children}</KeyCloakContext.Provider>
    </TokenInfoContext.Provider>
  )
}
