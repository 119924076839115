import { makeStyles } from '@material-ui/core'

export const useDayStyles = makeStyles((theme) => ({
  root: {
    width: 36,
    height: 36,
    cursor: 'pointer',
  },
  month: {
    width: 90,
  },
  quarter: {
    width: 140,
  },
  year: {
    width: 90,
  },
  wrapper: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '100px',
    border: '1px solid transparent',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    fontWeight: theme.textStyles.body1MonoMedium.fontWeight,
    fontSize: theme.textStyles.body1MonoMedium.fontSize,
    lineHeight: theme.textStyles.body1MonoMedium.lineHeight,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {
    '& $wrapper': {
      backgroundColor: theme.palette.primary.main,
      color: theme.neutral.noChangeWhite,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  disableTimeChange: {
    '& $wrapper': {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.paper,
      cursor: 'not-allowed',
    },
  },
  today: {
    '& $wrapper': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '& $wrapper': {
      color: theme.palette.text.disabled,
    },
  },
  hoverState: {},
  start: {
    borderTopLeftRadius: '100px',
    borderBottomLeftRadius: '100px',
    backgroundColor: theme.palette.action.selected,
    '& $wrapper': {
      backgroundColor: 'transparent',
    },
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -2,
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: theme.palette.action.selected,
    },
    '&$selected': {
      background: theme.palette.secondary.dark,
      '&$hoverState': {
        backgroundColor: theme.palette.action.selected,
        '&::before': {
          backgroundColor: theme.palette.action.selected,
        },
        '&::after': {
          backgroundColor: theme.palette.action.selected,
        },
      },
      '&::after': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&::before': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '& $wrapper': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  mid: {
    backgroundColor: theme.palette.action.selected,
    '& $wrapper': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: theme.palette.action.selected,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -2,
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: theme.palette.action.selected,
    },
    '&$selected': {
      cursor: 'pointer',
      '& $wrapper': {
        cursor: 'pointer',
      },
      background: theme.palette.secondary.dark,
      '&:hover $wrapper': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&::before': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&::after': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  end: {
    borderTopRightRadius: '100px',
    borderBottomRightRadius: '100px',
    backgroundColor: theme.palette.action.selected,
    '& $wrapper': {
      backgroundColor: 'transparent',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: theme.palette.action.selected,
    },
    position: 'relative',
    '&$start': {
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
    '&$selected': {
      background: theme.palette.secondary.dark,
      '&$hoverState': {
        backgroundColor: theme.palette.action.selected,
        '&::before': {
          backgroundColor: theme.palette.action.selected,
        },
      },
      '&::before': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '& $wrapper': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))
