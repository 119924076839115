import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Input,
  Loader,
  MenuItem,
  RefreshSvgIcon,
  Select,
  TableColumn,
} from '../../../../ui-kit/components'
import LoaderWrapper from '../../../../ui-kit/frontend-components/LoaderWrapper'
// import { useAlert } from 'react-alert';
import Table from '../../../../ui-kit/frontend-components/Table'
import { useAsyncTableManager } from '../../../../ui-kit/hooks/useAsyncTableManager'
import { useAllDictionaryTypes } from '../hooks/useAllDictionaryTypes'
import { columnsQuantityFound, pageSizeValues, SearchTableQuantityFound } from './constants'
import { useDictionaries } from './hooks/useDictionaries'
import { useDictionary } from './hooks/useDictionary'
import { getReducerKey } from './utils'
import { makeStyles } from '@material-ui/core'
import service from 'services/service'
import DICTIONARY_WORK_STATUS from 'shared/enums/DictionaryWorkStatus'
import getSortedDictionary from 'utils/getSortedDictionary'

import DictionaryTable from './components/DictionaryTable'

const styles = makeStyles({
  contentContainer: {
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const tableStyle = {
  root: {
    style: {
      flexGrow: 1,
      maxHeight: '610px',
      overflow: 'auto',
    },
  },
}

const columnOrder: Record<string, number> = {
  startDateTime: 1000,
  finishDateTime: 1000,
  setDateTime: 1000,
  updateDateTime: 1000,
  approveDateTime: 1000,
}

const getColumnOrder = (name: string): number => columnOrder?.[name] ?? 0

const sortColumns = <R,>(columns: TableColumn<R>[]): TableColumn<R>[] => {
  return columns.sort((a, b) => getColumnOrder(a.name) - getColumnOrder(b.name))
}

const canExpandRow = () => true

const DisplayDictionaries = (): JSX.Element => {
  const classes = styles()
  // const alert = useAlert();

  const [selectedDictionaryName, setSelectedDictionaryName] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [quantityFound, setQuantityFound] = useState<SearchTableQuantityFound[]>([])
  const [isShowSearchResults, setIsShowSearchResults] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingResults, setIsLoadingResults] = useState({})
  const [currentPage, setCurrentPage] = useState({})
  const [loadedTable, setLoadedTable] = useState<any[]>([])
  const [pageSize, setPageSize] = useState(10)

  const { dictionaries, request: dictionariesRequest } = useDictionaries()

  const { dictionariesTypesInfo } = useAllDictionaryTypes()

  const dictionarySearchByType = useCallback(
    async ({ type, page = 0, size = 10 }: { type: string; page?: number; size?: number }) => {
      setIsLoadingResults((prev) => {
        return { ...prev, [type]: true }
      })
      try {
        const {
          content,
        }: {
          columnsInfo: {}
          content: { totalPages: number }
          dictionaryInfo: {}
        } = await service.dictionarySearchByType(type, searchInput, page, size)

        const table: any = Object.entries(content)
        const contentTable = table?.find((el: Record<string, {}>) => el[0] === 'content')?.[1]

        const newTable = {
          name: type,
          rows: contentTable.content,
          columns: Object.entries(table.find((field: any) => field[0] === 'columnInfo')[1]).map(
            (column: any) => {
              return {
                name: column[0],
                title: column[1].name,
                render: (row: any) => {
                  if (column[1].type === 'Date') {
                    return row?.[column[0]]
                      ? String(
                          `${new Date(row?.[column[0]]).toLocaleDateString()},
                      ${new Date(row?.[column[0]]).toLocaleTimeString()}`,
                        )
                      : null
                  }

                  if (column[0] === `nodesBinding`) return null
                  return row?.[column[0]]
                },
              }
            },
          ),
          totalPages: content.totalPages,
        }

        setLoadedTable((prev) => {
          return prev.find((el: any) => el.name === type)
            ? prev.map((el: any) => (el.name === type ? newTable : el))
            : [...prev, newTable]
        })
      } catch (error) {
        // alert.error(String(error));
      } finally {
        setIsLoadingResults((prev) => {
          return Object(Object.entries(prev).map((el: any) => el[0] !== type))
        })
      }
    },
    [searchInput, alert],
  )

  const preSearch = useCallback(async () => {
    setIsShowSearchResults(true)
    setIsLoading(true)
    setSelectedDictionaryName('')

    try {
      const { content } = await service.preGlobalSearch(DICTIONARY_WORK_STATUS.ACTUAL, searchInput)

      setQuantityFound(
        Object.entries(content).map((el) => {
          return {
            name: String(el[0]),
            count: String(el[1]),
          }
        }),
      )
    } catch (error) {
      // alert.error(String(error));
    } finally {
      setIsLoading(false)
    }
  }, [searchInput, alert])

  const { dictionary, request: dictionaryRequst, pageable } = useDictionary({
    dictionaryName: selectedDictionaryName,
  })

  useEffect(() => {
    if (selectedDictionaryName !== '') {
      dictionaryRequst.execute()
    }
  }, [dictionaryRequst.execute])

  const tableManagerColumns = useMemo(() => dictionary?.columns ?? [], [dictionary?.columns])

  const { manager, columns: reduxManagerColumns, resetManagerParams } = useAsyncTableManager(
    getReducerKey(selectedDictionaryName),
    tableManagerColumns,
  )

  const columns = useMemo(() => sortColumns(reduxManagerColumns), [reduxManagerColumns])

  const dispatch = useDispatch()

  const handleChangeCurrentPage = useCallback(
    (page) => {
      if (manager) {
        dispatch(manager.changePage(page - 1))
      }
    },
    [manager],
  )

  const newColumns = useMemo(
    () =>
      columnsQuantityFound.map((item) => {
        const { name } = item

        if (name === 'name') {
          return {
            ...item,
            render: (row: { name: string }) => {
              return (
                dictionariesTypesInfo.find((el) => el.dictionaryType === row?.[name])?.infoName ??
                '—'
              )
            },
          }
        }

        return {
          ...item,
          render: (row: any) => {
            return row?.[name] ?? '—'
          },
        }
      }),
    [dictionariesTypesInfo],
  )

  const handleOpenExpandRow = useCallback(
    (row: { name: string }) => {
      dictionarySearchByType({ type: row.name })
      setCurrentPage((prev) => {
        return { ...prev, [row.name]: 1 }
      })
    },
    [dictionarySearchByType],
  )

  const renderExpandRow = useCallback(
    (row: SearchTableQuantityFound) => {
      return !(row.name in isLoadingResults) ? (
        <Table
          fixHeader
          noDataMessage="Нет заданий"
          narrowColumns
          style={tableStyle}
          enableColumnResizing
          rows={loadedTable?.find((el: { name: string }) => el.name === row.name)?.rows || []}
          columns={loadedTable.find((el: { name: string }) => el.name === row.name)?.columns || []}
          enablePaging={true}
          pageSize={pageSize}
          currentPage={
            (Object?.entries(currentPage)
              .find((el: any) => el[0] === row.name)
              ?.pop() as number) || 0
          }
          onCurrentPageChange={(page) => {
            setCurrentPage((prev) => {
              return {
                ...prev,
                [loadedTable?.find((el: { name: string }) => el.name === row.name)?.name]: page,
              }
            })
            dictionarySearchByType({ type: row.name, page: page - 1, size: pageSize })
          }}
          totalPages={loadedTable?.find((el: { name: string }) => el.name === row.name)?.totalPages}
          pageSizeList={pageSizeValues}
          onPageSizeChange={(size) => {
            setPageSize(size)
            dictionarySearchByType({ type: row.name, size })
          }}
        />
      ) : (
        <Loader />
      )
    },
    [isLoadingResults, loadedTable, pageSize, currentPage, dictionarySearchByType],
  )

  return (
    <Box className={classes.contentContainer} spacing={2}>
      <LoaderWrapper
        rootStyles={{ flexShrink: 0 }}
        isLoad={dictionariesRequest.status === 'pending'}
      >
        <Box width="1000" justifyContent="space-between">
          <Box spacing={2}>
            <Select
              formControlStyle={{ width: '350px' }}
              label="Справочник"
              defaultValue=""
              value={selectedDictionaryName}
              onChange={({ target }) => {
                setSelectedDictionaryName(String(target.value))
                resetManagerParams()
                setIsShowSearchResults(false)
              }}
            >
              {getSortedDictionary(dictionaries).map(
                ({ shop = '', dictionaryName, section, typeName, area, infoName, typeAlias }) => {
                  const shopTitle = [shop, area, section].filter((item) => item).join(' - ')
                  const dictionaryTitle = [typeName, typeAlias, infoName]
                    .filter((item) => item)
                    .join(' - ')
                  return (
                    <MenuItem key={dictionaryName} value={dictionaryName}>
                      {shopTitle}&nbsp;-&nbsp;{dictionaryTitle}
                    </MenuItem>
                  )
                },
              )}
            </Select>
            <Button
              disabled={selectedDictionaryName === ''}
              variant="contained"
              color="primary"
              onClick={dictionaryRequst.execute}
            >
              <RefreshSvgIcon />
            </Button>
          </Box>

          <Box spacing={1} alignItems="center">
            <Input value={searchInput} onChange={({ target }) => setSearchInput(target.value)} />

            <Button variant="outlined" color="primary" onClick={preSearch}>
              Поиск
            </Button>
          </Box>
        </Box>
      </LoaderWrapper>
      {isLoading && <Loader />}
      {isShowSearchResults && !isLoading && (
        <Table
          canExpandRow={canExpandRow}
          onOpenExpandRow={handleOpenExpandRow}
          renderExpandRow={renderExpandRow}
          columns={newColumns}
          rows={quantityFound}
        />
      )}

      {selectedDictionaryName && manager && (
        <DictionaryTable
          rows={dictionary?.content ?? []}
          columns={columns}
          totalPages={pageable.totalPages}
          currentPage={pageable.currentPage + 1}
          onChangeCurrentPage={handleChangeCurrentPage}
          isLoading={dictionaryRequst.status === 'pending'}
          manager={manager}
        />
      )}
    </Box>
  )
}

export default DisplayDictionaries
