const PRINT_PAGE_STYLE = {
  tablePrint: `@media print {
    @page {
      size: A4;
    }

    html,
    body {
      font-size: 12px;
    }

  }`,
  tablePrintLandscape: `@media print {
    @page {
      size: A4 landscape;
    }

    html,
    body {
      font-size: 12px;
    }

  }`,
  cardPrint: `@media print {
    @page {
      size: A5 landscape;
    }

    html,
    body {
      font-size: 15px;
    }
  }`,
  cardPrintPortrait: `@media print {
    @page {
      size: A5;
    }

    html,
    body {
      font-size: 14px;
    }
  }`,

  labPrint: `@media print {
    @page {
      size: A5 landscape;
    }

    html,
    body {
      font-size: 10px;
    }
  }`,
  labPrintPortrait: `@media print {
    @page {
      size: A5;
    }

    html,
    body {
      font-size: 12px;
    }
  }`,
  weightPrint: `@media print {
    @page {
      size: 9cm 11cm landscape;
      margin: 0;
    }
  }`,
  tagPrint: `@media print {
    @page {
      orientation: landscape;
      size: 9cm 11cm landscape;      
      margin: 0;
    }
  }`,
  bigTagPrint: `@media print {
    @page {
      orientation: landscape;
      size: 9cm 18cm landscape;      
      margin: 0;
    }
  }`,
}

export default PRINT_PAGE_STYLE
