import {
  createTableReducer as createDSTableReducer,
  generateDefaultTableState,
  TableColumn,
  TableState,
} from '../ui-kit/components'
import ViewTableManager from '../ui-kit/managers/ViewTableManager'

export const createTableReducer = <R>(
  key: string,
  columns: TableColumn<R>[],
): typeof tableReducer => {
  const initialState: TableState = generateDefaultTableState(columns)
  const state = ViewTableManager.merge(key, initialState)
  const tableReducer = createDSTableReducer<TableState>(key, state)
  return tableReducer
}
