import React, { FC, PropsWithChildren, useEffect, useState } from 'react'

import service from 'services/service'
import { useKeyCloak, useKeyCloakLogout } from 'ui-kit/components/Passport'

const AuthRedirect: FC<PropsWithChildren<any>> = ({ children }: any) => {
  const loginOut = useKeyCloakLogout()

  const kc = useKeyCloak()
  const expiresTime = (kc?.tokenParsed?.exp || 0) * 1000 - new Date().valueOf() - 5000
  const [refreshing, setRefreshing] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (refreshing === false && expiresTime > 0) {
      const timeoutId = setTimeout(() => {
        setRefreshing(true)
        kc?.updateToken(-1)
          .then(() => {
            service.setToken(kc!.token!)
            setRefreshing(false)
          })
          .catch(() => setRefreshing(false))
      }, expiresTime)
      return () => clearTimeout(timeoutId)
    }
  }, [kc, expiresTime, refreshing, setRefreshing])

  useEffect(() => {
    service.setLoginOutHandler(() => loginOut())
  }, [])

  return <>{children}</>
}

export default AuthRedirect
