import React from 'react'

import { Badge as MUBadge } from '@material-ui/core'

import { IBadgeProps } from './types'

import { useBadgeStyles } from './styles'

export const Badge: React.FC<IBadgeProps> = ({ color, ...props }) => {
  const classes = useBadgeStyles({ color: color || 'primary' })

  return <MUBadge classes={classes} {...props} />
}
