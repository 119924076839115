import { makeStyles } from '@material-ui/core'

export const useDaysCalendarStyles = makeStyles(() => ({
  month: {},
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    padding: '4px 0 10px 0',
    gap: '4px',
  },
}))
