import React from 'react'

import { InfoSvgIcon } from '../../../Icon'
import { Tooltip } from '../../../Tooltip'
import { Typography } from '../../../Typography'
import { DrawerHeaderProps } from '../../types'
import { Box, withStyles } from '@material-ui/core'

const StyledTypography = Typography
withStyles({
  root: {
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    '& > div.MuiBox-root': {
      marginLeft: '10px',
    },
  },
})

export const HeaderHint = ({
  title,
  visibleTooltipIcon,
  tooltip,
}: DrawerHeaderProps): JSX.Element => (
  <StyledTypography variant="Heading3">
    {title}
    {visibleTooltipIcon && (
      <Tooltip title={tooltip || ''}>
        <Box>
          <InfoSvgIcon fontSize="small" color="disabled" />
        </Box>
      </Tooltip>
    )}
  </StyledTypography>
)
