import React from 'react'

import { TableColumn } from '../types'

export const hiddenColumnName = 'controls'

export const getHoverColumn = <R extends unknown>(
  Render: React.FC<{ row: R }>,
): TableColumn<R> => ({
  name: hiddenColumnName,
  title: ' ',
  width: '1px',
  style: { cell: { className: 'hiddenCell' } },
  render: (row) => <Render row={row} />,
})
