import React, { ReactElement, useContext } from 'react'

import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'

import { useHeaderStyles } from '../styles'

// any использован в силу необходимости соответствовать свойству headComponent в Table из @devexpress/dx-react-grid-material-ui
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableHeaderProps = any & StyledProps

export function TableHeader(props: TableHeaderProps): ReactElement<TableHeaderProps> {
  const classes = useHeaderStyles()
  const context = useContext(CustomFieldsCtx)
  const fixHeader = context.fixHeader
  const fixHeaderClass = classes.fixed
  const customClassNames = fixHeader ? fixHeaderClass : ''
  const withoutBorderClass = !context.columnBands ? '' : classes.withoutBorder
  const narrowHeaderClass = !context.narrowRows ? '' : classes.narrowHeader
  const [className, style] = useStyleProps(props, useContext(CustomFieldsCtx).style?.header)
  return (
    <DevexpressTable.TableHead
      {...props}
      className={[customClassNames, className, withoutBorderClass, narrowHeaderClass].join(' ')}
      style={style}
    />
  )
}
