import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Typography } from '../../../'
import { Badge } from '../../../Badge/Badge'
import { SwapSvgIcon } from '../../../Icon/icons_old'
import { TableState } from '../../../TableManager'
import { TableToolbarProps } from '../../types'
import { checkToExcludeColumnNames, overrideColumnsTitles } from '../../utils'
import { CreateSortTab } from './blocks/Create'
import { ListSortTab } from './blocks/List'
import { SortContext } from './context'
import { SortItem } from './typings'

import { TableToolbarButton } from '../../styles'

export type SortTableProps<T extends Record<string, unknown>> = Pick<
  TableToolbarProps<T>,
  'manager' | 'columns' | 'overrideTitles' | 'excludeColumnNames'
>

const initialTab = { tab: 0 }

export function SortTable<T extends Record<string, unknown>>({
  columns,
  manager,
  overrideTitles,
  excludeColumnNames,
}: SortTableProps<T>): JSX.Element {
  const [visible, setVisible] = useState(false)
  const [innerSort, setInnerSort] = useState<SortItem[]>([])
  const dispatch = useDispatch()
  const [tabState, setTabState] = useState<{
    tab: number
    edit?: SortItem
  }>(initialTab)
  const [initHint, setInitHint] = useState(true)
  const mappedColumns = useMemo(() => checkToExcludeColumnNames(columns, excludeColumnNames), [
    excludeColumnNames,
    columns,
  ])

  /** получаем уже добавленные значения сортировки */
  const sort: SortItem[] = useSelector(
    ({
      [manager.key]: {
        params: { sort },
      },
    }: {
      [key: string]: TableState
    }) => sort,
  )

  useEffect(() => {
    setInnerSort(sort)
  }, [sort])

  const HashTab: { [k: number]: React.ReactNode } = {
    0: (
      <ListSortTab
        isChangedState={sort.length !== innerSort.length}
        onSave={() => {
          setVisible(false)
          dispatch(manager.changeSortTable(innerSort))
          setTabState(initialTab)
        }}
      />
    ),
    1: <CreateSortTab values={tabState.edit} />,
  }

  function onClose() {
    setVisible(false)
    setInnerSort(sort)
    setTabState(initialTab)
  }

  return (
    <>
      <TableToolbarButton
        variant="text"
        onClick={() => setVisible(true)}
        startIcon={<SwapSvgIcon color="primary" />}
        endIcon={!!sort.length && <Badge badgeContent={sort.length} />}
      >
        <Typography variant="Body1-Bold">Сортировка</Typography>
      </TableToolbarButton>
      <SortContext.Provider
        value={{
          sort: innerSort,
          onClose,
          setSort: (s) => setInnerSort(s),
          setTab: (tab) => setTabState(tab),
          visible,
          header: overrideColumnsTitles(mappedColumns, overrideTitles),
          initHint,
          setInitHint,
        }}
      >
        {HashTab[tabState.tab]}
      </SortContext.Provider>
    </>
  )
}
