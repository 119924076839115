export const dateFormat = 'dd.MM.yyyy'
export const dateTimeFormat = 'dd.MM.yyyy HH:mm'
export const dateTimeSecondsFormat = 'dd.MM.yyyy HH:mm:ss'
export const dateFormatWithoutYear = 'dd.MM'
export const dateTimeFormatWithoutYear = 'dd.MM HH:mm'
export const dateTimeSecondsFormatWithoutYear = 'dd.MM HH:mm:ss'
export const onlyYearFormat = 'yyyy'
export const quarterFormat = 'MM.yyyy'
export const monthAndYearFormat = 'MM.yyyy'
export const onlyYearMask = '9999'
export const onlyYearInnerMask = '____'
export const monthAndYearMask = '99.9999'
export const monthAndYearInnerMask = '__.____'
export const quarterAndYearMask = '9 квартал 9999'
export const quarterAndYearInnerMask = '_ квартал ____'
export const dateInnerMask = '__.__.____'
export const dateMask = '99.99.9999'
export const dateTimeMask = '99.99.9999 99:99'
export const dateTimeSecondsMask = '99.99.9999 99:99:99'
export const dateMaskWithoutYear = '99.99'
export const dateTimeMaskWithoutYear = '99.99 99:99'
export const dateTimeSecondsMaskWithoutYear = '99.99 99:99:99'
export const periodYearMask = '9999 — 9999'
export const periodMonthMask = '99.9999 — 99.9999'
export const periodQuarterMask = '9 квартал 9999 — 9 квартал 9999'
export const periodDefaultMask = '99.99.9999 — 99.99.9999'
export const periodWithShiftsMaskDefault = '99.99.9999/3 — 99.99.9999/3'
export const periodWithShiftsMaskCustom = '99.99.9999/2 — 99.99.9999/2'

export const dateFormatByLevel = {
  day: dateFormat,
  month: monthAndYearFormat,
  quarter: quarterFormat,
  year: onlyYearFormat,
}

export const periodMaskByLevel = {
  day: periodDefaultMask,
  month: periodMonthMask,
  quarter: periodQuarterMask,
  year: periodYearMask,
}

export const periodInnerMaskByLevel = {
  day: dateInnerMask,
  month: monthAndYearInnerMask,
  quarter: quarterAndYearInnerMask,
  year: onlyYearInnerMask,
}

export const periodMaskByLeveWithOneDate = {
  day: dateMask,
  month: monthAndYearMask,
  quarter: quarterAndYearMask,
  year: onlyYearMask,
}
