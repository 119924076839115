import React, { useContext } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useGetCurrentTheme } from '../../CommonWrapper'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'

import { useTableStyles } from '../styles'

export type TableContainerProps = React.PropsWithChildren<unknown> & StyledProps

function TableContainer(props: TableContainerProps): JSX.Element {
  const context = useContext(CustomFieldsCtx)

  const theme = useGetCurrentTheme()
  const classes = useTableStyles({ theme, isManyColumns: context.isManyColumns })

  const [containerClassName, containerStyle] = useStyleProps(props, context.style?.container, [
    classes.container,
  ])

  const [scrollClassName, scrollStyle] = useStyleProps(props, context.style?.container, [
    classes.scrollbar,
  ])

  return (
    <PerfectScrollbar {...context.scrollbarProps} className={scrollClassName} style={scrollStyle}>
      <DevexpressTable.Container {...props} style={containerStyle} className={containerClassName} />
    </PerfectScrollbar>
  )
}

export default TableContainer
