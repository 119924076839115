import React, { FC, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { LogoSvgIcon, LogoutSvgIcon } from '../Icon'
import { Modal } from '../Modal'
import { NewTooltip } from '../NewTooltip'
import NavigationContext from './context/NavigationContext'
import { AboutUser } from './subcomponents/AboutUser'
import { CreateItem } from './subcomponents/CreateItem'
import { DefaultRenderLink } from './subcomponents/DefaultRenderLink'
import { HideMenuItem } from './subcomponents/HideMenuItem'
import { NavigationItems } from './subcomponents/NavigationItems'
import { ThemeMenuItem } from './subcomponents/ThemeMenuItem'
import { mutationTopItemsAndNavigationItems } from './utils'
import { Drawer, IconButton, List, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { INavigationItem, NavigationProps } from './types'

import { useNavigationStyles } from './styles'

export const Navigation: FC<NavigationProps> = (props) => {
  const { pathname } = useLocation()
  const {
    renderLink,
    onLogout,
    isOpen,
    systemName,
    userInfo,
    topItems,
    bottomItems,
    useTheme,
    isLogoutView = true,
    ...contextProps
  } = props

  const classes = useNavigationStyles(props)

  const navigationItems = useMemo(() => {
    const uniqTopItems = new Set(topItems)
    return topItems && bottomItems
      ? topItems.concat(bottomItems)
      : Array.from(uniqTopItems) || bottomItems || []
  }, [topItems, bottomItems])

  const [selectedFirstLevel, setSelectedFirstLevel] = useState<string | null>(null)
  const [secondLevel, setSecondLevel] = useState<INavigationItem | null>(null)
  const [selectedListItemId, setSelectedListItemId] = useState<string | null>(null)

  const newRenderLink = renderLink || DefaultRenderLink

  mutationTopItemsAndNavigationItems(topItems, navigationItems)

  const firstLevel = useMemo(
    () =>
      selectedFirstLevel ? navigationItems.find((item) => item.id === selectedFirstLevel) : null,
    [navigationItems, selectedFirstLevel],
  )

  const navigationContextValue = useMemo(
    () => ({
      renderLink: newRenderLink,
      isOpen,
      selectedFirstLevel,
      setSecondLevel,
      setSelectedFirstLevel,
      selectedListItemId,
      setSelectedListItemId,
      pathname,
      ...contextProps,
    }),
    [newRenderLink, isOpen, selectedFirstLevel, selectedListItemId, pathname, contextProps],
  )

  return (
    <NavigationContext.Provider value={navigationContextValue}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={clsx(classes.toolbar)}>
          <LogoSvgIcon />
          <Typography variant="body1">{systemName}</Typography>
          {isLogoutView && (
            <NewTooltip title="Выйти" placement="right" className={classes.tooltipLogOut}>
              <IconButton onClick={onLogout} size="medium" className={classes.sizeOfLogOut}>
                <LogoutSvgIcon />
              </IconButton>
            </NewTooltip>
          )}
        </div>
        <div className={classes.mainContent}>
          <List className={classes.list}>
            {userInfo ? <AboutUser userInfo={userInfo} /> : null}
            {topItems.map((item, index) =>
              item.isShow === undefined || item.isShow ? (
                <CreateItem key={index} item={item} />
              ) : undefined,
            )}
          </List>
          <List className={classes.list}>
            {bottomItems?.map((item, index) =>
              item.isShow === undefined || item.isShow ? (
                <CreateItem key={index} item={item} />
              ) : undefined,
            )}
            {useTheme && <ThemeMenuItem />}
            <HideMenuItem />
          </List>
        </div>
      </Drawer>
      <Modal
        {...props.modalProps}
        disableBackdrop={Boolean(firstLevel && secondLevel)}
        withMenu={!isOpen}
        withOpenMenu={isOpen}
        modalName={firstLevel?.title ?? selectedFirstLevel ?? undefined}
        classes={{
          ...props.modalProps?.classes,
          root: clsx(classes.firstModalPaper, props.modalProps?.classes?.root),
        }}
        isOpen={!!firstLevel && !firstLevel.hasOwnProperty('onClick')}
        onClose={() => setSelectedFirstLevel(null)}
        style={{ zIndex: 1250 }}
      >
        {(firstLevel && 'render' in firstLevel
          ? firstLevel.render && firstLevel.render(firstLevel)
          : firstLevel && <NavigationItems item={firstLevel} />) || null}
      </Modal>
    </NavigationContext.Provider>
  )
}
