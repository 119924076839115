import { makeStyles, Theme } from '@material-ui/core'

export const useCheckboxControlLabelStyles = makeStyles<Theme, { colored?: boolean }>((theme) => ({
  root: {
    width: 'max-content',
    borderRadius: 4,
    backgroundColor: ({ colored }) => (colored && theme.palette.warning['A100']) || 'transparent',
    color: theme.palette.text.primary,
  },
}))
