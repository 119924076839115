import { TableReducers } from '../../../../../interfaces/TableReducers'
import {
  createTableReducer,
  generateDefaultTableState,
  TableState,
} from '../../../../../ui-kit/components'
import ViewTableManager from '../../../../../ui-kit/managers/ViewTableManager'
import { COLUMNS, reducerKeyPrefix, TableReducerKey } from '../../../constants'

const otkShiftTableReducers: TableReducers<TableReducerKey> = Object.entries(COLUMNS).reduce(
  (acc, [abbreviation, columns]) => {
    const key = `${reducerKeyPrefix}-${abbreviation}`
    const initialState: TableState = generateDefaultTableState(columns)
    const state = ViewTableManager.merge(key, initialState)
    const tableReducer = createTableReducer<TableState>(key, state)
    return {
      ...acc,
      [key]: tableReducer,
    }
  },
  {} as TableReducers<TableReducerKey>,
)

export default otkShiftTableReducers
