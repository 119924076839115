import React, { FC, memo, useCallback, useMemo, useState } from 'react'

import { ArrowUpSvgIcon } from '../../../Icon'
import { INavigationItem } from '../../types'
import { NavigationItem } from '../NavigationItem'
import { Collapse } from '@material-ui/core'
import clsx from 'clsx'

import { useCollapsibleNavigationItemStyles } from './styles'

export const CollapsibleNavigationItem: FC<{
  item: INavigationItem
}> = memo(({ item }) => {
  const classes = useCollapsibleNavigationItemStyles()
  const containsSelected = useMemo(
    () =>
      item?.items?.find((item) => {
        // eslint-disable-next-line no-restricted-globals
        return location.pathname === item.href
      }),
    // eslint-disable-next-line no-restricted-globals
    [item, location.pathname],
  )
  const [isOpen, setIsOpen] = useState(Boolean(containsSelected) || false)
  const toggle = useCallback(() => setIsOpen((s) => !s), [])

  if (!item.items) {
    return null
  }

  return (
    <div>
      <div className={classes.title} onClick={toggle}>
        {item.title}
        <ArrowUpSvgIcon
          className={clsx(classes.collapsedIcon, {
            [classes.isCollapsedIcon]: isOpen,
          })}
        />
      </div>
      <Collapse className={classes.collapse} in={isOpen}>
        {item.items.map((curItem, idx) => {
          if (curItem.items && curItem.items.length) {
            return <CollapsibleNavigationItem key={curItem.title || idx} item={curItem} />
          }
          return <NavigationItem key={curItem.title || idx} item={curItem} />
        })}
      </Collapse>
    </div>
  )
})
