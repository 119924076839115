import React from 'react'

import { TableCell } from './TableCell'
import { TableTreeColumn as TableTreeColumnBase } from '@devexpress/dx-react-grid'
import { makeStyles } from '@material-ui/core'

export type TableTreeColumnCellProps = TableTreeColumnBase.CellProps

const useStyles = makeStyles(() => ({
  treeColumnCell: {
    display: 'flex',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

export function TableTreeColumnCell(props: TableTreeColumnCellProps) {
  const classes = useStyles()

  return (
    <TableCell className={classes.treeColumnCell} {...props}>
      {props.children}
    </TableCell>
  )
}
