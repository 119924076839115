import React, { useMemo } from 'react'

import { TableColumn } from '../types'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

import { useTableModuleStyles } from '../styles'

type TableHeaderCellProps = TableHeaderRow.CellProps &
  StyledProps & { column: TableColumn<unknown> }

export const TableHeaderCell: React.FC<TableHeaderCellProps> = (props) => {
  const { column, children } = props
  const { title, hint, name, type, jsxTitle } = column
  const s = useTableModuleStyles()
  const customClassNames = useMemo(() => [s.Cell, { [s.Cell_center]: type }], [type])
  const [className, style] = useStyleProps(props, column.style?.header, customClassNames)

  const tableHeaderHint = hint || title

  return (
    <TableHeaderRow.Cell
      title={tableHeaderHint}
      {...props}
      className={className + ` Cell_align_${column.align}`}
      style={style}
    >
      {jsxTitle ?? children ?? name}
    </TableHeaderRow.Cell>
  )
}
