import React from 'react'

import { IconButton } from '../../Icon/IconButton'
import { TableCell } from './TableCell'
import { TableRowDetail as TableRowDetailBase } from '@devexpress/dx-react-grid'
import { ExpandMore } from '@material-ui/icons'

import { useTableExpanderStyles } from '../styles'

export const TableToggleCell: React.FC<
  TableRowDetailBase.ToggleCellProps & { canExpandRow?: boolean }
> = ({ onToggle, expanded, row, tableColumn, tableRow, canExpandRow }) => {
  const tableExpanderStyles = useTableExpanderStyles()
  return (
    <TableCell
      style={{ padding: 0 }}
      value={null}
      row={row}
      column={{
        name: 'expand',
      }}
      tableRow={tableRow}
      tableColumn={tableColumn}
    >
      {canExpandRow ? (
        <IconButton
          className={tableExpanderStyles.expander}
          style={{ transform: `rotate(${expanded ? '180' : '0'}deg)` }}
          onClick={onToggle}
        >
          <ExpandMore />
        </IconButton>
      ) : null}
    </TableCell>
  )
}
