export const RegexPattern = {
  onlyWordAndNumber: /[^a-zа-яё0-9]/gi,
  numberDotComma: /^[\d,.]*$/,
  numberDotCommaHyphen: /^[\d,.-]*$/,
  numberSpaces: /[^0-9/\s+/]/gi,
  /**
   * Число с n-количеством знаков до и n-после запятой
   */
  getDecimalByComma: (count1: number, count2: number) =>
    new RegExp(`^(\\d{1,${count1}})(([,.])\\d{1,${count2}})?$`),
}
