import React from 'react'

import { Button } from '../../../Button'
import clsx from 'clsx'

import { FooterProps } from './types'

import { useFooterStyles } from './styles'

export const Footer: React.FC<FooterProps> = ({ className, onAccept, onDecline, disable }) => {
  const styles = useFooterStyles()
  return (
    <div className={clsx(styles.root, className)}>
      <Button
        className={styles.button}
        onClick={onAccept}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Применить
      </Button>
      <Button onClick={onDecline} color="secondary" variant="contained">
        Отменить
      </Button>
    </div>
  )
}
