import React, { PropsWithChildren, useRef } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { DragIndicatorSvgIcon } from '../Icon'
import { Box } from '@material-ui/core'

import { SortableItemsProps } from './types'

const SortableHandler = SortableHandle(() => {
  return <DragIndicatorSvgIcon style={{ margin: '0px 8px', cursor: 'move' }} />
})

const SortableItem = SortableElement<PropsWithChildren>(
  ({ children }: { children: React.ReactChild }) => {
    return (
      <Box zIndex={10000} display="flex" alignItems="center">
        <SortableHandler />
        {children}
      </Box>
    )
  },
)

export function SortableItems<T>(props: SortableItemsProps<T>): JSX.Element {
  const { current: ItemsComponent } = useRef(
    SortableContainer(({ items, onRenderItem }: SortableItemsProps<T>) => {
      return (
        <div>
          {items.map((item, id) => {
            const ItemComponent = onRenderItem(item, id)
            return (
              <SortableItem index={id} key={`render-sort-${id}`}>
                {ItemComponent}
              </SortableItem>
            )
          })}
        </div>
      )
    }),
  )
  return <ItemsComponent {...props} />
}
