import React, { useMemo, useState } from 'react'

import { getCalendar } from '../../helpers/generateCalendar'
import { Day } from '../Day'
import { Weekdays } from '../Weekdays'
import { useIsDaySelected } from './helpers/useIsDaySelected'
import { useIsDisabled } from './helpers/useIsDisabled'
import { useIsEndDay } from './helpers/useIsEndDay'
import { useIsMidDay } from './helpers/useIsMidDay'
import { useIsStartDay } from './helpers/useIsStartDay'
import { useIsToday } from './helpers/useIsToday'

import { CalendarProps } from './types'

import { useDaysCalendarStyles } from './styles'

export const DaysCalendar: React.FC<CalendarProps> = ({
  withTime,
  disableChange,
  valueFrom,
  valueTo,
  withPeriod,
  enabledFrom,
  enabledTo,
  panelValue,
  selectedDate,
  onSelect,
}) => {
  const isToday = useIsToday()
  const dateFrom = useMemo(
    () => valueFrom && new Date(valueFrom.getFullYear(), valueFrom.getMonth(), valueFrom.getDate()),
    [valueFrom],
  )
  const dateTo = useMemo(
    () => valueTo && new Date(valueTo.getFullYear(), valueTo.getMonth(), valueTo.getDate()),
    [valueTo],
  )
  const year = useMemo(() => (panelValue ?? new Date()).getFullYear(), [panelValue])
  const month = useMemo(() => (panelValue ?? new Date()).getMonth(), [panelValue])
  const [innerCurrentHover, setCurrentHover] = useState<null | Date>(null)
  const currentHover = useMemo(() => (!valueFrom || !valueTo ? innerCurrentHover : null), [
    innerCurrentHover,
    valueFrom,
    valueTo,
  ])
  const dateCurrentHover = useMemo(
    () =>
      currentHover &&
      new Date(currentHover.getFullYear(), currentHover.getMonth(), currentHover.getDate()),
    [currentHover],
  )
  const styles = useDaysCalendarStyles()

  const isDaySelected = useIsDaySelected({
    dateCurrentHover,
    withPeriod,
    dateFrom,
    dateTo,
    withTime,
    selectedDate,
  })
  const isStartDay = useIsStartDay({ dateCurrentHover, withPeriod, dateFrom })
  const isMidDay = useIsMidDay({ dateCurrentHover, withPeriod, dateFrom, dateTo })
  const isEndDay = useIsEndDay({ dateCurrentHover, withPeriod, dateFrom, dateTo })
  const isDisabled = useIsDisabled(enabledFrom, enabledTo)

  return (
    <>
      <Weekdays />
      <div className={styles.content}>
        {getCalendar(year)[year][month].map((day, idx) => {
          const dayAsDate = new Date(year, month, parseInt(day))
          return day ? (
            <Day
              onClick={() => onSelect && onSelect(dayAsDate)}
              onHover={() => withPeriod && setCurrentHover(dayAsDate)}
              isHoverState={Boolean(dateCurrentHover)}
              selected={isDaySelected(dayAsDate)}
              disabled={disableChange || isDisabled(dayAsDate)}
              today={isToday(dayAsDate)}
              start={isStartDay(dayAsDate)}
              mid={isMidDay(dayAsDate)}
              end={isEndDay(dayAsDate)}
              key={day}
            >
              {day}
            </Day>
          ) : (
            <div key={`empty-${idx}`} />
          )
        })}
      </div>
    </>
  )
}
