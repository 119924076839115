import React, { CSSProperties } from 'react'

import { Loader } from '../../components'

type LoaderWrapperProps = {
  isLoad: boolean
  rootStyles?: CSSProperties
  rootClassName?: string
  contentStyles?: CSSProperties
  contentClassName?: string
  loaderStyles?: CSSProperties
  loaderClassName?: string
  children?: any
}

const LoaderWrapper: React.FC<LoaderWrapperProps> = ({
  isLoad,
  rootStyles,
  rootClassName,
  contentStyles,
  contentClassName,
  loaderStyles,
  loaderClassName,
  children,
}) => (
  <div className={rootClassName} style={{ position: 'relative', ...rootStyles }}>
    <div
      className={contentClassName}
      style={{
        width: '100%',
        height: '100%',
        opacity: isLoad ? 0.25 : 1,
        pointerEvents: isLoad ? 'none' : undefined,
        transition: '.3s',
        ...contentStyles,
      }}
    >
      {children}
    </div>
    {isLoad && (
      <div
        className={loaderClassName}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
          ...loaderStyles,
        }}
      >
        <Loader />
      </div>
    )}
  </div>
)

export default LoaderWrapper
