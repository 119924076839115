import React, { useContext } from 'react'

import { PenSvgIcon } from '../../../../Icon/icons_old'
import { FilterContext } from '../context'
import { IFilterContext, FilterItem as IFilterItem } from '../typings'
import { useItemFilter } from '../utils/helpers'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

export interface FilterItemProps {
  onEdit: () => void
  onDelete: () => void
  item: IFilterItem
}

function FilterItem({ item, onDelete, onEdit }: FilterItemProps) {
  const { header } = useContext<IFilterContext>(FilterContext)
  const { type, hashRenderItem } = useItemFilter(item, header)

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={8}>
        <Typography>{hashRenderItem[type]}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onEdit}>
          <PenSvgIcon fill="#DADADA" />
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onDelete}>
          <CloseIcon style={{ color: '#DADADA' }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default FilterItem
