import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconTrolley24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M3.75002 17.6644C3.75002 17.5662 3.82866 17.4866 3.92567 17.4866L4.38411 17.4866C4.48112 17.4866 4.55976 17.4071 4.55976 17.3089L4.55976 8.19134C4.55976 8.09316 4.6384 8.01358 4.73541 8.01358L14.7983 8.01358C14.8953 8.01358 14.974 8.09316 14.974 8.19134L14.974 16.0074C14.974 16.1652 15.1692 16.2519 15.2988 16.1644C15.4704 16.0484 15.6589 15.9562 15.8597 15.8924C15.9368 15.8678 15.9922 15.7973 15.9922 15.7155L15.9922 5.90456C15.9922 4.71653 16.9472 3.75 18.1211 3.75C19.295 3.75 20.25 4.71653 20.25 5.90456L20.25 6.75921C20.25 6.85738 20.1714 6.93697 20.0744 6.93697L19.3718 6.93697C19.2748 6.93697 19.1961 6.85738 19.1961 6.75921L19.1961 5.90456C19.1961 5.30464 18.7139 4.81658 18.1211 4.81658C17.5283 4.81658 17.046 5.30464 17.046 5.90456L17.046 15.7155C17.046 15.7973 17.1015 15.8679 17.1786 15.8924C18.0724 16.1767 18.7226 17.0227 18.7226 18.02C18.7226 19.2496 17.7341 20.25 16.5191 20.25C15.5338 20.25 14.6979 19.592 14.4169 18.6874C14.3926 18.6094 14.323 18.5533 14.2421 18.5533L3.92564 18.5533C3.82863 18.5533 3.75 18.4737 3.75 18.3755L3.75002 17.6644ZM16.5191 19.1834C17.153 19.1834 17.6687 18.6614 17.6687 18.0199C17.6687 17.3784 17.153 16.8564 16.5191 16.8564C15.8852 16.8564 15.3695 17.3784 15.3695 18.0199C15.3695 18.6614 15.8852 19.1834 16.5191 19.1834ZM5.61365 17.3089C5.61365 17.4071 5.69229 17.4866 5.78929 17.4866L13.7444 17.4866C13.8414 17.4866 13.9201 17.4071 13.9201 17.3089L13.9201 9.25792C13.9201 9.15974 13.8414 9.08015 13.7444 9.08015L5.78929 9.08016C5.69229 9.08016 5.61365 9.15974 5.61365 9.25792L5.61365 17.3089Z"
        fill="currentColor"
      />
      <path
        d="M9.75557 11.1625C9.82416 11.093 9.93538 11.093 10.004 11.1625L11.8469 13.0276C11.9155 13.097 11.9155 13.2096 11.8469 13.279L11.3501 13.7818C11.2815 13.8512 11.1703 13.8512 11.1017 13.7818L10.7066 13.3819C10.5959 13.2699 10.4067 13.3492 10.4067 13.5076L10.4067 15.5621C10.4067 15.6603 10.3281 15.7399 10.2311 15.7399L9.52848 15.7399C9.43147 15.7399 9.35283 15.6603 9.35283 15.5621L9.35283 13.5076C9.35283 13.3492 9.16363 13.2699 9.05298 13.3819L8.65781 13.7818C8.58922 13.8512 8.478 13.8512 8.40941 13.7818L7.91261 13.279C7.84401 13.2096 7.84401 13.097 7.91261 13.0276L9.75557 11.1625Z"
        fill="currentColor"
      />
      <path
        d="M3.75002 17.6644C3.75002 17.5662 3.82866 17.4866 3.92567 17.4866L4.38411 17.4866C4.48112 17.4866 4.55976 17.4071 4.55976 17.3089L4.55976 8.19134C4.55976 8.09316 4.6384 8.01358 4.73541 8.01358L14.7983 8.01358C14.8953 8.01358 14.974 8.09316 14.974 8.19134L14.974 16.0074C14.974 16.1652 15.1692 16.2519 15.2988 16.1644C15.4704 16.0484 15.6589 15.9562 15.8597 15.8924C15.9368 15.8678 15.9922 15.7973 15.9922 15.7155L15.9922 5.90456C15.9922 4.71653 16.9472 3.75 18.1211 3.75C19.295 3.75 20.25 4.71653 20.25 5.90456L20.25 6.75921C20.25 6.85738 20.1714 6.93697 20.0744 6.93697L19.3718 6.93697C19.2748 6.93697 19.1961 6.85738 19.1961 6.75921L19.1961 5.90456C19.1961 5.30464 18.7139 4.81658 18.1211 4.81658C17.5283 4.81658 17.046 5.30464 17.046 5.90456L17.046 15.7155C17.046 15.7973 17.1015 15.8679 17.1786 15.8924C18.0724 16.1767 18.7226 17.0227 18.7226 18.02C18.7226 19.2496 17.7341 20.25 16.5191 20.25C15.5338 20.25 14.6979 19.592 14.4169 18.6874C14.3926 18.6094 14.323 18.5533 14.2421 18.5533L3.92564 18.5533C3.82863 18.5533 3.75 18.4737 3.75 18.3755L3.75002 17.6644ZM16.5191 19.1834C17.153 19.1834 17.6687 18.6614 17.6687 18.0199C17.6687 17.3784 17.153 16.8564 16.5191 16.8564C15.8852 16.8564 15.3695 17.3784 15.3695 18.0199C15.3695 18.6614 15.8852 19.1834 16.5191 19.1834ZM5.61365 17.3089C5.61365 17.4071 5.69229 17.4866 5.78929 17.4866L13.7444 17.4866C13.8414 17.4866 13.9201 17.4071 13.9201 17.3089L13.9201 9.25792C13.9201 9.15974 13.8414 9.08015 13.7444 9.08015L5.78929 9.08016C5.69229 9.08016 5.61365 9.15974 5.61365 9.25792L5.61365 17.3089Z"
        stroke="currentColor"
        strokeWidth="0.3"
      />
      <path
        d="M9.75557 11.1625C9.82416 11.093 9.93538 11.093 10.004 11.1625L11.8469 13.0276C11.9155 13.097 11.9155 13.2096 11.8469 13.279L11.3501 13.7818C11.2815 13.8512 11.1703 13.8512 11.1017 13.7818L10.7066 13.3819C10.5959 13.2699 10.4067 13.3492 10.4067 13.5076L10.4067 15.5621C10.4067 15.6603 10.3281 15.7399 10.2311 15.7399L9.52848 15.7399C9.43147 15.7399 9.35283 15.6603 9.35283 15.5621L9.35283 13.5076C9.35283 13.3492 9.16363 13.2699 9.05298 13.3819L8.65781 13.7818C8.58922 13.8512 8.478 13.8512 8.40941 13.7818L7.91261 13.279C7.84401 13.2096 7.84401 13.097 7.91261 13.0276L9.75557 11.1625Z"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default IconTrolley24
