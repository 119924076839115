import { useEffect, useMemo, useState } from 'react'

type UsePageableParams<T> = {
  items: Array<T>
  countOnPage: number
}

type UsePageableReturn<T> = {
  items: Array<T>
  totalPages: number
  currentPage: number
  setCurrentPage: (value: number) => void
}

/**
 * @default currentPage = 1
 */
const usePageable = <T>({ items, countOnPage }: UsePageableParams<T>): UsePageableReturn<T> => {
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = useMemo(() => Math.ceil(items.length / countOnPage), [items, countOnPage])

  useEffect(() => {
    if (items.length > 0 && currentPage > totalPages) {
      setCurrentPage(totalPages)
    }
  }, [items.length, currentPage, totalPages])

  const itemsOnPage = useMemo(
    () =>
      items.slice((currentPage - 1) * countOnPage, (currentPage - 1) * countOnPage + countOnPage),
    [countOnPage, items, currentPage],
  )

  return {
    items: itemsOnPage,
    totalPages,
    currentPage,
    setCurrentPage,
  }
}

export default usePageable
