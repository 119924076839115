import React, { CSSProperties } from 'react'
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar'

import { ContextMenuItem } from '../ContextMenu'
import { Item } from '../TableToolbar/components/Filter/utils/helpers'
import {
  Column,
  SortingStateProps,
  SummaryItem,
  TableBandHeader,
  TableSummaryRow,
} from '@devexpress/dx-react-grid'
import { TableColumnResizingProps, VirtualTable } from '@devexpress/dx-react-grid-material-ui'

/**
 * Свойства таблицы.
 */
export interface TableProps<R> {
  /**
   * Массив рядов.
   */
  rows: R[]

  /**
   * Массив колонок для рядов.
   */
  columnBands?: TableBandHeader.ColumnBands[]

  /**
   * Выравнивание заголовка columnBand по вертикали
   */
  columnBandsAlign?: CSSProperties['alignItems']

  /**
   * Выравнивание заголовка columnBand по горизонтали
   */
  columnBandsJustifyContent?: CSSProperties['justifyContent']

  /**
   * Массив колонок для рядов.
   */
  columns: TableColumn<R>[]

  /**
   * Объединения ячеек.
   */
  spans?: TableSpan<R>[]

  /**
   * Поведение ширины колонок.
   */
  tableColumnResizingProps?: TableColumnResizingProps

  /**
   * Функция, вызываемая при клике на кнопке, принимает ряд и колонку с кнопкой, на которых был осуществлен клик.
   */
  onButtonClick?: (row: R, column: TableColumn<R>) => void

  /**
   * Функция, вызываемая при изменении содержимого инпута в колонке, принимает ряд и колонку с инпутом, в которых произошло изменение.
   */
  onInputChange?: (row: R, column: TableColumn<R>, value: string) => void

  /**
   * Функция, вызываемая при изменении состояния чекбокса в колонке, принимает ряд и колонку с чекбоксом, в которых произошло изменение.
   */
  onCheckboxChange?: (row: R, column: TableColumn<R>, value: boolean) => void

  /**
   * Состояние сортировки.
   */
  sortingProps?: SortingStateProps

  /**
   * Пропсы react-perfect-scrollbar
   */
  scrollbarProps?: ScrollBarProps & { ref: React.RefObject<PerfectScrollbar> }

  /**
   * Зафиксированные колонки слева/справа.
   */
  fixedColumns?: {
    left?: string[]
    right?: string[]
  }

  /**
   * Состояние выбора рядов таблицы.
   */
  rowsSelection?: RowsSelection<R>

  /**
   * Включение возможности сортировки.
   */
  enableSorting?: boolean

  /**
   * Включение возможности изменения размеров колонок.
   */
  enableDragging?: boolean

  /**
   * Включение возможности drag'n'drop.
   */
  enableColumnResizing?: boolean

  /**
   * Включение/выключение возможности скролла.
   */
  enableScroll?: boolean

  /**
   * Стиль таблицы.
   */
  style?: TableStyle<R>

  /**
   * Узкие строки
   */
  narrowRows?: boolean

  /**
   * Узкие колонки
   */
  narrowColumns?: boolean

  /**
   * Очень узкие строки
   */
  superNarrowRows?: boolean

  /**
   * Строки с минимальной высотой в 28px
   */
  ultraNarrowRows?: boolean

  /**
   * Очень узкие колонки
   */
  superNarrowColumns?: boolean

  /**
   * Функция для нахождения выделенных строк
   */
  hasCheckedElement?: (row: R, rows: R[]) => boolean

  /**
   * Функция для нахождения индекса отмеченного элемента в массиве rows; -1 в случае отсутствия отмеченных строк
   */
  indexCheckedElement?: (row: R, rows: R[]) => number

  /**
   * Функция для нахождения индекса выделенного элемента
   */
  indexHighlightElement?: (row: R, rows: R[]) => number

  /**
   * Сообщение при отсутствии данных
   */
  noDataMessage?: string | JSX.Element

  /**
   * Сообщение при отсутствии колонок
   */
  noColumnMessage?: string

  /**
   * Скрыть блок с сообщением об отсутствии данных
   */
  hideNoDataMessage?: boolean

  /**
   * Скрыть строку с заголовками
   */
  hideHeadComponent?: boolean

  /**
   * Зафиксировать заголовок таблицы
   */
  fixHeader?: boolean

  /**
   * Включить пагинацию
   */
  enablePaging?: boolean

  /**
   * Возможность скрыть/отобразить таблицу полностью
   */
  isHide?: boolean

  /**
   * Количество элементов на странице
   */
  pageSize?: number

  /**
   * Событие изменения размера таблицы
   */
  onPageSizeChange?: (pageSize: number) => void

  /**
   * Массив элементов выбора размера страницы
   */
  pageSizeList?: { name: string; value: number }[]

  /**
   * Текущая страница
   */
  currentPage?: number

  /**
   * Событие изменения страницы
   */
  onCurrentPageChange?: (currentPage: number) => void

  /**
   * Общее количество
   */
  totalPages?: number

  /**
   * Флаг загрузки для пагинации
   */
  loading?: boolean

  /**
   * Массив раскрывающихся рядов
   */
  expandedRows?: (string | number)[]

  /**
   * Функция рендеринга раскрытия строки
   * @param row объект строки
   */
  renderExpandRow?: (row: R) => React.ReactElement

  /**
   * Функция для определения, доступно ли раскрытие строки
   * @param row объект строки
   */
  canExpandRow?: ((row: R) => boolean) | boolean

  /**
   * Сторонний эффект-обработчик на раскрытие строки
   */
  onOpenExpandRow?: (row: R) => void

  /**
   * Сторонний эффект-обработчик на закрытие строки
   */
  onCloseExpandRow?: (row: R) => void

  /**
   * Функция для получения вложенных строк
   * @param currentRow - строка, которая отрисовывается
   * @param rootRows - корневые строки
   */
  getChildRows?: (currentRow: R | null, rootRows: Array<R>) => Array<R> | null

  /**
   * массив с id расхлопнутых строк в TreeDataTable
   */
  expandedRowIds?: Array<number | string>

  /**
   * callback для управления состоянем расхолпнутых строк таблицы
   */
  onExpandedRowIdsChange?: (expandedRowIds: Array<number | string>) => void

  /**
   * ref на virtualTable
   */
  tableRef?: React.RefObject<typeof VirtualTable>

  /**
   * Чередование фона строк
   */
  isRowsBgAlternation?: boolean

  /**
   *Для получения строки которая рендерится при скролле обязательно нужно включить enableScroll
   */
  rowShown?: (row: R) => void
  getRowId?: (row: R) => string | number

  /**
   * Флаг для события hover блока controls
   */
  onHoverControls?: boolean

  /**
   * Высота VirtualTable default 530
   */
  virtualTableHeight?: string | number

  /**
   * Для подключения плагинов @devexpress
   */
  children?: React.ReactNode

  /**
   * Для компонента итоговой строки @devexpress
   */
  summary?: {
    /**
     * Сводка данных
     */
    summaryItems: SummaryItem[]

    /**
     * Позволяет рассчитать пользовательскую сводку данных
     */
    summaryRowItems: string[]

    /**
     * Компонент итоговой строки
     */
    summaryRowItemComponent: React.ComponentType<TableSummaryRow.ItemProps>
  }
  /**
   * callback получения содержимого наведенной строки
   */
  onHoverGetRow?: (row: any) => void
  /**
   * callback получения содержимого строки по клику
   */
  onClickGetRow?: (row: any) => void
  /**
   * массив данных с содержимым контекстного меню
   */
  contextMenu?: ContextMenuItem[]
}

/**
 * Колонка таблицы.
 */
export interface TableColumn<R> extends Column {
  /**
   * Ширина, если number - в пикселях, если string - css-свойство (например: '100px', '20%', 'auto').
   */
  width?: number | string

  /**
   * Выравнивание. Выравнивание не только текста, но и самих отступов.
   */
  align?: 'left' | 'right' | 'center'

  /**
   * Включение переноса по словам.
   */
  wordWrapEnabled?: boolean

  /**
   * Стиль колонки.
   */
  style?: ColumnStyle<R>

  /**
   * Функция для определения иконки по ряду.
   */
  getIcon?: (row: R) => React.ReactNode | undefined

  /**
   * Тип колонки.
   */
  type?: 'checkbox' | 'button' | 'input'

  /**
   * Функция для определения рисуемого элемента в ячейке, если необходимо нарисовать что-то больше, чем просто текст или элемент, доступный через type.
   */
  render?: (row: R) => React.ReactNode | undefined

  /**
   * Тип данных
   */
  dataType?: 'numeric' | 'string' | 'boolean' | 'date' | 'datetime' | 'custom'

  /**
   * Если dataType === 'custom', то следует передать массив значений для методов фильтрации в поле customMethods
   */
  customMethods?: Array<Item>

  /**
   * Если dataType === 'custom', то следует передать массив значений для фильтрации в поле customValues
   */
  customValues?: Array<{ value: string; title: string; type?: string }>

  /**
   * Если title колонки нужен в виде JSX - передаем
   */
  jsxTitle?: JSX.Element | null

  /**
   * Флаг отображения текущего столбца
   */
  visible?: boolean

  /**
   * Флаг закрепления столба слева
   */
  pinned?: boolean

  /**
   * Флаг закрепления столба справа
   */
  rightPinned?: boolean

  /**
   * Текст нативной браузерной подсказки
   */
  hint?: string
}

/**
 * Свойства стиля элемента.
 */
export interface StyleProps {
  /**
   * Стиль.
   */
  style?: CSSProperties

  /**
   * Имя класса.
   */
  className?: string
}

/**
 * Свойства стиля элемента, относящегося к какому-то ряду таблицы.
 */
export interface RowStyleProps<R> {
  /**
   * Предустановленный цвет
   */
  color?: (row: R) => 'green' | 'red' | 'yellow' | undefined

  /**
   * Пользовательский набор цветов
   */
  colors?: (row: R) => string[] | undefined

  /**
   * Стиль.
   */
  style?: CSSProperties | ((row: R) => CSSProperties | undefined)

  /**
   * Имя класса.
   */
  className?: string | ((row: R) => string | undefined)

  /**
   * Строка таблицы со sticky
   */
  sticky?: (row: R) => boolean
}

/**
 * Стиль таблицы.
 */
export interface TableStyle<R> {
  /**
   * Стиль контейнера таблицы
   */
  container?: StyleProps

  /**
   * Стиль скроллбара
   */
  scrollbar?: StyleProps

  /**
   * Стиль корневого div-элемента.
   */
  root?: StyleProps

  /**
   * Стиль table-элемента.
   */
  table?: StyleProps

  /**
   * Стиль thead-элемента.
   */
  header?: StyleProps

  /**
   * Стиль tbody-элемента.
   */
  body?: StyleProps

  /**
   * Стиль tr-элемента заголовков.
   */
  headerRow?: StyleProps

  /**
   * Стиль tr-элемента рядов.
   */
  row?: RowStyleProps<R>

  /**
   * Стиль tr-элемента выбранных рядов.
   */
  selectedRow?: RowStyleProps<R>

  /**
   * Стиль для нижнего закрепленного ряда
   */
  summaryRow?: RowStyleProps<R>
}

/**
 * Стиль колонки.
 */
export interface ColumnStyle<R> {
  /**
   * Стиль th-элемента заголовка.
   */
  header?: StyleProps

  /**
   * Стиль td-элемента колонки.
   */
  cell?: RowStyleProps<R>
}

/**
 * Состояние выбора рядов таблицы.
 */
export interface RowsSelection<R> {
  /**
   * Включение выбора рядов подсвечиванием.
   */
  highlightEnabled?: boolean

  /**
   * Включение исключающего подсвечивания.
   */
  singleHighlight?: boolean

  /**
   * Подсвеченные ряды.
   */
  highlighted?: R[]

  /**
   * Функция должна возвращать индекс выделенного элемента; -1 в случае отсутствия выделенных строк
   */
  indexHighlightElement?: (row: R, rows: R[]) => number

  /**
   * Функция, вызываемая при изменении подсвеченных рядов.
   */
  onHighlightedChange?: (selection: R[]) => void

  /**
   * Включение выбора рядов чекбоксом.
   */
  checkEnabled?: boolean

  /**
   * Зафиксировать столбец с чекбоксами при горизонтальном скролле
   */
  isStickyCheckColumn?: boolean

  /**
   * Включение возможности выбора конкретного ряда.
   */
  rowCheckEnabled?: (row: R) => boolean

  /**
   * Включение возможности изменения состояния выбора конкретного ряда.
   */
  rowCheckReadonly?: (row: R) => boolean

  /**
   * Функция, для задания кастомного JSX для TableSelectionCell
   */
  rowCheckElement?: (row: R) => JSX.Element | null

  /**
   * Включение исключающего выбора чекбоксом.
   */
  singleCheck?: boolean

  /**
   * Выбранные чекбоксом ряды.
   */
  checked?: R[]

  /**
   * Функция, вызываемая при изменении выбранных чекбоксом рядов.
   */
  onCheckedChange?: (checked: R[]) => void

  /**
   * Ширина колонки чекбоксов для выбора рядов.
   */
  checkColumnWidth?: number
}

/**
 * Возможные ориентации объединения ячеек.
 */
export enum SpanOrientation {
  HORIZONTAL,
  VERTICAL,
}

export interface TableSpan<R> {
  /**
   * Ряд начальной ячейки.
   */
  row: R

  /**
   * Колонка начальной ячейки.
   */
  column: TableColumn<R>

  /**
   * Ориентация объединения.
   */
  orientation: SpanOrientation

  /**
   * Количество стираемых ячеек (для горизонтального объединения - ячеек справа, для вертикального - ячеек снизу).
   */
  span: number
}
