import React from 'react'

import { Button } from '../../../'
import { Badge } from '../../../Badge/Badge'
import Grid from '@material-ui/core/Grid'

export interface BaseToolbarFooterProps {
  onSave: () => void
  onClose: () => void
  length?: number
  disabled?: boolean
}

function BaseToolbarFooter({ onClose, onSave, length, disabled }: BaseToolbarFooterProps) {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          size="small"
          endIcon={!!length && <Badge badgeContent={length} color="secondary" />}
          onClick={onSave}
        >
          Применить
        </Button>
      </Grid>
      {!disabled && (
        <Grid item>
          <Button color="secondary" variant="contained" size="small" onClick={onClose}>
            Закрыть
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default BaseToolbarFooter
