import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { Box } from '../Box'
import { ContextMenuProvider } from '../ContextMenu'
import { SortAscendingSvgIcon, SortDescendingSvgIcon } from '../Icon'
import { Loader } from '../Loader'
import { Pagination } from '../Pagination'
import { GridTable } from './subcomponents/GridTable'
import getTableBandCell from './subcomponents/TableBandCell'
import { TableBase } from './subcomponents/TableBase'
import { TableBody } from './subcomponents/TableBody'
import { TableCell } from './subcomponents/TableCell'
import TableContainer from './subcomponents/TableContainer'
import { TableFixedCell } from './subcomponents/TableFixedCell'
import { TableHeader } from './subcomponents/TableHeader'
import { TableHeaderCell } from './subcomponents/TableHeaderCell'
import { TableHeaderRow } from './subcomponents/TableHeaderRow'
import { TablePageSelect } from './subcomponents/TablePageSelect'
import { TableRoot } from './subcomponents/TableRoot'
import { TableRow } from './subcomponents/TableRow'
import { TableSelectionCell } from './subcomponents/TableSelectionCell'
import { TableSelectionHeaderCell } from './subcomponents/TableSelectionHeaderCell'
import { TableSelectionRow } from './subcomponents/TableSelectionRow'
import { TableSummaryRow } from './subcomponents/TableSummaryRow'
import { TableToggleCell } from './subcomponents/TableToggleCell'
import { TableTreeColumnCell } from './subcomponents/TableTreeColumnCell'
import { TreeDataState } from './subcomponents/TreeDataState'
import { addIdxesIfNeeded } from './utils/addIdxesIfNeeded'
import {
  ContextCustomFields,
  CustomFieldsCtx,
  RowCellsSpanData,
  SpanData,
} from './utils/customFieldsCtx'
import {
  CustomSummary,
  CustomTreeData,
  IntegratedSelection,
  IntegratedSorting,
  PagingPanel,
  PagingState,
  RowDetailState,
  SelectionState,
  SelectionStateProps,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid'
import {
  Table as DevexpressTable,
  TableHeaderRow as DevexpressTableHeaderRow,
  TableSummaryRow as DevexpressTableSummaryRow,
  DragDropProvider,
  TableBandHeader,
  TableColumnResizing,
  TableColumnResizingProps,
  TableColumnVisibility,
  TableFixedColumns,
  TableRowDetail,
  TableSelection,
  TableTreeColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import difference from 'ramda/src/difference'

import { RowsSelection, SpanOrientation, TableColumn, TableProps } from './types'

import { useTableModuleStyles } from './styles'

export function Table<R>(props: TableProps<R>): ReactElement<TableProps<R>> {
  const {
    rows,
    columns,
    tableColumnResizingProps,
    sortingProps,
    enableDragging,
    fixedColumns,
    enableSorting,
    expandedRows,
    onOpenExpandRow,
    onCloseExpandRow,
    pageSize,
    onPageSizeChange,
    pageSizeList,
    enablePaging,
    currentPage,
    onCurrentPageChange,
    totalPages,
    loading,
    isHide,
    children,
    columnBandsAlign,
    columnBandsJustifyContent,
    noDataMessage = 'Нет данных',
    noColumnMessage = 'Все колонки скрыты. Перейдите в "Отображение" чтобы изменить',
    summary,
    tableRef,
    onHoverGetRow,
    onClickGetRow,
    contextMenu,
  } = props
  let firstColumnKey, treeDataInitialColumn
  if (columns.length !== 0) {
    firstColumnKey = String(Object.keys(columns[0])[0])
    treeDataInitialColumn = columns[0][firstColumnKey]
  }
  const columnExtensions = useMemo(
    () => createColumnExtensions(columns, tableColumnResizingProps),
    [columns, tableColumnResizingProps],
  )
  const { highlightEnabled, checkEnabled, selectionProps, checkColumnWidth } = useSelectionProps(
    rows,
    props.rowsSelection,
  )
  const enableSelection = highlightEnabled || checkEnabled
  const contextCustomFields = useContextCustomFields(props)
  const [expandedTableRows, setExpandedRows] = useState<(string | number)[]>(expandedRows || [])
  useEffect(() => {
    if (pageSizeList && !pageSizeList?.some((el) => el.value == pageSize)) {
      pageSizeList && onPageSizeChange && onPageSizeChange(pageSizeList[0].value)
      console.error('Current pageSize property does not exist in pageSizeList')
    }
  }, [])
  const styles = useTableModuleStyles()

  useEffect(() => {
    isHide
      ? setExpandedRows([])
      : setExpandedRows(
          // any потому что не знаем что может быть в row, в будущем стоит пересмотреть логику
          rows.map((row: any) => {
            if (typeof props.canExpandRow === 'boolean' && props.canExpandRow) {
              return row?.id
            }
            if (typeof props.canExpandRow === 'function' && props.canExpandRow(row)) {
              return row.id
            }
          }),
        )
  }, [isHide])

  useEffect(() => {
    setExpandedRows(expandedRows || [])
  }, [])

  /**
   * Для отображения раскрывающихся строк таблицы
   */
  const contentComponent = useCallback(
    ({ row }) => (props.renderExpandRow ? props.renderExpandRow(row) : null),
    [props.renderExpandRow],
  )

  const expandRowComponent = useCallback(
    (rowProps) => {
      return (
        <TableRow
          isRowsBgAlternation={
            props.isRowsBgAlternation &&
            typeof rowProps.row.idx === 'number' &&
            rowProps.row.idx % 2 === 0
          }
          className={
            expandedTableRows.includes(rowProps.row.id) &&
            rowProps.row.id !== undefined &&
            rowProps.row.id !== null &&
            'expanded'
          }
          onShow={props.rowShown}
          onHoverGetRow={onHoverGetRow}
          onClickGetRow={onClickGetRow}
          contextMenu={contextMenu}
          {...rowProps}
        />
      )
    },
    [expandedTableRows],
  )
  const tableSummaryRow = useCallback((props) => <TableSummaryRow {...props} />, [summary])
  const toggleCellComponent = useCallback((toggleCellComponentProps) => {
    return (
      <TableToggleCell
        canExpandRow={
          typeof props.canExpandRow === 'boolean'
            ? props.canExpandRow
            : props.canExpandRow
            ? props.canExpandRow(toggleCellComponentProps.row)
            : false
        }
        {...toggleCellComponentProps}
      />
    )
  }, [])
  const onExpandRowChange = (props) => {
    setExpandedRows((prev) => {
      if (prev.length < props.length) {
        onOpenExpandRow && onOpenExpandRow(rows[difference(props, prev)[0]])
      }
      if (prev.length > props.length) {
        onCloseExpandRow && onCloseExpandRow(rows[difference(props, prev)[0]])
      }
      return props
    })
  }

  const renderTable = () => {
    return (
      <GridTable
        rows={addIdxesIfNeeded<R>(rows, props.isRowsBgAlternation)}
        columns={columns}
        rootComponent={TableRoot}
        getRowId={props.getRowId}
      >
        {selectionProps && <SelectionState {...selectionProps} />}
        {summary && <SummaryState totalItems={summary?.summaryItems} />}
        {summary && <CustomSummary totalValues={summary?.summaryRowItems} />}
        {enableSorting && sortingProps && <SortingState {...sortingProps} />}
        {(enablePaging || pageSize || pageSizeList) && (
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />
        )}
        {enableDragging && <DragDropProvider />}
        {props.getChildRows && (
          <TreeDataState
            expandedRowIds={props.expandedRowIds}
            onExpandedRowIdsChange={props.onExpandedRowIdsChange}
          />
        )}
        {props.getChildRows && <CustomTreeData getChildRows={props.getChildRows} />}

        <DevexpressTable
          containerComponent={TableContainer}
          columnExtensions={columnExtensions}
          tableComponent={TableBase}
          headComponent={props.hideHeadComponent ? () => <></> : TableHeader}
          bodyComponent={TableBody}
          cellComponent={TableCell}
          rowComponent={expandRowComponent}
          noDataCellComponent={(cell) =>
            props.hideNoDataMessage ? (
              <></>
            ) : (
              <td className={`MuiTableCell-root ${styles.NoDataCell}`} colSpan={cell.colSpan}>
                <div className={styles.Message}>{noDataMessage}</div>
              </td>
            )
          }
        />
        {props.renderExpandRow && (
          <RowDetailState
            expandedRowIds={expandedTableRows}
            onExpandedRowIdsChange={onExpandRowChange}
          />
        )}
        {props.renderExpandRow && (
          <TableRowDetail
            toggleCellComponent={toggleCellComponent}
            rowComponent={expandRowComponent}
            contentComponent={contentComponent}
          />
        )}
        {enableSelection && <IntegratedSelection />}
        {enableSorting && <IntegratedSorting />}
        {props.enableScroll && (
          <VirtualTable
            height={props.virtualTableHeight}
            rowComponent={expandRowComponent}
            columnExtensions={columnExtensions}
            tableComponent={TableBase}
            headComponent={props.hideHeadComponent ? () => <></> : TableHeader}
            cellComponent={TableCell}
            ref={tableRef}
            noDataCellComponent={(cell) =>
              props.hideNoDataMessage ? (
                <></>
              ) : (
                <td className={`MuiTableCell-root ${styles.NoDataCell}`} colSpan={cell.colSpan}>
                  <div className={styles.Message}>{noDataMessage}</div>
                </td>
              )
            }
          />
        )}
        {enableSelection && (
          <TableSelection
            rowComponent={TableSelectionRow}
            cellComponent={TableSelectionCell}
            headerCellComponent={TableSelectionHeaderCell}
            selectionColumnWidth={checkColumnWidth}
            selectByRowClick={highlightEnabled}
            highlightRow={highlightEnabled}
            showSelectAll={checkEnabled}
            showSelectionColumn={checkEnabled}
          />
        )}
        {props.enableColumnResizing && <TableColumnResizing {...tableColumnResizingProps} />}
        <DevexpressTableHeaderRow
          showSortingControls={enableSorting}
          sortLabelComponent={(props) => {
            const isDirectionDesc = props.direction === 'desc'
            return (
              (enableSorting && (
                <div
                  onClick={() =>
                    props.onSort({
                      direction: isDirectionDesc ? 'asc' : 'desc',
                    })
                  }
                  className={styles.SortWrapper}
                >
                  {props.direction ? (
                    isDirectionDesc ? (
                      <SortAscendingSvgIcon className={styles.SortIcon} />
                    ) : (
                      <SortDescendingSvgIcon className={styles.SortIcon} />
                    )
                  ) : null}
                  {props.children}
                </div>
              )) ||
              null
            )
          }}
          rowComponent={TableHeaderRow}
          cellComponent={TableHeaderCell}
        />
        {props.getChildRows && columns.length !== 0 && (
          <TableTreeColumn cellComponent={TableTreeColumnCell} for={treeDataInitialColumn} />
        )}
        {props.columnBands && (
          <TableBandHeader
            columnBands={props.columnBands}
            cellComponent={getTableBandCell(columnBandsAlign, columnBandsJustifyContent)}
          />
        )}
        <TableColumnVisibility
          messages={{
            noColumns: noColumnMessage,
          }}
          hiddenColumnNames={
            columns &&
            columns
              .filter((e) => e.hasOwnProperty('visible') && e.visible === false)
              .map((e) => e.name)
          }
        />
        {summary && (
          <DevexpressTableSummaryRow
            totalRowComponent={tableSummaryRow}
            itemComponent={summary?.summaryRowItemComponent}
          />
        )}
        <TableFixedColumns
          cellComponent={TableFixedCell}
          leftColumns={
            fixedColumns?.left || (columns && columns.filter((e) => e.pinned).map((e) => e.name))
          }
          rightColumns={
            fixedColumns?.right ||
            (columns && columns.filter((e) => e.rightPinned).map((e) => e.name))
          }
        />
        {(enablePaging || pageSize || pageSizeList) && (
          <PagingPanel
            containerComponent={() => (
              <div>
                <Box className={styles.PagingWrapper}>
                  {(pageSize || pageSizeList) && (
                    <TablePageSelect
                      pageSize={pageSize}
                      pageSizeList={pageSizeList}
                      onPageSizeChange={onPageSizeChange}
                    />
                  )}
                  {enablePaging && onCurrentPageChange && totalPages && pageSize && (
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(_, page) => onCurrentPageChange(page)}
                    />
                  )}
                </Box>
              </div>
            )}
          />
        )}
        {children}
        {loading && <Loader />}
      </GridTable>
    )
  }

  return (
    <CustomFieldsCtx.Provider value={contextCustomFields}>
      {contextMenu ? <ContextMenuProvider>{renderTable()}</ContextMenuProvider> : renderTable()}
    </CustomFieldsCtx.Provider>
  )
}

function createColumnExtensions<R>(
  columns: TableColumn<R>[],
  tableColumnResizingProps?: TableColumnResizingProps,
): DevexpressTable.ColumnExtension[] {
  const widthInfos = tableColumnResizingProps?.columnWidths || []
  return columns?.map((column) => ({
    columnName: column.name,
    width:
      column.width ||
      widthInfos.filter((widthInfo) => widthInfo.columnName === column.name)[0]?.width,
    align: column.align,
    wordWrapEnabled: column.wordWrapEnabled,
    visible: column.visible,
  }))
}

interface SelectionProps {
  readonly highlightEnabled: boolean
  readonly checkEnabled: boolean
  readonly selectionProps?: SelectionStateProps
  readonly checkColumnWidth: number
}

function useSelectionProps<R>(rows: R[], rowsSelection?: RowsSelection<R>): SelectionProps {
  const highlightEnabled = !!rowsSelection?.highlightEnabled
  const checkEnabled = !!rowsSelection?.checkEnabled
  const highlighted = rowsSelection?.highlighted
  const onHighlightedChange = rowsSelection?.onHighlightedChange
  const singleHighlight = rowsSelection?.singleHighlight
  const indexHighlightElement = rowsSelection?.indexHighlightElement
  const selectionProps: SelectionStateProps | undefined = useMemo(() => {
    return (
      ((highlightEnabled || checkEnabled) && {
        selection:
          (highlightEnabled &&
            highlighted?.map((row: R): number =>
              rowsSelection?.indexHighlightElement
                ? rowsSelection?.indexHighlightElement(row, rows)
                : rows.indexOf(row),
            )) ||
          [],
        defaultSelection: [],
        onSelectionChange: (selection: Array<number | string>) => {
          if (highlightEnabled && onHighlightedChange) {
            let newHighlighted = selection
              .map((value) => typeof value === 'number' && rows[value])
              .filter((row): row is R => !!row)
            if (singleHighlight) {
              const highlightedSet = new Set(highlighted)
              newHighlighted = newHighlighted.filter((row) =>
                indexHighlightElement
                  ? indexHighlightElement(row, Array.from(highlightedSet)) === -1
                  : !highlightedSet.has(row),
              )
            }
            onHighlightedChange(newHighlighted)
          }
        },
      }) ||
      undefined
    )
  }, [rows, highlightEnabled, checkEnabled, singleHighlight, highlighted, onHighlightedChange])
  const checkColumnWidth = checkEnabled
    ? typeof rowsSelection?.checkColumnWidth === 'number'
      ? rowsSelection?.checkColumnWidth
      : 60
    : 0
  return { highlightEnabled, checkEnabled, selectionProps, checkColumnWidth }
}

function useContextCustomFields<R>(props: TableProps<R>): ContextCustomFields<unknown> {
  const {
    columnBands,
    rows,
    columns,
    onButtonClick,
    onInputChange,
    onCheckboxChange,
    hasCheckedElement,
    indexCheckedElement,
    indexHighlightElement,
    rowsSelection,
    style,
    spans,
    narrowRows,
    narrowColumns,
    superNarrowRows,
    ultraNarrowRows,
    superNarrowColumns,
    fixHeader,
    scrollbarProps,
    onHoverControls,
  } = props
  const rowCheckEnabled = rowsSelection?.rowCheckEnabled
  const rowCheckReadonly = rowsSelection?.rowCheckReadonly
  const checkEnabledRows = useMemo(
    () => (!rowCheckEnabled ? rows : rows.filter((row) => rowCheckEnabled(row))),
    [rows, rowCheckEnabled],
  )
  const checkableRows = useMemo(
    () =>
      !rowCheckReadonly
        ? checkEnabledRows
        : checkEnabledRows.filter((row) => !rowCheckReadonly(row)),
    [checkEnabledRows, rowCheckReadonly],
  )
  const checked = rowsSelection?.checked
  const highlighted = rowsSelection?.highlighted
  const checkedRows = useMemo(() => new Set(checked), [checked])
  const highlightedRows = useMemo(() => new Set(highlighted), [highlighted])
  const alwaysCheckedRows = useMemo(
    () => (!rowCheckReadonly ? [] : (checked || []).filter((row) => rowCheckReadonly(row))),
    [checked, rowCheckReadonly],
  )
  const contextSpans: Map<R, RowCellsSpanData<R>> | null = useMemo(() => {
    if (!spans) {
      return null
    }
    const result = new Map<R, RowCellsSpanData<R>>()
    const getRowSpanData = (row: R): RowCellsSpanData<R> => {
      const possibleRowCellsSpanData = result.get(row)
      const rowCellsSpanData = possibleRowCellsSpanData || new Map<TableColumn<R>, SpanData[]>()
      if (!possibleRowCellsSpanData) {
        result.set(row, rowCellsSpanData)
      }
      return rowCellsSpanData
    }
    for (const { row, column, orientation, span } of spans) {
      const rowSpanData = getRowSpanData(row)
      const startRowIndex = rows.indexOf(row)
      const startColumnIndex = columns && columns.indexOf(column)
      const possibleRowSpanData = rowSpanData.get(column)
      const spanData = (possibleRowSpanData && [...possibleRowSpanData]) || []
      spanData.push({
        orientation,
        span: Math.min(
          span,
          orientation === SpanOrientation.HORIZONTAL
            ? columns && columns.length - startColumnIndex
            : rows.length - startRowIndex,
        ),
      })
      rowSpanData.set(column, spanData)
      const verticalSpans = spanData
        .filter((sd) => sd.orientation === SpanOrientation.VERTICAL)
        .map((sd) => sd.span)
      const lastRowIndex =
        startRowIndex + ((verticalSpans.length && Math.max(...verticalSpans)) || 1)
      const horizontalSpans = spanData
        .filter((sd) => sd.orientation === SpanOrientation.HORIZONTAL)
        .map((sd) => sd.span)
      const lastColumnIndex =
        startColumnIndex + ((horizontalSpans.length && Math.max(...horizontalSpans)) || 1)
      for (let rowIndex = startRowIndex; rowIndex < lastRowIndex; rowIndex++) {
        const rowSpanData = getRowSpanData(rows[rowIndex])
        for (let columnIndex = startColumnIndex; columnIndex < lastColumnIndex; columnIndex++) {
          if (columnIndex !== startColumnIndex || rowIndex !== startRowIndex) {
            rowSpanData.set(columns[columnIndex], null)
          }
        }
      }
    }
    return result
  }, [rows, columns, spans])
  // 8 - максимально возможно число колонок в таблице, чобы в ней все еще не появлялся scroll, пришло от дизайнеров и аналитиков
  const isManyColumns = columns && columns.length >= 8
  return useMemo(
    () => ({
      columnBands,
      rows,
      onButtonClick,
      onCheckboxChange,
      onInputChange,
      style,
      rowsSelection,
      checkEnabledRows,
      checkableRows,
      checkedRows,
      alwaysCheckedRows,
      highlightedRows,
      spans: contextSpans,
      narrowRows,
      narrowColumns,
      superNarrowRows,
      ultraNarrowRows,
      superNarrowColumns,
      fixHeader,
      scrollbarProps,
      onHoverControls,
      isManyColumns,
      hasCheckedElement,
      indexCheckedElement,
      indexHighlightElement,
    }),
    [
      rows,
      onButtonClick,
      onCheckboxChange,
      onInputChange,
      style,
      rowsSelection,
      checkEnabledRows,
      checkableRows,
      checkedRows,
      alwaysCheckedRows,
      highlightedRows,
      contextSpans,
      narrowRows,
      narrowColumns,
      superNarrowRows,
      ultraNarrowRows,
      superNarrowColumns,
      fixHeader,
      scrollbarProps,
      onHoverControls,
      isManyColumns,
      hasCheckedElement,
      indexCheckedElement,
      indexHighlightElement,
    ],
  ) as ContextCustomFields<unknown>
}
