import { quarterQuarterKeys } from './quarters'
import { isEqual } from 'date-fns'

export const isEqualDatesAfterLevel = (level: string, valueFrom: Date, valueTo: Date): boolean => {
  const dateFrom = new Date(
    valueFrom?.getFullYear() as number,
    valueFrom?.getMonth() as number,
    valueFrom?.getDate() as number,
  )
  const dateTo = new Date(
    valueTo?.getFullYear() as number,
    valueTo?.getMonth() as number,
    valueTo?.getDate() as number,
  )
  switch (level) {
    case 'day':
      return isEqual(dateFrom, dateTo)
    case 'month':
      dateFrom.setDate(1)
      dateTo.setDate(1)
      return isEqual(dateFrom, dateTo)
    case 'quarter':
      const quarterKeyValueFrom = quarterQuarterKeys[valueFrom?.getMonth() as number]
      const quarterKeyValueTo = quarterQuarterKeys[valueTo?.getMonth() as number]
      const isYearsEqual = (dateFrom.getFullYear() as number) === (dateTo.getFullYear() as number)
      return quarterKeyValueFrom === quarterKeyValueTo && isYearsEqual
    default:
      dateFrom.setMonth(0)
      dateTo.setMonth(0)
      return isEqual(dateFrom, dateTo)
  }
}
