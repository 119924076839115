import { makeStyles } from '@material-ui/core'

export const useCollapsibleNavigationItemStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.textStyles.body1medium.fontSize,
    lineHeight: theme.textStyles.body1medium.lineHeight,
    fontWeight: theme.textStyles.body1medium.fontWeight,
    padding: '14px 24px 16px 0',
    cursor: 'pointer',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  collapse: {
    marginLeft: 16,
  },
  collapsedIcon: {
    position: 'absolute',
    right: -36,
    top: 12,
    transform: 'rotate(-180deg)',
    transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  isCollapsedIcon: {
    transform: 'rotate(0)',
  },
}))
