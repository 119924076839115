import React from 'react'

import {
  useChangeCurrentTheme,
  useGetCurrentTheme,
} from '../../../../components/CommonWrapper/CommonWrapper'
import { MoonSvgIcon, SunSvgIcon } from '../../../Icon'
import { NewTooltip } from '../../../NewTooltip'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import clsx from 'clsx'

import { useNavigationStyles } from '../../styles'

export const ThemeMenuItem = (): JSX.Element => {
  const changeCurrentTheme = useChangeCurrentTheme()
  const theme = useGetCurrentTheme()
  const { listItemGutters, listItemIcon } = useNavigationStyles()

  const handleClick = () => {
    changeCurrentTheme()
  }

  const themeIcon = theme === 'light' ? <MoonSvgIcon /> : <SunSvgIcon />
  const tooltipTitle = 'Сменить тему'

  return (
    <NewTooltip title={tooltipTitle} placement="right">
      <ListItem
        classes={{
          gutters: listItemGutters,
        }}
        onClick={handleClick}
      >
        <ListItemIcon classes={{ root: clsx(listItemIcon) }}>{themeIcon}</ListItemIcon>
        <ListItemText primary={tooltipTitle} />
      </ListItem>
    </NewTooltip>
  )
}
