import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// import { reducerManager } from 'store';
// import { useStoreSelector } from 'store/hooks';
import { createTableReducer } from '../../utils/createTableReducer'
import { TableColumn } from '../components'
import TableManager from '../managers/TableManager'

type UseAsyncTableManagerReturn<R> = {
  manager: TableManager | null
  columns: TableColumn<R>[]
  resetManagerParams: () => void
}

export const useAsyncTableManager = <R>(
  reducerKey: string,
  columns: TableColumn<R>[],
  withResetFilters = true,
): UseAsyncTableManagerReturn<R> => {
  const dispatch = useDispatch()

  const [manager, setManager] = useState<TableManager | null>(null)

  // const reduxColumns = useStoreSelector(
  //   (state) => (state?.[reducerKey]?.params?.viewTable ?? []) as TableColumn<R>[]
  // );
  const reduxColumns = []

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (reducerKey !== '' && columns.length > 0) {
      const reducer = createTableReducer(reducerKey, columns)

      // reducerManager.injectReducer(reducerKey, reducer);
      // setManager(new TableManager({ key: reducerKey }));

      // return () => {
      //   setManager(null);
      //   reducerManager.rejectReducer(reducerKey);
      // };
    }
  }, [reducerKey, columns.length])

  const resetManagerParams = useCallback(() => {
    if (manager) {
      dispatch(manager.resetParams())
    }
  }, [manager])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // if (withResetFilters) return resetManagerParams;
    return resetManagerParams
  }, [resetManagerParams])

  return {
    manager,
    columns: reduxColumns,
    resetManagerParams,
  }
}
