import { IIconButtonProps } from './IconButton'
import { makeStyles, Theme } from '@material-ui/core'

const getBackgroundColor = (theme, variant) => {
  const backgroundColor = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    gray: theme.neutral.grey50,
    outline: 'none',
    text: 'none',
  }
  return backgroundColor[variant] || theme.palette.background.paper
}

const getColor = (theme, variant) => {
  const color = {
    primary: theme.neutral.noChangeWhite,
    secondary: theme.buttonTextColor,
    gray: theme.buttonTextColor,
    outline: theme.palette.primary.main,
    text: theme.palette.primary.main,
  }
  return color[variant] || theme.palette.primary.main
}

const getHoverColor = (theme, variant) => {
  const hoverColor = {
    primary: theme.palette.primary.light,
    secondary: theme.palette.secondary.light,
    gray: theme.neutral.grey100,
    outline: theme.palette.secondary.main,
    text: theme.palette.secondary.light,
  }
  return hoverColor[variant] || theme.palette.secondary.light
}

const getActiveColor = (theme, variant) => {
  const activeColor = {
    primary: theme.palette.primary.dark,
    secondary: theme.palette.secondary.dark,
    gray: theme.neutral.grey200,
    outline: theme.palette.secondary.main,
    text: theme.palette.secondary.main,
  }
  return activeColor[variant] || theme.palette.secondary.dark
}

export const useIconButtonStyles = makeStyles<Theme, IIconButtonProps>((theme) => ({
  root: (props) => ({
    padding: theme.spacing(1),
    color: getColor(theme, props.variant),
    borderRadius: 4,
    backgroundColor: !props.variant ? 'none' : getBackgroundColor(theme, props.variant),
    border: props.variant === 'outline' ? `2px solid ${theme.palette.primary.main}` : undefined,
    '&:hover': {
      backgroundColor: getHoverColor(theme, props.variant),
    },
    '&:active': {
      backgroundColor: getActiveColor(theme, props.variant),
    },
  }),
  sizeSmall: {
    height: '31px',
    padding: '4px 8px 4px 8px !important',
  },
  disabled: (props) => ({
    backgroundColor: `${getBackgroundColor(theme, props.variant)} !important`,
    color: `${getColor(theme, props.variant)} !important`,
    opacity: 0.5,
    cursor: 'default',
    border: props.variant === 'outline' ? `2px solid ${theme.palette.primary.main}` : undefined,
  }),
}))
