import React, { useState } from 'react'

import { ROUTER_CONFIG } from '../../config/routeConfig'
import { IconListAlt24, IconPrint24, IconTableChart24 } from '../../ui-kit/components/Icon'
import { Navigation } from '../../ui-kit/components/Navigation/Navigation'
import { useKeyCloakLogout, useKeyCloakUserName } from 'ui-kit/components/Passport'

export const UserNavigation = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const userName = useKeyCloakUserName()
  const loginOut = useKeyCloakLogout()

  const openMenu = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <Navigation
      onOpenChange={openMenu}
      userInfo={{ name: userName }}
      onLogout={loginOut}
      systemName="Меню"
      isOpen={isOpen}
      topItems={topTabs}
    />
  )
}

export const topTabs: any[] = [
  {
    id: 'warehouse',
    title: 'Склад',
    icon: <IconTableChart24 />,
    items: [
      {
        id: ROUTER_CONFIG.zones.path,
        title: ROUTER_CONFIG.zones.name,
        href: ROUTER_CONFIG.zones.path,
      },
      {
        id: ROUTER_CONFIG.agregates.path,
        title: ROUTER_CONFIG.agregates.name,
        href: ROUTER_CONFIG.agregates.path,
      },
    ],
  },
  {
    id: 'print',
    title: 'Печать',
    icon: <IconPrint24 />,
    href: ROUTER_CONFIG.print.path,
  },
  {
    id: 'report',
    title: 'Отчеты',
    icon: <IconListAlt24 />,
    href: ROUTER_CONFIG.report.path,
  },
]
