import React, { useContext } from 'react'

import { prepareColumnOptions } from '../../../utils'
import { FilterContext } from '../context'
import { ICreateFilter } from '../form'
import { IFilterContext } from '../typings'
import { initialMethod } from '../utils/enums'
import { getInitialLabel } from '../utils/helpers'
import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useFormikContext } from 'formik'

function FilterLabel() {
  const { filter, header, editFilter } = useContext<IFilterContext>(FilterContext)
  const {
    values: { label },
    setFieldValue,
  } = useFormikContext<ICreateFilter>()

  return (
    <Grid item xs={12}>
      <Autocomplete<any>
        options={prepareColumnOptions(header, filter, editFilter?.label)}
        onChange={(_, v) => {
          const newValue = v ? v : initialMethod
          setFieldValue('label', newValue, true)
          setFieldValue('method', initialMethod)
        }}
        value={getInitialLabel(header, label.value)}
        getOptionLabel={(option) => option.label || ''}
        renderInput={(params) => (
          <TextField {...params} label="Название столбца" variant="outlined" size="small" />
        )}
      />
    </Grid>
  )
}

export default FilterLabel
