import React, { FC, useState } from 'react'

import TabPanel from '../../ui-kit/frontend-components/TabPanel'
import UserContent from '../../ui-kit/frontend-components/UserContent'
import WarehouseContainer from '../../ui-kit/frontend-components/WarehouseContainer'
import { EVENT_REASON } from '../../ui-kit/frontend-components/WarehouseContainer/constants'
import { Box, Button } from './../../ui-kit/components'
import { Tabs } from './../../ui-kit/components/Tabs'
import { Tab } from './../../ui-kit/components/Tabs/subcomponents/Tab'
import { makeStyles } from '@material-ui/core'

import ModalSearch from './components/ModalSearch/ModalSearch'

const styles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const Warehouse: FC = () => {
  const classes = styles()

  const [currentTab, setCurrentTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <UserContent title="АРМ склада">
        <Box flexDirection="column" spacing={2}>
          <Box justifyContent="space-between" alignItems="center">
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, newValue: number): void => {
                setCurrentTab(newValue)
              }}
            >
              <Tab label="Размещение" />
              <Tab label="Инвентаризация" />
            </Tabs>
            <Button onClick={() => setIsOpen(true)} color="primary" variant="contained">
              Поиск продукции
            </Button>
          </Box>
          <div key={String(isOpen)}>
            <TabPanel
              className={currentTab === 0 ? classes.flexGrow : undefined}
              value={currentTab}
              index={0}
            >
              <WarehouseContainer
                role="posting"
                workshopName="warehouse.inplast.output"
                selectZoneType="group"
                eventReason={EVENT_REASON.MOVING}
                alias="pipe"
              />
            </TabPanel>
            <TabPanel
              className={currentTab === 1 ? classes.flexGrow : undefined}
              value={currentTab}
              index={1}
            >
              <WarehouseContainer
                role="inventory"
                workshopName="warehouse.inplast.output"
                selectZoneType="group"
                eventReason={EVENT_REASON.INVENTORY}
                alias="pipe"
              />
            </TabPanel>
          </div>
        </Box>
      </UserContent>
      {isOpen && <ModalSearch isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  )
}

export default Warehouse
