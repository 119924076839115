import React from 'react'

import { CardDataInterfaceAttributes } from '../../../../interfaces/TaskMills'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'

type TProps = {
  highlighted: CardDataInterfaceAttributes
}

const CardCoatingLine = React.forwardRef<HTMLDivElement, TProps>(({ highlighted }, ref) => {
  return (
    <div className="print-block print-block_nails print-block_manz" ref={ref}>
      <Box flexDirection="column" width={600}>
        <Box alignSelf="center" marginBottom={2}>
          <Barcode value={highlighted.barcode} />
        </Box>
        <Box border={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Дата</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}>
            <Typography></Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Фамилия оператора</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>№ сопроводительной карты с ХТО</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Типоразмер изделия</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}>
            <Typography>{highlighted.nail_standard}</Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>ТУ</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}>
            <Typography variant="Body1-Medium">ТУ 16-40-.....-55798700-2006</Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>№ плавки</Typography>
          </Box>
          <Box flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Режим</Typography>
          </Box>
          <Box flexShrink={1} flexBasis={350}>
            <Box padding="10px 5px" flexShrink={1} flexBasis={150} borderRight={1}>
              I, А
            </Box>
            <Box padding="10px 5px" flexShrink={1} flexBasis={100}>
              t, мин
            </Box>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>№ контейнера</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Вес изделий после покрытия, кг</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Толщина покрытия</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={250}>
            <Typography>Прочность сцепления</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={350}></Box>
        </Box>
      </Box>
    </div>
  )
})

CardCoatingLine.displayName = 'CardCoatingLine'

export default CardCoatingLine
