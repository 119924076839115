import { makeStyles } from '@material-ui/core'

export const Styles = makeStyles((theme) => ({
  root: {
    color: theme.neutral.grey500,
  },
  boxClass: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
}))
