import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconBalance24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5253 8.58304L12.5253 2.775C12.5253 2.48505 12.2903 2.25 12.0003 2.25C11.7104 2.25 11.4753 2.48505 11.4753 2.775L11.4753 8.40394L6.81368 7.60877C6.40537 7.53912 6.0179 7.81366 5.94825 8.22198C5.91513 8.41616 5.95985 8.60563 6.05978 8.75844C6.05621 8.76529 6.05277 8.77225 6.04947 8.77932L3.14257 15.0084C2.96476 15.1455 2.85022 15.3607 2.85022 15.6026C2.85022 15.6382 2.85075 15.6736 2.8518 15.7089C2.84812 15.7543 2.85045 15.7997 2.85838 15.8438C2.99642 17.8727 4.86527 19.3526 6.97522 19.3526C9.16791 19.3526 11.1002 17.7543 11.1002 15.6026C11.1002 15.3326 10.9576 15.096 10.7435 14.9639L8.05802 9.34269L16.0041 10.6981L13.1187 16.8811C12.9403 17.0182 12.8253 17.2338 12.8253 17.4762C12.8253 17.5121 12.8259 17.5479 12.8269 17.5835C12.8232 17.6294 12.8256 17.6752 12.8336 17.7197C12.973 19.7473 14.8412 21.2262 16.9503 21.2262C19.0598 21.2262 20.9283 19.7468 21.0671 17.7186C21.0749 17.6747 21.0773 17.6297 21.0737 17.5846C21.0748 17.5486 21.0753 17.5125 21.0753 17.4762C21.0753 17.2256 20.9524 17.0037 20.7636 16.8675L17.722 10.6452C17.7123 10.6253 17.7015 10.6064 17.6897 10.5883C17.7648 10.4931 17.8178 10.3783 17.8397 10.2504C17.9093 9.84205 17.6348 9.45459 17.2264 9.38494L12.5253 8.58304ZM9.44693 16.3526H4.50351C4.85466 17.1931 5.77992 17.8526 6.97522 17.8526C8.17052 17.8526 9.09578 17.1931 9.44693 16.3526ZM6.92011 9.39662L9.52668 14.8526H4.37397L6.92011 9.39662ZM14.4786 18.2262C14.8298 19.0667 15.755 19.7262 16.9503 19.7262C18.1456 19.7262 19.0709 19.0667 19.422 18.2262H14.4786ZM16.8777 11.309L19.5258 16.7262H14.3498L16.8777 11.309Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconBalance24
