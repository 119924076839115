import getFullDate from './getFullDate'
import dayjs from 'dayjs'

const getFilterFullDate = (date?: Date | dayjs.Dayjs | null): string | null => {
  if (!date) return null
  const [day, month, year, hours, minutes, seconds] = getFullDate(date)
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

export default getFilterFullDate
