import { useCallback, useState } from 'react'

import { DependentDictionary } from 'interfaces/responses/Dictionary'
// import { useAlert } from 'react-alert';
import service from 'services/service'

export type DependentDictionaries = Record<
  string,
  {
    isLoading: boolean
    refresh: () => Promise<void>
    value: DependentDictionary[]
    error: string | null
  }
>

export const useGetDependentDictionaries = () => {
  // const alert = useAlert();

  const [dependentDictionaries, setDependentDictionaries] = useState<DependentDictionaries>({})

  const refresh = useCallback(async (column: string, dictionaryType: string) => {
    const refreshDictionaries = () => refresh(column, dictionaryType)

    try {
      setDependentDictionaries((prev) => ({
        ...prev,
        [column]: {
          isLoading: true,
          value: [],
          refresh: refreshDictionaries,
          error: null,
        },
      }))

      const response = await service.getDependentDictionary(dictionaryType)

      setDependentDictionaries((prev) => ({
        ...prev,
        [column]: {
          isLoading: false,
          value: response.content,
          refresh: refreshDictionaries,
          error: null,
        },
      }))
    } catch (e) {
      setDependentDictionaries((prev) => ({
        ...prev,
        [column]: {
          isLoading: false,
          value: [],
          refresh: refreshDictionaries,
          error: String(e),
        },
      }))
    }
  }, [])

  const getDependentDictionaries = useCallback(
    async (dictionaryTypes: Record<string, string>) => {
      const dictionaryEntries = Object.entries(dictionaryTypes)

      const loadingValue = dictionaryEntries.reduce(
        (acc, [column, dictionaryType]) => ({
          ...acc,
          [column]: {
            isLoading: true,
            value: [],
            refresh: () => refresh(column, dictionaryType),
            error: null,
          },
        }),
        {} as DependentDictionaries,
      )

      setDependentDictionaries(loadingValue)

      const responses = await Promise.allSettled(
        dictionaryEntries.map(([, dictionaryType]) =>
          service.getDependentDictionary(dictionaryType),
        ),
      )

      const results = responses.reduce((acc, curr, index) => {
        const [column, dictionaryType] = dictionaryEntries[index]
        return {
          ...acc,
          [column]: {
            isLoading: false,
            refresh: () => refresh(column, dictionaryType),
            value: curr.status === 'fulfilled' ? curr.value.content : [],
            error: curr.status === 'rejected' ? curr.reason : null,
          },
        }
      }, {} as DependentDictionaries)

      setDependentDictionaries(results)
    },
    [alert, refresh],
  )

  return {
    dependentDictionaries,
    getDependentDictionaries,
  }
}
