import React from 'react'

import { TabPanel as DSTabPanel, TabPanelProps as DSTabPanelProps } from '../../components/Tabs'

type TabPanelProps = DSTabPanelProps & {
  className?: string
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  return <DSTabPanel {...props} />
}

export default TabPanel
