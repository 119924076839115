import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button } from '../Button'
import { InputMaskCorrect } from '../DatePicker/subcomponents/InputMaskCorrect'
import CalendarIcon from '../Icon/DatePicker/CalendarSvgIcon'
import LeftDatePickerItem from '../Icon/DatePicker/LeftDatePickerItemSvgIcon'
import RightDatePickerItem from '../Icon/DatePicker/RightDatePickerItemSvgIcon'
import { Input } from '../Input'
import clsx from 'clsx'
import { isEqual } from 'date-fns'
import moment, { Moment } from 'moment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localization from 'moment/locale/ru'
import Picker from 'rc-picker'
import 'rc-picker/assets/index.css'
import ru from 'rc-picker/es/locale/ru_RU'
import { isSameDate } from 'rc-picker/es/utils/dateUtil'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'

import { OldDatePickerProps } from './types'

import { useDatePickerStyles } from './styles'

moment.updateLocale('ru', localization)

function triggerMouseEvent(node, eventType) {
  const clickEvent = document.createEvent('MouseEvents')
  clickEvent.initEvent(eventType, true, true)
  if (node) {
    node.dispatchEvent(clickEvent)
  }
}

function getFormat(picker: OldDatePickerProps['picker'] = 'date') {
  const hashFormat: { [k in typeof picker]: string } = {
    date: 'DD.MM.YYYY',
    datetime: 'DD.MM.YYYY HH:mm',
    month: 'MM.YYYY',
    year: 'YYYY',
    time: 'HH:mm',
  }
  return hashFormat[picker]
}

function getMask(picker: OldDatePickerProps['picker'] = 'date') {
  const hashMask: { [k in typeof picker]: string } = {
    date: '99.99.9999',
    datetime: '99.99.9999 99:99',
    month: '99.9999',
    year: '9999',
    time: '99:99',
  }
  return hashMask[picker]
}

export const OldDatePicker: React.FC<OldDatePickerProps> = ({
  value,
  className,
  onChange,
  customFooter,
  disabled,
  disabledDate,
  disabledTime,
  picker: propsPicker,
  showTime: propsShowTime,
  overflow,
  ...inputProps
}) => {
  const classes = useDatePickerStyles()

  const [innerValue, setInnerValue] = React.useState<Moment | null>(value ? moment(value) : null)
  // Это временно пока showTime не удалят совсем
  const showTime = useMemo(() => (!propsShowTime && propsPicker === 'datetime') || propsShowTime, [
    propsShowTime,
    propsPicker,
  ])
  const picker = useMemo(
    () => (showTime && propsPicker !== 'datetime' ? 'datetime' : propsPicker),
    [propsPicker, showTime],
  )
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  const currentMonth = useMemo(() => new Date().getMonth(), [])
  //
  const format = useMemo(() => getFormat(picker), [picker])

  const id = useMemo(() => `DatePicker-${performance.now().toString().split('.').join('')}`, [])
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const [open, setOpen] = useState(false)
  const [innerMaskedValue, setInnerMaskedValue] = useState('')

  const onSelect = useCallback(
    (newValue: Moment) => {
      setInnerValue(newValue)
      setInnerMaskedValue(moment(newValue).format(format))
      if (!showTime) {
        setTimeout(() => {
          onChange(newValue?.toDate() || null)
          inputRef?.blur()
        }, 0)
      }
    },
    [format, showTime, onChange, inputRef],
  )

  const onAccept = useCallback(
    (value?: moment.Moment | null) => {
      if (value || innerValue) {
        onChange((value || innerValue)?.toDate() || null)
      }
      setOpen(false)
    },
    [innerValue, onChange],
  )

  const onClose = useCallback(() => {
    if (moment(value).isValid()) {
      setInnerValue(moment(value))
    } else {
      setInnerMaskedValue('')
    }
    setOpen(false)
    inputRef?.blur()
  }, [value, inputRef])

  const sharedProps = useMemo(
    () => ({
      generateConfig: {
        ...momentGenerateConfig,
        shortWeekDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      },
      value: innerValue,
      onSelect,
      ...(className && { className }),
    }),
    [onSelect, innerValue, className],
  )

  const setDate = () => {
    const parsed = moment(innerMaskedValue, format)
    onChange(parsed.isValid() ? parsed.toDate() : null)
    setOpen(false)
  }

  const handleOpen = (visible: boolean) => {
    if (
      open &&
      !visible &&
      (innerValue === null ||
        value === null ||
        (value && innerValue && !isEqual(innerValue.toDate(), value)))
    ) {
      inputRef?.blur()
    }
    setOpen(visible)
  }

  const onMouseEvent = () =>
    triggerMouseEvent(
      document.querySelector<HTMLInputElement>(`#${id} .rc-picker-input input`),
      'mousedown',
    )

  useEffect(() => {
    const tryToSetCurrentMonth = () => {
      document
        .querySelector(
          `.rc-picker-cell[title="${currentYear}-${
            currentMonth < 10 ? `0${currentMonth}` : currentMonth
          }"]`,
        )
        ?.classList.add('current-month')
      document
        .querySelector(`.rc-picker-cell[title="${currentYear}"`)
        ?.classList.add('current-month')
    }
    const intId = setInterval(tryToSetCurrentMonth, 1000)
    return () => clearInterval(intId)
  }, [currentMonth, currentYear])

  useEffect(() => {
    const numOfEmptyFields = [...(innerMaskedValue.match(/_/g) || [])].length
    if (!innerMaskedValue.includes('_')) {
      const parsed = moment(innerMaskedValue, format)
      if (parsed.isValid()) {
        setInnerValue(parsed)
      }
    } else if (
      (showTime && value && numOfEmptyFields === 12) ||
      (!showTime && value && numOfEmptyFields === 8)
    ) {
      setInnerValue(null)
    }
  }, [innerMaskedValue, format, showTime, value])
  const lastValue = useRef(value)

  useEffect(() => {
    if (value && moment(value).isValid()) {
      const momentValue = moment(value)
      const formattedValue = momentValue.format(format)
      if (lastValue.current !== value) {
        setInnerMaskedValue(formattedValue)
        setInnerValue(momentValue)
      }
      lastValue.current = value
    }
  }, [value, format])

  useEffect(() => {
    if (!open) {
      if (value && moment(value).isValid()) {
        if (!isSameDate(sharedProps.generateConfig, moment(value), innerValue)) {
          setInnerValue(moment(value))
        }
        if (!isSameDate(sharedProps.generateConfig, moment(value), moment(innerMaskedValue))) {
          setInnerMaskedValue(moment(value).format(format))
        }
      } else if (innerValue !== null) {
        setInnerValue(null)
        setInnerMaskedValue('')
      }
    }
  }, [value, innerValue, sharedProps.generateConfig, open, format, innerMaskedValue])

  return (
    <div
      className={clsx(
        classes.rootDatePicker,
        inputProps.fullWidth && classes.rootDatePicker__fullWidth,
      )}
      id={id}
    >
      <InputMaskCorrect
        onFocus={() => {
          onMouseEvent()
          setOpen(true)
        }}
        mask={getMask(picker)}
        onChange={(e) => {
          !open && onMouseEvent()
          setInnerMaskedValue(e.target.value)
        }}
        value={innerMaskedValue}
        disabled={disabled}
      >
        {() => (
          <Input
            onKeyDown={(e) => ['Tab', 'Enter'].includes(e.key) && setDate()}
            inputRef={setInputRef}
            className={classes.inputRoot}
            disabled={disabled}
            focused={open}
            {...inputProps}
            InputProps={{
              endAdornment: (
                <CalendarIcon
                  className={clsx(classes.calendarIcon, {
                    [classes.calendarIcon_disabled]: disabled,
                  })}
                  onClick={() => !disabled && setOpen((s) => !s)}
                />
              ),
              className: classes.inputRoot,
              classes: { input: classes.inputOutlined },
            }}
            variant="outlined"
          />
        )}
      </InputMaskCorrect>
      <Picker<Moment>
        {...sharedProps}
        disabled={disabled}
        locale={ru}
        open={open}
        format={format}
        picker={picker === 'datetime' ? undefined : picker}
        onOpenChange={disabled ? undefined : handleOpen}
        superNextIcon={<RightDatePickerItem />}
        nextIcon={<RightDatePickerItem />}
        dropdownAlign={{
          overflow: { adjustX: false, adjustY: false, ...overflow },
          useCssTransform: true,
        }}
        prevIcon={<LeftDatePickerItem />}
        superPrevIcon={<LeftDatePickerItem />}
        renderExtraFooter={(mode) => {
          if (mode !== 'date') {
            return null
          }
          return (
            <div className={classes.footerWrapper}>
              {!showTime && customFooter && (
                <div className={classes.footerWrapper__customContent}>{customFooter}</div>
              )}
              {showTime && (
                <div className={classes.footer}>
                  <Button onClick={() => onAccept()} variant="contained" color="primary">
                    Применить
                  </Button>
                  <Button
                    onClick={() => {
                      if (value) {
                        setInnerMaskedValue(moment(value).format(format))
                      } else {
                        setInnerMaskedValue('')
                      }
                      onClose()
                    }}
                    color="secondary"
                    variant="contained"
                  >
                    Отменить
                  </Button>
                </div>
              )}
            </div>
          )
        }}
        showTime={
          showTime && {
            showSecond: false,
          }
        }
        popupStyle={open ? { top: '8px' } : undefined}
        showToday={false}
        disabledDate={disabledDate ? (date) => disabledDate(date.toDate()) : undefined}
        disabledTime={
          disabledTime ? (date) => disabledTime(date ? date.toDate() : null) : undefined
        }
      />
    </div>
  )
}
