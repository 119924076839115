import React, { FC, useMemo, useState } from 'react'

import { Tab, Tabs } from '../../ui-kit/components/Tabs'
import TabPanel from '../../ui-kit/frontend-components/TabPanel'
import UserContent from '../../ui-kit/frontend-components/UserContent'
import { usePermission } from '../../ui-kit/hooks/usePermission'
import { makeStyles } from '@material-ui/core'
import { PERMISSION } from 'shared/enums/Permission'

import DisplayDictionaries from './components/DisplayDictionaries'
import EditDictionaries from './components/EditDictionaries'
import { NodeManagment } from './components/NodeManagment'

const styles = makeStyles({
  tab: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

const Dictionaries: FC = () => {
  const classes = styles()

  const [currentTab, setCurrentTab] = useState(0)

  const { can } = usePermission()

  const canRead = useMemo(() => can(PERMISSION.DICTIONARY__VIEW__READ), [can])

  const canEdit = useMemo(() => can(PERMISSION.DICTIONARY__VIEW__EDIT), [can])

  if (!canRead && !canEdit) return null

  return (
    <UserContent title="Справочники">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={(e, tab) => setCurrentTab(tab)}
      >
        {canRead && <Tab label="Просмотр" />}
        {canEdit && <Tab label="Редактирование" />}
        {canEdit && <Tab label="Управление узлами" />}
      </Tabs>
      {canRead && (
        <TabPanel
          className={currentTab === 0 ? classes.tab : undefined}
          value={currentTab}
          index={0}
        >
          <DisplayDictionaries />
        </TabPanel>
      )}
      {canEdit && (
        <>
          <TabPanel
            className={currentTab === 1 ? classes.tab : undefined}
            value={currentTab}
            index={1}
          >
            <EditDictionaries />
          </TabPanel>
          <TabPanel
            className={currentTab === 2 ? classes.flexGrow : undefined}
            value={currentTab}
            index={2}
          >
            <NodeManagment />
          </TabPanel>
        </>
      )}
    </UserContent>
  )
}

export default Dictionaries
