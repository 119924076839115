import { IColorMap, IStatusColor, TStatusColor } from '../types'
import { Theme } from '@material-ui/core'

type TGetColor = (theme: Theme, color: TStatusColor, withBg?: boolean) => IStatusColor
export const getColor: TGetColor = (theme, color: TStatusColor, withBg?: boolean) => {
  const backgroundKey = withBg ? 'withBg' : 'noBg'
  const colorMap: IColorMap = {
    withBg: {
      gray: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.text.hint,
        backgroundColor: theme.neutral.grey100,
      },
      default: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.secondary.main,
      },
      green: {
        textColor: theme.palette.text.primary,
        iconColor: theme.tableSuccessBG.icon,
        backgroundColor: theme.tableSuccessBG.main,
      },
      red: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.error.dark,
        backgroundColor: theme.tableErrorBG.light,
      },
      yellow: {
        textColor: theme.palette.text.primary,
        iconColor: theme.tableWarningBG.icon,
        backgroundColor: theme.tableWarningBG.main,
      },
    },
    noBg: {
      gray: {
        textColor: theme.palette.text.primary,
        iconColor: theme.neutral.grey500,
        backgroundColor: 'transparent',
      },
      default: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
      green: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.success.main,
        backgroundColor: 'transparent',
      },
      red: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.error.main,
        backgroundColor: 'transparent',
      },
      yellow: {
        textColor: theme.palette.text.primary,
        iconColor: theme.palette.warning.main,
        backgroundColor: 'transparent',
      },
    },
  }
  return colorMap[backgroundKey][color]
}
