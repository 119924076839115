import React, { FC } from 'react'

import { ISvgProps } from '../types'

export const MoonSvgIcon: FC<ISvgProps> = (): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.37 9.51C13.19 10.15 13.1 10.82 13.1 11.5C13.1 15.58 16.42 18.9 20.5 18.9C21.18 18.9 21.85 18.81 22.49 18.63C21.45 21.19 18.93 23 16 23C12.14 23 9 19.86 9 16C9 13.07 10.81 10.55 13.37 9.51ZM16 7C11.03 7 7 11.03 7 16C7 20.97 11.03 25 16 25C20.97 25 25 20.97 25 16C25 15.54 24.96 15.08 24.9 14.64C23.92 16.01 22.32 16.9 20.5 16.9C17.52 16.9 15.1 14.48 15.1 11.5C15.1 9.69 15.99 8.08 17.36 7.1C16.92 7.04 16.46 7 16 7Z"
        fill="currentColor"
      />
    </svg>
  )
}
