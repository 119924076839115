import React, { FC, useEffect, useState } from 'react'

import { mainWhite } from '../../../colors'
import { Zone } from '../../../interfaces/Fullness'
import { TWorkshopName } from '../../../interfaces/Warehouse'
import service from '../../../services/service'
import zoneComparator from '../../../utils/zoneComparator'
import {
  ArrowUpSvgIcon,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InfoSvgIcon,
  Loader,
  Typography,
} from '../../components'
import { Alert } from '../../components/Alert'
import useToggle from '../../hooks/useToggle'
import FullnessItem from '../FullnessItem'
import LoaderWrapper from '../LoaderWrapper'

type Props = {
  workshopName: TWorkshopName
  onClickZone: (id: number) => void
  setZoneName: (name: string) => void
}

type ZoneGroups = Record<string, Zone[]>

const ZONE_GROUPS_PRIORITY: Record<string, number> = {
  Основной: 0,
  default: 100,
}

const getZoneGroupPriority = (groupName: string) => {
  return ZONE_GROUPS_PRIORITY?.[groupName] ?? ZONE_GROUPS_PRIORITY.default
}

export const sortZoneGroups = (zoneGroups: ZoneGroups): Array<[string, Zone[]]> => {
  return Object.entries(zoneGroups).sort(
    ([groupName1], [groupName2]) =>
      getZoneGroupPriority(groupName1) - getZoneGroupPriority(groupName2),
  )
}

export const DEFAULT_ZONE_NAME = 'Основной'

const Fullness: FC<Props> = ({ workshopName, onClickZone, setZoneName }) => {
  const [isOpen, setIsOpen] = useToggle(true)
  const [zoneGroups, setZoneGroups] = useState<ZoneGroups>({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const [selectedZoneId, setSelectedZoneId] = useState(0)

  const getZones = async () => {
    setError(false)

    try {
      const { content }: { content: { childs: Zone[] } } = await service.getWarehouseZones(
        workshopName,
      )

      const value = content.childs.sort(
        zoneComparator<Zone>('attributes.name', content.childs.length),
      )
      const zoneGroups = value.reduce((acc, curr) => {
        const groupName = curr.attributes?.groupName ?? DEFAULT_ZONE_NAME
        return {
          ...acc,
          [groupName]: [...(acc?.[groupName] ?? []), curr],
        }
      }, {} as Record<string, Zone[]>)
      setZoneGroups(zoneGroups)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getZones()
    const interval = setInterval(() => {
      getZones()
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {loading && <LoaderWrapper isLoad rootStyles={{ height: '19vh' }} />}
      <div style={{ marginTop: '20px' }}>
        {!loading && (
          <div>
            <Box spacing={1} justifyContent="center" alignItems="center">
              <InfoSvgIcon color="disabled" />
              <Typography style={{ display: 'flex', alignItems: 'center' }} variant="Subheading3">
                Выберите зону на складе
              </Typography>
            </Box>
            <Button
              onClick={setIsOpen}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                margin: '0 auto',
                transform: 'translate(-50%, -50%)',
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                backgroundColor: mainWhite,
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              {isOpen && <ArrowUpSvgIcon color="primary" />}

              {!isOpen && (
                <ArrowUpSvgIcon style={{ transform: 'rotate(180deg)' }} color="primary" />
              )}
              {/* <span className="visually-hidden">Показать/скрыть заполненность склада</span> */}
            </Button>
            <Card>
              {isOpen && (
                <CardContent>
                  {loading && <Loader useAsWrapper />}

                  {error && <Alert severity="error">Контент не загружен</Alert>}

                  {zoneGroups && (
                    <Box flexDirection="column" spacing={3}>
                      {sortZoneGroups(zoneGroups).map(([groupName, zones]) => (
                        <Box key={groupName} flexDirection="column">
                          {Object.keys(zoneGroups).length > 1 && (
                            <Box mb={1}>
                              <Typography variant="Body1-Bold">{groupName}</Typography>
                            </Box>
                          )}
                          <Grid container spacing={1}>
                            {zones.map(
                              ({
                                id,
                                attributes: { name: zone, fullness: current, capacity: max },
                              }) => (
                                <FullnessItem
                                  key={id}
                                  onClickZone={() => {
                                    onClickZone(id)
                                    setZoneName(zone)
                                    setSelectedZoneId(id)
                                  }}
                                  zone={zone}
                                  current={Number(current)}
                                  max={Number(max)}
                                  workshopName={workshopName}
                                  selected={selectedZoneId === id}
                                />
                              ),
                            )}
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  )}
                </CardContent>
              )}
              {!isOpen && <CardContent />}
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}

export default Fullness
