import { TableColumn } from '../Table'
import { cloneDeep } from 'lodash'

import { FilterItem } from './components/Filter/typings'
import { SortItem } from './components/Sort/typings'

import { TableToolbarProps } from './types'

export function prepareColumnOptions<T>(
  columns: TableColumn<T>[],
  data: Array<FilterItem | SortItem>,
  selectedItem?: string,
) {
  const selectedLabels = data
    .filter((e) => e.label !== selectedItem)
    .reduce((acc, e) => ({ ...acc, [e.label]: e.label }), {})
  return columns
    .filter((e) => !selectedLabels[e.name])
    .map((e) => ({
      label: e.title || '',
      value: e.name,
    }))
}

export function getNextIndex<T extends { [k: string]: any }>(
  views: T[],
  oldIndex: number,
  newIndex: number,
  propertyChecker: string,
): number {
  const pinned = views.map((e) => e[propertyChecker])
  const lastPinned = pinned.lastIndexOf(true)
  const newId = views[oldIndex][propertyChecker]
    ? newIndex
    : lastPinned < newIndex
    ? newIndex
    : lastPinned + 1
  return newId
}

export function propertyToggleHandler<T extends { [k: string]: any }>(
  views: T[],
  id: number,
  propertyName: string,
): T[] {
  const copy = views.map((item) => cloneDeep(item))
  ;(copy[id] as { [k: string]: any })[propertyName] = !copy[id][propertyName]
  return copy
}

export function overrideColumnsTitles<T extends Record<string, unknown>>(
  columns: TableColumn<T>[],
  dictionary?: TableToolbarProps<T>['overrideTitles'],
  isView?: boolean,
) {
  const cols = columns.map((e) => ({
    ...e,
    title: dictionary && e.name in dictionary ? dictionary[e.name]?.title : e.title,
    name:
      dictionary && e.name in dictionary && !isView
        ? dictionary[e.name]?.newName || e.name
        : e.name,
  }))
  return cols
}

export function generateDefaultColumnsTitles<T extends Record<string, unknown>>(
  columns: TableColumn<T>[],
): TableToolbarProps<T>['overrideTitles'] {
  return columns.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: {
        title: cur.title,
      },
    }
  }, {}) as TableToolbarProps<T>['overrideTitles']
}

export function checkToExcludeColumnNames<T extends Record<string, unknown>>(
  columns: TableColumn<T>[],
  excludeList?: TableToolbarProps<T>['excludeColumnNames'],
) {
  return columns.filter((e) => !excludeList?.includes(e.name))
}
