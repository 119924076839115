import React from 'react'

import { CardContentProps, CardContent as MUICardContent } from '@material-ui/core'

import { useCardContentStyles } from './styles'

export const CardContent: React.FC<CardContentProps> = ({ ...props }) => {
  const classes = useCardContentStyles()

  return (
    <MUICardContent
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  )
}
