import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'

import {
  Box,
  Button,
  Card,
  Dialog,
  Pagination,
  PrintSvgIcon,
  Tooltip,
} from '../../../../ui-kit/components'
import Table from '../../../../ui-kit/frontend-components/Table'
import useCards from '../../../../ui-kit/hooks/useCards'
import { makeStyles } from '@material-ui/core'
import { auto } from '@popperjs/core'
import { CardDataInterfaceAttributes2 } from 'interfaces/TaskMills'
import getPageStyle, { getTagSizeByColor } from 'utils/getPageStyle'

type Props = {
  rows: CardDataInterfaceAttributes2[]
  columns: {
    name: string
    title: string
    width?: string
  }[]
  modal?: React.ReactNode
  currentRegion: string
  checked: CardDataInterfaceAttributes2[]
  setChecked: (checkedRows: CardDataInterfaceAttributes2[]) => void
  currentPage: number
  setPage: (isGet: number) => void
  totalPages: number
}

const DISABLED_COLORS: Record<string, Record<string, string[]>> = {
  ebner: {
    uto: ['green'],
  },
  danielli: {
    uto: ['green'],
  },
  uef: {
    uto: ['green'],
  },
  ukp: {
    uto: ['green'],
  },
}

const CARD_COLOR = {
  green: 'Зеленый(формат офисный А5)',
  green_tag: 'Зеленый(формат бирка 9х11)',
  green_medium_tag: 'Зеленый(формат бирка 9х18)',
  white_blue_stripe: 'Бело-голубая(формат офисный А5)',
  white_blue_stripe_tag: 'Бело-голубая(Формат бирка)',
  white_blue_stripe_medium_tag: 'Бело-голубая(формат бирка 9х18)',
  yellow: 'Желтый(формат офисный А5)',
  yellow_tag: 'Желтый(формат бирка 9х11)',
  yellow_medium_tag: 'Желтый(формат бирка 9х18)',
  white: 'Белый(формат офисный А5)',
  white_tag: 'Белый(формат бирка 9х11)',
  white_medium_tag: 'Белый(формат бирка 9х18)',
  blue: 'Голубой(формат офисный А5)',
  blue_tag: 'Голубой(формат бирка 9х11)',
  blue_medium_tag: 'Голубой(формат бирка 9х18)',
  red: 'Красный',
  red_stripe: 'Красный c полосой',
  red_blue_stripe: 'Красный с голубой полосой',
  red_tag: 'Красный(формат бирка 9х11)',
  red_medium_tag: 'Красный(формат бирка 9х18)',
}

const styles = makeStyles({
  primary: {
    width: '900px',
    height: '550px',
  },
})

const tableStyle = {
  root: {
    style: {
      height: '550px',
      overflow: 'scroll',
    },
  },
}

const NewPrint: FC<Props> = ({
  rows,
  columns,
  currentRegion,
  checked,
  setChecked,
  currentPage,
  setPage,
  totalPages,
}) => {
  const [highlighted, setHighlighted] = useState<CardDataInterfaceAttributes2[]>([])
  const isOpen = highlighted.length === 1

  // const [currentPage, setCurrentPage] = useState(0)

  console.log('rows')
  console.log(rows)
  const columnsConfig = useMemo(
    () =>
      columns.map((item) => {
        const { name } = item
        if (name === 'controls') {
          return {
            ...item,
            render: (row: CardDataInterfaceAttributes2) => {
              return (
                <Tooltip title="Печать" placement="left">
                  <span>
                    <Button
                      onClick={() => setHighlighted([row])}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      <PrintSvgIcon />
                    </Button>
                  </span>
                </Tooltip>
              )
            },
          }
        }
        if (name === 'color') {
          return {
            ...item,
            render: (row: CardDataInterfaceAttributes2) => {
              return <div>{CARD_COLOR[row.color]}</div>
            },
          }
        }
        return item
      }),
    [currentRegion, columns],
  )
  const componentRef = useRef<HTMLDivElement>(null)

  const classes = styles()

  const highlightedCards = useMemo(
    () => [
      {
        card: highlighted?.[0] ?? null,
        ref: componentRef,
      },
    ],
    [highlighted, componentRef],
  )

  const cards = useCards({ cards: highlightedCards, currentRegion })

  const TableMemo = useCallback(() => {
    return (
      <>
        <Table
          rowsSelection={{
            checkEnabled: false,
            checked,
            onCheckedChange: setChecked,
          }}
          // style={{
          //   root: {
          //     style: {
          //       overflow: 'auto',
          //     },
          //   },
          // }}
          style={tableStyle}
          rows={rows}
          columns={columnsConfig}
          enableColumnResizing
          narrowColumns
          narrowRows
        />
        <Box p={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            onChange={(_, page) => {
              setPage(page - 1)
              // setCurrentPage(page - 1)
            }}
          />
        </Box>
      </>
    )
  }, [checked, rows, columnsConfig])

  return (
    <Card title="Печать">
      <TableMemo />
      {isOpen && (
        <Dialog
          fullWidth
          maxWidth="xl"
          classes={{ paper: classes.primary }}
          open={isOpen}
          onClose={() => {
            setHighlighted([])
          }}
          title="Предпросмотр"
          actions={[
            <ReactToPrint
              key={1}
              copyStyles
              pageStyle={getPageStyle(
                currentRegion,
                getTagSizeByColor(highlightedCards[0].card.color),
              )}
              trigger={() => (
                <Button variant="contained" color="primary">
                  Печать
                </Button>
              )}
              content={() => componentRef.current}
            />,
            <Button
              key={2}
              onClick={() => {
                setHighlighted([])
              }}
              variant="contained"
              color="secondary"
            >
              Отменить
            </Button>,
          ]}
        >
          <Box spacing={2} flexDirection="column">
            <Box>{cards}</Box>
          </Box>
        </Dialog>
      )}
    </Card>
  )
}

export default NewPrint
