import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import nlmkLogo from '../../../images/nlmkLogo.jpg'
import pctIcon from '../../../images/pctIcon.png'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardGreenNails = React.forwardRef<HTMLDivElement, CardProps>(
  ({ highlighted, barcode = 0 }, ref) => {
    const referenceData: ReferenceDataType[] = [
      {
        title: 'ТУ',
        data: {
          left: [
            { name: '', title: 'Кол-во коробок, шт', unit: 'шт.' },
            { name: '', title: 'Масса (нетто) одной коробки', unit: 'кг.' },
            { name: '', title: 'Упаковщик №' },
            { name: '', title: 'ОТК' },
          ],
          right: [
            { name: '', title: 'Масса нетто (общая)', unit: 'кг.' },
            { name: '', title: 'Масса брутто (общая)', unit: 'кг.' },
            { name: '', title: 'Дата изготовления' },
            { name: 'date', title: 'Дата упаковки' },
          ],
        },
      },
    ]

    const renderBlock = (data?: Block[]): React.ReactNode => {
      if (!data) return null
      return data
        .map(({ name, title, unit }) => {
          const newDescription: string =
            // @ts-ignore
            highlighted[0][name] !== undefined ? highlighted[0][name] : `____ ${unit || ''}`
          return { title, description: newDescription }
        })
        .map(({ title, description }) => (
          <Box style={{ paddingBottom: '6px' }} justifyContent="space-between" key={title}>
            <Typography style={{ maxWidth: '120px' }} variant="Caption">
              {title}
            </Typography>

            <Typography variant="Caption">{description}</Typography>
          </Box>
        ))
    }
    return (
      <div className="print-block print-block-nail_green" ref={ref}>
        <Box height="553px" padding="0px" marginLeft="50px">
          <Box width="210mm" height="148mm">
            <Box width="198mm" height="130mm" border={1} paddingBottom="100px">
              <Box
                flexDirection="column"
                spacing={1}
                style={{
                  padding: '40px 86px 24px 57px',
                  position: 'relative',
                }}
              >
                {referenceData.map(({ title, data }, i) => (
                  <Box
                    key={i}
                    style={{ paddingLeft: '80px', marginTop: '40px', flexDirection: 'column' }}
                  >
                    <Box flexDirection="column" alignItems="center" justifyContent="center">
                      {' '}
                      <Box
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="500px"
                        padding="0 30 0 30"
                      >
                        <Box height="100px" width="80px">
                          <img src={nlmkLogo} alt="НЛМК" />
                        </Box>

                        <Typography style={{ paddingBottom: '8px' }} variant="Body1-Bold">
                          ООО “НЛМК-Метиз”
                        </Typography>
                        <Box height="60px" width="65px">
                          <img src={pctIcon} alt="РСТ" />
                        </Box>
                      </Box>
                      {highlighted[0].nail_standard}
                      <Typography
                        style={{ paddingBottom: '8px', marginTop: '10px', marginLeft: '-90px' }}
                        variant="Body1-Bold"
                      >
                        {title}
                      </Typography>
                      <Typography
                        style={{ fontSize: '10px', marginTop: '-10px' }}
                        variant="Caption"
                      >
                        (Винты самонарезающие)
                      </Typography>
                    </Box>

                    <Box>
                      {Object.values(data).map((value, i) => (
                        <Box
                          style={{
                            width: '260px',
                            paddingRight: '10px',
                            marginRight: '10px',
                            borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                            marginTop: '20px',
                          }}
                          flexDirection="column"
                          key={i}
                        >
                          {renderBlock(value)}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
                <Box justifyContent="space-between" flex-direction="row" marginTop="50px">
                  <Box paddingLeft="80px" width="300px">
                    <Typography variant="Caption">
                      Россия, Свердловская обл., г.Березовский, Кольцевая, 5
                    </Typography>
                  </Box>
                  <Box width="300px" flexDirection="column" paddingLeft="50px">
                    <Typography variant="Caption">Тел.:(343) 380-19-14</Typography>
                    <Typography variant="Caption">Тел./факс:(343) 380-19-10</Typography>
                  </Box>
                </Box>
                <div className={styles.barcodeContainerThree}>
                  <Barcode value={barcode} />
                </div>
                <div className={styles.qrCodeContainer}>
                  <QRCode value={String(barcode)} />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    )
  },
)

export default CardGreenNails
