import React, { FC } from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const SortDescendingSvgIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="swap_vert_24px">
        <path
          id="icon/content/sort_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6V8H21V6H3ZM3 18H9V16H3V18ZM15 13H3V11H15V13Z"
        />
      </g>
    </SvgIcon>
  )
}
