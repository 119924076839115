import { useEffect, useState } from 'react'

import useAsync from '../../../../../ui-kit/hooks/useAsync'
// import { useAlert } from 'react-alert';
import { ResponseDictionaryInfo } from 'interfaces/responses/Dictionary'
import { Request } from 'interfaces/responses/Request'
import service from 'services/service'

export type UseDictionariesParams = {
  immediateExecute?: boolean
}

export type UseDictionariesReturn = {
  dictionaries: ResponseDictionaryInfo[]
  setDictionaries: (value: ResponseDictionaryInfo[]) => void
  request: Request<{ content: ResponseDictionaryInfo[] }>
}

const initial = {
  immediateExecute: true,
}

export const useDictionaries = ({
  immediateExecute = initial.immediateExecute,
}: UseDictionariesParams = initial): UseDictionariesReturn => {
  // const alert = useAlert();

  const [dictionaries, setDictionaries] = useState<ResponseDictionaryInfo[]>([])

  const request = useAsync(service.getDictionariesInfo, immediateExecute)

  useEffect(() => {
    if (request.value) {
      setDictionaries(request.value.content)
    }
  }, [request.value])

  useEffect(() => {
    if (request.error) {
      // alert.error(request.error);
    }
  }, [request.error])

  return {
    dictionaries,
    setDictionaries,
    request,
  }
}
