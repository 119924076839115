import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconAdd32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M25.3334 17.3333H17.3334V25.3333H14.6667V17.3333H6.66675V14.6666H14.6667V6.66663H17.3334V14.6666H25.3334V17.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconAdd32
