import React from 'react'

import { MenuItemProps, MenuItem as MUIMenuItem } from '@material-ui/core'

import { useMenuItemStyles } from './styles'

export const MenuItem: React.FC<MenuItemProps> = ({ ...props }) => {
  const classes = useMenuItemStyles()
  return (
    <MUIMenuItem
      {...props}
      classes={{
        root: classes.root,
      }}
      button={true}
    />
  )
}
