import React from 'react'

import { Typography } from '../../../'

function HintView() {
  return (
    <Typography variant="caption" style={{ margin: 0 }}>
      <b>Порядок, скрытие/отображение и закрепление колонок в таблице:</b>
      <ul style={{ paddingInlineStart: '16px' }}>
        <li>Перетаскивайте строки с названием колонок, чтобы выстроить нужный вам порядок.</li>
        <li>Нажмите на кнопку с «глазом», чтобы скрыть или показать колонку в таблице.</li>
        <li>
          Нажмите на кнопку с «канцелярской кнопкой», чтобы закрепить колонку, и она будет в зоне
          видимости при прокрутке таблицы.
        </li>
      </ul>
    </Typography>
  )
}

function HintSort() {
  return (
    <Typography variant="caption" style={{ margin: 0 }}>
      Выберите колонки, в которых вы хотите распределить значения по возрастанию или убыванию.
    </Typography>
  )
}

function HintFilter() {
  return (
    <Typography variant="caption" style={{ margin: 0 }}>
      Здесь вы можете выстроить фильтрацию по столбцам в нужном порядке.
    </Typography>
  )
}

export { HintView, HintSort, HintFilter }
