import React from 'react'

import { Tab as MUITab, TabProps } from '@material-ui/core'

import { useTabStyles } from './styles'

export const Tab: React.FC<TabProps & { number?: number }> = ({ label, number, ...props }) => {
  const classes = useTabStyles()

  return (
    <MUITab
      classes={{
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.wrapper,
      }}
      label={
        <>
          {label}
          {typeof number === 'number' && <div className={classes.number}>{number}</div>}
        </>
      }
      {...props}
    />
  )
}
