import { createContext } from 'react'

import { ISortContext } from './typings'
import { noop } from 'lodash'

export const SortContext = createContext<ISortContext>({
  visible: false,
  onClose: noop,
  setTab: noop,
  sort: [],
  setSort: noop,
  header: [],
  initHint: true,
  setInitHint: noop,
})
