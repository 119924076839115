import { useCallback, useState } from 'react'

// import { useAlert } from 'react-alert';
import service from 'services/service'

export type UseDeleteDictionaryValueReturn = {
  isLoading: boolean
  deleteDictionaryValue: (id: number) => Promise<void>
}

export const useDeleteDictionaryValue = (
  onSuccess: (id: number) => void,
): UseDeleteDictionaryValueReturn => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const deleteDictionaryValue = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)

        await service.deleteDictionaryValue(id)

        onSuccess(id)
        // alert.success('Значение успешно удалено');
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess],
  )

  return {
    isLoading,
    deleteDictionaryValue,
  }
}
