import { DaysCalendar } from '../subcomponents/DaysCalendar'
import { MonthsCalendar } from '../subcomponents/MonthsCalendar'
import { QuartersCalendar } from '../subcomponents/QuartersCalendar'
import { YearsCalendar } from '../subcomponents/YearsCalendar'

export const panelToCalendarMapping = {
  day: DaysCalendar,
  month: MonthsCalendar,
  quarter: QuartersCalendar,
  year: YearsCalendar,
}
