import React, { PropsWithChildren, useCallback, useContext } from 'react'

import { Checkbox } from '../../Checkbox'
import { useGetCurrentTheme } from '../../CommonWrapper'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { TableCell } from './TableCell'
import { TableSelection } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'

import { useTableStyles } from '../styles'

const COLUMN = { name: '' }

export const TableSelectionCell: React.FC<TableSelection.CellProps & PropsWithChildren> = ({
  row,
  children,
  ...tableCellProps
}) => {
  const {
    rows = [],
    checkedRows,
    narrowRows,
    hasCheckedElement,
    indexCheckedElement,
    rowsSelection = {},
  } = useContext(CustomFieldsCtx) || {}
  const {
    checked = [],
    singleCheck,
    checkEnabled,
    rowCheckEnabled,
    rowCheckReadonly,
    rowCheckElement,
    onCheckedChange,
    isStickyCheckColumn = false,
  } = rowsSelection
  const checkboxChecked =
    hasCheckedElement && checkedRows
      ? hasCheckedElement(row, Array.from(checkedRows))
      : checkedRows?.has(row)
  const isCheckEnabled = checkEnabled && (!rowCheckEnabled || rowCheckEnabled(row))
  const disabled = !isCheckEnabled || (rowCheckReadonly && rowCheckReadonly(row))
  const rowCheckElementContent = rowCheckElement && rowCheckElement(row)
  const theme = useGetCurrentTheme()
  const classes = useTableStyles({ theme })

  const filterFunction = hasCheckedElement
    ? (checkedRow) => !hasCheckedElement(checkedRow, [row])
    : (checkedRow) => checkedRow !== row

  const handleCheckboxChange: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (isCheckEnabled && onCheckedChange) {
        e.stopPropagation()
        if (singleCheck) {
          onCheckedChange(checkboxChecked ? [] : [row])
        } else {
          if (e.shiftKey && checked.length) {
            // индекс первого отмеченного элемента в таблице
            const firstCheckedIndex: number = Math.min(
              ...checked
                .map((row) =>
                  indexCheckedElement
                    ? indexCheckedElement(row, rows as unknown[])
                    : rows.indexOf(row),
                )
                .filter((el) => el !== -1),
            )
            // индекс элемента который отметили
            const rowIndex: number = indexCheckedElement
              ? indexCheckedElement(row, rows as unknown[])
              : rows.indexOf(row)
            const [firstMultiplyIndex, lastMultiplyIndex] = [firstCheckedIndex, rowIndex].sort(
              (a, b) => a - b,
            )
            // массив элементов в отрезке с первого элемента до последнего отмеченного, исключая те которые уже были отмечены
            const filteredMultipleSelectionArray = rows.length
              ? rows
                  .slice(firstMultiplyIndex, lastMultiplyIndex + 1)
                  .filter(
                    (row) =>
                      !rowCheckEnabled ||
                      (rowCheckEnabled(row) && !(rowCheckReadonly && rowCheckReadonly(row))),
                  )
                  .filter(
                    (row) =>
                      !(hasCheckedElement && checkedRows
                        ? hasCheckedElement(row, Array.from(checkedRows))
                        : checkedRows?.has(row)),
                  )
              : []
            const newCheckedRows = [...filteredMultipleSelectionArray, ...checked]
            // массив отмеченных элементов, выстроенный в том порядке в котором пришел в таблицу
            const filteredCheckRows = rows.filter((el) =>
              hasCheckedElement
                ? hasCheckedElement(el, newCheckedRows)
                : newCheckedRows?.includes(el),
            )
            onCheckedChange(checkboxChecked ? checked.filter(filterFunction) : filteredCheckRows)
          } else {
            onCheckedChange(checkboxChecked ? checked.filter(filterFunction) : [...checked, row])
          }
        }
      }
    },
    [row, isCheckEnabled, singleCheck, checkboxChecked, checked, onCheckedChange],
  )

  return (
    <TableCell
      value={null}
      row={row}
      column={COLUMN}
      {...tableCellProps}
      className={clsx(
        classes.base,
        isCheckEnabled && 'Check__Cell',
        isCheckEnabled && isStickyCheckColumn && 'Check__Cell_stickyCheckColumn',
        narrowRows && 'Check__Cell_narrowRows',
      )}
    >
      {isCheckEnabled && (
        <Checkbox
          className="selectionCheckbox"
          checked={checkboxChecked}
          onClick={handleCheckboxChange}
          disabled={disabled}
        />
      )}
      {rowCheckElementContent}
      {children}
    </TableCell>
  )
}
