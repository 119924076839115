import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Typography } from '../../../'
import { Badge } from '../../../Badge/Badge'
import { FilterIcon } from '../../../Icon'
import { TableState } from '../../../TableManager'
import { TableToolbarProps } from '../../types'
import { checkToExcludeColumnNames, overrideColumnsTitles } from '../../utils'
import CreateFilterTab from './blocks/Create'
import ListFilterTab from './blocks/List'
import { FilterContext } from './context'
import { FilterItem } from './typings'
import { convertBeforeSave } from './utils/helpers'
import type {} from 'redux-thunk/extend-redux'

import { TableToolbarButton } from '../../styles'

export type FilterTableProps<T extends Record<string, unknown>> = Pick<
  TableToolbarProps<T>,
  'manager' | 'columns' | 'overrideTitles' | 'excludeColumnNames'
>

const initialTab = { tab: 0 }

export function FilterTable<T extends Record<string, unknown>>({
  columns,
  manager,
  overrideTitles,
  excludeColumnNames,
}: FilterTableProps<T>): JSX.Element {
  const [visible, setVisible] = useState(false)
  const [innerFilter, setInnerFilter] = useState<FilterItem[]>([])
  const dispatch = useDispatch()
  const [initHint, setInitHint] = useState(true)
  const [tabState, setTabState] = useState<{
    tab: number
    edit?: FilterItem
  }>(initialTab)
  const mappedColumns = useMemo(() => checkToExcludeColumnNames(columns, excludeColumnNames), [
    excludeColumnNames,
    columns,
  ])

  const filter: FilterItem[] = useSelector(
    ({
      [manager.key]: {
        params: { filter },
      },
    }: {
      [key: string]: TableState
    }) => filter,
  )

  useEffect(() => {
    setInnerFilter(filter)
  }, [filter])
  const HashTab: { [k: number]: React.ReactNode } = {
    0: (
      <ListFilterTab
        isChangedState={filter.length !== innerFilter.length}
        onSave={() => {
          setVisible(false)
          dispatch(manager.changeFilterTable(convertBeforeSave(innerFilter)))
          setTabState(initialTab)
        }}
      />
    ),
    1: <CreateFilterTab />,
  }

  function onClose() {
    setVisible(false)
    setInnerFilter(filter)
    setTabState(initialTab)
  }

  return (
    <>
      <TableToolbarButton
        variant="text"
        onClick={() => setVisible(true)}
        startIcon={<FilterIcon color="primary" />}
        endIcon={!!filter.length && <Badge badgeContent={filter.length} />}
      >
        <Typography variant="Body1-Bold">Фильтры</Typography>
      </TableToolbarButton>
      <FilterContext.Provider
        value={{
          filter: innerFilter,
          onClose,
          setFilter: (v) => setInnerFilter(v),
          setTab: (tab) => setTabState(tab),
          visible,
          header: overrideColumnsTitles(mappedColumns, overrideTitles),
          initHint,
          setInitHint,
          editFilter: tabState.edit,
        }}
      >
        {HashTab[tabState.tab]}
      </FilterContext.Provider>
    </>
  )
}
