import { getColor } from './helpers/getColor'
import { makeStyles, Theme } from '@material-ui/core'

import { IBadgeProps, IUseBadgeClassesProps } from './types'

export const useBadgeStyles = makeStyles((theme: Theme) => ({
  root: ({ badgeContent }: IBadgeProps) => ({
    width: badgeContent ? 'unset' : '20px',
  }),
  anchorOriginTopRightRectangle: {
    transform: 'unset',
  },
  badge: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
    fontVariantNumeric: 'tabular-nums lining-nums',
    position: 'relative',
    backgroundColor: ({ color }: IUseBadgeClassesProps) =>
      getColor(theme, color || 'primary').backgroundColor,
    color: ({ color }: IUseBadgeClassesProps) => getColor(theme, color || 'primary').textColor,
  },
}))
