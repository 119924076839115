export enum WarehouseAliasEnum {
  WAREHOUSE_UGV_OUTPUT = 'warehouse.ugv.output',
  WAREHOUSE_UGV_INPUT = 'warehouse.ugv.input',
  WAREHOUSE_UGV_VIRTUAL_INPUT = 'warehouse.ugv_virtual.input',
  WAREHOUSE_UGV2_INPUT = 'warehouse.ugv2.input',
  WAREHOUSE_UGV2_OUTPUT = 'warehouse.ugv2.output',
  WAREHOUSE_UPP_INPUT = 'warehouse.upp.input',
  WAREHOUSE_UPP_OUTPUT = 'warehouse.upp.output',
  WAREHOUSE_FIB1_OUTPUT = 'warehouse.fib1.output',
  WAREHOUSE_FIB1_INPUT = 'warehouse.fib1.input',
  WAREHOUSE_FIB2_INPUT = 'warehouse.fib2.input',
  WAREHOUSE_FIB2_OUTPUT = 'warehouse.fib2.output',
  WAREHOUSE_FIB3_INPUT = 'warehouse.fib3.input',
  WAREHOUSE_FIB3_OUTPUT = 'warehouse.fib3.output',
  WAREHOUSE_EBNER_OUTPUT = 'warehouse.ebner.output',
  WAREHOUSE_EBNER_INPUT = 'warehouse.ebner.input',
  WAREHOUSE_DANIELLI_OUTPUT = 'warehouse.danielli.output',
  WAREHOUSE_DANIELLI_INPUT = 'warehouse.danielli.input',
  WAREHOUSE_UEF_OUTPUT = 'warehouse.uef.output',
  WAREHOUSE_UEF_INPUT = 'warehouse.uef.input',
  WAREHOUSE_UKP_OUTPUT = 'warehouse.ukp.output',
  WAREHOUSE_UKP_INPUT = 'warehouse.ukp.input',
  WAREHOUSE_PRESSING_MACHINE_1_INPUT = 'warehouse.pressing_machine_1.input',
  WAREHOUSE_PRESSING_MACHINE_1_OUTPUT = 'warehouse.pressing_machine_1.output',
  WAREHOUSE_PRESSING_MACHINE_2_INPUT = 'warehouse.pressing_machine_2.input',
  WAREHOUSE_PRESSING_MACHINE_2_OUTPUT = 'warehouse.pressing_machine_2.output',
  WAREHOUSE_ROLLING_MACHINE_1_INPUT = 'warehouse.rolling_machine_1.input',
  WAREHOUSE_ROLLING_MACHINE_1_OUTPUT = 'warehouse.rolling_machine_1.output',
  WAREHOUSE_ROLLING_MACHINE_2_INPUT = 'warehouse.rolling_machine_2.input',
  WAREHOUSE_ROLLING_MACHINE_2_OUTPUT = 'warehouse.rolling_machine_2.output',
  WAREHOUSE_SHARPENING_MACHINES_INPUT = 'warehouse.sharpening_machines.input',
  WAREHOUSE_SHARPENING_MACHINES_OUTPUT = 'warehouse.sharpening_machines.output',
  WAREHOUSE_CTO_LINES_INPUT = 'warehouse.cto_lines.input',
  WAREHOUSE_CTO_LINES_OUTPUT = 'warehouse.cto_lines.output',
  WAREHOUSE_COATING_LINES_INPUT = 'warehouse.coating_lines.input',
  WAREHOUSE_COATING_LINES_OUTPUT = 'warehouse.coating_lines.output',
  WAREHOUSE_NAIL_PACKING_LINE_INPUT = 'warehouse.nail_packing_line.input',
  WAREHOUSE_NAIL_PACKING_LINE_OUTPUT = 'warehouse.nail_packing_line.output',
  WAREHOUSE_WASHING_MACHINES_INPUT = 'warehouse.washing_machines.input',
  WAREHOUSE_WASHING_MACHINES_OUTPUT = 'warehouse.washing_machines.output',
  WAREHOUSE_CKI_INPUT = 'warehouse.cki.input',
  WAREHOUSE_CKI_OUTPUT = 'warehouse.cki.output',
  WAREHOUSE_GVC_INPUT = 'warehouse.gvc.input',
  WAREHOUSE_GVC_OUTPUT = 'warehouse.gvc.output',
  WAREHOUSE_SPC_OUTPUT = 'warehouse.spc.output',
  WAREHOUSE_SPC_GVC_OUTPUT = 'warehouse.spc_gvc.output',
  WAREHOUSE_UGV_UOP_OUTPUT = 'warehouse.ugv_uop.output',
  WAREHOUSE_UGV_UTO_OUTPUT = 'warehouse.ugv_uto.output',
  WAREHOUSE_PACKING_LINE_INPUT = 'warehouse.packing_line.input',
  WAREHOUSE_PACKING_LINE_OUTPUT = 'warehouse.packing_line.output',
  WAREHOUSE_NAIL_PACKING_MULTIPALLET_LINE_OUTPUT = 'warehouse.packing_line.remains',
  WAREHOUSE_WIRE_NAILING_MACHINE_INPUT = 'warehouse.wire_nailing_machine.input',
  WAREHOUSE_WIRE_NAILING_MACHINE_OUTPUT = 'warehouse.wire_nailing_machine.output',
  WAREHOUSE_POLISHING_DRUM_INPUT = 'warehouse.polishing_drum.input',
  WAREHOUSE_POLISHING_DRUM_OUTPUT = 'warehouse.polishing_drum.output',
  WAREHOUSE_POLISHING_DRUM_1_INPUT = 'warehouse.polishing_drum1.input',
  WAREHOUSE_POLISHING_DRUM_1_OUTPUT = 'warehouse.polishing_drum1.output',
  WAREHOUSE_POLISHING_DRUM_2_INPUT = 'warehouse.polishing_drum2.input',
  WAREHOUSE_POLISHING_DRUM_2_OUTPUT = 'warehouse.polishing_drum2.output',
  WAREHOUSE_POLISHING_DRUM_3_INPUT = 'warehouse.polishing_drum3.input',
  WAREHOUSE_POLISHING_DRUM_3_OUTPUT = 'warehouse.polishing_drum3.output',
  WAREHOUSE_POLISHING_DRUM_4_INPUT = 'warehouse.polishing_drum4.input',
  WAREHOUSE_POLISHING_DRUM_4_OUTPUT = 'warehouse.polishing_drum4.output',
  WAREHOUSE_POLISHING_DRUM_5_INPUT = 'warehouse.polishing_drum5.input',
  WAREHOUSE_POLISHING_DRUM_5_OUTPUT = 'warehouse.polishing_drum5.output',
  WAREHOUSE_POLISHING_DRUM_6_INPUT = 'warehouse.polishing_drum6.input',
  WAREHOUSE_POLISHING_DRUM_6_OUTPUT = 'warehouse.polishing_drum6.output',
  WAREHOUSE_POLISHING_DRUM_7_INPUT = 'warehouse.polishing_drum7.input',
  WAREHOUSE_POLISHING_DRUM_7_OUTPUT = 'warehouse.polishing_drum7.output',
  WAREHOUSE_POLISHING_DRUM_8_INPUT = 'warehouse.polishing_drum8.input',
  WAREHOUSE_POLISHING_DRUM_8_OUTPUT = 'warehouse.polishing_drum8.output',
  WAREHOUSE_FURNACE_EBNER_1_1_INPUT = 'warehouse.furnaceEbner1-1.input',
  WAREHOUSE_FURNACE_EBNER_1_1_OUTPUT = 'warehouse.furnaceEbner1-1.output',
  WAREHOUSE_FURNACE_EBNER_1_2_INPUT = 'warehouse.furnaceEbner1-2.input',
  WAREHOUSE_FURNACE_EBNER_1_2_OUTPUT = 'warehouse.furnaceEbner1-2.output',
  WAREHOUSE_FURNACE_EBNER_2_1_INPUT = 'warehouse.furnaceEbner2-1.input',
  WAREHOUSE_FURNACE_EBNER_2_1_OUTPUT = 'warehouse.furnaceEbner2-1.output',
  WAREHOUSE_FURNACE_EBNER_2_2_INPUT = 'warehouse.furnaceEbner2-2.input',
  WAREHOUSE_FURNACE_EBNER_2_2_OUTPUT = 'warehouse.furnaceEbner2-2.output',
  WAREHOUSE_FURNACE_EBNER_3_1_INPUT = 'warehouse.furnaceEbner3-1.input',
  WAREHOUSE_FURNACE_EBNER_3_1_OUTPUT = 'warehouse.furnaceEbner3-1.output',
  WAREHOUSE_FURNACE_EBNER_3_2_INPUT = 'warehouse.furnaceEbner3-2.input',
  WAREHOUSE_FURNACE_EBNER_3_2_OUTPUT = 'warehouse.furnaceEbner3-2.output',
  WAREHOUSE_FURNACE_DANIELLI_1_1_INPUT = 'warehouse.furnaceDanielli.input',
  WAREHOUSE_FURNACE_DANIELLI_1_1_OUTPUT = 'warehouse.furnaceDanielli.output',
  WAREHOUSE_GVC_VIRTUAL_INPUT = 'warehouse.gvc_virtual.input',
  WAREHOUSE_UGV_RESIDUALS = 'warehouse.ugv.residuals',
  WAREHOUSE_UOP_RESIDUALS = 'warehouse.uop.residuals',
}

export enum ProductAlias {
  WIRE = 'wire',
  WIRE_ROD = 'wire_rod',
  FASTENING_PRODUCTS = 'fastening_products',
  PALLET_PRODUCTS = 'pallet_products',
  NAIL = 'nail',
  RESIDUALS = 'residuals',
  REMAINS = 'remains',
  WIRE_ROSETTE = 'wire-rosette',
}

export enum SumWeightField {
  WEIGHT = 'weight',
  GROSS_WEIGHT = 'gross_weight',
}

export enum DateFilterType {
  NONE = 'none',
  START = 'start',
  FINISH = 'finish',
  FINISH_IS_NULL = 'finishIsNull',
  CURRENT = 'current',
}
