import { getColor } from './helpers/getColor'
import { makeStyles, Theme } from '@material-ui/core'

import { IUseChipClassesProps } from './types'

export const useChipInStatusStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '24px',
    height: '38px',
    color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).textColor,
    backgroundColor: ({ color, withBg }: IUseChipClassesProps) =>
      getColor(theme, color, withBg).backgroundColor,
    '& .MuiChip-avatar': {
      width: '20px',
      height: '20px',
      paddingLeft: '12px',
      paddingRight: '6px',
      margin: 0,
      fill: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& svg': {
      width: '20px',
      height: '20px',
      paddingLeft: '12px',
      paddingRight: '6px',
      margin: 0,
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& .MuiChip-label': {
      fontSize: theme.textStyles.body1medium.fontSize,
      fontWeight: theme.textStyles.body1medium.fontWeight,
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).textColor,
      paddingLeft: '0px',
      paddingRight: '16px',
    },
  },
  sizeBig: {
    borderRadius: '22px',
    height: '44px',
    '& .MuiChip-avatar': {
      width: '24px',
      height: '24px',
      paddingLeft: '12px',
      paddingRight: '10px',
      margin: 0,
      fill: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& svg': {
      width: '24px',
      height: '24px',
      paddingLeft: '12px',
      paddingRight: '10px',
      margin: 0,
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& .MuiChip-label': {
      fontSize: theme.textStyles.subheading3medium.fontSize,
      fontWeight: theme.textStyles.subheading3medium.fontWeight,
      paddingLeft: '0px',
      paddingRight: '16px',
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).textColor,
    },
  },
  sizeSmall: {
    borderRadius: '13px',
    height: '26px',
    '& .MuiChip-avatar': {
      width: '18px',
      height: '18px',
      paddingLeft: '8px',
      paddingRight: '4px',
      margin: 0,
      fill: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& svg': {
      width: '18px',
      height: '18px',
      paddingLeft: '8px',
      paddingRight: '4px',
      margin: 0,
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).iconColor,
    },
    '& .MuiChip-label': {
      fontSize: theme.textStyles.body2medium.fontSize,
      fontWeight: theme.textStyles.body2medium.fontWeight,
      paddingLeft: '0px',
      paddingRight: '12px',
      color: ({ color, withBg }: IUseChipClassesProps) => getColor(theme, color, withBg).textColor,
    },
  },
}))
