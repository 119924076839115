import { quarterQuarterKeys, quarters } from './quarters'

export const getLastValue = (
  level: string,
  valueTo: Date | undefined,
  valueFrom: Date | undefined,
): Date => {
  switch (level) {
    case 'day':
      return new Date(
        (valueTo || valueFrom)?.getFullYear() as number,
        (valueTo || valueFrom)?.getMonth() as number,
        (valueTo || valueFrom)?.getDate() as number,
        23,
        59,
        59,
      )

    case 'month':
      return new Date(
        (valueTo || valueFrom)?.getFullYear() as number,
        ((valueTo || valueFrom)?.getMonth() as number) + 1,
        0,
        23,
        59,
        59,
      )
    case 'quarter':
      const quarterKey = quarterQuarterKeys[(valueTo || valueFrom)?.getMonth() as number]
      const lastMonthInQuarter = quarters.find((el) => el.month === quarterKey)?.values[2]
      return new Date(
        (valueTo || valueFrom)?.getFullYear() as number,
        (lastMonthInQuarter || ((valueTo || valueFrom)?.getMonth() as number)) + 1,
        0,
        23,
        59,
        59,
      )
    default:
      return new Date((valueTo || valueFrom)?.getFullYear() as number, 11, 31, 23, 59, 59)
  }
}
