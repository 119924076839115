import { makeStyles } from '@material-ui/core'

export const useTabsStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 23, 41, 0.12)',
  },
  flexContainer: {
    gap: 24,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))
