// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import service from 'services/service'

type UseRemoveNodeParams = {
  onSuccess: () => void
}

export const useRemoveNode = ({ onSuccess }: UseRemoveNodeParams) => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const removeNode = useCallback(
    async (nodeId: number) => {
      try {
        setIsLoading(true)

        await service.removeNode(nodeId)

        // alert.success('Узел удален из отображения');

        onSuccess()
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert],
  )

  return {
    isLoading,
    removeNode,
  }
}
