// import { DictionaryValue } from 'features';

export type TColumns = {
  name: keyof Attributes
  title: string
  width?: string
}

export const weightFields = ['plan_weight', 'gross_weight', 'net_weight', 'weight'] as Array<
  keyof Attributes
>

export const COLUMNS: {
  [key in
    | 'ugv'
    | 'ugv_uef'
    | 'uop'
    | 'cki'
    | 'gvc'
    | 'ugv_invoice'
    | 'ugv_packing'
    | 'gvc_remains'
    | 'residuals']: TColumns[]
} = {
  ugv: [
    { name: 'barcode', title: '№ СпК', width: '110px' },
    { name: 'appointment', title: 'Назначение', width: '200px' },
    { name: 'controls', title: ' ', width: '1px' },
    { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
    { name: 'steel_grade', title: 'Марка стали', width: '140px' },
    { name: 'aggregate', title: '№ стана', width: '120px' },
    { name: 'coil_no', title: '№ катушки', width: '100px' },
    { name: 'date', title: 'Дата начала обработки', width: '240px' },
    { name: 'startStoreTime', title: 'Дата окончания обработки', width: '240px' },
    { name: 'swim_number', title: '№ плавки', width: '120px' },
    { name: 'order_code', title: 'Шифр заказа', width: '100px' },
    { name: 'grease_type', title: 'Тип смазки', width: '140px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'net_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
    { name: 'defect', title: 'Дефект', width: '150px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  ugv_uef: [
    { name: 'barcode', title: '№ СпК', width: '110px' },
    { name: 'appointment', title: 'Назначение', width: '200px' },
    { name: 'controls', title: ' ', width: '1px' },
    { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
    { name: 'steel_grade', title: 'Марка стали', width: '140px' },
    { name: 'aggregate', title: '№ стана', width: '120px' },
    { name: 'coil_no', title: '№ катушки', width: '100px' },
    { name: 'date', title: 'Дата начала обработки', width: '240px' },
    { name: 'startStoreTime', title: 'Дата окончания обработки', width: '240px' },
    { name: 'swim_number', title: '№ плавки', width: '120px' },
    { name: 'grease_type', title: 'Тип смазки', width: '140px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'net_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
    { name: 'defect', title: 'Дефект', width: '150px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  uop: [
    { name: 'barcode', title: '№ СпК', width: '110px' },
    { name: 'appointment', title: 'Назначение', width: '200px' },
    { name: 'controls', title: ' ', width: '1px' },
    { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
    { name: 'steel_grade', title: 'Марка стали', width: '140px' },
    { name: 'aggregate', title: '№ стана', width: '120px' },
    { name: 'coil_no', title: '№ катушки', width: '100px' },
    { name: 'date', title: 'Дата начала обработки', width: '240px' },
    { name: 'startStoreTime', title: 'Дата окончания обработки', width: '240px' },
    { name: 'swim_number', title: '№ плавки', width: '120px' },
    { name: 'grease_type', title: 'Тип смазки', width: '140px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
    { name: 'defect', title: 'Дефект', width: '150px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'brigade', title: '№ бригады', width: '100px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  residuals: [
    { name: 'name', title: 'Название', width: '240px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'controls', title: ' ', width: '140px' },
  ],
  cki: [
    { name: 'barcode', title: '№ СпК', width: '180px' },
    { name: 'controls', title: ' ', width: '1px' },
    { name: 'standard', title: 'Типоразмер', width: '240px' },
    { name: 'kubel_number', title: '№ Контейнера', width: '240px' },
    { name: 'aggregate', title: '№ стана', width: '240px' },
    { name: 'startStoreTime', title: 'Дата изготовления', width: '240px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'gross_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
    { name: 'defect', title: 'Дефект', width: '150px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  gvc: [
    { name: 'barcode', title: '№ СпК', width: '180px' },
    { name: 'aggregate', title: '№ стана', width: '240px' },
    { name: 'coil_no', title: '№ кюбеля', width: '100px' },
    { name: 'nail_standard', title: 'Сортамент' },
    { name: 'nail_type', title: '№ гвоздя' },
    { name: 'date', title: 'Дата начала обработки', width: '240px' },
    { name: 'startStoreTime', title: 'Дата окончания обработки', width: '240px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'net_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  gvc_remains: [
    { name: 'number', title: '№ гвоздя', width: '100px' },
    { name: 'nail_standard', title: 'Сортамент', width: '90px' },
    { name: 'tu_gost', title: 'ТУ, ГОСТ', width: '150px' },
    { name: 'count', title: 'Кол-во коробок', width: '90px' },
    { name: 'net_weight_box', title: 'Вес коробки, кг', width: '80px' },
  ],
  ugv_invoice: [
    { name: 'barcode', title: '№ СпК', width: '110px' },
    { name: 'appointment', title: 'Назначение', width: '200px' },
    { name: 'controls', title: ' ', width: '1px' },
    { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
    { name: 'steel_grade', title: 'Марка стали', width: '140px' },
    { name: 'aggregate', title: '№ стана', width: '120px' },
    { name: 'coil_no', title: '№ катушки', width: '100px' },
    { name: 'date', title: 'Дата начала обработки', width: '240px' },
    { name: 'startStoreTime', title: 'Дата окончания обработки', width: '240px' },
    { name: 'swim_number', title: '№ плавки', width: '120px' },
    { name: 'grease_type', title: 'Тип смазки', width: '140px' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'net_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'invoice_number', title: '№ накладной', width: '120px' },
    { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
    { name: 'defect', title: 'Дефект', width: '150px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
  ],
  ugv_packing: [
    { name: 'barcode', title: '№ СпК', width: '180px' },
    { name: 'aggregate', title: '№ линии', width: '240px' },
    { name: 'nail_standard', title: 'Сортамент' },
    { name: 'count', title: 'Кол-во коробок на паллете', width: '240px' },
    { name: 'net_weight_box', title: 'Вес коробки, кг', width: '240px' },
    { name: 'number', title: '№ гвоздя' },
    { name: 'weight', title: 'Теор.вес, тн', width: '120px' },
    { name: 'net_weight', title: 'Фактический вес, тн', width: '120px' },
    { name: 'controlOtk', title: ' ', width: '140px' },
    { name: 'otk_cause', title: 'Причина дефекта', width: '150px' },
    { name: 'operator', title: 'Создал', width: '150px' },
    { name: 'change_operator', title: 'Изменил', width: '150px' },
    { name: 'type', title: 'Тип ЕП', width: '100px' },
    { name: 'controls', title: ' ', width: '1px' },
  ],
}

export const reducerKeyPrefix = 'WAREHOUSE-TABLE-output'

export type ReducerKey = `${typeof reducerKeyPrefix}-${keyof typeof COLUMNS}`

const convertReducerKey = (abbreviation: keyof typeof COLUMNS): ReducerKey =>
  `${reducerKeyPrefix}-${abbreviation}` as ReducerKey

export const getReducerKey = (
  masterName: 'uop' | 'ugv' | 'cki' | 'gvc',
  code?: string,
  controlBtns?: boolean,
  subsection?: string,
): ReducerKey => {
  // TODO: переписать на нормальное получение столбцов
  if (subsection === 'uef') return convertReducerKey('ugv_uef')
  if (code === 'packing_line-output') return convertReducerKey('ugv_packing')
  if (code === 'packing_line-remains') return convertReducerKey('gvc_remains')
  if (code === 'residuals') return convertReducerKey('residuals')
  return controlBtns ? convertReducerKey('ugv_invoice') : convertReducerKey(masterName)
}

export type Attributes = {
  barcode: string
  operator: string
  change_operator: string
  appointment: string
  controls: string
  diameter: string
  steel_grade: string
  weight: string
  theor_weight?: string
  aggregate: string
  coil_no: string
  grease_type: string
  swim_number: string
  id: number
  order_code?: string
  startStoreTime: string
  statusOTK: string
  defect: string
  otk_cause?: string
  otk_defect?: string
  linkedLabRequestBarcodes: Array<string>
  controlOtk: string
  nail_length: string
  galvanized: string
  invoice_number?: string
  brigade?: string
  plan_weight?: string
  nail_type?: string
  nail_standard?: string
  fact_weight: string
  net_weight?: string
  count_packing?: string
  weight_packing?: string
  standard?: string
  gross_weight?: string
  kubel_number?: string
  zone_number?: string
  type?: string
  net_weight_box?: string
  number?: string
  count?: string
  multipallet?: string
  tu_gost?: string
  input?: string
  name?: string
  date?: string
  remaining_weight?: string
  multipallets?: {
    id: number
    type: string
    alias: string
    attributes: {
      aggregate: string
      appointment: string
      card: string
      change_operator: string
      coil_no: string
      count: string
      direction: string
      nail_standard: string
      net_weight_box: string
      note: string
      number: string
      operator: string
      tu_gost: string
      weight: string
    }
  }[]
}

type Product = {
  id: number
  alias: string | null
  type: string | null
}

export type Row = Attributes & {
  product: Product
}

export type SetStatus = (args: {
  barcode: number
  product: Product
  // statusOTK: DictionaryValue;
  statusOTK: {
    id: String
    value: String
  }
  otkCause?:
    | string
    | null
    | {
        id?: number
        value?: string
      }
  otkDefect?:
    | string
    | null
    | {
        id?: number
        value?: string
      }
  clearDefect?: boolean
}) => Promise<void>

export type TResContent = {
  id: number
  type: string | null
  alias: string | null
  attributes: Attributes
  childs: any[]
}

export type TResInvoice = {
  content: {
    node: {
      id: number
      attributes: {
        number: string
      }
      childs: TResContent[]
      type: string
    }
    startDateTime: string
    unit: {
      alias: string
      attributes: Attributes
      id: number
      type: string
    }
  }[]
}

export const mapProductAliasToProductLabel: Record<string, string> = {
  wire: 'Проволока',
  rosette: 'Розетта',
  wire_rod: 'Катанка',
  fastening_products: 'Крепежные изделия',
  pallet_products: 'Паллеты',
  nail: 'Гвозди',
}
