import React from 'react'

import { OldDatePicker } from '../../OldDatePicker'
import { FormHelperText } from '@material-ui/core'
import isValid from 'date-fns/isValid'
import { useField } from 'formik'

import { FormDatePickerProps } from './types'

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  disabled,
  size,
  label,
  type,
  ...pickerProps
}) => {
  const [{ value, onBlur }, { error: fieldError, touched }, { setValue, setTouched }] = useField(
    name,
  )
  const error = touched && Boolean(fieldError)

  return (
    <div>
      <OldDatePicker
        fullWidth
        disabled={disabled}
        size={size || 'small'}
        name={name}
        label={label}
        type={type}
        value={value}
        onChange={(e) => {
          setValue(isValid(e) ? e : value, true)
          setTouched(true)
        }}
        inputProps={{ onBlur }}
        error={error}
        {...pickerProps}
      />
      <FormHelperText error={error}>{touched && fieldError}</FormHelperText>
    </div>
  )
}
