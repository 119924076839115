import { TableColumnWidthInfo } from '../interfaces/Table'
import { TableColumn } from '../ui-kit/components'
import DEFAULT_COLUMN_WIDTH from '../ui-kit/frontend-components/Table/constants'

export const getColumnWidth = (columnWidth?: TableColumn<unknown>['width']): number => {
  if (!columnWidth) {
    return DEFAULT_COLUMN_WIDTH
  }
  if (typeof columnWidth === 'string') {
    return Number(columnWidth.match(/\d+/g)?.[0] ?? DEFAULT_COLUMN_WIDTH)
  }
  return columnWidth
}

const getResizableColumns = <R>(columns: TableColumn<R>[]): TableColumnWidthInfo<R>[] => {
  const resizableColumns = columns
    ?.map(({ width, ...rest }) => ({
      ...rest,
      width: getColumnWidth(width),
    }))
    .map(({ name, width }) => ({
      columnName: name,
      width,
    }))
  return resizableColumns
}

export default getResizableColumns
