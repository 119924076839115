import { makeStyles, Theme } from '@material-ui/core'

export const useContextMenuStyles = makeStyles<Theme>((theme) => ({
  contextMenu: {
    position: 'fixed',
    backgroundColor: theme.neutral.white,
    borderRadius: '4px',
    padding: '8px 0',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, .25)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: 1000000,
    listStyleType: 'none',
  },

  contextMenuItem: {
    boxSizing: 'border-box',
    backgroundColor: theme.neutral.white,
    color: theme.neutral.black,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    minWidth: '100%',
    fontSize: '14px',
    border: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.default,
    },
  },

  icon: {
    color: theme.neutral.black,
    padding: '0 8px 0 0',
  },

  disabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'default',
      backgroundColor: theme.neutral.white,
    },
  },
}))
