import { combineReducers } from 'redux'

import { ManagerAction, TableState, TableStateParams, TableStateVisual } from './types'

const defaultState: TableState = {
  params: {
    filter: [],
    viewTable: [],
    sort: [],
    query: '',
    page: 0,
  },
  visual: {
    loading: false,
  },
}

function createTableParamsReducer<T extends TableStateParams>(key: string, initialState: T) {
  const startInitialState: T = { ...defaultState.params, ...initialState }
  return (state = { ...startInitialState }, action: ManagerAction<T>): T => {
    switch (action.type) {
      case `[TABLE-${key}.PARAMS] reset`:
        return { ...startInitialState }
      case `[TABLE-${key}.PARAMS] change`:
        return { ...state, ...action.payload }
      default:
        return state
    }
  }
}

function createTableVisualReducer<T extends TableStateVisual>(key: string, initialState: T) {
  const startInitialState = { ...defaultState.visual, ...initialState }
  return (state = { ...startInitialState }, action: ManagerAction<T>): T => {
    switch (action.type) {
      case `[TABLE-${key}.VISUAL] reset`:
        return { ...startInitialState }
      case `[TABLE-${key}.VISUAL] change`:
        return { ...state, ...action.payload }
      default:
        return state
    }
  }
}

const createTableReducer = <State extends TableState>(key: string, initialState: State) => {
  const state = { ...defaultState, ...initialState }
  return combineReducers({
    params: createTableParamsReducer<State['params']>(key, state.params),
    visual: createTableVisualReducer<State['visual']>(key, state.visual),
  })
}

export { createTableReducer, createTableVisualReducer, createTableParamsReducer, defaultState }
