import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import useAsync from '../../../../../ui-kit/hooks/useAsync'
import { convertFilters } from '../../DisplayDictionaries/hooks/useDictionary'
import { getReducerKey } from '../utils'
import { ResponseAllWithNodes } from 'interfaces/responses/Dictionary'
import { Request } from 'interfaces/responses/Request'
// import { useAlert } from 'react-alert';
import service from 'services/service'
import DICTIONARY_WORK_STATUS from 'shared/enums/DictionaryWorkStatus'
import { useStoreSelector } from 'store/hooks'

export type AllWithNodes = ResponseAllWithNodes['content']['content']

export type DictionaryInfo = ResponseAllWithNodes['dictionaryInfo']

export type Column = {
  main: boolean
  name: string
  title: string
  order: number
  optional?: boolean
  type: 'Date' | 'Integer' | 'String' | 'Double' | 'Array'
  dataType: 'custom'
  customMethods: {
    label: string
    value: string
  }[]
}

type UseDictionaryValuesReturn = {
  dictionaryValues: AllWithNodes
  setDictionaryValues: Dispatch<SetStateAction<AllWithNodes>>
  columns: Column[]
  request: Request<ResponseAllWithNodes>
  dictionaryInfo: DictionaryInfo
  dependentColumns: Record<string, string>
  pageable: {
    currentPage: number
    totalPages: number
    changeTotalPages: (totalPages: number) => void
  }
}

const convertColumns = (
  columnInfo: ResponseAllWithNodes['columnInfo'],
  dictionaryInfo: DictionaryInfo,
): Column[] => {
  return Object.entries<typeof columnInfo[string]>(columnInfo)
    .sort(([, a], [, b]) => a.order - b.order)
    .map(([key, value]) => {
      const title =
        value.main && dictionaryInfo.mainMeasure
          ? `${value.name}, ${dictionaryInfo.mainMeasure.nationalSymbol}`
          : value.name
      return {
        main: value.main,
        optional: Boolean(value.fillNull),
        name: key,
        title,
        order: value.order,
        type: value.type,
        dataType: 'custom' as const,
        customMethods: [
          {
            label: 'Содержит',
            value: 'string-have',
          },
        ],
      }
    })
    .filter(({ main, name }) => name !== 'value' || main)
}

const ADDITIONAL_COLUMNS = {
  nodesBinding: {
    dependentColumn: null,
    main: false,
    fillNull: null,
    name: 'Места использования',
    order: 50,
    type: 'Array' as const,
  },
}

export const useDictionaryValues = (
  dictionaryType: string,
  workStatus?: DICTIONARY_WORK_STATUS,
): UseDictionaryValuesReturn => {
  // const alert = useAlert();

  const [totalPages, setTotalPages] = useState(0)

  const reducerKey = useMemo(() => getReducerKey(dictionaryType), [dictionaryType])

  const currentPage = useStoreSelector((state) => state?.[reducerKey]?.params?.page ?? 0)

  const filters = useStoreSelector((state) => state?.[reducerKey]?.params?.filter ?? [])

  const [columns, setColumns] = useState<Column[]>([])
  const [dependentColumns, setDependentColumns] = useState<Record<string, string>>({})
  const [dictionaryValues, setDictionaryValues] = useState<AllWithNodes>([])
  const [dictionaryInfo, setDictionaryInfo] = useState<DictionaryInfo>({
    codeRequired: false,
    mainMeasure: null,
  })

  const getAllWithNodes = useCallback(
    () =>
      service.getAllWithNodes({
        dictionaryType,
        workStatus,
        page: currentPage,
        listFilter: convertFilters(filters),
      }),
    [dictionaryType, workStatus, filters, currentPage],
  )

  const request = useAsync(getAllWithNodes, false)

  useEffect(() => {
    if (request.value && request.status === 'success') {
      setTotalPages(request.value.content.totalPages)
      setColumns(
        convertColumns(
          { ...request.value.columnInfo, ...ADDITIONAL_COLUMNS },
          request.value.dictionaryInfo,
        ),
      )
      setDependentColumns(
        Object.entries(request.value.columnInfo).reduce((acc, [key, { dependentColumn }]) => {
          if (dependentColumn) {
            return {
              ...acc,
              [key]: dependentColumn.dictionaryType,
            }
          }
          return acc
        }, {}),
      )
      setDictionaryValues(request.value.content.content)
      setDictionaryInfo(request.value.dictionaryInfo)
    }
  }, [request.value, request.status])

  useEffect(() => {
    if (request.error) {
      // alert.error(request.error);
    }
  }, [request.error])

  return {
    dictionaryValues,
    setDictionaryValues,
    columns,
    request,
    dictionaryInfo,
    dependentColumns,
    pageable: {
      totalPages,
      currentPage,
      changeTotalPages: setTotalPages,
    },
  }
}
