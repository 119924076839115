import React from 'react'

import { Footer } from '../Footer'
import { PeriodPicker } from '../PeriodPicker'
import { ShiftSelector } from '../ShiftSelector'
import clsx from 'clsx'

import { CommonCalendarProps } from './types'

import { useCommonCalendarStyles } from './styles'

export const CommonCalendar = React.forwardRef<HTMLDivElement, CommonCalendarProps>(
  (
    {
      disableContentOfPeriodPicker,
      showFooter,
      onDecline,
      onAccept,
      onRightClick,
      onLeftClick,
      onContentClick,
      children,
      period,
      timeSlot,
      shiftTo,
      shiftFrom,
      shiftLength,
      onChangeShiftFrom,
      onChangeShiftTo,
      withSeconds,
      disableChange,
      showShiftsSelector,
      level,
      isHideYear,
      selectedPanel,
      ...props
    },
    ref,
  ) => {
    const styles = useCommonCalendarStyles()
    return (
      <div
        ref={ref}
        className={clsx(
          styles.root,
          Boolean(timeSlot) && styles.withTime,
          Boolean(withSeconds) && styles.withSeconds,
        )}
        {...props}
      >
        <div
          className={clsx(timeSlot && styles.withTimeSlot, withSeconds && styles.withSecondsSlot)}
        >
          <PeriodPicker
            disableContent={disableChange || disableContentOfPeriodPicker}
            onLeftClick={onLeftClick}
            onRightClick={onRightClick}
            onContentClick={onContentClick}
            isHideYear={isHideYear}
            selectedPanel={selectedPanel}
          >
            {period}
          </PeriodPicker>
          <div className={styles.calendarPanel}>{children}</div>
          {timeSlot && <div className={styles.timePanel}>{timeSlot}</div>}
        </div>
        {showShiftsSelector && (
          <div className={styles.period}>
            <div className={styles.periodTitle}>Смены начала и конца периода</div>
            <div className={styles.periodSelectors}>
              <ShiftSelector
                disabled={disableChange}
                value={shiftFrom}
                shiftLength={shiftLength}
                onChange={onChangeShiftFrom}
              />{' '}
              —{' '}
              <ShiftSelector
                disabled={disableChange}
                value={shiftTo}
                shiftLength={shiftLength}
                onChange={onChangeShiftTo}
              />
            </div>
          </div>
        )}
        {showFooter && (
          <Footer
            onDecline={onDecline}
            onAccept={onAccept}
            className={styles.footer}
            disable={disableChange}
          />
        )}
      </div>
    )
  },
)
