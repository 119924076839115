import { makeStyles } from '@material-ui/core'

export const useShiftSelectorStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.neutral.grey100}`,
    borderRadius: '4px',
    display: 'flex',
    color: theme.palette.text.primary,
  },
  shift: {
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.neutral.grey100}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
  },
  disabled: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  selectedDisabled: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))
