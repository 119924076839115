import { TableColumn } from '../../Table/types'
import { getInitialViews } from '../../TableToolbar/components/View/utils'
import { defaultState } from '../reducer'
import { TableState } from '../types'

export function generateDefaultTableState<T extends unknown>(
  columns: TableColumn<T>[],
): TableState {
  return {
    ...defaultState,
    params: {
      ...defaultState.params,
      viewTable: getInitialViews(columns),
    },
  }
}
