import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconFeedback32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M9.31801 11.8877C9.21328 12.0681 9.18533 12.2817 9.24021 12.4823C9.29509 12.6828 9.42839 12.854 9.61119 12.9589L20.6403 19.0411C20.7638 19.1129 20.9046 19.1507 21.0481 19.1507C21.1916 19.1507 21.3325 19.1129 21.4559 19.0411L32 12.8178V22.9549C32 23.2321 31.8877 23.4979 31.6877 23.6939C31.4878 23.8899 31.2166 24 30.9339 24H0.7996C0.587533 24 0.384152 23.9174 0.234197 23.7704C0.0842434 23.6234 0 23.4241 0 23.2162C0 23.0083 0.0842434 22.809 0.234197 22.662C0.384152 22.515 0.587533 22.4324 0.7996 22.4324H8.30518V20.4781H2.47876C2.26669 20.4781 2.06331 20.3955 1.91336 20.2485C1.7634 20.1016 1.67916 19.9022 1.67916 19.6943C1.67916 19.4864 1.7634 19.2871 1.91336 19.1401C2.06331 18.9931 2.26669 18.9105 2.47876 18.9105H8.30518V16.8831H4.61103C4.39896 16.8831 4.19558 16.8005 4.04562 16.6535C3.89567 16.5065 3.81143 16.3072 3.81143 16.0993C3.81143 15.8914 3.89567 15.692 4.04562 15.545C4.19558 15.3981 4.39896 15.3155 4.61103 15.3155H8.30518V9.04507C8.30518 8.7679 8.41751 8.50208 8.61744 8.30609C8.81738 8.11011 9.08856 8 9.37131 8H30.9339C31.2166 8 31.4878 8.11011 31.6877 8.30609C31.8877 8.50208 32 8.7679 32 9.04507V10.7433L21.0135 17.4474L10.4055 11.5898C10.2209 11.4883 10.0028 11.4626 9.79894 11.5184C9.5951 11.5743 9.42215 11.707 9.31801 11.8877Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconFeedback32
