export type TColumns = {
  name: keyof Attributes
  title: string
  width?: string
}

export const COLUMNS: TColumns[] = [
  { name: 'swim_number', title: 'Плавка' },
  { name: 'coil_number', title: '№ бунта', width: '160px' },
  { name: 'weight', title: 'Вес, тн' },
  { name: 'remaining_weight', title: 'Вес остатка, тн' },
  { name: 'barcode', title: '№ Штрихкода', width: '240px' },
  { name: 'status', title: 'Оприходование', width: '200px' },
  { name: 'aggregate', title: '№ стана' },
  { name: 'steel_grade', title: 'Марка стали' },
  { name: 'diameter', title: '⌀' },
  { name: 'theor_weight', title: 'Теор.вес, тн' },
  { name: 'startStoreTime', title: 'Датa приёмки', width: '300px' },
  { name: 'type', title: 'Тип ЕП', width: '100px' },
]

export const COLUMNS_ALL: TColumns[] = [
  { name: 'swim_number', title: 'Плавка' },
  { name: 'coil_number', title: '№ бунта', width: '160px' },
  { name: 'weight', title: 'Вес, тн', width: '120px' },
  { name: 'remaining_weight', title: 'Вес остатка, тн', width: '120px' },
  { name: 'theor_weight', title: 'Теор.вес, тн', width: '120px' },
  { name: 'barcode', title: '№ Штрихкода', width: '240px' },
  { name: 'steel_grade', title: 'Марка стали' },
  { name: 'zone_number', title: 'Номер зоны', width: '150px' },
  { name: 'deliveryNum', title: 'Номер накладной', width: '150px' },
  { name: 'diameter', title: '⌀' },
  { name: 'borating', title: 'Признак борирования' },
  { name: 'no_those_cards', title: '№ Техкарты' },
  { name: 'date', title: 'Дата изготовления' },
  { name: 'startStoreTime', title: 'Дата размещения в зону', width: '300px' },
]

export const COLUMNS_WIRE = [
  { name: 'barcode', title: '№ СпК', width: '120px' },
  { name: 'appointment', title: 'Назначение', width: '200px' },
  { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
  { name: 'steel_grade', title: 'Марка стали', width: '140px' },
  { name: 'status_inventory', title: 'Инвентаризация', width: '180px' },
  { name: 'swim_number', title: '№ плавки', width: '120px' },
  { name: 'grease_type', title: 'Тип смазки', width: '140px' },
  { name: 'weight', title: 'Вес, тн', width: '120px' },
  { name: 'remaining_weight', title: 'Вес остатка, тн', width: '120px' },
  { name: 'theor_weight', title: 'Теор.вес, тн', width: '120px' },
  { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
  { name: 'operator', title: 'Оператор', width: '150px' },
  { name: 'type', title: 'Тип ЕП', width: '100px' },
]

export const COLUMNS_WIRE_ALL_ZONES = [
  { name: 'barcode', title: '№ СпК', width: '120px' },
  { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
  { name: 'steel_grade', title: 'Марка стали', width: '140px' },
  { name: 'zone_number', title: 'Номер зоны', width: '120px' },
  { name: 'swim_number', title: '№ плавки', width: '120px' },
  { name: 'weight', title: 'Вес, тн', width: '120px' },
  { name: 'net_weight', title: 'Вес нетто', width: '150px' },
  { name: 'remaining_weight', title: 'Вес остатка, тн', width: '120px' },
  { name: 'theor_weight', title: 'Теор.вес, тн', width: '120px' },
  { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
  { name: 'operator', title: 'Оператор', width: '150px' },
  { name: 'type', title: 'Тип ЕП', width: '100px' },
]

export const COLUMNS_PRESSING_MACHINE = [
  { name: 'barcode', title: '№ СпК', width: '120px' },
  { name: 'appointment', title: 'Назначение', width: '200px' },
  { name: 'diameter', title: 'Диаметр, мм', width: '100px' },
  { name: 'steel_grade', title: 'Марка стали', width: '140px' },
  { name: 'status_inventory', title: 'Инвентаризация', width: '180px' },
  { name: 'controls', title: ' ', width: '1px' },
  { name: 'swim_number', title: '№ плавки', width: '120px' },
  { name: 'grease_type', title: 'Тип смазки', width: '140px' },
  { name: 'weight', title: 'Вес, тн', width: '120px' },
  { name: 'remaining_weight', title: 'Вес остатка, тн', width: '120px' },
  { name: 'theor_weight', title: 'Теор.вес, тн', width: '120px' },
  { name: 'statusOTK', title: 'Отметка ОТК', width: '100px' },
  { name: 'operator', title: 'Оператор', width: '150px' },
]

export const COLUMNS_SHARE = {
  common: COLUMNS,
  all: COLUMNS_ALL,
  defaultAllZones: COLUMNS_WIRE_ALL_ZONES,
  pressingMachine: COLUMNS_PRESSING_MACHINE,
  default: COLUMNS_WIRE,
}

export const reducerKeyPrefix = 'WAREHOUSE-TABLE-input'

export type ReducerKey = `${typeof reducerKeyPrefix}-${keyof typeof COLUMNS_SHARE}`

const convertReducerKey = (abbreviation: keyof typeof COLUMNS_SHARE): ReducerKey =>
  `${reducerKeyPrefix}-${abbreviation}` as ReducerKey

// TODO: refactor get columns by masterName
export const getReducerKey = (location: string, masterName?: string): ReducerKey => {
  if (location === 'warehouse' && masterName === 'ugv') return convertReducerKey('all')
  if (location === 'warehouse' && (masterName === 'gvc' || masterName === 'cki'))
    return convertReducerKey('defaultAllZones')
  if (masterName === 'ugv' || masterName === 'uop') return convertReducerKey('common')
  if (masterName?.includes('pressing_machine')) return convertReducerKey('pressingMachine')
  return convertReducerKey('default')
}

export type Attributes = {
  coil_number: string
  aggregate: string
  barcode: string
  status: string
  diameter: string
  steel_grade: string
  weight?: string
  coil_no: string
  grease_type: string
  swim_number: string
  statusOTK: string
  id: number
  startStoreTime: string
  remaining_weight?: string
  theor_weight?: string
  zone_number?: string
  zone: {
    id: number
    attributes: {
      number: string
    }
  }
  type?: string
  borating?: string
  no_those_cards?: string
  date?: string
  deliveryNum?: string
}

export type TResContent = {
  id: number
  attributes: Attributes
}

export const weightFields = ['weight', 'theor_weight', 'remaining_weight'] as Array<
  keyof Attributes
>
