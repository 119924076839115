import React, { useCallback, useContext } from 'react'

import ChevronLeftIcon from '../../../Icon/icons_internal/ChevronLeftIcon'
import { NewTooltip } from '../../../NewTooltip'
import NavigationContext from '../../context/NavigationContext'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import clsx from 'clsx'

import { useNavigationStyles } from '../../styles'

export const HideMenuItem = (): JSX.Element => {
  const { isOpen, setSelectedFirstLevel, onOpenChange } = useContext(NavigationContext)
  const {
    listItemGutters,
    listItemIconNotOpenRoot,
    listItemIcon,
    listItemIconClosed,
    listItemIconNotOpen,
  } = useNavigationStyles()

  const handleToggle = useCallback(() => {
    if (isOpen) {
      setSelectedFirstLevel(null)
    }
    onOpenChange?.(!isOpen)
  }, [isOpen, setSelectedFirstLevel, onOpenChange])

  return (
    <NewTooltip title={!isOpen ? 'Развернуть' : ''} placement="right">
      <ListItem
        classes={{
          gutters: listItemGutters,
          root: clsx({
            [listItemIconNotOpenRoot]: !isOpen,
          }),
        }}
        onClick={handleToggle}
      >
        <ListItemIcon
          classes={{
            root: clsx(listItemIcon, {
              [listItemIconClosed]: !isOpen,
              [listItemIconNotOpen]: !isOpen,
            }),
          }}
        >
          <ChevronLeftIcon />
        </ListItemIcon>
        <ListItemText primary={'Свернуть'} />
      </ListItem>
    </NewTooltip>
  )
}
