import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconCrane24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M20.1885 9.51458L14.0965 4.83949H19.8577C20.259 4.83949 20.625 4.50857 20.625 4.10725C20.625 3.70591 20.2977 3.375 19.8964 3.375H4.11551C3.71431 3.375 3.38348 3.70591 3.38348 4.10725C3.38348 4.50857 3.71431 4.83949 4.11551 4.83949H9.87671C9.87671 4.83949 3.65448 9.61665 3.64392 9.6237C3.43979 9.77513 3.34477 10.0145 3.38348 10.2538V19.8928C3.38348 20.2941 3.71431 20.625 4.11551 20.625H19.8577C20.259 20.625 20.5898 20.2941 20.625 19.9279V10.187C20.6285 9.88777 20.4455 9.63075 20.1885 9.51458ZM17.7531 9.45473H6.19547L11.9884 5.02255L17.7531 9.45473ZM19.1644 19.1957H4.81235V10.884H19.1644V19.1957Z"
        fill="currentColor"
      />
      <path
        d="M8.28969 17.4387C8.69087 17.4387 9.02169 17.1078 9.02169 16.7064V13.3375C9.02169 12.9361 8.69087 12.6052 8.28969 12.6052C7.88844 12.6052 7.55762 12.9361 7.55762 13.3375V16.7064C7.55762 17.1078 7.88844 17.4387 8.28969 17.4387Z"
        fill="currentColor"
      />
      <path
        d="M11.9883 17.4387C12.3896 17.4387 12.7204 17.1078 12.7204 16.7064V13.3375C12.7204 12.9361 12.3896 12.6052 11.9883 12.6052C11.5872 12.6052 11.2563 12.9361 11.2563 13.3375V16.7064C11.2563 17.1078 11.5836 17.4387 11.9883 17.4387Z"
        fill="currentColor"
      />
      <path
        d="M15.6872 17.4387C16.0883 17.4387 16.4192 17.1078 16.4192 16.7064V13.3375C16.4192 12.9361 16.0883 12.6052 15.6872 12.6052C15.2859 12.6052 14.9551 12.9361 14.9551 13.3375V16.7064C14.9551 17.1078 15.2824 17.4387 15.6872 17.4387Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconCrane24
