import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconMetal32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M26.9768 18.8074L24.0451 6.62498C23.9998 6.43694 23.8821 6.27185 23.7347 6.17191L19.3443 3.13129C19.1372 2.98728 18.9277 2.99982 18.9327 3.00058C18.8166 3.00074 18.7005 3.03156 18.6054 3.08097L9.80493 7.65203C9.65701 7.72886 9.52712 7.86765 9.45884 8.03335L5.05865 18.6993C4.9537 18.9535 4.98953 19.2608 5.16678 19.4839L12.5005 28.7277C12.5046 28.7328 12.5093 28.7373 12.5135 28.7424C12.651 28.9069 12.8518 29 13.0673 29C13.0679 29 13.0683 28.9999 13.0689 28.9999H21.8669C22.1458 28.9999 22.4006 28.8406 22.5242 28.5809L26.9244 19.3372C26.9248 19.3364 26.9251 19.3355 26.9255 19.3347C27.0054 19.1647 27.0185 18.9722 26.9768 18.8074ZM19.6667 5.1859L22.0115 6.80972L19.6667 8.43355V5.1859ZM18.2 4.99506V9.60324L14.6009 14.5885L11.1692 8.64698L18.2 4.99506ZM10.242 10.003L13.4339 15.5295L7.05013 17.7401L10.242 10.003ZM12.5483 26.3922L6.97808 19.3712L13.6623 17.0565L12.5483 26.3922ZM21.4114 27.4812H13.8964L15.1591 16.8998L25.2062 19.5091L21.4114 27.4812ZM15.7825 15.4912L19.4447 10.4187L22.8745 8.04335L25.2591 17.9523L15.7825 15.4912Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default IconMetal32
