import React from 'react'

import { IconButton } from '../../../Icon/IconButton'
import { Typography } from '../../../Typography'
import { DrawerHeaderProps } from '../../types'
import { withStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const StyledTypography = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})(Typography)

export const HeaderBack = ({ onBack, title }: DrawerHeaderProps): JSX.Element => (
  <StyledTypography variant="Heading3">
    {onBack && (
      <IconButton onClick={onBack}>
        <ArrowBackIcon fontSize="large" color="primary" />
      </IconButton>
    )}
    {title}
  </StyledTypography>
)
