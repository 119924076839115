import { useEffect, useMemo } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'

import { AllWithNodes, Column, DictionaryInfo } from '../../../hooks/useDictionaryValues'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export type ExcludeColumnsName =
  | 'nodesBinding'
  | 'id'
  | 'operatorSet'
  | 'operatorUpdate'
  | 'setDateTime'
  | 'updateDateTime'
  | 'approveDateTime'
  | 'controls'

type FormColumnType = Exclude<Column['type'], 'Array'>

export const excludeColumns = [
  'nodesBinding',
  'id',
  'operatorSet',
  'operatorUpdate',
  'setDateTime',
  'updateDateTime',
  'approveDateTime',
  'controls',
]

const getYupObject = {
  String: yup.string(),
  Integer: yup.number().typeError('Введите число'),
  Double: yup.number().typeError('Введите число'),
  Date: yup.date(),
}

const defaultYupObject = yup.string()

const getDefaultFormValue = (type: FormColumnType) => {
  const defaultValues = {
    String: '',
    Integer: '',
    Double: '',
    Date: undefined,
  }
  return type in defaultValues ? defaultValues[type] : ''
}

const getValueByType = (type: FormColumnType, value: unknown) => {
  if (type === 'Date') {
    if (value === null) return undefined

    return new Date(String(value))
  }

  if (type === 'Double' || type === 'Integer') {
    return Number(String(value))
  }

  if (type === 'String' && value === null) return ''

  return value
}

export type DictionaryForm = Record<
  Exclude<keyof AllWithNodes[number], ExcludeColumnsName>,
  ReturnType<typeof getDefaultFormValue>
>

export const useDictionaryForm = (
  columns: Column[],
  defaultColumnsValues: AllWithNodes[number] | null,
  dictionaryInfo: DictionaryInfo,
  optionalFields: Array<keyof AllWithNodes[number]>,
): UseFormReturn<DictionaryForm> => {
  const formItems = useMemo(
    () =>
      columns
        .filter(({ name }) => !excludeColumns.includes(name))
        .filter(({ type }) => type !== 'Array')
        .filter(({ main, name }) => {
          if (name === 'value' && !main) {
            return false
          }
          return true
        }),
    [columns],
  )

  const defaultForm = useMemo(
    () =>
      formItems.reduce((acc, curr) => {
        const name = curr.name as keyof typeof defaultColumnsValues
        return {
          ...acc,
          [name]:
            name in (defaultColumnsValues ?? {})
              ? getValueByType(curr.type as FormColumnType, defaultColumnsValues?.[name])
              : getDefaultFormValue(curr.type as FormColumnType),
        }
      }, {} as DictionaryForm),
    [formItems, defaultColumnsValues],
  )

  const yupObject = useMemo(
    () =>
      formItems.reduce((yupObject, formItem) => {
        const yupValue = getYupObject?.[formItem.type as FormColumnType] ?? defaultYupObject
        if (formItem.name === 'code') {
          return {
            ...yupObject,
            code: dictionaryInfo.codeRequired ? yupValue.required('Заполните поле') : yupValue,
          }
        }
        const isRequired =
          !optionalFields.includes(formItem.name as keyof AllWithNodes[number]) &&
          !formItem.optional
        return {
          ...yupObject,
          [formItem.name]: isRequired ? yupValue.required('Заполните поле') : yupValue,
        }
      }, {}),
    [formItems, dictionaryInfo],
  )

  const form = useForm<DictionaryForm>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultForm,
    resolver: yupResolver(yup.object(yupObject)),
  })

  useEffect(() => {
    form.reset(defaultForm)
  }, [defaultForm])

  return form
}
