import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { Typography } from '../../Typography'
import { ContextMenu, ContextMenuItem } from './ContextMenu.context'
import clsx from 'clsx'

import { useContextMenuStyles } from './styles'

export const ContextMenuProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>([])
  const [position, setPosition] = useState<number[]>()
  const [row, setRow] = useState<any>()
  const classes = useContextMenuStyles()
  const setContextMenu = useCallback((items: ContextMenuItem[], position: number[], row: any) => {
    setContextMenuItems(items)
    setPosition(position)
    setRow(row)
  }, [])

  const closeMenu = useCallback(() => {
    setPosition(undefined)
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', closeMenu)

    return () => {
      document.body.removeEventListener('click', closeMenu)
    }
  }, [])

  return (
    <ContextMenu.Provider value={{ setContextMenu }}>
      {!!position && (
        <ul style={{ left: position[0], top: position[1] }} className={classes.contextMenu}>
          {contextMenuItems.map((item) => {
            const disabled = item.disabled && item.disabled(row)
            return (
              <li key={item.title} style={{ boxSizing: 'border-box', minWidth: '100%' }}>
                <button
                  type="button"
                  className={clsx(classes.contextMenuItem, disabled && classes.disabled)}
                  disabled={disabled}
                  onClick={() => item.onClick(row)}
                >
                  {item.icon && <div className={classes.icon}>{item.icon}</div>}
                  <Typography variant="Body1-Medium">{item.title}</Typography>
                </button>
              </li>
            )
          })}
        </ul>
      )}
      {children}
    </ContextMenu.Provider>
  )
}
