import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconFeedback24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M6.98851 8.91574C6.90996 9.05108 6.889 9.21131 6.93016 9.3617C6.97132 9.51209 7.07129 9.64051 7.2084 9.71914L15.4803 14.2809C15.5728 14.3347 15.6785 14.3631 15.7861 14.3631C15.8937 14.3631 15.9994 14.3347 16.092 14.2809L24 9.61332V17.2162C24 17.4241 23.9158 17.6234 23.7658 17.7704C23.6158 17.9174 23.4125 18 23.2004 18H0.5997C0.44065 18 0.288114 17.9381 0.175648 17.8278C0.0631825 17.7176 0 17.5681 0 17.4121C0 17.2562 0.0631825 17.1067 0.175648 16.9965C0.288114 16.8862 0.44065 16.8243 0.5997 16.8243H6.22889V15.3586H1.85907C1.70002 15.3586 1.54748 15.2967 1.43502 15.1864C1.32255 15.0762 1.25937 14.9266 1.25937 14.7707C1.25937 14.6148 1.32255 14.4653 1.43502 14.3551C1.54748 14.2448 1.70002 14.1829 1.85907 14.1829H6.22889V12.6623H3.45827C3.29922 12.6623 3.14668 12.6004 3.03422 12.4901C2.92175 12.3799 2.85857 12.2304 2.85857 12.0745C2.85857 11.9186 2.92175 11.769 3.03422 11.6588C3.14668 11.5485 3.29922 11.4866 3.45827 11.4866H6.22889V6.7838C6.22889 6.57592 6.31313 6.37656 6.46308 6.22957C6.61304 6.08258 6.81642 6 7.02849 6H23.2004C23.4125 6 23.6159 6.08258 23.7658 6.22957C23.9158 6.37656 24 6.57592 24 6.7838V8.05748L15.7601 13.0856L7.8041 8.69236C7.66567 8.6162 7.50209 8.59695 7.34921 8.63882C7.19632 8.6807 7.06661 8.78028 6.98851 8.91574Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconFeedback24
