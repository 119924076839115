import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const ArrowDownSvgIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z" />
    </SvgIcon>
  )
}
