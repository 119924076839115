import React from 'react'

import { getFavoriteItems, getItemsForFavorites, uniqueBy } from '../../helpers'
import { WhiteStarSvgIcon } from '../Icon'

import { INavigationItem } from './types'

export const mutationTopItemsAndNavigationItems = (
  topItems: INavigationItem[],
  navigationItems: INavigationItem[],
): void => {
  const favorites = getFavoriteItems()

  if (favorites.userFavorites && topItems.length) {
    const favItems: INavigationItem[] = favorites.userFavorites.favorites
      .filter((favoritesItem) => favoritesItem)
      .map((favoritesHrefOrId) => getItemsForFavorites(navigationItems, favoritesHrefOrId))
      .reduce((items, newItems) => items.concat(newItems), [])
      .filter((item, index, array) => array.indexOf(item) === index)

    const uniqueFavoriteItems = uniqueBy(favItems, 'id')

    const favMenuItem = {
      id: 'favorites',
      title: 'Избранное',
      icon: <WhiteStarSvgIcon />,
      items: uniqueFavoriteItems,
    }

    if (topItems[0].title !== 'Избранное') {
      topItems.unshift(favMenuItem)
      navigationItems.unshift(favMenuItem)
    } else {
      navigationItems[0].items = uniqueFavoriteItems
    }
  }

  if (topItems.length && favorites.userFavorites?.favorites.length === 0) {
    if (topItems[0].title === 'Избранное') {
      topItems.shift()
    }
    if (navigationItems.length && navigationItems[0]?.title === 'Избранное') {
      navigationItems.shift()
    }
  }

  // Если после всех телодвижений раздел избранного пуст, то удаляем раздел избранного
  if (topItems.length && topItems[0].title === 'Избранное' && !topItems[0].items?.length) {
    topItems.shift()
  }
}
