import React, { ReactElement, useContext } from 'react'

import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'

// any использован в силу необходимости соответствовать свойству bodyComponent в Table из @devexpress/dx-react-grid-material-ui
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableBodyProps = any & StyledProps

export function TableBody(props: TableBodyProps): ReactElement<TableBodyProps> {
  const [className, style] = useStyleProps(props, useContext(CustomFieldsCtx).style?.body)
  return <DevexpressTable.TableBody {...props} className={className} style={style} />
}
