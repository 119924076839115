import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { arrayMove } from 'react-sortable-hoc'

import { Typography } from '../../../'
import { Drawer } from '../../../Drawer/Drawer'
import { GearSvgIcon } from '../../../Icon/icons_old'
import { InputDebounce } from '../../../InputDebounce/InputDebounce'
import { SortableItems } from '../../../SortableItems/SortableItems'
import { TableColumn } from '../../../Table/types'
import HintInit from '../../common/blocks/HintInit'
import { HintView } from '../../common/constants/hint'
import { TableToolbarProps } from '../../types'
import {
  checkToExcludeColumnNames,
  generateDefaultColumnsTitles,
  getNextIndex,
  overrideColumnsTitles,
  propertyToggleHandler,
} from '../../utils'
import { getInitialViews, insertAndMergeArrays } from './utils'
import { Grid } from '@material-ui/core'
import { isEqual } from 'lodash-es'

import ViewItem from './components/ViewItem'

import { TableToolbarButton } from '../../styles'

export type ViewTableProps<T extends Record<string, unknown>> = Pick<
  TableToolbarProps<T>,
  'manager' | 'columns' | 'overrideTitles' | 'excludeColumnNames'
>

export function ViewTable<T extends Record<string, unknown>>({
  manager,
  columns,
  overrideTitles,
  excludeColumnNames,
}: ViewTableProps<T>): JSX.Element {
  const [visible, setVisible] = useState(false)
  const [initHint, setInitHint] = useState(true)
  const dispatch = useDispatch()
  const [innerView, setInnerView] = useState<TableColumn<T>[]>([])

  const mappedColumns = useMemo(() => checkToExcludeColumnNames(columns, excludeColumnNames), [
    excludeColumnNames,
    columns,
  ])
  const initialValues = getInitialViews<T>(mappedColumns)
  const hasDisabled = isEqual(initialValues, innerView)

  function onClose() {
    setVisible(false)
    setInnerView(overrideColumnsTitles(mappedColumns, overrideTitles, true))
  }

  useEffect(() => {
    setInnerView(overrideColumnsTitles(mappedColumns, overrideTitles, true))
  }, [overrideTitles, mappedColumns])

  function onSave() {
    const copy = [...columns]
    const overrideColumns = overrideColumnsTitles(
      innerView,
      generateDefaultColumnsTitles(mappedColumns),
      true,
    )

    const merged = insertAndMergeArrays(copy, overrideColumns, 'name')

    dispatch(manager.changeViewTable(merged))
    setVisible(false)
  }

  return (
    <>
      <TableToolbarButton
        variant="text"
        onClick={() => setVisible(true)}
        startIcon={<GearSvgIcon color="primary" />}
      >
        <Typography variant="Body1-Bold">Отображение</Typography>
      </TableToolbarButton>
      <Drawer
        header={{
          title: 'Отображение таблицы',
          tooltip: <HintView />,
          visibleTooltipIcon: !initHint,
        }}
        footer={{
          onSubmit: {
            handler: onSave,
            label: 'Применить',
            disabled: hasDisabled,
          },
          onCancel: {
            handler: onClose,
            label: 'Отмена',
          },
        }}
        open={visible}
        onClose={onClose}
      >
        <Grid container spacing={1}>
          {initHint && (
            <HintInit onClose={() => setInitHint(false)}>
              <HintView />
            </HintInit>
          )}
          <Grid item xs={12}>
            <InputDebounce
              fullWidth
              onChange={(value) => {
                const newViews = innerView.filter((e) =>
                  (e.title || '').toLocaleLowerCase().includes(value.toLocaleLowerCase()),
                )
                setInnerView(value ? newViews : initialValues)
              }}
              label="Найти"
            />
          </Grid>
          <Grid item xs={12}>
            <SortableItems
              onItemsChange={setInnerView}
              useDragHandle
              onSortEnd={({ oldIndex, newIndex }) => {
                const newArray = arrayMove(innerView, oldIndex, newIndex).sort(
                  (a, b) => Number(b.pinned) - Number(a.pinned),
                )
                setInnerView(newArray)
              }}
              onRenderItem={(item, id) => (
                <ViewItem
                  item={item}
                  onPinnedToggle={() => {
                    const copy = propertyToggleHandler(innerView, id, 'pinned').sort(
                      (a, b) => Number(b.pinned) - Number(a.pinned),
                    )
                    setInnerView(copy)
                  }}
                  onVisibleToggle={() => {
                    const copy = propertyToggleHandler(innerView, id, 'visible')
                    const newId = getNextIndex(innerView, id, id, 'pinned')
                    setInnerView(arrayMove(copy, id, newId))
                  }}
                />
              )}
              items={innerView}
            />
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}
