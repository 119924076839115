import React, { PropsWithChildren, useContext } from 'react'

import { Drawer } from '../../../../Drawer'
import { FilterContext } from '../context'
import { ICreateFilter } from '../form'
import { FilterItem, IFilterContext } from '../typings'
import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'

export type DrawerWrapperProps = PropsWithChildren<{
  values?: FilterItem
}>

function DrawerWrapper(props: DrawerWrapperProps) {
  const { isValid, dirty, handleSubmit } = useFormikContext<ICreateFilter>()
  const { visible, onClose, setTab } = useContext<IFilterContext>(FilterContext)

  return (
    <Drawer
      header={{
        title: 'Добавление фильтра',
        onBack: () => setTab({ tab: 0 }),
      }}
      footer={{
        onSubmit: {
          handler: handleSubmit,
          label: props.values ? 'Сохранить' : 'Добавить фильтр',
          disabled: !isValid || !dirty,
        },
        onCancel: { handler: () => setTab({ tab: 0 }), label: 'Отмена' },
      }}
      open={visible}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        {props.children}
      </Grid>
    </Drawer>
  )
}

export default DrawerWrapper
