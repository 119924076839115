import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const Icon3Bunker32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 6C7.17765 6 0 13.0655 0 21.75V26.475C0 26.7648 0.238376 27 0.533315 27H10.1334C10.4283 27 10.6667 26.7648 10.6667 26.475V21.75C10.6667 18.8552 13.0592 16.5 16.0001 16.5C18.9409 16.5 21.3334 18.8552 21.3334 21.75V24.375C21.3334 24.6648 21.5718 24.9 21.8667 24.9C22.1616 24.9 22.4001 24.6648 22.4001 24.375V21.75C22.4001 18.2761 19.5291 15.45 16.0001 15.45C12.471 15.45 9.60004 18.2761 9.60004 21.75V25.95H1.06669V21.75C1.06669 13.6445 7.76534 7.05002 16.0001 7.05002C24.2348 7.05002 30.9334 13.6445 30.9334 21.75V25.95H20.2668V21.75C20.2668 19.4342 18.3526 17.55 16.0001 17.55C13.6475 17.55 11.7334 19.4342 11.7334 21.75V26.475C11.7334 26.7648 11.9717 27 12.2667 27C12.5616 27 12.8 26.7648 12.8 26.475V21.75C12.8 20.0128 14.2352 18.6 16 18.6C17.7648 18.6 19.2 20.0128 19.2 21.75V26.475C19.2 26.7648 19.4384 27 19.7333 27H31.4667C31.7616 27 32 26.7648 32 26.475V21.75C32.0001 13.0655 24.8225 6 16.0001 6ZM3.19995 19.65C3.19995 20.8082 4.15677 21.75 5.33327 21.75C6.50984 21.75 7.46659 20.8082 7.46659 19.65C7.46659 18.4919 6.50978 17.5501 5.33327 17.5501C4.15677 17.5501 3.19995 18.4919 3.19995 19.65ZM6.39997 19.65C6.39997 20.2291 5.92103 20.7001 5.33327 20.7001C4.74502 20.7001 4.26658 20.2292 4.26658 19.65C4.26658 19.0709 4.74496 18.6 5.33327 18.6C5.92103 18.6 6.39997 19.071 6.39997 19.65ZM26.6667 17.5501C27.8432 17.5501 28.8 18.4919 28.8 19.65C28.8 20.8082 27.8432 21.75 26.6667 21.75C25.4902 21.75 24.5334 20.8082 24.5334 19.65C24.5334 18.4919 25.4902 17.5501 26.6667 17.5501ZM26.6667 18.6C26.0784 18.6 25.6 19.071 25.6 19.65C25.6 20.2291 26.0784 20.7001 26.6667 20.7001C27.2544 20.7001 27.7334 20.2292 27.7334 19.65C27.7334 19.0709 27.2544 18.6 26.6667 18.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon3Bunker32
