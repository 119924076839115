import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CustomCheckBoxIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <rect x="3.2" y="3.1" rx="4" ry="4" width="17.5" height="17.5" fill={props.htmlColor} />
      <path
        d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
