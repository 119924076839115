import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardYellowMediumTag = React.forwardRef<HTMLDivElement, CardProps>(
  ({ highlighted, barcode }, ref) => {
    const referenceData: ReferenceDataType[] = [
      {
        title: '',
        data: {
          left: [
            { name: '', title: 'УОП' },
            { name: 'end_diameter', title: 'Диаметр, мм' },
            { name: 'steel_grade', title: 'Марка' },
            { name: 'aggregate', title: '№ cтана' },
          ],
          mid: [
            { name: '', title: '№ катушки' },
            { name: '', title: 'Масса, тн' },
            { name: 'date', title: 'Дата' },
            { name: '', title: '№ плавки' },
            { name: '', title: 'Оператор' },
          ],
          right: [
            { name: '', title: 'Тип смазки' },
            { name: 'brigade', title: 'Бригада' },
            { name: '', title: 'ОТК' },
          ],
        },
      },
    ]

    const renderBlock = (data?: Block[]) => {
      if (!data) return null
      return data
        .map(({ name, title }) => {
          const newDescription: string =
            // @ts-ignore
            highlighted[0][name] !== undefined ? highlighted[0][name] : '_____'
          return { title, description: newDescription }
        })
        .map(({ title, description }) => (
          <Box style={{ paddingBottom: '0px' }} justifyContent="space-between" key={title}>
            <Typography style={{ maxWidth: '180px' }} variant="Heading3">
              {title}
            </Typography>
            <Typography variant="Heading3">{description}</Typography>
          </Box>
        ))
    }

    return (
      <div className="print-block print-block_yellow-medium-tag" ref={ref}>
        <Box
          flexDirection="column"
          alignItems="start"
          style={{
            position: 'relative',
            maxWidth: '18.4cm',
          }}
        >
          {referenceData.map(({ title, data }, index) => (
            <div
              style={{
                padding: '3px 10px 10px 10px',
                position: 'relative',
                marginLeft: '10px',
                marginRight: '10px',
              }}
              key={`title${index}`}
            >
              <Typography variant="Heading3">{title}</Typography>
              <Box justifyContent="space-between">
                {Object.values(data).map((value, i) => (
                  <Box
                    style={{
                      width: '210px',
                      maxHeight: '180px',
                      paddingRight: '2px',
                      marginRight: '2px',
                      borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                      lineHeight: '600',
                      fontSize: '16px',
                    }}
                    flexDirection="column"
                    key={i}
                  >
                    <div
                      style={{
                        lineHeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      {renderBlock(value)}
                    </div>
                  </Box>
                ))}
              </Box>
            </div>
          ))}

          <div className={styles.barcodeContainer}>
            <Barcode height={45} width={3} fontSize={22} value={barcode} />
          </div>
          <div className={styles.qrCodeContainer}>
            <QRCode size={100} value={String(barcode)} />
          </div>
        </Box>
      </div>
    )
  },
)

export default CardYellowMediumTag
