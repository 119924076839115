import { ReportProps } from 'interfaces/Reports'

export const MOCK_REPORT_DATA: ReportProps = {
  unit_condition: {
    operation_unit: {
      list_type_alias: ['pipe'],
    },
    operation_node: {
      list_node_parent_alias: ['warehouse.inplast.virtual'],
    },
    operation_date_time: {
      start_date_time: {
        date_time_predicate: 'MORE',
        list_value: ['01.05.2023 00:00:00'],
      },
      finish_date_time: {
        date_time_predicate: 'NULL',
      },
      combine: 'AND',
    },
    list_filler_attr: ['START_STORE_TIME', 'FINISH_STORE_TIME', 'ZONE_ID', 'ZONE_NAME'],
    page: {
      page: 0,
      size: 15,
      sort: 'START_DATE_TIME',
      direction: 'ASC',
    },
  },
  list_column_position: [
    {
      position: 1,
      alias: 'barcode',
    },
    {
      position: 2,
      alias: 'external_diameter',
    },
    {
      position: 3,
      alias: 'inner_diameter',
    },
    {
      position: 4,
      alias: 'start_store_time',
    },
    {
      position: 5,
      alias: 'finish_store_time',
    },
    {
      position: 6,
      alias: 'zone_id',
    },
    {
      position: 7,
      alias: 'zone_name',
    },
  ],
}
