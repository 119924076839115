import React, { FC } from 'react'

import { IconButtonProps, IconButton as MUIIconButton } from '@material-ui/core'

import { useIconButtonStyles } from './styles'

export type IIconButtonProps = IconButtonProps & {
  variant?: 'primary' | 'secondary' | 'gray' | 'outline' | 'text'
}

export const IconButton: FC<IIconButtonProps> = ({ ...props }) => {
  const classes = useIconButtonStyles(props)

  return (
    <MUIIconButton
      classes={{
        root: classes.root,
        sizeSmall: classes.sizeSmall,
        disabled: classes.disabled,
      }}
      {...props}
    />
  )
}
