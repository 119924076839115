import React from 'react'

import { Tabs as MUITabs } from '@material-ui/core'

import { ITabsProps } from './types'

import { useTabsStyles } from './styles'

export const Tabs: React.FC<ITabsProps> = (props) => {
  const classes = useTabsStyles(props)
  return (
    <MUITabs
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
        indicator: classes.indicator,
      }}
      {...props}
    />
  )
}
