import React, { useMemo } from 'react'

import { StyledProps } from '../utils/StyledProps'
import { TableRow } from './TableRow'
import { Table as DevexpressTable, TableSelection } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'

type TableSelectionRowProps = DevexpressTable.RowProps & TableSelection.RowProps & StyledProps

export const TableSelectionRow: React.FC<TableSelectionRowProps> = (props) => {
  const { highlighted } = props
  const propsClassName = props.className
  const className = useMemo(() => clsx(propsClassName, { ['Row__highlighted']: highlighted }), [
    highlighted,
    propsClassName,
  ])
  return (
    <TableRow
      onClick={() => props.selectByRowClick && props.onToggle()}
      row={props.tableRow.row}
      {...props}
      className={className}
      highlighted={highlighted}
    />
  )
}
