import { makeStyles } from '@material-ui/core'
import { Theme } from '@storybook/theming'

export const useTimeSelectorStyles = makeStyles<Theme, { isTimeWithSecondsType: boolean }>(
  (theme) => ({
    root: ({ isTimeWithSecondsType }) => ({
      display: 'grid',
      width: isTimeWithSecondsType ? '120px' : '80px',
      height: '308px',
      backgroundColor: theme.neutral.white,
      gridTemplateColumns: 'repeat(3, 36px)',
      gap: '4px',
      borderLeft: `1px solid ${theme.neutral.grey100}`,
      boxShadow: '0px 2px 8px rgb(0 0 0 / 25%)',
      padding: '20px 20px 20px 16px',
      marginTop: '8px',
      overflow: 'hidden',
      scrollBehavior: 'smooth',
      position: 'relative',
      zIndex: 100000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: 4,
    }),
    column: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '4px',
      overflow: 'auto',
      scrollBehavior: 'smooth',
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
    },
    columnTitle: {
      position: 'sticky',
      top: '0px',
      color: theme.palette.text.disabled,
      fontWeight: theme.textStyles.body1medium.fontWeight,
      fontSize: theme.textStyles.body1medium.fontSize,
      lineHeight: theme.textStyles.body1medium.lineHeight,
      background: theme.palette.background.paper,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '36px',
      height: '36px',
    },
  }),
)
