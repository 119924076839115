import React, { useContext } from 'react'

import DrawerWrapper from '../components/DrawerWrapper'
import FilterLabel from '../components/FilterLabel'
import FilterMethod from '../components/FilterMethod'
import FilterValues from '../components/FilterValues'
import { FilterContext } from '../context'
import { ICreateFilter, initialValues, validationFiltersSchema } from '../form'
import { FilterItem, IFilterContext } from '../typings'
import { Form, Formik } from 'formik'

function CreateFilterTab() {
  const { filter, setFilter, setTab, editFilter } = useContext<IFilterContext>(FilterContext)
  const existingValues = editFilter
    ? {
        ...editFilter,
        label: { label: editFilter.label, value: editFilter.label },
        method: { label: editFilter.method, value: editFilter.method },
      }
    : {}

  const onSubmit = ({ label, method, value, secondValue, values }: ICreateFilter) => {
    const newFilter = [...filter]
    const addingFilter: FilterItem = {
      label: label.value,
      method: method.value,
      value,
      secondValue,
      values,
    }
    if (editFilter) {
      const index = newFilter.findIndex((e) => e.label === editFilter.label)
      newFilter[index] = addingFilter
      setFilter(newFilter)
    } else {
      setFilter([...newFilter, addingFilter])
    }
    setTab({ tab: 0 })
  }

  return (
    <Formik<ICreateFilter>
      initialValues={{
        ...initialValues,
        ...existingValues,
      }}
      validationSchema={validationFiltersSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      <Form id="filter-form">
        <DrawerWrapper>
          <FilterLabel />
          <FilterMethod />
          <FilterValues />
        </DrawerWrapper>
      </Form>
    </Formik>
  )
}

export default CreateFilterTab
