import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconExit24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M15.375 3.18823C17.3425 3.18823 18.9375 4.78322 18.9375 6.75073V17.2507C18.9375 19.2182 17.3425 20.8132 15.375 20.8132H9.375C7.40748 20.8132 5.8125 19.2182 5.8125 17.2507V15.6674C5.8125 15.3567 6.06434 15.1049 6.375 15.1049C6.68566 15.1049 6.9375 15.3567 6.9375 15.6674V17.2507C6.9375 18.5969 8.02881 19.6882 9.375 19.6882H15.375C16.7212 19.6882 17.8125 18.5969 17.8125 17.2507V6.75073C17.8125 5.40454 16.7212 4.31323 15.375 4.31323H9.375C8.02881 4.31323 6.9375 5.40454 6.9375 6.75073V8.33407C6.9375 8.64473 6.68566 8.89657 6.375 8.89657C6.06434 8.89657 5.8125 8.64473 5.8125 8.33407V6.75073C5.8125 4.78322 7.40749 3.18823 9.375 3.18823H15.375Z"
        fill="currentColor"
      />
      <path
        d="M11.2727 8.60298C11.0531 8.38332 10.6969 8.38332 10.4773 8.60298C10.2576 8.82265 10.2576 9.17881 10.4773 9.39848L12.517 11.4382H5.625C5.31434 11.4382 5.0625 11.6901 5.0625 12.0007C5.0625 12.3114 5.31434 12.5632 5.625 12.5632H12.517L10.4773 14.603C10.2576 14.8227 10.2576 15.1788 10.4773 15.3985C10.6969 15.6181 11.0531 15.6181 11.2727 15.3985L14.2727 12.3985C14.4924 12.1788 14.4924 11.8227 14.2727 11.603L11.2727 8.60298Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconExit24
