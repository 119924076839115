const getLocalDate = (date: string | undefined): string => {
  if (!date) return ''

  const dateWithoutGMT = Date.parse(date.split('.')[0])
  const newDate = new Date(dateWithoutGMT)

  return newDate.toLocaleString('ru-RU').toString()
}

export default getLocalDate
