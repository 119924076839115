import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const ClosedEyeSvgIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69 6.5248L2.01 3.8448L3.42 2.4248L21.15 20.1648L19.74 21.5748L16.32 18.1548C14.98 18.6848 13.52 18.9748 12 18.9748C7 18.9748 2.73 15.8648 1 11.4748C1.77 9.5048 3.06 7.8048 4.69 6.5248ZM12 5.9748C15.79 5.9748 19.17 8.1048 20.82 11.4748C20.23 12.6948 19.4 13.7448 18.41 14.5948L19.82 16.0048C21.21 14.7748 22.31 13.2348 23 11.4748C21.27 7.0848 17 3.9748 12 3.9748C10.73 3.9748 9.51 4.1748 8.36 4.5448L10.01 6.1948C10.66 6.0648 11.32 5.9748 12 5.9748ZM10.93 7.1148L13 9.1848C13.57 9.4348 14.03 9.8948 14.28 10.4648L16.35 12.5348C16.43 12.1948 16.49 11.8348 16.49 11.4648C16.5 8.9848 14.48 6.9748 12 6.9748C11.63 6.9748 11.28 7.02481 10.93 7.1148ZM9.51 11.3448L12.12 13.9548C12.08 13.9648 12.04 13.9748 12 13.9748C10.62 13.9748 9.5 12.8548 9.5 11.4748C9.5 11.4498 9.5025 11.4298 9.505 11.4098L9.505 11.4098L9.505 11.4098C9.5075 11.3898 9.51 11.3698 9.51 11.3448ZM7.86 9.6948L6.11 7.9448C4.9 8.8648 3.88 10.0448 3.18 11.4748C4.83 14.8448 8.21 16.9748 12 16.9748C12.95 16.9748 13.87 16.8348 14.75 16.5948L13.77 15.6148C13.23 15.8448 12.63 15.9748 12 15.9748C9.52 15.9748 7.5 13.9548 7.5 11.4748C7.5 10.8448 7.63 10.2448 7.86 9.6948Z"
      />
    </SvgIcon>
  )
}
