import React, { FC, useEffect, useMemo } from 'react'
import { useState } from 'react'

import { CardDataInterfaceAttributes2 } from '../../interfaces/TaskMills'
import { PERMISSION } from '../../shared/enums/Permission'
import TabPanel from '../../ui-kit/frontend-components/TabPanel'
import { usePermission } from '../../ui-kit/hooks/usePermission'
import useToggle from '../../ui-kit/hooks/useToggle'
import { usePrintCardsStyles } from './PrintCards.styles'
import { makeStyles } from '@material-ui/core'
import service from 'services/service'
import { Box } from 'ui-kit/components/Box'
import UserContent from 'ui-kit/frontend-components/UserContent'

import ControlButtons from './components/ControlButtons'
import NewPrint from './components/Print'

export const printStyles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

var totalPages = 1

const PrintCards: FC = () => {
  const styles = printStyles()
  const { can } = usePermission()
  const [isAnswerGet, setIsAnswerGet] = useState(false)
  const [isOpen, setIsOpen] = useToggle(false)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState<CardDataInterfaceAttributes2[]>([
    { barcode: '71445074', date: '22.05.2023', color: 'white_tag', idCard: 71445074 },
    { barcode: '71445074', date: '22.05.2023', color: 'white_tag' },
  ])
  const [checked, setChecked] = useState<CardDataInterfaceAttributes2[]>([])
  const permissions = useMemo(
    () => ({
      request:
        can(PERMISSION.MASTER_UGV__REQUEST__READ) || can(PERMISSION.MASTER_UOP__REQUEST__READ),
      sample: can(PERMISSION.MASTER_UGV__SAMPLE__READ) || can(PERMISSION.MASTER_UOP__SAMPLE__READ),
    }),
    [can],
  )

  function getEmptyBarcodes(page) {
    //TODO проверить работу
    service.getAllEmptyBarcodes(page).then((value) => {
      rows.splice(0, rows.length)
      value.content.forEach((barcode) => {
        rows.push({
          barcode: barcode.attributes.barcode,
          date: barcode.attributes.date_create_barcode,
          color: 'white_tag',
          idCard: Number(barcode.attributes.barcode),
        })
      })
      totalPages = value.totalPages
      setIsAnswerGet(true)
    })
  }

  useEffect(() => {
    getEmptyBarcodes(page)
  }, [page])

  useEffect(() => {
    setIsAnswerGet(false)
  }, [isAnswerGet])

  return (
    <UserContent title="Печать">
      <Box>
        <ControlButtons
          title=""
          rows={rows}
          currentRegion="wire_nailing_machine"
          subSection="ugv"
          page={page}
          setPage={setPage}
        />
      </Box>

      <Box>
        <NewPrint
          rows={rows}
          columns={[
            { name: 'barcode', title: 'Штрих-код' },
            { name: 'color', title: 'Цвет', width: '230px' },
            { name: 'date', title: 'Дата' },
            { name: 'controls', title: ' ', width: '1px' },
          ]}
          currentRegion="ugv"
          checked={checked}
          setChecked={setChecked}
          currentPage={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </Box>
    </UserContent>

    // <Box className={styles.flexGrow} spacing={2}>
    //   {/* <NewTable
    //     rows={rows}
    //   /> */}

    // </Box>
  )
}

export default PrintCards
