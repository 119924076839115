import { useEffect, useMemo, useState } from 'react'

import useAsync from '../../../../../ui-kit/hooks/useAsync'
import { ResponseAllNodes } from 'interfaces/responses/Dictionary'
import { Request } from 'interfaces/responses/Request'
// import { useAlert } from 'react-alert';
import service from 'services/service'

export type AllNodes = ResponseAllNodes['content']

type UseDictionaryNodesReturn = {
  dictionariesNodes: AllNodes
  setDictionariesNodes: (value: AllNodes) => void
  request: Request<ResponseAllNodes>
}

export const useDictionaryNodes = (shop?: string): UseDictionaryNodesReturn => {
  // const alert = useAlert();

  const [dictionariesNodes, setDictionariesNodes] = useState<AllNodes>([])

  const requestHandle = useMemo(() => {
    if (shop) {
      return () => service.getNodesByShop(shop)
    }

    return service.getAllAddNodes
  }, [shop])

  const request = useAsync(requestHandle)

  useEffect(() => {
    if (request.value) {
      setDictionariesNodes(request.value.content)
    }
  }, [request.value])

  useEffect(() => {
    if (request.error) {
      // alert.error(request.error);
    }
  }, [request.error])

  return {
    dictionariesNodes,
    setDictionariesNodes,
    request,
  }
}
