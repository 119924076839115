import React from 'react'

import { CardNailsProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'

const fieldPackaging = [
  {
    name: 'Нетто:',
    code: '',
  },
  {
    name: 'Плановый вес паллет, кг:',
    code: 'one_weight',
  },
  {
    name: 'Кол-во коробок в шт.:',
    code: 'multiple',
  },
  {
    name: 'Плановый вес коробки, кг:',
    code: 'packaging_weight',
  },
  {
    name: 'Сортамент:',
    code: 'nail_standard',
  },
  {
    name: 'Упаковщик:',
    code: '',
  },
  {
    name: 'Дата:',
    code: '',
  },
]

const CardPackaging = React.forwardRef<HTMLDivElement, CardNailsProps>(
  ({ highlighted, barcode }, ref) => {
    return (
      <div className="print-block print-block_packaging " ref={ref}>
        <Box flexDirection="column" spacing={2}>
          <Box spacing={9} alignItems="center">
            <Typography variant="Subheading2-Medium">ГВОЗДИ ГОСТ 4028-63</Typography>
            <Barcode value={barcode} />
          </Box>
          {fieldPackaging.map((item) => (
            <Box key={item.name}>
              <Typography variant="Subheading3-Medium">{item.name}</Typography>
              <Typography style={{ marginLeft: '10px' }} variant="Subheading3">
                {
                  // @ts-ignore
                  highlighted[item.code]
                }
              </Typography>
            </Box>
          ))}
        </Box>
      </div>
    )
  },
)

CardPackaging.displayName = 'CardPackaging'

export default CardPackaging
