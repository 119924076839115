import React, { CSSProperties, PropsWithChildren, useContext } from 'react'

import { Box } from '../../Box'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { Table as TableBase } from '@devexpress/dx-react-grid'
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  cell: {
    border: 'inherit !important',
  },
  content: {
    height: 55,
    borderBottom: '1px solid rgba(0, 23, 41, 0.12)',
  },
}))

type TTableBaseCellProps = TableBase.CellProps &
  PropsWithChildren & {
    align?: CSSProperties['alignItems']
    justifyContent?: CSSProperties['justifyContent']
  }
type TTableBandCell = (
  align?: CSSProperties['alignItems'],
  justifyContent?: CSSProperties['justifyContent'],
) => React.FC<TTableBaseCellProps>

const getTableBandCell: TTableBandCell = (
  alignProps = 'center',
  justifyContentProps = 'flex-end',
) => {
  const tableComponent: React.FC<TTableBaseCellProps> = ({
    children,
    align = alignProps,
    justifyContent = justifyContentProps,
    ...restProps
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const context = useContext(CustomFieldsCtx)
    return (
      <TableBandHeader.Cell className={classes.cell} align={align} {...restProps}>
        <Box
          paddingX="12px"
          alignItems={align}
          justifyContent={justifyContent}
          className={classes.content}
          style={{
            height: context.narrowRows ? '40px' : '',
          }}
        >
          {children}
        </Box>
      </TableBandHeader.Cell>
    )
  }
  return tableComponent
}

export default getTableBandCell
