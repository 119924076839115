import React from 'react'

import { FixedTextField, formatNumberString } from '../../FixedTextField'
import { useField } from 'formik'

import { FormTextFieldProps } from './types'

export const FormTextField: React.FC<FormTextFieldProps> = ({ hideHelperText, ...props }) => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(props.name)

  return (
    <FixedTextField
      {...props}
      fullWidth
      size={props.size || 'small'}
      variant={props.variant || 'outlined'}
      name={props.name}
      label={props.label}
      value={value}
      onBlur={onBlur}
      type={props.type}
      disabled={props.disabled}
      onChange={(e) => {
        let valueToSet = e.target.value
        if (props.type === 'number') {
          valueToSet = formatNumberString(valueToSet)
        }
        setValue(valueToSet)
      }}
      error={touched && Boolean(error)}
      helperText={!hideHelperText && touched && error}
      data-testid={props.name}
    />
  )
}
