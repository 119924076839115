type Item = { label: string; value: string }

export interface ITypeMethod {
  [k: string]: Array<Item>
}

export const withoutValueRegex = /(empty|full|boolean|custom)/

const getWithoutMethods = (type: string) => [
  { label: 'ячейка пуста', value: `${type}-empty` },
  { label: 'ячейка заполнена', value: `${type}-full` },
]

const dateMethods = [
  { label: 'равно', value: 'date-equally' },
  { label: 'не равно', value: 'date-notEqually' },
  { label: 'больше', value: 'date-more' },
  { label: 'меньше', value: 'date-less' },
  { label: 'от и до', value: 'date-between' },
  { label: 'больше или равно', value: 'date-moreEqually' },
  { label: 'меньше или равно', value: 'date-lessEqually' },
  ...getWithoutMethods('date'),
]

const hashTypeMethod: ITypeMethod = {
  boolean: [
    { label: 'Да', value: 'boolean-true' },
    { label: 'Нет', value: 'boolean-false' },
  ],
  numeric: [
    { label: 'равно', value: 'numeric-equally' },
    { label: 'не равно', value: 'numeric-notEqually' },
    { label: 'больше', value: 'numeric-more' },
    { label: 'меньше', value: 'numeric-less' },
    { label: 'от и до', value: 'numeric-between' },
    { label: 'больше или равно', value: 'numeric-moreEqually' },
    { label: 'меньше или равно', value: 'numeric-lessEqually' },
    ...getWithoutMethods('numeric'),
  ],
  string: [
    { label: 'совпадает c', value: 'string-equally' },
    { label: 'не совпадает c', value: 'string-notEqually' },
    { label: 'содержит', value: 'string-have' },
    { label: 'не содержит', value: 'string-notHave' },
    { label: 'начинается с', value: 'string-startedAt' },
    { label: 'заканчивается на', value: 'string-expiredAt' },
    ...getWithoutMethods('string'),
  ],
  date: [...dateMethods],
  datetime: [...dateMethods],
}

const hashNumericLabel: { [k: string]: (v1?: number, v2?: number) => string } = {
  'numeric-equally': (v1) => `= ${v1}`,
  'numeric-notEqually': (v1) => `≠ ${v1}`,
  'numeric-more': (v1) => `> ${v1}`,
  'numeric-less': (v1) => `< ${v1}`,
  'numeric-moreEqually': (v1) => `≥ ${v1}`,
  'numeric-lessEqually': (v1) => `≤ ${v1}`,
  'numeric-between': (v1, v2) => `от ${v1} до ${v2}`,
  'numeric-empty': () => 'ячейка пуста',
  'numeric-full': () => 'ячейка заполнена',
}

const initialLabel: Item = {
  label: '',
  value: '',
}
const initialMethod = { value: '', label: '' }

export { hashTypeMethod, hashNumericLabel, initialLabel, initialMethod }
