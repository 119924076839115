import React, { ReactElement, useContext } from 'react'

import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Grid } from '@devexpress/dx-react-grid-material-ui'

type TableRootProps = Grid.RootProps & StyledProps

export function TableRoot(props: TableRootProps): ReactElement<TableRootProps> {
  const [className, style] = useStyleProps(props, useContext(CustomFieldsCtx).style?.root)
  return <Grid.Root {...props} className={className} style={style} />
}
