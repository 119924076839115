import React from 'react'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 12px',
    borderRadius: '4px',
    backgroundColor: '#E8F5FF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '24px',
  },
}))

export interface HintInitProps {
  onClose: () => void
  children: React.ReactNode
}

function HintInit({ onClose, children }: HintInitProps) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {children}
      <IconButton onClick={onClose}>
        <CloseIcon style={{ fontSize: '21px' }} />
      </IconButton>
    </Box>
  )
}

export default HintInit
