import React from 'react'

import { Button } from '../Button'
import { HeaderBack } from './subcomponents/BackButton'
import { HeaderHint } from './subcomponents/HeaderHint'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import BaseDrawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { DrawerProps } from './types'

import { useStyles } from './styles'

export const Drawer: React.FC<DrawerProps> = (props) => {
  const classes = useStyles()

  return (
    <BaseDrawer
      {...props}
      anchor="right"
      classes={{ paper: classes.drawer }}
      PaperProps={{ classes: { root: classes.paper } }}
      BackdropProps={{ className: classes.backdropRoot }}
    >
      <DialogTitle>
        <IconButton
          className={classes.closeBtn}
          aria-label="close"
          onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        {props.header &&
          (props.header.onBack ? (
            <HeaderBack {...props.header} />
          ) : (
            <HeaderHint {...props.header} />
          ))}
      </DialogTitle>
      <DialogContent className={classes.content}>{props.children}</DialogContent>
      <DialogActions classes={{ root: classes.footer }}>
        <Box className={classes.footerContainer}>
          {props.footer &&
            (props.footer.render || (
              <Grid container spacing={1}>
                <Grid item>
                  {props.footer.onSubmit && (
                    <Button
                      disabled={props.footer.onSubmit.disabled || false}
                      onClick={props.footer.onSubmit.handler}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      {props.footer.onSubmit.label}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {props.footer.onCancel && (
                    <Button
                      onClick={props.footer.onCancel.handler}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      {props.footer.onCancel.label}
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
        </Box>
      </DialogActions>
    </BaseDrawer>
  )
}
