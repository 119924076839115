import { makeStyles } from '@material-ui/core'
import { Theme } from '@storybook/theming'

import { InputProps } from './types'

export const useInputStyles = makeStyles<
  Theme,
  InputProps & { heightBool: boolean; rightIconBool: boolean }
>((theme) => ({
  input: {
    padding: '8px 16px',
    borderRadius: 4,
    height: 40,
    boxSizing: 'border-box',
    zIndex: 10,
    '&:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
  },
  notchedOutline: {
    borderColor: theme.neutral.grey200,
    minInlineSize: 'unset',
    '$disabled &': {
      borderColor: `${theme.neutral.grey200} !important`,
      backgroundColor: theme.neutral.grey50,
      color: theme.palette.text.disabled,
    },
    '$error &': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '$colored &': {
      backgroundColor: `${theme.palette.warning.A100} !important`,
    },
    '&.Mui-focused & legend': {
      maxWidth: '100%',
    },
    '& span': {
      padding: 0,
      fontSize: theme.textStyles.body2medium.fontSize,
      margin: '0 5px 0 7px',
    },
    '& legend': {
      lineHeight: '10px',
      '&[class*="legendNotched"]': {
        maxWidth: '100%',
      },
    },
    '& svg': {
      color: theme.palette.primary.main,
    },
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        borderWidth: '1px',
        padding: '0 8px',
      },
    },
    '&.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important',
      padding: '0 8px',
    },
  },
  leftIcon: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '4px',
    },
    '& $input': {
      paddingLeft: '8px',
    },
  },
  rightIcon: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '8px',
    },
    '& $input': {
      paddingRight: '8px',
    },
    '& svg': {
      zIndex: 10,
      color: theme.palette.primary.main,
    },
  },
  colored: {
    backgroundColor: theme.palette.warning.A100,
    borderRadius: 4,
  },
  error: {
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  disabled: ({ disabled }) => ({
    '& svg': {
      color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
    },
  }),
  smallHeight: {
    '& input': {
      height: '32px',
    },
  },
  multiline: ({ fullWidth, resize, disabled }) => ({
    '& .MuiOutlinedInput-multiline': {
      minHeight: '80px',
      display: 'inline-block',
      width: fullWidth ? undefined : '320px',
      overflow: 'auto',
      resize: resize ? 'both' : 'none',
      padding: 0,
      '& $textarea': {
        color: disabled ? theme.palette.text.disabled : undefined,
        width: '288px',
        padding: '0 0 16px 0',
        position: 'relative',
        top: '8px',
        left: '16px',
        right: '16px',
        bottom: '8px',
      },
    },
  }),
  MuiInputLabelOutlined: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 12px)',
    transform: 'translate(15px, 13px) scale(1)',
    color: theme.palette.grey['700'],
  },
  inputLabelRoot: ({ heightBool, rightIconBool }) => ({
    width: rightIconBool ? 'calc(100% - 52px)' : 'calc(100% - 26px)',
    fontSize: theme.textStyles.body1medium.fontSize,
    fontWeight: theme.textStyles.body1medium.fontWeight,
    top: heightBool ? -6 : 0,
    '&.MuiInputLabel-outlined': {
      '&.MuiInputLabel-shrink': {
        transform: 'translate(15px, -6px) scale(0.875)',
        top: 0,
        color: theme.palette.grey['700'],
      },
    },
    '&.MuiInputLabel-shrink': {
      width: '100% !important',
    },
    '&.MuiFormLabel-root.Mui-focused[class]': {
      color: theme.palette.primary.main,
    },
    '&.MuiFormLabel-root.Mui-error[class]': {
      color: theme.palette.error.main,
    },
  }),
  inputRoot: {
    fontWeight: theme.textStyles.body2medium.fontWeight,
    '$light &': {
      backgroundColor: theme.tableDefaultBG.main,
    },
  },
  helperTextRoot: {
    '&.MuiFormHelperText-contained': {
      marginLeft: '16px',
    },
  },
  light: {},
  dark: {},
}))

export const useIconMeasureStyles = makeStyles<Theme>((theme) => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    color: theme.palette.grey['600'],
    zIndex: 5,
  },
}))
