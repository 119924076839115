import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TableColumnResizingProps } from '../../../interfaces/Table'
import getResizableColumns from '../../../utils/getResizableColumns'
import { Table as NLMKTable, TableProps, TableToolbar } from '../../components'
import { ExcludeColumnNames } from '../../components/TableToolbar/types'
import TableManager from '../../managers/TableManager'
import { makeStyles } from '@material-ui/core'

type ITableProps<R> = TableProps<R> & {
  withTableToolBar?: boolean
  manager?: TableManager
  excludeColumnNames?: ExcludeColumnNames<R>
  withFilter?: boolean
  withSort?: boolean
  withView?: boolean
}

const styles = makeStyles({
  row: {
    '&:hover .ControlsBlock': {
      display: 'block',
      backgroundColor: '#EDEEEF',
    },
  },
})

const Table = <R extends Record<string, unknown>>({
  withTableToolBar = true,
  manager,
  columns,
  excludeColumnNames,
  onHoverControls = true,
  style = {},
  withFilter = false,
  withSort = false,
  withView = true,
  narrowRows = true,
  narrowColumns = true,
  ...props
}: ITableProps<R>): ReactElement<TableProps<R>> => {
  const classes = styles()

  const dispatch = useDispatch()

  const [columnWidths, setColumnWidths] = useState(getResizableColumns(columns))

  useEffect(() => {
    setColumnWidths(getResizableColumns(columns))
  }, [columns])

  const tableColumnResizingProps = useMemo(() => {
    const tableColumns: TableColumnResizingProps<R> = {
      columnWidths: getResizableColumns(columns),
      defaultColumnWidths: getResizableColumns(columns),
      onColumnWidthsChange: (nextColumnsWidth) => {
        if (manager) {
          dispatch(manager.changeViewTable(columns, nextColumnsWidth))
        } else {
          setColumnWidths(nextColumnsWidth)
        }
      },
    }
    if (!manager) {
      const columnsNames = columns.map(({ name }) => name)
      if (
        columns.length === columnWidths.length &&
        columnWidths.every(({ columnName }) => columnsNames.includes(columnName))
      ) {
        tableColumns.columnWidths = columnWidths
      } else {
        tableColumns.columnWidths = getResizableColumns(columns)
      }
    }
    return tableColumns
  }, [columns, manager, dispatch, columnWidths])
  return (
    <>
      {withTableToolBar && manager && (
        <TableToolbar<R>
          filter={withFilter}
          sort={withSort}
          view={withView}
          manager={manager}
          columns={columns}
          excludeColumnNames={excludeColumnNames}
        />
      )}
      <NLMKTable
        tableColumnResizingProps={tableColumnResizingProps}
        columns={columns}
        narrowRows={narrowRows}
        narrowColumns={narrowColumns}
        style={{
          ...style,
          row: {
            ...(style?.row ?? {}),
            className: `${classes.row} ${style?.row?.className ?? ''}`,
          },
        }}
        onHoverControls={onHoverControls}
        {...props}
      />
    </>
  )
}

export default React.memo(Table) as typeof Table
