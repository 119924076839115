import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconTrolley32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M4.00003 24.2391C4.00004 24.0963 4.11442 23.9806 4.25552 23.9806L4.92235 23.9806C5.06345 23.9806 5.17784 23.8648 5.17784 23.722L5.17783 10.4601C5.17783 10.3173 5.29222 10.2016 5.43332 10.2016L20.0703 10.2016C20.2114 10.2016 20.3258 10.3173 20.3258 10.4601L20.3258 21.829C20.3258 22.0584 20.6098 22.1846 20.7983 22.0573C21.0478 21.8886 21.322 21.7545 21.614 21.6616C21.7262 21.626 21.8068 21.5234 21.8068 21.4044L21.8068 7.1339C21.8068 5.40587 23.1959 4 24.9034 4C26.6109 4 28 5.40587 28 7.1339L28 8.37703C28 8.51983 27.8856 8.63559 27.7445 8.63559L26.7226 8.63559C26.5815 8.63559 26.4671 8.51983 26.4671 8.37703L26.4671 7.1339C26.4671 6.2613 25.7656 5.55139 24.9034 5.55139C24.0412 5.55139 23.3397 6.2613 23.3397 7.1339L23.3397 21.4044C23.3397 21.5234 23.4204 21.626 23.5325 21.6617C24.8326 22.0752 25.7783 23.3058 25.7783 24.7563C25.7783 26.5449 24.3406 28 22.5732 28C21.1401 28 19.9242 27.0429 19.5155 25.7271C19.4802 25.6136 19.3788 25.532 19.2613 25.532L4.25548 25.532C4.11438 25.532 4 25.4162 4 25.2734L4.00003 24.2391ZM22.5732 26.4486C23.4953 26.4486 24.2454 25.6894 24.2454 24.7563C24.2454 23.8231 23.4953 23.0639 22.5732 23.0639C21.6512 23.0639 20.9011 23.8231 20.9011 24.7563C20.9011 25.6894 21.6512 26.4486 22.5732 26.4486ZM6.71076 23.722C6.71076 23.8648 6.82514 23.9806 6.96625 23.9806L18.5374 23.9806C18.6785 23.9806 18.7929 23.8648 18.7929 23.722L18.7929 12.0115C18.7929 11.8687 18.6785 11.753 18.5374 11.753L6.96625 11.753C6.82514 11.753 6.71076 11.8687 6.71076 12.0115L6.71076 23.722Z"
        fill="currentColor"
      />
      <path
        d="M12.7354 14.7818C12.8351 14.6808 12.9969 14.6808 13.0967 14.7818L15.7773 17.4947C15.8771 17.5957 15.8771 17.7594 15.7773 17.8604L15.0547 18.5917C14.9549 18.6927 14.7932 18.6927 14.6934 18.5917L14.1186 18.01C13.9577 17.8471 13.6825 17.9625 13.6825 18.1928L13.6825 21.1813C13.6825 21.3241 13.5681 21.4399 13.427 21.4399L12.4051 21.4399C12.264 21.4399 12.1496 21.3241 12.1496 21.1813L12.1496 18.1928C12.1496 17.9625 11.8744 17.8471 11.7134 18.01L11.1386 18.5917C11.0389 18.6927 10.8771 18.6927 10.7773 18.5917L10.0547 17.8604C9.95493 17.7594 9.95493 17.5957 10.0547 17.4947L12.7354 14.7818Z"
        fill="currentColor"
      />
      <path
        d="M4.00003 24.2391C4.00004 24.0963 4.11442 23.9806 4.25552 23.9806L4.92235 23.9806C5.06345 23.9806 5.17784 23.8648 5.17784 23.722L5.17783 10.4601C5.17783 10.3173 5.29222 10.2016 5.43332 10.2016L20.0703 10.2016C20.2114 10.2016 20.3258 10.3173 20.3258 10.4601L20.3258 21.829C20.3258 22.0584 20.6098 22.1846 20.7983 22.0573C21.0478 21.8886 21.322 21.7545 21.614 21.6616C21.7262 21.626 21.8068 21.5234 21.8068 21.4044L21.8068 7.1339C21.8068 5.40587 23.1959 4 24.9034 4C26.6109 4 28 5.40587 28 7.1339L28 8.37703C28 8.51983 27.8856 8.63559 27.7445 8.63559L26.7226 8.63559C26.5815 8.63559 26.4671 8.51983 26.4671 8.37703L26.4671 7.1339C26.4671 6.2613 25.7656 5.55139 24.9034 5.55139C24.0412 5.55139 23.3397 6.2613 23.3397 7.1339L23.3397 21.4044C23.3397 21.5234 23.4204 21.626 23.5325 21.6617C24.8326 22.0752 25.7783 23.3058 25.7783 24.7563C25.7783 26.5449 24.3406 28 22.5732 28C21.1401 28 19.9242 27.0429 19.5155 25.7271C19.4802 25.6136 19.3788 25.532 19.2613 25.532L4.25548 25.532C4.11438 25.532 4 25.4162 4 25.2734L4.00003 24.2391ZM22.5732 26.4486C23.4953 26.4486 24.2454 25.6894 24.2454 24.7563C24.2454 23.8231 23.4953 23.0639 22.5732 23.0639C21.6512 23.0639 20.9011 23.8231 20.9011 24.7563C20.9011 25.6894 21.6512 26.4486 22.5732 26.4486ZM6.71076 23.722C6.71076 23.8648 6.82514 23.9806 6.96625 23.9806L18.5374 23.9806C18.6785 23.9806 18.7929 23.8648 18.7929 23.722L18.7929 12.0115C18.7929 11.8687 18.6785 11.753 18.5374 11.753L6.96625 11.753C6.82514 11.753 6.71076 11.8687 6.71076 12.0115L6.71076 23.722Z"
        stroke="currentColor"
        strokeWidth="0.3"
      />
      <path
        d="M12.7354 14.7818C12.8351 14.6808 12.9969 14.6808 13.0967 14.7818L15.7773 17.4947C15.8771 17.5957 15.8771 17.7594 15.7773 17.8604L15.0547 18.5917C14.9549 18.6927 14.7932 18.6927 14.6934 18.5917L14.1186 18.01C13.9577 17.8471 13.6825 17.9625 13.6825 18.1928L13.6825 21.1813C13.6825 21.3241 13.5681 21.4399 13.427 21.4399L12.4051 21.4399C12.264 21.4399 12.1496 21.3241 12.1496 21.1813L12.1496 18.1928C12.1496 17.9625 11.8744 17.8471 11.7134 18.01L11.1386 18.5917C11.0389 18.6927 10.8771 18.6927 10.7773 18.5917L10.0547 17.8604C9.95493 17.7594 9.95493 17.5957 10.0547 17.4947L12.7354 14.7818Z"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default IconTrolley32
