import { makeStyles } from '@material-ui/core'

export const useTimeSelectorStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 36px)',
    gap: '4px',
    borderLeft: `1px solid ${theme.neutral.grey100}`,
    paddingTop: '20px',
    paddingLeft: '18px',
    paddingBottom: '20px',
    marginTop: '-8px',
    overflow: 'hidden',
    scrollBehavior: 'smooth',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  column: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '4px',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
  },
  columnTitle: {
    position: 'sticky',
    top: '0px',
    color: theme.palette.text.disabled,
    fontWeight: theme.textStyles.body1medium.fontWeight,
    fontSize: theme.textStyles.body1medium.fontSize,
    lineHeight: theme.textStyles.body1medium.lineHeight,
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
  },
}))
