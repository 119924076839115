import { makeStyles } from '@material-ui/core'

export const useDatePickerStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    borderTop: '1px solid rgba(0, 23, 41, 0.12)',
    padding: '16px',
    '& button:first-child': {
      marginRight: '8px',
    },
  },
  footerWrapper: {},
  footerWrapper__customContent: {
    padding: '0px 16px 16px 16px',
  },
  inputRoot: {
    height: '40px',
    paddingRight: '0px',
    '@global': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.neutral.grey200,
        borderWidth: '1px !important',
      },
    },
  },
  inputOutlined: {
    paddingLeft: '16px',
  },
  calendarIcon: {
    minWidth: '32px',
    minHeight: '32px',
    marginRight: '4px',
    cursor: 'pointer',
  },
  calendarIcon_disabled: {
    '& path': {
      fill: 'gray',
    },
  },
  rootDatePicker: {
    position: 'relative',
    width: 196,
    '@global': {
      '.rc-picker': {
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        opacity: 0.03,
        width: 0,
      },
    },
  },
  rootDatePicker__fullWidth: {
    width: '100%',
  },
  '@global': {
    '.rc-picker': {
      height: 40,
      border: 'none',
    },
    '.rc-picker-cell': {
      fontWeight: '500 !important',
    },
    '.rc-picker-panel': {
      background: theme.tableDefaultBG.main,
      border: 'none',
      borderRadius: 4,
    },
    '.rc-picker-label': {
      background: 'linear-gradient(to bottom, transparent 60%, #FFFFFF 60%, #FFFFFF 100%)',
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '2px',
      borderRadius: '4px 4px 0 0',
      '.rc-picker-focused &': {
        color: theme.palette.primary.main,
      },
      zIndex: 10,
      fontSize: '1rem',
      fontFamily: 'PT Root UI',
      fontWeight: '400',
      lineHeight: 1,
      transform: 'translate(14px, -10px) scale(0.75)',
      position: 'absolute',
      top: 0,
      left: 0,
      maxWidth: 'calc(100% - 20px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      transformOrigin: 'top left',
    },
    '.rc-picker-header': {
      margin: '8px 24px 2px 24px',
      height: '52px',
      alignItems: 'center',
      '.rc-picker-date-panel &': {
        margin: '8px 0 2px 0',
      },
    },
    '.rc-picker-time-panel-column': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&:nth-child(1) .rc-picker-time-panel-cell': {
        justifyContent: 'flex-end',
      },
      '&:nth-child(2) .rc-picker-time-panel-cell': {
        justifyContent: 'flex-start',
      },
    },
    '.rc-picker-date-panel': {
      padding: '0 16px',
      '& .rc-picker-header-super-next-btn': {
        display: 'none',
      },
      '& .rc-picker-header-super-prev-btn': {
        display: 'none',
      },
      '& .rc-picker-month-btn, .rc-picker-year-btn': {
        padding: '5px 7px',
      },
      '& .rc-picker-body': {
        marginBottom: '10px',
      },
    },
    '.rc-picker-dropdown': {
      background: 'transparent',
      boxShadow: 'none',
      zIndex: '2000',
    },
    '.rc-picker-panel-container': {
      filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
      borderRadius: '4px',
      background: 'white',
      boxShadow: 'none',
      zIndex: 100000,
      marginBottom: '4px',
      marginTop: '4px',
    },
    '.rc-picker-month-btn, .rc-picker-decade-btn, .rc-picker-year-btn': {
      '&:hover': {
        background: theme.palette.background.default,
        borderRadius: '100px',
      },
    },
    '.rc-picker-date-panel .rc-picker-header-view': {
      '&:after': {
        top: '4px',
      },
    },
    '.rc-picker-header-view': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '0px',
        width: '24px',
        height: '24px',
        color: 'red',
      },
    },
    '.rc-picker-month-btn, .rc-picker-decade-btn, .rc-picker-year-btn, .rc-picker-header-view': {
      fontFamily: '"PT Root UI"',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.375rem',
      textAlign: 'center',
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    '.current-month .rc-picker-cell-inner': {
      border: '1px solid rgba(0, 23, 41, 0.5)',
      borderRadius: '100px',
    },
    '.rc-picker-time-panel-cell': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 4,
      '&.rc-picker-time-panel-cell-disabled': {
        opacity: '0.2',
      },
    },
    '.rc-picker-time-panel-cell-inner': {
      fontFamily: '"PT Root UI"',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.375rem',
      textAlign: 'center',
      height: '38px !important',
      width: '38px !important',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      color: `${theme.palette.text.secondary} !important`,
      padding: '0 !important',
      '.rc-picker-time-panel-cell-selected &': {
        background: theme.palette.primary.main,
        borderRadius: '100px',
        fontFamily: '"PT Root UI"',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.375rem',
        color: `${theme.neutral.white} !important`,
      },
    },
    '.rc-picker-suffix': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        color: 'rgba(0, 23, 41, 0.6)',
      },
      '.rc-picker-focused & svg': {
        color: theme.palette.primary.main,
      },
    },
    '.rc-picker-focused': {
      border: 'none',
    },
    '.rc-picker-input': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      background: theme.neutral.white,
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 4px 0px 16px',
      '& > input': {
        fontFamily: '"PT Root UI"',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.375rem',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        border: 'none',
        outline: 'none',
      },
    },
    '.rc-picker-footer': {
      background: 'transparent',
      '& .rc-picker-ranges': {
        display: 'none',
      },
    },
    '.rc-picker-time-panel': {
      borderLeft: '1px solid rgba(0, 23, 41, 0.12) !important',
      '& .rc-picker-header': {
        display: 'none',
      },
      '& .rc-picker-content': {
        maxHeight: '336px',
        padding: '16px 0 0 0',
      },
    },
    '.rc-picker-content': {
      '& thead th': {
        color: theme.palette.text.secondary,
        fontFamily: '"PT Root UI"',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.375rem',
        textTransform: 'lowercase',
      },
      '& thead tr': {
        height: '40px',
      },
    },
    '.rc-picker-header-next-btn, .rc-picker-header-prev-btn, .rc-picker-header-super-next-btn, .rc-picker-header-super-prev-btn': {
      background: 'transparent',
      width: 32,
      height: 32,
      padding: 0,
      margin: 0,
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        background: 'rgba(0, 23, 41, 0.04)',
        borderRadius: '100px',
      },
    },
    '.rc-picker-decade-panel': {
      '& .rc-picker-cell .rc-picker-cell-inner': {
        width: '120px',
      },
      '& .rc-picker-header-view': {
        margin: '8px 0 2px 0',
      },
      '& .rc-picker-cell-inner': {
        color: theme.palette.text.secondary,
      },
    },
    '.rc-picker-year-panel, .rc-picker-month-panel': {
      '& .rc-picker-cell .rc-picker-cell-inner': {
        width: '80px',
      },
      '& .rc-picker-cell-inner': {
        color: theme.palette.text.secondary,
      },
      '& .rc-picker-header-view': {
        margin: '10px 0',
      },
      '& .rc-picker-body': {
        margin: '8px 16px',
      },
    },
    '.rc-picker-cell-inner': {
      fontFamily: '"PT Root UI"',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.375rem',
      borderRadius: '100px',
      color: theme.palette.text.secondary,
      '.rc-picker-date-panel &': {
        opacity: 0.5,
      },
      '.rc-picker-cell-in-view &': {
        color: theme.palette.text.primary,
        opacity: 1,
      },
      '.rc-picker-cell &': {
        width: '38px',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.rc-picker-cell-today &': {
        border: `1px solid ${theme.neutral.grey500}`,
        borderRadius: '100px',
      },
      '.rc-picker-cell-selected &': {
        background: theme.palette.primary.main,
        borderRadius: '100px',
        color: theme.neutral.white,
        '&:hover': {
          background: `${theme.palette.primary.main} !important`,
        },
      },
      '&:hover': {
        background: `${theme.palette.background.default} !important`,
        borderRadius: '100px',
      },
    },
  },
}))
