/**
 * Функция возвращает первое значение, которое находит в объекте или в любом из его
 * вложенных массивов.
 *
 * Функция принимает три аргумента:
 * @param {T} obj - объект для поиска,
 * @param {string} targetValueName - имя значения, которое вы ищете,
 * @param {string} arrName - имя свойства массива, в котором вы хотите выполнить поиск,
 * @returns искомое значение или null.
 */
export const findArrayDeepValue = <T extends Record<string, any>>(
  obj: T,
  targetValueName: string,
  arrName: string,
): string | null => {
  if (!obj || typeof obj !== 'object') {
    return null
  }

  if (obj.hasOwnProperty(targetValueName)) {
    return obj[targetValueName]
  }

  if (obj.hasOwnProperty(arrName) && Array.isArray(obj[arrName])) {
    const newArr = obj[arrName].map((el: T) => findArrayDeepValue(el, targetValueName, arrName))
    return newArr.find((el: T) => el !== null)
  }

  return null
}
