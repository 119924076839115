export const ROUTE_PATH = {
  root: '/',
  zones: '/warehouse/zones',
  agregates: '/warehouse/agregates',
  print: '/print',
  report: '/report',
}

type RouterConfig = Record<
  keyof typeof ROUTE_PATH,
  {
    name: string
    shortName: string
    path: typeof ROUTE_PATH[keyof typeof ROUTE_PATH]
  }
>

export const ROUTER_CONFIG: RouterConfig = {
  root: {
    name: 'Главная',
    shortName: 'Главная',
    path: ROUTE_PATH.root,
  },
  zones: {
    name: 'Зоны',
    shortName: 'Зоны',
    path: ROUTE_PATH.zones,
  },
  agregates: {
    name: 'Словари',
    shortName: 'Словари',
    path: ROUTE_PATH.agregates,
  },
  print: {
    name: 'Печать',
    shortName: 'Печать',
    path: ROUTE_PATH.print,
  },
  report: {
    name: 'Отчеты',
    shortName: 'Отчеты',
    path: ROUTE_PATH.report,
  },
}
