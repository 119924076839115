import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CustomIndeterminateCheckBoxIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <rect x="7" y="10.8" width="10" height="2.2" fill="white" />
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z" />
    </SvgIcon>
  )
}
