import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'

import { Attributes } from '../../../../../interfaces/Warehouse'
import { Button, Dialog } from '../../../../components'
import CardWire from '../../../Card/CardWire'
// import PRINT_PAGE_STYLE from 'config/printConfig';
import { makeStyles } from '@material-ui/core'

type TProps = {
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  wireRow: Attributes[]
}

const ModalAdd: React.FC<TProps> = ({ isOpen, setIsOpen, wireRow }) => {
  const cardsRef = useRef(null)

  const onAfterPrint = () => {
    setIsOpen(false)
  }

  const styles = makeStyles({
    primary: {
      height: '650px',
      width: '700px',
    },
  })

  const classes = styles()

  return (
    <Dialog
      maxWidth="lg"
      classes={{ paper: classes.primary }}
      onClose={() => {
        setIsOpen(false)
      }}
      actions={[
        <ReactToPrint
          key={1}
          copyStyles
          onAfterPrint={onAfterPrint}
          removeAfterPrint
          // pageStyle={PRINT_PAGE_STYLE.cardPrint}
          trigger={() => (
            <Button variant="contained" color="primary">
              Распечатать
            </Button>
          )}
          content={() => cardsRef.current}
        />,
        <Button onClick={() => setIsOpen(false)} key={2} color="secondary" variant="contained">
          Отмена
        </Button>,
      ]}
      title="Печать бирки"
      open={isOpen}
    >
      <CardWire highlighted={wireRow} ref={cardsRef}></CardWire>
    </Dialog>
  )
}

export default ModalAdd
