import React from 'react'

import { Card as MUCard } from '@material-ui/core'
import clsx from 'clsx'

import { CardProps } from './types'

import { useCardStyles } from './styles'

export const Card: React.FC<CardProps> = ({ ...props }) => {
  const classes = useCardStyles()
  return (
    <MUCard
      classes={{
        root: props.withBoxShadow ? clsx(classes.root, classes.shadow) : classes.root,
      }}
      elevation={0}
      {...props}
    />
  )
}
