import { format as formatFn } from 'date-fns'

import { TableColumn } from '../../../../Table'
import { FilterItem } from '../typings'
import { hashNumericLabel, hashTypeMethod, initialLabel } from './enums'

// TODO TYPE
type Item = { label: string; value: string }

function getDateLabel({ value, method, secondValue }: FilterItem, currentMethod?: Item) {
    const date = formatFn(value ? new Date(value) : new Date(), 'dd.MM.yyyy')
    const secondDate = formatFn(secondValue ? new Date(secondValue) : new Date(), 'dd.MM.yyyy')

    if (/(empty|full)/.exec(method)) {
        return currentMethod?.label
    }

    return method === 'date-between' ? `от ${date} до ${secondDate}` : `${currentMethod?.label} ${date}`
}

function getInitialLabel<T>(header: TableColumn<T>[], label: string) {
    const newLabel = header.find((e: TableColumn<T>) => e.name === label)
    return newLabel ? { label: newLabel.title, value: newLabel.name } : initialLabel
}

function useItemFilter<T>(item: FilterItem, header: TableColumn<T>[]) {
    const type = item.method.split('-')[0]
    const headerObj = header.find((e: TableColumn<T>) => e.name === item.label)
    const label = headerObj?.title

    const customMethods: Item[] = headerObj?.customMethods || []
    const customMethod: string = customMethods.find((e) => e.value === item.method)?.label || ''

    const customValues: string[] | undefined = headerObj?.customValues?.reduce((acc: string[], el) => {
        if ((item.values || []).includes(el.value)) {
            acc.push(el.title)
        }
        return acc
    }, [])

    const currentItem: Item = type !== 'custom' ? hashTypeMethod[type].find((e) => e.value === item.method) || initialLabel : initialLabel

    const hashRenderItem: { [k: string]: string } = {
        boolean: `${label} - ${currentItem?.label}`,
        numeric: `${label} ${type === 'numeric' && hashNumericLabel[item.method](item.value as number, item.secondValue as number)}`,
        string: `${label} ${currentItem?.label} ${item.value}`,
        date: `${label} ${type === 'date' && getDateLabel(item, currentItem)}`,
        custom: `${label} ${customMethod} ${customValues}`,
    }
    return { type, hashRenderItem }
}

function filterCreator<T>(header: TableColumn<T>[], label: string) {
    const headerObj = header.find((e: TableColumn<T>) => e.name === label)
    const dataType = headerObj?.dataType
    let filterMethods = hashTypeMethod[`${dataType}`]
    if (dataType === 'custom' && headerObj?.customMethods) {
        filterMethods = headerObj.customMethods
    }

    const filterValues = headerObj?.customValues || []

    return { dataType, filterMethods, filterValues }
}

function convertValue(item: FilterItem): { value: string | number; secondValue?: string | number } {
    const isDateType = item.method.includes('date')
    let value = item.value
    const secondValue = item.secondValue
    if (isDateType) {
        value = value || new Date().getTime()
    }
    return { value, secondValue }
}

function convertBeforeSave(values: FilterItem[]): FilterItem[] {
    return values.map((e) => {
        return {
            ...e,
            value: convertValue(e).value,
            secondValue: convertValue(e).secondValue,
        }
    })
}





export { getInitialLabel, useItemFilter, filterCreator, convertBeforeSave, type Item }
