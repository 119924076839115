import React, { FC, useContext, useMemo } from 'react'

import { NewTooltip } from '../../../NewTooltip'
import NavigationContext from '../../context/NavigationContext'
import { UserInfo } from '../../types'
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import clsx from 'clsx'

import { useNavigationStyles } from '../../styles'

export const AboutUser: FC<{ userInfo: UserInfo }> = ({ userInfo }) => {
  const { isOpen } = useContext(NavigationContext)
  const {
    listItemGutters,
    listItemIconNotOpenRoot,
    iconAvatarSmall,
    listItemIcon,
    listItemIconNotOpen,
    username,
  } = useNavigationStyles()

  const { name, avatarUrl } = userInfo

  const userName = useMemo(() => name ?? '', [name])
  return (
    <NewTooltip title={!isOpen ? userName : ''} placement="right">
      <ListItem
        classes={{
          gutters: listItemGutters,
          root: clsx({ [listItemIconNotOpenRoot]: !isOpen }),
        }}
      >
        <ListItemIcon
          classes={{
            root: clsx(listItemIcon, {
              [listItemIconNotOpen]: !isOpen,
            }),
          }}
        >
          <Avatar alt={name} src={avatarUrl} className={iconAvatarSmall} />
        </ListItemIcon>
        <ListItemText className={username} primary={name} />
      </ListItem>
    </NewTooltip>
  )
}
