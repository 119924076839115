import React from 'react'

import { CardDataInterfaceAttributes } from '../../../../interfaces/TaskMills'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'

type TProps = {
  highlighted: CardDataInterfaceAttributes
}

const treatmentModes = ['Р, кг/ч', 'Тз, С', 'tз, мин', 'С, %', 'То, С', 'tо, мин']

const CardCkiCto = React.forwardRef<HTMLDivElement, TProps>(({ highlighted }, ref) => {
  return (
    <div className="print-block print-block_nails print-block_cto" ref={ref}>
      <Box flexDirection="column" width={600}>
        <Box alignSelf="center" marginBottom={2}>
          <Barcode value={highlighted.barcode} />
        </Box>
        <Box border={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>Дата</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}>
            <Typography></Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>Фамилия оператора</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>ТУ на изделие</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}>
            <Typography variant="Body1-Medium">ТУ 16-40-.....-55798700-2006</Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>Типоразмер изделия</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}>
            <Typography>{highlighted.nail_standard}</Typography>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>№ плавки</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}></Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>№ контейнера</Typography>
          </Box>
          <Box flexShrink={1} flexBasis={450}>
            <Box flexShrink={1} flexBasis={100} borderRight={1}></Box>
            <Box padding="10px 5px">
              <Typography>Протокол №</Typography>
            </Box>
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>Режим термообработки</Typography>
          </Box>
          <Box flexShrink={1} flexBasis={450}>
            {treatmentModes.map((item, i) => {
              const isBorderRight = i !== treatmentModes.length - 1 ? 1 : 0
              return (
                <Box key={item} flexDirection="column" flexBasis="25%">
                  <Box padding="10px 5px" borderRight={isBorderRight} borderBottom={1}>
                    <Typography>{item}</Typography>
                  </Box>
                  <Box padding="20px 5px" borderRight={isBorderRight}></Box>
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box borderBottom={1} borderRight={1} borderLeft={1}>
          <Box padding="10px 5px" borderRight={1} flexShrink={1} flexBasis={150}>
            <Typography>Вес:</Typography>
          </Box>
          <Box padding="10px 5px" flexShrink={1} flexBasis={450}></Box>
        </Box>
      </Box>
    </div>
  )
})

CardCkiCto.displayName = 'CardCkiWhite'

export default CardCkiCto
