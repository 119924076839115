import React from 'react'

import { TreeDataState as DevExpressTreeDataState } from '@devexpress/dx-react-grid'

export const TreeDataState = ({ expandedRowIds, onExpandedRowIdsChange }) => {
  return (
    <DevExpressTreeDataState
      expandedRowIds={expandedRowIds}
      onExpandedRowIdsChange={onExpandedRowIdsChange}
    />
  )
}
