import { makeStyles } from '@material-ui/core'

export const useLoaderStyles = makeStyles(() => ({
  loaderWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  loaderWrapper__loader: {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000,
  },
}))
