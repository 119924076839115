import React, { useEffect, useState } from 'react'

import { useDebounceCb } from '../../hooks/useDebounceCb'
import { Input } from '../Input'

import { InputDebounceProps } from './types'

export const InputDebounce: React.FC<InputDebounceProps> = ({ onChange, value, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debounceCb = useDebounceCb<string>((newValue) => {
    if (newValue !== value) {
      onChange(newValue)
    }
  }, 600)

  useEffect(() => {
    if (value) {
      setSearchTerm(value)
    }
  }, [value])

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearchTerm(value)
    debounceCb(value)
  }

  return (
    <Input variant="outlined" size="small" value={searchTerm} onChange={changeHandler} {...props} />
  )
}
