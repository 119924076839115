import React, { useCallback, useContext } from 'react'
import { arrayMove } from 'react-sortable-hoc'

import { Button } from '../../../../'
import { Drawer } from '../../../../Drawer'
import { SortableItems } from '../../../../SortableItems/SortableItems'
import { Typography } from '../../../../Typography/Typography'
import BaseToolbarFooter from '../../../common/blocks/Footer'
import HintInit from '../../../common/blocks/HintInit'
import { HintSort } from '../../../common/constants/hint'
import SortItem from '../components/SortItem'
import { SortContext } from '../context'
import { ISortContext, SortItem as ISortItem } from '../typings'
import { Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

export interface ListSortTabProps {
  onSave: () => void
  isChangedState: boolean
}

export const ListSortTab: React.FC<ListSortTabProps> = (props) => {
  const {
    sort,
    visible,
    onClose,
    setSort,
    setTab,
    initHint,
    setInitHint,
  } = useContext<ISortContext>(SortContext)

  const changeSortMethod = useCallback(
    (item: ISortItem) => {
      const index = sort.findIndex((e) => e.label === item.label)
      const newSort = [...sort]
      newSort[index].method = item.method === 'ASC' ? 'DESC' : 'ASC'
      setSort(newSort)
    },
    [setSort, sort],
  )

  return (
    <Drawer
      header={{
        title: 'Сортировка',
        tooltip:
          'Выберите колонки, в которых вы хотите распределить значения по возрастанию или убыванию.',
        visibleTooltipIcon: !initHint,
      }}
      footer={{
        render: (
          <BaseToolbarFooter
            onClose={onClose}
            onSave={props.onSave}
            length={sort.length}
            disabled={!sort.length && !props.isChangedState}
          />
        ),
      }}
      open={visible}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        {initHint && (
          <HintInit onClose={() => setInitHint(false)}>
            <HintSort />
          </HintInit>
        )}
        <Grid item xs={12}>
          {!!sort.length && (
            <SortableItems
              onItemsChange={setSort}
              useDragHandle
              onSortEnd={({ oldIndex, newIndex }) => {
                setSort(arrayMove(sort, oldIndex, newIndex))
              }}
              onRenderItem={(item, id) => (
                <SortItem
                  item={item}
                  onDelete={() => setSort(sort.filter((_, i) => i !== id))}
                  onEdit={() => {
                    setTab({ tab: 1, edit: sort[id] })
                  }}
                  onChangeSortMethod={changeSortMethod}
                />
              )}
              items={sort}
            />
          )}

          <Grid item xs={12} style={{ marginTop: sort.length ? '32px' : '0px' }}>
            <Button
              startIcon={<AddIcon color="primary" />}
              variant="text"
              onClick={() => setTab({ tab: 1 })}
            >
              <Typography variant="Body1-Medium" color="primary">
                Добавить сортировку
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
