import { makeStyles } from '@material-ui/core'

export const useNavigationItemStyles = makeStyles((theme) => ({
  root: {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: '1rem',
    lineHeight: '1.125rem',
    fontWeight: 500,
    minHeight: '20px',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  linkExternalOpen: {
    display: 'block',
    '&:hover': {
      color: 'currentColor',
    },
  },
  linkExternalClosed: {
    color: 'currentColor',
    textDecoration: 'none',
  },
  listItemText: {
    '& span': {
      wordWrap: 'normal',
    },
  },
  selected: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  disabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'default',
      color: 'inherit',
    },
  },
  wrapper: {
    padding: '14px 72px 16px 0',
  },
  favoriteIcon: {
    position: 'absolute',
    left: '15px',
    display: 'none',
    cursor: 'pointer',
    paddingRight: '100%',
  },
  activeFavoriteIcon: {
    position: 'absolute',
    left: '15px',
    display: 'block',
    cursor: 'pointer',
    paddingRight: '100%',
  },
}))
