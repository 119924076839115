// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import service from 'services/service'

export type UseApproveDictionaryValueReturn = {
  isLoading: boolean
  approveDictionaryValue: (value: number[]) => Promise<void>
}

export const useApproveDictionaryValue = (
  onSuccess: (id: number, dateTime: string) => void,
): UseApproveDictionaryValueReturn => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const approveDictionaryValue = useCallback(
    async (value: number[]) => {
      try {
        setIsLoading(true)

        const response = await service.approveDictionaryValues(value)

        const { listIds, dateTime } = response.content

        onSuccess(listIds[0], dateTime)
        // alert.success('Значение успешно утверждено');
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess],
  )

  return {
    isLoading,
    approveDictionaryValue,
  }
}
