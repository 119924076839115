import React, { FC } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { UserNavigation } from '../../components/user-navigation/user-navigation'
import { ROUTER_CONFIG } from '../../config/routeConfig'
import { Box } from '../../ui-kit/components/Box/Box'
import Agregates from '../agregates/agregates'
import Home from '../home/home'
import Warehouse from '../Warehouse copy'
import Dictionaries from 'pages/Dictionaries'
import PrintCards from 'pages/PrintCards'
import Report from 'pages/Report'

const Main: FC = () => {
  return (
    <Router>
      <Box
        style={{
          height: 'calc(var(--vh, 1vh) * 100)',
          overflow: 'hidden',
        }}
      >
        <UserNavigation />
        <Routes>
          <Route path={ROUTER_CONFIG.root.path} element={<Home />} />
          <Route path={ROUTER_CONFIG.agregates.path} element={<Dictionaries />} />
          <Route path={ROUTER_CONFIG.zones.path} element={<Warehouse />} />
          <Route path={ROUTER_CONFIG.print.path} element={<PrintCards />} />
          <Route path={ROUTER_CONFIG.report.path} element={<Report />} />
        </Routes>
      </Box>
    </Router>
  )
}

export default Main
