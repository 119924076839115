import React, { useMemo, useState } from 'react'

import { months } from '../../helpers/months'
import { Day } from '../Day'
import { useIsDisabledMonth } from './helpers/useIsDisabledMonth'
import { useIsEndMonth } from './helpers/useIsEndMonth'
import { useIsMidMonth } from './helpers/useIsMidMonth'
import { useIsSelectedMonth } from './helpers/useIsSelectedMonth'
import { useIsStartMonth } from './helpers/useIsStartMonth'
import { useIsTodayMonth } from './helpers/useIsTodayMonth'

import { MonthsCalendarProps } from './types'

import { useMonthsCalendarStyles } from './styles'

export const MonthsCalendar: React.FC<MonthsCalendarProps> = ({
  panelValue,
  disableChange,
  withPeriod,
  valueFrom,
  valueTo,
  enabledTo,
  enabledFrom,
  selectedDate,
  onSelect,
}) => {
  const isTodayMonth = useIsTodayMonth({ panelValue })
  const dateFrom = useMemo(
    () => valueFrom && new Date(valueFrom.getFullYear(), valueFrom.getMonth()),
    [valueFrom],
  )
  const dateTo = useMemo(() => valueTo && new Date(valueTo.getFullYear(), valueTo.getMonth()), [
    valueTo,
  ])
  const [innerCurrentHover, setCurrentHover] = useState<null | Date>(null)
  const currentHover = useMemo(() => (!valueFrom || !valueTo ? innerCurrentHover : null), [
    innerCurrentHover,
    valueFrom,
    valueTo,
  ])
  const dateCurrentHover = useMemo(
    () =>
      currentHover && new Date(currentHover.getFullYear(), currentHover.getMonth(), 1, 0, 0, 0, 0),
    [currentHover],
  )
  const styles = useMonthsCalendarStyles()

  const isSelectedMonth = useIsSelectedMonth({
    dateCurrentHover,
    withPeriod,
    dateFrom,
    dateTo,
    selectedDate,
    panelValue,
  })
  const isStartMonth = useIsStartMonth({ dateCurrentHover, withPeriod, dateFrom })
  const isMidMonth = useIsMidMonth({ dateCurrentHover, withPeriod, dateFrom, dateTo })
  const isEndMonth = useIsEndMonth({ dateCurrentHover, withPeriod, dateFrom, dateTo })
  const isDisabled = useIsDisabledMonth(enabledFrom, enabledTo)

  return (
    <div className={styles.root}>
      {months.map((month, idx) => {
        const dayAsDate = new Date((panelValue || new Date()).getFullYear(), idx)
        return (
          <Day
            onClick={() => onSelect && onSelect(dayAsDate)}
            onHover={() => withPeriod && setCurrentHover(dayAsDate)}
            isHoverState={Boolean(dateCurrentHover)}
            selected={isSelectedMonth(dayAsDate)}
            disabled={disableChange || isDisabled(panelValue?.getFullYear(), idx)}
            today={isTodayMonth(idx)}
            start={isStartMonth(dayAsDate)}
            mid={isMidMonth(dayAsDate)}
            end={isEndMonth(dayAsDate)}
            key={month}
            type="month"
          >
            {month}
          </Day>
        )
      })}
    </div>
  )
}
