const getWeights = <A extends Record<string, unknown>, F extends Array<keyof A>, CV>(
  attributes: A,
  weightFields: F,
  converterValueFunc?: (value: unknown) => CV,
): Partial<A> => {
  const result = Object.entries(attributes).reduce((acc, [key, value]) => {
    if (weightFields.includes(key)) {
      return {
        ...acc,
        [key]: converterValueFunc?.(value) ?? value,
      }
    }
    return acc
  }, {} as Partial<A>)
  return result
}

export default getWeights
