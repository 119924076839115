import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconMetal24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M18.735 13.7816L16.836 6.05047C16.8067 5.93114 16.7304 5.82637 16.635 5.76295L13.7912 3.83332C13.6571 3.74193 13.5214 3.74989 13.5246 3.75037C13.4494 3.75047 13.3742 3.77003 13.3126 3.80138L7.61228 6.70225C7.51647 6.75101 7.43234 6.83908 7.38811 6.94424L4.53799 13.713C4.47001 13.8743 4.49322 14.0694 4.60803 14.211L9.35826 20.0772C9.36092 20.0805 9.36396 20.0833 9.36671 20.0865C9.45578 20.1909 9.58582 20.25 9.72542 20.25C9.72577 20.25 9.72609 20.25 9.72644 20.25H15.4251C15.6058 20.25 15.7709 20.1488 15.8509 19.984L18.7011 14.1178C18.7013 14.1173 18.7015 14.1168 18.7017 14.1162C18.7535 14.0084 18.762 13.8862 18.735 13.7816ZM14.0001 5.1372L15.5188 6.16771L14.0001 7.19822V5.1372ZM13.05 5.0161V7.94052L10.7187 11.1042L8.49599 7.33366L13.05 5.0161ZM7.89541 8.19424L9.96289 11.7014L5.82793 13.1043L7.89541 8.19424ZM9.38926 18.595L5.78126 14.1394L10.1108 12.6705L9.38926 18.595ZM15.1301 19.2862H10.2624L11.0803 12.571L17.5881 14.2269L15.1301 19.2862ZM11.4841 11.6771L13.8562 8.45801L16.0778 6.95059L17.6224 13.239L11.4841 11.6771Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default IconMetal24
