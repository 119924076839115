import React, { FC, memo, useCallback, useContext, useMemo } from 'react'

import { findArrayDeepValue } from '../../../../helpers/findDeepArrayValue'
import { NewTooltip } from '../../../NewTooltip'
import NavigationContext from '../../context/NavigationContext'
import { INavigationItem } from '../../types'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import clsx from 'clsx'

import { useNavigationStyles } from '../../styles'
import { useNavigationItemStyles } from '../NavigationItem/styles'

export const CreateItem: FC<{ item: INavigationItem }> = memo(({ item }) => {
  const navigationContext = useContext(NavigationContext)
  const classes = useNavigationItemStyles()
  const {
    isOpen,
    renderLink,
    selectedFirstLevel,
    setSecondLevel,
    setSelectedFirstLevel,
    selectedListItemId,
    setSelectedListItemId,
    pathname,
  } = navigationContext
  const {
    listItemGutters,
    listItemIconNotOpenRoot,
    listItemSelected,
    listItem,
    listItemIcon,
    listItemIconNotOpen,
    listItemDisabled,
  } = useNavigationStyles()

  const listItemClassName = useMemo(() => {
    if (selectedListItemId) {
      return selectedListItemId === (item.id || item.title)
    }
    return findArrayDeepValue(item, 'href', 'items') === pathname
  }, [selectedListItemId, item, pathname])

  const handleClick = useCallback(() => {
    if (!item.disabled) {
      const itemIsSelected = selectedFirstLevel === item.id
      setSelectedFirstLevel(itemIsSelected ? null : item.id)

      if (item.onClick) {
        item.onClick()
      }
      if (setSelectedListItemId) {
        setSelectedListItemId(item.id)
      }
      setSecondLevel(null)
    }
  }, [item, selectedFirstLevel, setSelectedFirstLevel, setSelectedListItemId, setSecondLevel])

  const itemComponent = (
    <NewTooltip title={!isOpen ? item.title : ''} placement="right">
      <ListItem
        classes={{
          gutters: listItemGutters,
          root: clsx({
            [listItemIconNotOpenRoot]: !isOpen,
            [listItemSelected]: selectedFirstLevel === (item.id || item.title),
            [listItem]: listItemClassName,
          }),
          disabled: listItemDisabled,
        }}
        disabled={item.disabled}
        onClick={handleClick}
        key={item.title}
      >
        <ListItemIcon
          id={item.id}
          classes={{
            root: clsx(listItemIcon, {
              [listItemIconNotOpen]: !isOpen,
            }),
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={item.title} />
      </ListItem>
    </NewTooltip>
  )

  if (item.external) {
    return (
      <a
        className={
          isOpen ? clsx(classes.root, classes.linkExternalOpen) : classes.linkExternalClosed
        }
        href={item.href}
        target={item.target}
      >
        {itemComponent}
      </a>
    )
  }

  if (item.href) {
    return renderLink({
      id: item.id,
      href: item.href,
      onClick: item.onClick,
      children: <>{itemComponent}</>,
    })
  }

  return itemComponent
})
