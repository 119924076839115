import { IBadgeColor, IColorMap, TBadgeColor } from '../types'
import { Theme } from '@material-ui/core'

type TGetColor = (theme: Theme, color: TBadgeColor) => IBadgeColor
export const getColor: TGetColor = (theme, color) => {
  const colorMap: IColorMap = {
    background: {
      primary: {
        backgroundColor: theme.palette.primary.main,
        textColor: theme.neutral.noChangeWhite,
      },
      secondary: {
        backgroundColor: theme.palette.secondary.light,
        textColor: theme.buttonTextColor,
      },
      error: {
        backgroundColor: theme.palette.error.main,
        textColor: theme.neutral.noChangeWhite,
      },
      warning: {
        backgroundColor: theme.palette.warning.main,
        textColor: theme.neutral.black,
      },
      success: {
        backgroundColor: theme.tableSuccessBG.main,
        textColor: theme.neutral.black,
      },
    },
  }
  return colorMap['background'][color]
}
