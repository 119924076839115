import { TableColumnWidthInfo } from '../../interfaces/Table'
import { TableState, ThunkAction, ThunkTableManager } from '../components'
import { FilterItem } from '../components/TableToolbar/components/Filter/typings'
import ViewTableManager from './ViewTableManager'

class TableManager extends ThunkTableManager {
  public onChangeParams = (params: TableState['params']) => (): unknown => params

  public changeFilterTable(filter: (FilterItem | undefined)[]): ThunkAction {
    this.changePage(0)
    return super.changeFilterTable(filter)
  }

  public changeViewTable = (
    viewTable: TableState['params']['viewTable'],
    updatedWidths?: TableColumnWidthInfo<unknown>[],
  ): ThunkAction => {
    let updatedViewTable = [...viewTable]
    if (updatedWidths) {
      updatedViewTable = ViewTableManager.getUpdatedColumns(viewTable, updatedWidths)
    }
    ViewTableManager.update(this.key, updatedViewTable)
    return super.changeViewTable(updatedViewTable)
  }
}

export default TableManager
