import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconPlaylistAdd24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5H14V7.5H2V5.5ZM2 9.5H14V11.5H2V9.5ZM18 9.5H16V13.5H12V15.5H16V19.5H18V15.5H22V13.5H18V9.5ZM10 15.5H2V13.5H10V15.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconPlaylistAdd24
