import React, { FC } from 'react'

import { Box } from '../../ui-kit/components/Box'
import { Typography } from '../../ui-kit/components/Typography'
import { useHomeStyles } from './Home.styles'

const Home: FC = () => {
  const styles = useHomeStyles()

  return (
    <Box className={styles.root}>
      <Typography variant="Heading1">Домашняя страница</Typography>
    </Box>
  )
}

export default Home
