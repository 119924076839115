import React from 'react'

import { weekdays } from '../../helpers/weekdays'

import { useWeekdaysStyles } from './styles'

export const Weekdays = () => {
  const styles = useWeekdaysStyles()
  return (
    <div className={styles.root}>
      {weekdays.map((weekday) => (
        <div className={styles.weekday} key={weekday}>
          {weekday}
        </div>
      ))}
    </div>
  )
}
