import React, { useContext } from 'react'

import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useRowStyleProps } from '../utils/StyledProps'
import {
  Table as DevexpressTable,
  TableSummaryRow as DXTableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui'

type TableRowProps = DevexpressTable.DataRowProps & StyledProps

export const TableSummaryRow: React.FC<TableRowProps> = (props) => {
  const context = useContext(CustomFieldsCtx)
  const customStyle = context.style
  const [className, style] = useRowStyleProps(props?.row, props, customStyle?.row)
  return <DXTableSummaryRow.TotalRow {...props} className={className} style={style} />
}
