import React, { FC } from 'react'

import { Box } from '../Box'
import { CloseSvgIcon } from '../Icon'
import { Typography } from '../Typography'
import { DialogActions } from './subcomponents/DialogActions'
import { DialogContent } from './subcomponents/DialogContent'
import { DialogTitle } from './subcomponents/DialogTitle'
import { Dialog as MUIDialog } from '@material-ui/core'
import clsx from 'clsx'

import { ExtendedDialogProps } from './types'

import { useDialogStyles } from './styles'

export const Dialog: FC<ExtendedDialogProps> = (props) => {
  const { title, status, actions, children, classes, open = true, ...rest } = props
  const styles = useDialogStyles(props)

  return (
    <MUIDialog
      classes={{
        ...classes,
        root: clsx(classes?.root, styles.root),
        paper: clsx(styles.paper, classes?.paper),
      }}
      open={open}
      fullWidth
      {...rest}
    >
      <CloseSvgIcon onClick={(e) => props.onClose?.(e, 'backdropClick')} className={styles.icon} />
      {(title || status) && (
        <DialogTitle disableTypography={true}>
          <Box justifyContent="space-between">
            {title && <Typography variant="Heading3">{title}</Typography>}
            {status && <div>{status}</div>}
          </Box>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MUIDialog>
  )
}
