import { IChipColor, IColorMap, TChipColor } from '../types'
import { Theme } from '@material-ui/core'

type TGetColor = (theme: Theme, color: TChipColor) => IChipColor
export const getColor: TGetColor = (theme, color) => {
  const colorMap: IColorMap = {
    backgroundColor: {
      gray: {
        backgroundColor: theme.palette.action.disabled,
      },
      default: {
        backgroundColor: theme.palette.secondary.dark,
      },
      blue: {
        backgroundColor: theme.palette.secondary.dark,
      },
      white: {
        backgroundColor: theme.palette.background.paper,
      },
      outline: {
        backgroundColor: theme.palette.background.paper,
      },
      green: {
        backgroundColor: theme.tableSuccessBG.main,
      },
      red: {
        backgroundColor: theme.tableErrorBG.light,
      },
      yellow: {
        backgroundColor: theme.tableWarningBG.main,
      },
    },
  }
  return colorMap['backgroundColor'][color]
}
