import React from 'react'

import { ButtonProps, Button as MUButton } from '@material-ui/core'

import { useButtonStyles } from './styles'

export const Button: React.FC<ButtonProps> = ({ ...props }) => {
  const classes = useButtonStyles()
  return (
    <MUButton
      classes={{
        root: classes.root,
        text: classes.text,
        containedPrimary: classes.containerPrimary,
        disabled: classes.disabled,
        containedSecondary: classes.containedSecondary,
        outlinedPrimary: classes.outlinedPrimary,
        textPrimary: classes.textPrimary,
        sizeSmall: classes.sizeSmall,
        label: classes.label,
        colorInherit: classes.inherit,
      }}
      {...props}
    />
  )
}
