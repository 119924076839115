import { TableReducers } from '../../../interfaces/TableReducers'
import {
  createTableReducer,
  generateDefaultTableState,
  TableState,
} from '../../../ui-kit/components'
import ViewTableManager from '../../../ui-kit/managers/ViewTableManager'
import { COLUMNS_SHARE, ReducerKey, reducerKeyPrefix } from '../constants'

const inputWarehouseTableReducers: TableReducers<ReducerKey> = Object.entries(COLUMNS_SHARE).reduce(
  (acc, [abbreviation, columns]) => {
    const key = `${reducerKeyPrefix}-${abbreviation}`
    const initialState: TableState = generateDefaultTableState(columns)
    const state = ViewTableManager.merge(key, initialState)
    const tableReducer = createTableReducer<TableState>(key, state)
    return {
      ...acc,
      [key]: tableReducer,
    }
  },
  {} as TableReducers<ReducerKey>,
)

export default inputWarehouseTableReducers
