import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconBunker24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2 2C6.57538 2 2 6.57538 2 12.2V20.7417C2 21.6563 2.74371 22.4 3.65833 22.4H20.7417C21.6563 22.4 22.4 21.6563 22.4 20.7417V12.2C22.4 6.57538 17.8246 2 12.2 2ZM3.65 12.2C3.65 7.48546 7.48546 3.65 12.2 3.65C16.9145 3.65 20.75 7.48546 20.75 12.2V20.7417C20.75 20.7418 20.75 20.7422 20.7496 20.7431L20.7487 20.7448L20.7469 20.7469C20.7461 20.7478 20.7452 20.7484 20.7445 20.7489C20.7439 20.7492 20.7435 20.7495 20.7431 20.7496C20.7422 20.75 20.7418 20.75 20.7417 20.75H18.65V16.9917C18.65 16.5362 18.2805 16.1667 17.825 16.1667H15.7333V11.9917C15.7333 11.5362 15.3638 11.1667 14.9083 11.1667H9.49166C9.03621 11.1667 8.66667 11.5362 8.66667 11.9917V16.1667H6.575C6.11954 16.1667 5.75 16.5362 5.75 16.9917V20.75H3.65833C3.65826 20.75 3.65851 20.75 3.65833 20.75C3.65811 20.75 3.65741 20.7498 3.65694 20.7496C3.65595 20.7492 3.65452 20.7483 3.65309 20.7469C3.65165 20.7455 3.65079 20.7441 3.65036 20.7431C3.65019 20.7427 3.65041 20.7433 3.65036 20.7431C3.65031 20.7428 3.65 20.7418 3.65 20.7417V12.2ZM10.3167 16.1667V12.8167H11.375V13.2417C11.375 13.6971 11.7445 14.0667 12.2 14.0667C12.6555 14.0667 13.025 13.6971 13.025 13.2417V12.8167H14.0833V16.1667H10.3167ZM7.4 20.75V17.8167H8.66667V18.2417C8.66667 18.6971 9.03621 19.0667 9.49166 19.0667C9.94712 19.0667 10.3167 18.6971 10.3167 18.2417V17.8167H11.375V20.75H7.4ZM13.025 20.75V17.8167L14.0833 17.8167V18.2417C14.0833 18.6971 14.4529 19.0667 14.9083 19.0667C15.3638 19.0667 15.7333 18.6971 15.7333 18.2417V17.8167H17V20.75H13.025Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconBunker24
