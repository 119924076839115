import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconControlMaps32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M6.2674 22.9792C7.14804 22.9792 7.92746 22.4375 8.29186 21.6563L23.5462 26.2604C23.7992 27.2604 24.69 28 25.7427 28C26.9776 28 28 26.9583 28 25.6771C28 24.7083 27.4129 23.875 26.6031 23.5208L26.178 13.0521C27.0384 12.7292 27.666 11.8646 27.666 10.8542C27.666 9.57292 26.6537 8.53125 25.4188 8.53125C24.5888 8.53125 23.8701 8.98958 23.4753 9.66667L15.0333 7.28125C15.0333 6.05208 14.0211 5 12.7862 5C11.5411 5 10.5188 6.05208 10.5188 7.33333C10.5188 8.02083 10.8123 8.64583 11.2779 9.07292L6.59131 18.3438C6.47997 18.3229 6.37874 18.3229 6.2674 18.3229C5.02235 18.3229 4 19.3646 4 20.6458C4 21.9271 5.02235 22.9792 6.2674 22.9792ZM12.7862 9.66667C13.5251 9.66667 14.1932 9.28125 14.5981 8.69792L23.1615 11.125C23.2628 12.0521 23.9106 12.8229 24.7507 13.0833L25.1151 23.4271C24.4471 23.6354 23.9005 24.1458 23.6373 24.8021L8.48418 20.2188C8.40321 19.7813 8.20076 19.375 7.91733 19.0625L12.6141 9.65625C12.6647 9.65625 12.7254 9.66667 12.7862 9.66667Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconControlMaps32
