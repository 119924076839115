import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const TaskSvgIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="m20.625 6.1178-9.8411 9.8497-3.657-3.657 1.2161-1.2161 2.4409 2.4409 8.625-8.625 1.2161 1.2074Zm-1.9061 4.347c.1121.4916.1811 1.0091.1811 1.5352 0 3.8123-3.0877 6.9-6.9 6.9-3.8123 0-6.9-3.0877-6.9-6.9 0-3.8123 3.0877-6.9 6.9-6.9 1.3628 0 2.622.3967 3.6915 1.0781l1.242-1.242C15.5362 3.953 13.8371 3.375 12 3.375c-4.761 0-8.625 3.864-8.625 8.625S7.239 20.625 12 20.625s8.625-3.864 8.625-8.625c0-1.0264-.1898-2.0096-.5175-2.9239l-1.3886 1.3887Z" />
    </SvgIcon>
  )
}
