import React, { useContext } from 'react'

import { FilterContext } from '../context'
import { ICreateFilter } from '../form'
import { IFilterContext } from '../typings'
import { initialMethod } from '../utils/enums'
import { filterCreator } from '../utils/helpers'
import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useFormikContext } from 'formik'

function FilterMethod() {
  const {
    values: { label, method },
    setFieldValue,
  } = useFormikContext<ICreateFilter>()
  const { header } = useContext<IFilterContext>(FilterContext)

  const { filterMethods } = filterCreator(header, label.value)

  return (
    <>
      {label.value && (
        <Grid item xs={12}>
          <Autocomplete
            options={filterMethods}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={(_, v) => {
              const newValue = v ? v : initialMethod
              setFieldValue('method', newValue, true)
              setFieldValue('value', '', false)
              setFieldValue('secondValue', '', false)
            }}
            value={filterMethods.find((e) => e.value === method.value) || initialMethod}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                name="method"
                label="Способ фильтрации"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      )}
    </>
  )
}

export default FilterMethod
