import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { State } from '../../../interfaces/Store'
// import { useAlert } from 'react-alert';
import {
  Attributes,
  TEventReason,
  TWorkshopName,
  WarehouseRowsData,
} from '../../../interfaces/Warehouse'
import service from '../../../services/service'
// import ModalConfirm from '../ModalConfirm';
import { convertKilosToTons } from '../../../utils/convertKilograms'
import getLocalDate from '../../../utils/getLocalDate'
import getWeights from '../../../utils/getWeights'
import {
  Box,
  Button,
  Card,
  CompetedSvgIcon,
  NoDataSvgIcon,
  Pagination,
  TableColumn,
  Typography,
} from '../../components'
import { Alert } from '../../components/Alert'
import useAsync from '../../hooks/useAsync'
import Fullness from '../Fullness'
import LoaderWrapper from '../LoaderWrapper'
import Table from '../Table'
// import { DictionariesContext, DictionaryValue } from '../../features';
// import ModalAdd from './components/ModalAdd';
import {
  COLUMNS,
  DEFAULT_STATUS_INVENTORY,
  getReducerKey,
  STATUS_INVENTORY,
  weightFields,
} from './constants'
import { managers } from './redux/manager'
import { warehouseTableReducers } from './redux/reducer'
import { title } from 'process'

import ModalPrint from './components/ModalPrint'

type TProps = {
  workshopName: TWorkshopName
  role?: 'posting' | 'inventory'
  totalWeightField?: 'weight'
  eventReason?: TEventReason
  selectZoneType?: 'list' | 'group'
  alias: string
}

const tableStyle = {
  root: {
    style: {
      height: '550px',
      overflow: 'scroll',
    },
  },
}
var types: Array<{
  name: string
  title: string
  width: string
  visible: boolean
  pinned: boolean
}> = []

const WarehouseContainer: FC<TProps> = ({
  workshopName,
  role = 'posting',
  totalWeightField = 'weight',
  eventReason,
  selectZoneType = 'list',
  alias = 'pipe',
}) => {
  // const { getDictionary } = useContext(DictionariesContext);

  // const statusInventoryDictionary = getDictionary(getStatusInventoryDictionaryKey(workshopName));

  // const getStatusInventoryDictionaryValue = useCallback(
  //   (statusInventory: keyof typeof STATUS_INVENTORY) => {
  //     return (
  //       statusInventoryDictionary.find(
  //         ({ value }) => value === STATUS_INVENTORY[statusInventory]
  //       ) ?? DEFAULT_STATUS_INVENTORY[statusInventory]
  //     );
  //   },
  //   [statusInventoryDictionary]
  // );

  service.getTableColumns(alias).then((value) => {
    types.splice(0, types.length)
    value.content.forEach((element) => {
      types.push({
        name: element.alias,
        title: element.name,
        width: '100px',
        visible: true,
        pinned: false,
      })
    })
    types.push({ name: 'controls', title: '', width: '1px', visible: true, pinned: false })
  })

  const [zoneId, setZoneId] = useState<number>(0)
  const [zoneName, setZoneName] = useState<string>('')
  const [wireRow, setWireRow] = useState<Array<Attributes>>([])
  const [isClickZone, setIsClockZone] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  // const [controlStatus, setControlStatus] = useState<DictionaryValue>(
  //   getStatusInventoryDictionaryValue('compliant')
  // );
  const [wiresId, setWireId] = useState<Array<number>>([])
  const [checked, setChecked] = useState<typeof tableRows>([])
  const [isOpenModalRow, setIsOpenModalRow] = useState(false)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isModalPrint, setIsModalPrint] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const onCheckedChange = (rows: typeof tableRows) => {
    const rowsChecked = rows.reduce((acc, item) => {
      if (item.status_inventory !== STATUS_INVENTORY.compliant) {
        return [...acc, item]
      }
      return acc
    }, [] as typeof tableRows)

    setChecked(rowsChecked)
  }

  const handleClickZone = (id: number) => {
    setIsClockZone(true)
    setCurrentPage(0)
    setZoneId(id)
  }

  const getWarehouseZone = useCallback(() => service.getWarehouseZone(workshopName, zoneId), [
    zoneId,
    currentPage,
  ])

  const { execute, status, value, error } = useAsync<{
    content: WarehouseRowsData<Attributes>[]
    totalPages: number
  }>(getWarehouseZone, false, false)

  useEffect(() => {
    if (value) {
      setTotalPages(value.totalPages)
    }
  }, [value])

  const tonValue =
    value?.content?.reduce((acc, item) => {
      return acc + Number(item.attributes[totalWeightField])
    }, 0) || 0

  useEffect(() => {
    execute()
  }, [zoneId, currentPage])

  const tableRows = useMemo(
    () =>
      value?.content?.map((row) => {
        const weights = getWeights(row.attributes, weightFields, (weightValue) =>
          convertKilosToTons(Number(weightValue)),
        )
        return {
          ...row.attributes,
          ...weights,
          id: row.id,
          zone: zoneName,
        }
      }) ?? [],
    [value, zoneName],
  )

  const onChangeUnit = useCallback((rowsId: Array<number>) => {
    setWireId(rowsId)
    setIsOpenModal(true)
  }, [])

  // const setWireRodInventory = async () => {
  //   const wireIds = controlStatus.value === STATUS_INVENTORY.compliant ? checked.map((item) => item.id) : wiresId;
  //   const unitType = workshopName === 'ugv' ? 'wire_rod' : 'wire';
  //   try {
  //     await service.setInventoryWireRod(wireIds, controlStatus, unitType);
  //     alert.success('Статус изменен');
  //     setIsOpenConfirm(false);
  //     execute();
  //     onCheckedChange([]);
  //   } catch (e) {
  //     alert.error(String(e));
  //   }
  // };

  // const ModalConfirmMemo = useMemo(() => {
  //   return (
  //     <ModalConfirm
  //       title={
  //         controlStatus.value === STATUS_INVENTORY.compliant
  //           ? 'Инвентаризировать выбранныe?'
  //           : 'Изменить на статус потеряна?'
  //       }
  //       isOpen={isOpenConfirm}
  //       setIsOpen={setIsOpenConfirm}
  //       handleOk={setWireRodInventory}
  //     />
  //   );
  // }, [isOpenConfirm, controlStatus]);

  // const ModalAddMemo = useMemo(() => {
  //   return (
  //     <ModalAdd
  //       isOpen={isOpenAddModal}
  //       setIsOpen={setIsOpenAddModal}
  //       zoneId={zoneId}
  //       getWireRod={execute}
  //     />
  //   );
  // }, [isOpenAddModal]);

  const ModalPrintMemo = useMemo(() => {
    return <ModalPrint wireRow={wireRow} setIsOpen={setIsModalPrint} isOpen={isModalPrint} />
  }, [isModalPrint, wireRow])

  const manager = useMemo(() => managers[getReducerKey(workshopName)], [workshopName])

  const columns = useSelector((state: State) => {
    console.log('state')
    console.log(state[getReducerKey(workshopName)].params)
    return state[getReducerKey(workshopName)].params.viewTable as TableColumn<unknown>[]
  }, shallowEqual)
  const newColumns: TableColumn<any>[] = types.map((row) => {
    if (row.name === 'startStoreTime') {
      return {
        ...row,
        render: (row: Attributes) => {
          const date = getLocalDate(row.startStoreTime)
          return <>{date}</>
        },
      }
    }
    if (row.name === 'controls') {
      console.log('controls')
      return {
        ...row,
        visible: checked.length === 0,
        render: (row: Attributes) => {
          if (checked.length === 0) {
            return (
              <Box spacing={2}>
                <Button
                  onClick={() => {
                    setWireRow([row])
                    setIsModalPrint(true)
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Печать
                </Button>
                {role === 'inventory' && row.status_inventory !== STATUS_INVENTORY.lost && (
                  <Button
                    onClick={() => {
                      setWireId([row.id])
                      setIsOpenConfirm(true)
                      // setControlStatus(getStatusInventoryDictionaryValue('lost'));
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Потеряна
                  </Button>
                )}
                <Button
                  onClick={() => onChangeUnit([row.id])}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Переместить
                </Button>
              </Box>
            )
          }
          return null
        },
      }
    }
    return row
  })

  // const newColumns = useMemo(
  //   () =>
  //   columns.map((item) => {
  //     console.log(item)
  //       const { name } = item

  //       if (item.name === 'startStoreTime') {
  //         return {
  //           ...item,
  //           render: (row: Attributes) => {
  //             const date = getLocalDate(row.startStoreTime)
  //             return <>{date}</>
  //           },
  //         }
  //       }
  //       if (name === 'weight') {
  //         return {
  //           ...item,
  //           render: (row: Attributes) => (row.remaining_weight ? row.remaining_weight : row.weight),
  //         }
  //       }
  //       if (name === 'statusOTK') {
  //         return {
  //           ...item,
  //           render: (row: Attributes) => {
  //             if (row.statusOTK) {
  //               return row.statusOTK === 'Годен' ? (
  //                 <CompetedSvgIcon color="primary" />
  //               ) : (
  //                 <NoDataSvgIcon color="error" />
  //               )
  //             }
  //             return '-'
  //           },
  //         }
  //       }
  //       if (name === 'status_inventory') {
  //         return {
  //           ...item,
  //           render: (row: Attributes) => {
  //             return <> {row.status_inventory ?? '-'}</>
  //           },
  //         }
  //       }
  //       if (name === 'controls') {
  //         return {
  //           ...item,
  //           visible: checked.length === 0,
  //           render: (row: Attributes) => {
  //             if (checked.length === 0) {
  //               return (
  //                 <Box spacing={2}>
  //                   <Button
  //                     onClick={() => {
  //                       setWireRow([row])
  //                       setIsModalPrint(true)
  //                     }}
  //                     variant="contained"
  //                     color="primary"
  //                     size="small"
  //                   >
  //                     Печать
  //                   </Button>
  //                   {role === 'inventory' && row.status_inventory !== STATUS_INVENTORY.lost && (
  //                     <Button
  //                       onClick={() => {
  //                         setWireId([row.id])
  //                         setIsOpenConfirm(true)
  //                         // setControlStatus(getStatusInventoryDictionaryValue('lost'));
  //                       }}
  //                       variant="contained"
  //                       color="primary"
  //                       size="small"
  //                     >
  //                       Потеряна
  //                     </Button>
  //                   )}
  //                   <Button
  //                     onClick={() => onChangeUnit([row.id])}
  //                     variant="contained"
  //                     color="primary"
  //                     size="small"
  //                   >
  //                     Переместить
  //                   </Button>
  //                 </Box>
  //               )
  //             }
  //             return null
  //           },
  //         }
  //       }
  //       return item
  //     }),
  //   // [columns, checked.length, onChangeUnit, role, getStatusInventoryDictionaryValue]
  //   [columns, checked.length, onChangeUnit, role],
  // )

  const tableRowsSelections = useMemo(
    () => ({
      checkEnabled: role === 'inventory',
      checked,
      onCheckedChange,
    }),
    [checked],
  )

  if (error) {
    return (
      <Alert severity="error">
        Контент не загружен: <span>{error}</span>
      </Alert>
    )
  }
  return (
    <div>
      <Box flexDirection="column" spacing={1}>
        {isClickZone && (
          <Card>
            <LoaderWrapper isLoad={status === 'pending'}>
              <Table
                fixHeader
                hideNoDataMessage
                narrowColumns
                rowsSelection={tableRowsSelections}
                style={tableStyle}
                withTableToolBar
                enableColumnResizing
                manager={manager}
                rows={tableRows}
                columns={newColumns}
              />
              <Box p={1}>
                <Pagination
                  count={totalPages}
                  page={currentPage + 1}
                  onChange={(_, page) => setCurrentPage(page - 1)}
                />
              </Box>
            </LoaderWrapper>
            {role === 'inventory' && (
              <Box padding="20px 20px" justifyContent="space-between">
                <Box spacing={2} alignItems="center">
                  <Button
                    disabled={checked.length === 0}
                    onClick={() => {
                      // setControlStatus(getStatusInventoryDictionaryValue('compliant'));
                      setIsOpenConfirm(true)
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Инвентаризировать
                  </Button>
                  {checked.length !== 0 && (
                    <Typography variant="Body1-Medium">Выбрано: {checked.length} шт.</Typography>
                  )}
                </Box>
                {/* {workshopName === 'warehouse.inplast.output' && (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsOpenAddModal(true)}
                    >
                      Восстановить
                    </Button>
                  </Box>
                )} */}
              </Box>
            )}
          </Card>
        )}
        {isClickZone && (
          <Typography variant="Body1-Medium">
            Всего: {convertKilosToTons(tonValue)} тонн.
          </Typography>
        )}
        {/* <Box justifyContent="flex-end" spacing={2}>
          {isClickZone && (
            <Button onClick={() => setIsOpenModalRow(true)} variant="outlined" color="primary">
              Печать
            </Button>
          )}
        </Box> */}
        <Fullness
          setZoneName={setZoneName}
          workshopName={workshopName}
          onClickZone={handleClickZone}
        />
      </Box>
      {/* {isOpenModal && ModalMemo}
      {isOpenAddModal && ModalAddMemo}
      {role === 'inventory' && ModalConfirmMemo}
      {isOpenModalRow && ModalTableMemo} */}
      {isModalPrint && ModalPrintMemo}
    </div>
  )
}

export default WarehouseContainer
