import React from 'react'

import { TableFixedColumns as TableFixedColumnsBase } from '@devexpress/dx-react-grid'
import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui'

import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(() => ({
  FixedHeaderCell: {
    '&#table-fixed-cell': {
      zIndex: ({ isHeading }: { isHeading: boolean }) => (isHeading ? '301' : 'inherit'),
    },
  },
}))

export const TableFixedCell: React.ComponentType<TableFixedColumnsBase.CellProps> = (props) => {
  const isHeading = props.tableRow.key.includes('heading')
  const fixedHeaderId = isHeading ? 'table-fixed-cell' : ''
  const classes = useStyles({ isHeading })
  return (
    <TableFixedColumns.Cell id={fixedHeaderId} className={classes.FixedHeaderCell} {...props} />
  )
}
