import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardYellow = React.forwardRef<HTMLDivElement, CardProps>(({ highlighted, barcode }, ref) => {
  const referenceData: ReferenceDataType[] = [
    {
      title: '',
      data: {
        left: [
          { name: 'end_diameter', title: 'Диаметр, мм' },
          { name: 'steel_grade', title: 'Марка стали' },
          { name: 'aggregate', title: '№ стана/катушки' },
          { name: '', title: 'Масса, тн' },
          { name: 'date', title: 'Дата' },
        ],
        right: [
          { name: '', title: '№ плавки' },
          { name: '', title: 'Оператор' },
          { name: '', title: 'Тип смазки' },
          { name: 'brigade', title: 'Бригада' },
        ],
      },
    },
  ]

  const renderBlock = (data?: Block[]) => {
    if (!data) return null
    return data
      .map(({ name, title }) => {
        const newDescription: string =
          // @ts-ignore
          highlighted[0][name] !== undefined ? highlighted[0][name] : '____'
        return { title, description: newDescription }
      })
      .map(({ title, description }) => (
        <Box style={{ paddingBottom: '6px' }} justifyContent="space-between" key={title}>
          <Typography style={{ maxWidth: '120px' }} variant="Caption">
            {title}
          </Typography>
          <Typography variant="Caption">{description}</Typography>
        </Box>
      ))
  }

  return (
    <div className="print-block print-block_yellow" ref={ref}>
      <Box
        flexDirection="column"
        style={{
          position: 'relative',
        }}
      >
        {referenceData.map(({ data }, index) => (
          <div style={{ padding: '28px 85px 40px 57px' }} key={`title${index}`}>
            <Box marginBottom="10px" width="180px" justifyContent="space-between">
              <Typography variant="Caption">Назначение</Typography>
              <Typography variant="Caption-Bold">УОП</Typography>
            </Box>
            <Box justifyContent="space-between">
              {Object.values(data).map((value, i) => (
                <Box
                  style={{
                    width: '210px',
                    paddingRight: '10px',
                    marginRight: '10px',
                    borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                  }}
                  flexDirection="column"
                  key={i}
                >
                  {renderBlock(value)}
                </Box>
              ))}
            </Box>
          </div>
        ))}

        <div className={styles.barcodeContainer}>
          <Barcode value={barcode} />
        </div>
        <div className={styles.qrCodeContainer}>
          <QRCode value={String(barcode)} />
        </div>
      </Box>
    </div>
  )
})

export default CardYellow
