import DICTIONARY_WORK_STATUS from 'shared/enums/DictionaryWorkStatus'

export const dictionaryWorkStatus = {
  [DICTIONARY_WORK_STATUS.ALL]: 'Все',
  [DICTIONARY_WORK_STATUS.OPEN]: 'Открытые справочные значения',
  [DICTIONARY_WORK_STATUS.CLOSE]: 'Закрытые справочные значения',
  [DICTIONARY_WORK_STATUS.UNBIND]: 'Значения, которые не привязаны ни к одному справочнику',

  [DICTIONARY_WORK_STATUS.APPROVE]: 'Утвержденные справочные значения',
  [DICTIONARY_WORK_STATUS.ACTUAL]: 'Использующиеся справочные значения',
}
