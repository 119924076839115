import React, { useContext } from 'react'

import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useStyleProps } from '../utils/StyledProps'
import { Table as RGTable } from '@devexpress/dx-react-grid'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'

type TableHeaderRowProps = RGTable.RowProps & StyledProps

export const TableHeaderRow: React.FC<TableHeaderRowProps> = (props) => {
  const [className, style] = useStyleProps(props, useContext(CustomFieldsCtx).style?.headerRow)
  return <DevexpressTable.StubRow {...props} className={className} style={style} />
}
