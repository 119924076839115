import React, { useContext } from 'react'
import { arrayMove } from 'react-sortable-hoc'

import { Button } from '../../../../Button'
import { Drawer } from '../../../../Drawer'
import { SortableItems } from '../../../../SortableItems/SortableItems'
import { Typography } from '../../../../Typography/Typography'
import BaseToolbarFooter from '../../../common/blocks/Footer'
import HintInit from '../../../common/blocks/HintInit'
import { HintFilter } from '../../../common/constants/hint'
import FilterItem from '../components/FilterItem'
import { FilterContext } from '../context'
import { IFilterContext } from '../typings'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'

export interface ListFilterTabProps {
  onSave: () => void
  isChangedState: boolean
}

const ListFilterTab: React.FC<ListFilterTabProps> = (props) => {
  const {
    filter,
    visible,
    onClose,
    setFilter,
    setTab,
    initHint,
    setInitHint,
  } = useContext<IFilterContext>(FilterContext)

  return (
    <Drawer
      header={{
        title: 'Фильтрация',
        tooltip: 'Здесь вы можете выстроить фильтрацию по столбцам в нужном порядке.',
        visibleTooltipIcon: !initHint,
      }}
      footer={{
        render: (
          <BaseToolbarFooter
            onClose={onClose}
            onSave={props.onSave}
            length={filter.length}
            disabled={!filter.length && !props.isChangedState}
          />
        ),
      }}
      open={visible}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        {initHint && (
          <HintInit onClose={() => setInitHint(false)}>
            <HintFilter />
          </HintInit>
        )}
        <SortableItems
          onItemsChange={setFilter}
          useDragHandle
          onSortEnd={({ oldIndex, newIndex }) => {
            setFilter(arrayMove(filter, oldIndex, newIndex))
          }}
          onRenderItem={(item, id) => (
            <FilterItem
              item={item}
              onDelete={() => setFilter(filter.filter((_, i) => i !== id))}
              onEdit={() => setTab({ tab: 1, edit: filter[id] })}
            />
          )}
          items={filter}
        />

        <Grid item xs={12} style={{ marginTop: filter.length ? '32px' : '0px' }}>
          <Button
            startIcon={<AddIcon color="primary" />}
            variant="text"
            onClick={() => setTab({ tab: 1 })}
          >
            <Typography variant="Body1-Medium" color="primary">
              Добавить фильтр
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default ListFilterTab
