import { makeStyles } from '@material-ui/core'

export const useDatePickerStyles = makeStyles(() => ({
  root: {
    userSelect: 'none',
    transition: '0.2s',
    width: '100%',
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
  },
  isOpen: {
    zIndex: 99999,
  },
}))
