import React from 'react'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import { Provider } from 'react-redux'

import { store } from '../src/store'
import Main from './pages/main/main'
import { CommonWrapper } from './ui-kit/components/CommonWrapper'
import AlertTemplate from 'components/AlertTemplate'
import AuthRedirect from 'components/AuthRedirect/AuthRedirect'
import service from 'services/service'
import { Passport } from 'ui-kit/components/Passport'

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 1500,
  },
}
function App() {
  return (
    <CommonWrapper>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Passport onTokenChange={(token) => service.setToken(token)}>
            <AuthRedirect>
              <Main />
            </AuthRedirect>
          </Passport>
        </AlertProvider>
      </Provider>
    </CommonWrapper>
  )
}

export default App
