import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconChevronLeft32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M21 9.9975L14.2011 16.5L21 23.0025L18.9069 25L10 16.5L18.9069 8L21 9.9975Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconChevronLeft32
