import React, { FC } from 'react'

import { DialogActionsProps, DialogActions as MUIDialogActions } from '@material-ui/core'

import { useDialogActionsStyles } from './styles'

export const DialogActions: FC<DialogActionsProps> = ({ ...props }) => {
  const classes = useDialogActionsStyles()

  return (
    <MUIDialogActions
      classes={{
        root: classes.root,
        spacing: classes.spacing,
      }}
      {...props}
    />
  )
}
