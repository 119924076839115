import { makeStyles } from '@material-ui/core'
import { Theme } from '@storybook/theming'

import { PseudoInputProps } from './types'

export const usePseudoInputStyles = makeStyles<Theme, PseudoInputProps>((theme: Theme) => ({
  root: ({ longLabel }) => ({
    height: longLabel ? 'auto' : '40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  label: ({ size }) => ({
    fontSize:
      size === 'l' ? theme.textStyles.body1medium.fontSize : theme.textStyles.body2medium.fontSize,
    lineHeight:
      size === 'l'
        ? theme.textStyles.body1medium.fontSize
        : theme.textStyles.body2medium.lineHeight,
    fontWeight:
      size === 'l'
        ? theme.textStyles.body1medium.fontSize
        : theme.textStyles.body2medium.fontWeight,
    color: theme.palette.text.disabled,
    top: '-8px',
  }),
  text: ({ size }) => ({
    fontSize:
      size === 'l'
        ? theme.textStyles.subheading3medium.fontSize
        : theme.textStyles.body1medium.fontSize,
    lineHeight:
      size === 'l'
        ? theme.textStyles.subheading3medium.fontSize
        : theme.textStyles.body1medium.lineHeight,
    fontWeight:
      size === 'l'
        ? theme.textStyles.subheading3medium.fontSize
        : theme.textStyles.body1medium.fontWeight,
    display: 'flex',
  }),
}))
