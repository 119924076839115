import { makeStyles } from '@material-ui/core'

export const tooltipStyled = makeStyles((theme) => ({
  tooltip: ({ nonContent }: { nonContent: boolean | number }) => {
    return {
      padding: '8px 12px',
      backgroundColor: theme.palette.text.primary,
      borderRadius: 4,
      color: theme.palette.background.paper,
      maxWidth: '400px',
      display: nonContent ? 'none' : 'flex',
      flexDirection: nonContent ? 'row' : 'column',
      '& .title + *': {
        paddingTop: 8,
      },
    }
  },
  ul: {
    paddingLeft: '18px',
    paddingRight: '10px',
    margin: 0,
    color: theme.palette.background.paper,
    fontSize: '0.875rem',
  },
  light: {},
  dark: {},
}))
