import { ControlsColumns, TableColumnWidthInfo } from '../../interfaces/Table'
import { TableColumn, TableState } from '../components'

class ViewTableManagerClass {
  public update = (key: string, viewTable: TableState['params']['viewTable']): void => {
    const value = viewTable.map((column) =>
      Object.entries(column).reduce((acc = {}, [key, value]) => {
        if (typeof value !== 'function') {
          return {
            ...acc,
            [key]: value,
          }
        }
        return acc
      }, {}),
    )
    localStorage.setItem(key, JSON.stringify(value))
  }

  public getUpdatedColumns = (
    viewTable: TableState['params']['viewTable'],
    updatedWidths: TableColumnWidthInfo<unknown>[],
  ): TableState['params']['viewTable'] => {
    const mapUpdatedWidths = updatedWidths.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.columnName]: curr.width,
      }),
      {} as Record<
        TableColumnWidthInfo<unknown>['columnName'],
        TableColumnWidthInfo<unknown>['width']
      >,
    )
    const updatedColumns = viewTable.map((column) => {
      const updatedColumn = { ...column }
      if (mapUpdatedWidths?.[column.name]) updatedColumn.width = mapUpdatedWidths?.[column.name]
      return updatedColumn
    })
    return updatedColumns
  }

  public get = (key: string): TableState['params']['viewTable'] =>
    JSON.parse(localStorage.getItem(key) || '[]') as TableState['params']['viewTable']

  public merge = <R, C extends TableColumn<unknown>>(
    key: string,
    columns: TableState,
    controlsColumns: ControlsColumns<R, C> = ['controls'],
  ): TableState => {
    const columnsNames = columns.params.viewTable.map(({ name }) => name)

    const savedColumns = this.get(key)

    const mapIndexSavedColumns = savedColumns.reduce(
      (acc, curr, index) => ({
        ...acc,
        [curr.name]: index,
      }),
      {},
    ) as Record<string, number>

    const getIndex = (name: C['name']) => {
      if (controlsColumns?.includes(name)) return 2000
      return mapIndexSavedColumns?.[name] ?? 1000
    }

    const mapSavedColumns = savedColumns
      .filter(({ name }) => columnsNames.includes(name))
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: curr,
        }),
        {},
      ) as Record<string, TableColumn<unknown>>

    const mergedViewTable = columns.params.viewTable
      .map((column) => {
        const mergedColumn = {
          ...column,
          pinned: mapSavedColumns?.[column.name]?.pinned ?? column.pinned,
          visible: mapSavedColumns?.[column.name]?.visible ?? column.visible,
        }
        if (mapSavedColumns?.[column.name]?.width !== undefined || column.width !== undefined) {
          mergedColumn.width = mapSavedColumns?.[column.name]?.width ?? column.width
        }
        return mergedColumn
      })
      .sort((a, b) => getIndex(a.name) - getIndex(b.name))

    this.update(key, mergedViewTable)

    return {
      ...columns,
      params: {
        ...columns.params,
        viewTable: mergedViewTable,
      },
    }
  }

  public clear = (key: string): void => {
    localStorage.removeItem(key)
  }
}

const ViewTableManager = new ViewTableManagerClass()

export default ViewTableManager
