import React, { PropsWithChildren, useCallback, useContext } from 'react'

import '../../css/index.css'
import useLocalStorage from '../../hooks/useLocalStorage'
import themeOption from './tokens/themeOption.json'
import token from './tokens/tokens.json'
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core'
import mergeDeepRight from 'ramda/src/mergeDeepRight'

import { ITokens } from './types'

import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

export type { Theme } from '@material-ui/core'

export const tokens: ITokens = {
  file: {
    borderRadius: token.radius['4'].value,
    spacing16: token.spacing['16'].value,
    spacing4: token.spacing['4'].value,
  },
  attachFiles: {
    borderRadius: token.radius['4'].value,
    spacing16: token.spacing['16'].value,
    spacing32: token.spacing['32'].value,
  },
  button: {
    sizeDefaultHeight: token.sizing['40'].value,
    sizeCompactHeight: token.sizing['28'].value,
    fontFamily: token['main-typography']['fontFamilies']['pt-root-ui'].value,
    fontWeight: token['main-typography']['fontWeights']['pt-root-ui-0'].value,
    lineHeight: token['main-typography']['lineHeights']['4'].value,
    fontSize: token['main-typography']['fontSize']['2'].value,
    border: token.sizing['2'].value,
    borderRadius: token.radius['4'].value,
    labelSpacing: token.spacing['12'].value,
    labelSpacingCompact: token.spacing['2'].value,
    iconSpacing: token.spacing['4'].value,
  },
}

const CommonWrapperContext = React.createContext<{
  theme: string
  setTheme: (theme: 'light' | 'dark') => void
  tokens: ITokens
}>({
  setTheme: () => void 0,
  theme: 'light',
  tokens,
})
const lightThemeOptions: ThemeOptions = {
  buttonTextColor: themeOption.lightTheme.buttonTextColor,
  palette: {
    primary: {
      50: themeOption.lightTheme.palette.primary['50'],
      100: themeOption.lightTheme.palette.primary['100'],
      200: themeOption.lightTheme.palette.primary['200'],
      300: themeOption.lightTheme.palette.primary['300'],
      400: themeOption.lightTheme.palette.primary['400'],
      500: themeOption.lightTheme.palette.primary['500'],
      600: themeOption.lightTheme.palette.primary['600'],
      700: themeOption.lightTheme.palette.primary['700'],
      800: themeOption.lightTheme.palette.primary['800'],
      900: themeOption.lightTheme.palette.primary['900'],
      main: themeOption.lightTheme.palette.primary.main,
      light: themeOption.lightTheme.palette.primary.light,
      dark: themeOption.lightTheme.palette.primary.dark,
      contrastText: themeOption.lightTheme.palette.primary.contrastText,
    },
    secondary: {
      main: themeOption.lightTheme.palette.secondary.main,
      light: themeOption.lightTheme.palette.secondary.light,
      dark: themeOption.lightTheme.palette.secondary.dark,
    },
    error: {
      50: themeOption.lightTheme.palette.error['50'],
      100: themeOption.lightTheme.palette.error['100'],
      200: themeOption.lightTheme.palette.error['200'],
      300: themeOption.lightTheme.palette.error['300'],
      400: themeOption.lightTheme.palette.error['400'],
      500: themeOption.lightTheme.palette.error['500'],
      600: themeOption.lightTheme.palette.error['600'],
      700: themeOption.lightTheme.palette.error['700'],
      800: themeOption.lightTheme.palette.error['800'],
      900: themeOption.lightTheme.palette.error['900'],
      main: themeOption.lightTheme.palette.error.main,
      light: themeOption.lightTheme.palette.error.light,
      dark: themeOption.lightTheme.palette.error.dark,
    },
    warning: {
      50: themeOption.lightTheme.palette.warning['50'],
      100: themeOption.lightTheme.palette.warning['100'],
      200: themeOption.lightTheme.palette.warning['200'],
      300: themeOption.lightTheme.palette.warning['300'],
      400: themeOption.lightTheme.palette.warning['400'],
      500: themeOption.lightTheme.palette.warning['500'],
      600: themeOption.lightTheme.palette.warning['600'],
      700: themeOption.lightTheme.palette.warning['700'],
      800: themeOption.lightTheme.palette.warning['800'],
      900: themeOption.lightTheme.palette.warning['900'],
      A100: themeOption.lightTheme.palette.warning.A100,
      main: themeOption.lightTheme.palette.warning.main,
      light: themeOption.lightTheme.palette.warning.light,
      dark: themeOption.lightTheme.palette.warning.dark,
    },
    success: {
      50: themeOption.lightTheme.palette.success['50'],
      100: themeOption.lightTheme.palette.success['100'],
      200: themeOption.lightTheme.palette.success['200'],
      300: themeOption.lightTheme.palette.success['300'],
      400: themeOption.lightTheme.palette.success['400'],
      500: themeOption.lightTheme.palette.success['500'],
      600: themeOption.lightTheme.palette.success['600'],
      700: themeOption.lightTheme.palette.success['700'],
      800: themeOption.lightTheme.palette.success['800'],
      900: themeOption.lightTheme.palette.success['900'],
      main: themeOption.lightTheme.palette.success.main,
      light: themeOption.lightTheme.palette.success.light,
      dark: themeOption.lightTheme.palette.success.dark,
    },
    text: {
      primary: themeOption.lightTheme.palette.text.primary,
      secondary: themeOption.lightTheme.palette.text.secondary,
      hint: themeOption.lightTheme.palette.text.hint,
      disabled: themeOption.lightTheme.palette.text.disabled,
    },
    info: {
      50: themeOption.lightTheme.palette.info['50'],
      100: themeOption.lightTheme.palette.info['100'],
      200: themeOption.lightTheme.palette.info['200'],
      300: themeOption.lightTheme.palette.info['300'],
      400: themeOption.lightTheme.palette.info['400'],
      500: themeOption.lightTheme.palette.info['500'],
      600: themeOption.lightTheme.palette.info['600'],
      700: themeOption.lightTheme.palette.info['700'],
      800: themeOption.lightTheme.palette.info['800'],
      900: themeOption.lightTheme.palette.info['900'],
      dark: themeOption.lightTheme.palette.info.dark,
      main: themeOption.lightTheme.palette.info.main,
      light: themeOption.lightTheme.palette.info.light,
    },
    grey: {
      50: themeOption.lightTheme.palette.grey['50'],
      100: themeOption.lightTheme.palette.grey['100'],
      200: themeOption.lightTheme.palette.grey['200'],
      300: themeOption.lightTheme.palette.grey['300'],
      400: themeOption.lightTheme.palette.grey['400'],
      500: themeOption.lightTheme.palette.grey['500'],
      600: themeOption.lightTheme.palette.grey['600'],
      700: themeOption.lightTheme.palette.grey['700'],
      800: themeOption.lightTheme.palette.grey['800'],
      900: themeOption.lightTheme.palette.grey['900'],
    },
    background: {
      paper: themeOption.lightTheme.palette.background.paper,
      default: themeOption.lightTheme.palette.background.default,
    },
    action: {
      disabled: themeOption.lightTheme.palette.action.disabled,
      disabledBackground: themeOption.lightTheme.palette.action.disabledBackground,
      selected: themeOption.lightTheme.palette.action.selected,
      hover: themeOption.lightTheme.palette.action.hover,
      active: themeOption.lightTheme.palette.action.active,
    },
    divider: themeOption.lightTheme.palette.divider.divider,
  },
  errorLight: {
    main: themeOption.lightTheme.errorLight.main,
    light: themeOption.lightTheme.errorLight.light,
    dark: themeOption.lightTheme.errorLight.dark,
    contrastText: themeOption.lightTheme.errorLight.contrastText,
  },
  successLight: {
    main: themeOption.lightTheme.successLight.main,
    light: themeOption.lightTheme.successLight.light,
    dark: themeOption.lightTheme.successLight.dark,
    contrastText: themeOption.lightTheme.successLight.contrastText,
  },
  warningLight: {
    main: themeOption.lightTheme.warningLight.main,
    light: themeOption.lightTheme.warningLight.light,
    dark: themeOption.lightTheme.warningLight.dark,
    contrastText: themeOption.lightTheme.warningLight.contrastText,
  },
  menu: {
    dark: themeOption.lightTheme.menu.dark,
    main: themeOption.lightTheme.menu.main,
    light: themeOption.lightTheme.menu.light,
    selected: themeOption.lightTheme.menu.selected,
    submenu: themeOption.lightTheme.menu.submenu,
  },
  neutral: {
    black: themeOption.lightTheme.neutral.black,
    white: themeOption.lightTheme.neutral.white,
    noChangeBlack: themeOption.lightTheme.neutral.noChangeBlack,
    noChangeWhite: themeOption.lightTheme.neutral.noChangeWhite,
    grey900: themeOption.lightTheme.neutral.grey900,
    grey800: themeOption.lightTheme.neutral.grey800,
    grey700: themeOption.lightTheme.neutral.grey700,
    grey600: themeOption.lightTheme.neutral.grey600,
    grey500: themeOption.lightTheme.neutral.grey500,
    grey400: themeOption.lightTheme.neutral.grey400,
    grey300: themeOption.lightTheme.neutral.grey300,
    grey200: themeOption.lightTheme.neutral.grey200,
    grey100: themeOption.lightTheme.neutral.grey100,
    grey50: themeOption.lightTheme.neutral.grey50,
  },
  tableErrorBG: {
    dark: themeOption.lightTheme.tableErrorBG.dark,
    main: themeOption.lightTheme.tableErrorBG.main,
    light: themeOption.lightTheme.tableErrorBG.light,
  },
  tableSuccessBG: {
    icon: themeOption.lightTheme.tableSuccessBG.icon,
    dark: themeOption.lightTheme.tableSuccessBG.dark,
    main: themeOption.lightTheme.tableSuccessBG.main,
    light: themeOption.lightTheme.tableSuccessBG.light,
  },
  tableWarningBG: {
    icon: themeOption.lightTheme.tableWarningBG.icon,
    dark: themeOption.lightTheme.tableWarningBG.dark,
    main: themeOption.lightTheme.tableWarningBG.main,
    light: themeOption.lightTheme.tableWarningBG.light,
  },
  tableDefaultBG: {
    dark: themeOption.lightTheme.tableDefaultBG.dark,
    main: themeOption.lightTheme.tableDefaultBG.main,
    light: themeOption.lightTheme.tableDefaultBG.light,
  },
  textStyles: {
    heading1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['0'],
      fontSize: themeOption.typography.fontSize['6'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['1'],
      fontSize: themeOption.typography.fontSize['5'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['1'],
      fontSize: themeOption.typography.fontSize['5'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading2medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading3: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading3: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading3medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading4: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1MonoMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1MonoBold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1bold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2MonoMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2MonoBold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2bold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    caption: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['6'],
      fontSize: themeOption.typography.fontSize['0'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    captionMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['6'],
      fontSize: themeOption.typography.fontSize['0'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    captionBold: {
      fontSize: themeOption.typography.fontSize['0'],
      lineHeight: themeOption.typography.lineHeights['6'],
      fontWeight: 700,
      fontStyle: 'normal',
      fontFeatureSettings: 'normal',
    },
  },
  typography: {
    fontFamily: themeOption.typography.fontFamily,
  },
}

const darkThemeOptions: ThemeOptions = {
  buttonTextColor: themeOption.lightTheme.buttonTextColor,
  palette: {
    primary: {
      50: themeOption.darkTheme.palette.primary['50'],
      100: themeOption.darkTheme.palette.primary['100'],
      200: themeOption.darkTheme.palette.primary['200'],
      300: themeOption.darkTheme.palette.primary['300'],
      400: themeOption.darkTheme.palette.primary['400'],
      500: themeOption.darkTheme.palette.primary['500'],
      600: themeOption.darkTheme.palette.primary['600'],
      700: themeOption.darkTheme.palette.primary['700'],
      800: themeOption.darkTheme.palette.primary['800'],
      900: themeOption.darkTheme.palette.primary['900'],
      main: themeOption.darkTheme.palette.primary.main,
      light: themeOption.darkTheme.palette.primary.light,
      dark: themeOption.darkTheme.palette.primary.dark,
      contrastText: themeOption.darkTheme.palette.primary.contrastText,
    },
    secondary: {
      main: themeOption.darkTheme.palette.secondary.main,
      light: themeOption.darkTheme.palette.secondary.light,
      dark: themeOption.darkTheme.palette.secondary.dark,
    },
    error: {
      50: themeOption.darkTheme.palette.error['50'],
      100: themeOption.darkTheme.palette.error['100'],
      200: themeOption.darkTheme.palette.error['200'],
      300: themeOption.darkTheme.palette.error['300'],
      400: themeOption.darkTheme.palette.error['400'],
      500: themeOption.darkTheme.palette.error['500'],
      600: themeOption.darkTheme.palette.error['600'],
      700: themeOption.darkTheme.palette.error['700'],
      800: themeOption.darkTheme.palette.error['800'],
      900: themeOption.darkTheme.palette.error['900'],
      main: themeOption.darkTheme.palette.error.main,
      light: themeOption.darkTheme.palette.error.light,
      dark: themeOption.darkTheme.palette.error.dark,
    },
    warning: {
      50: themeOption.darkTheme.palette.warning['50'],
      100: themeOption.darkTheme.palette.warning['100'],
      200: themeOption.darkTheme.palette.warning['200'],
      300: themeOption.darkTheme.palette.warning['300'],
      400: themeOption.darkTheme.palette.warning['400'],
      500: themeOption.darkTheme.palette.warning['500'],
      600: themeOption.darkTheme.palette.warning['600'],
      700: themeOption.darkTheme.palette.warning['700'],
      800: themeOption.darkTheme.palette.warning['800'],
      900: themeOption.darkTheme.palette.warning['900'],
      A100: themeOption.darkTheme.palette.warning.A100,
      main: themeOption.darkTheme.palette.warning.main,
      light: themeOption.darkTheme.palette.warning.light,
      dark: themeOption.darkTheme.palette.warning.dark,
    },
    success: {
      50: themeOption.darkTheme.palette.success['50'],
      100: themeOption.darkTheme.palette.success['100'],
      200: themeOption.darkTheme.palette.success['200'],
      300: themeOption.darkTheme.palette.success['300'],
      400: themeOption.darkTheme.palette.success['400'],
      500: themeOption.darkTheme.palette.success['500'],
      600: themeOption.darkTheme.palette.success['600'],
      700: themeOption.darkTheme.palette.success['700'],
      800: themeOption.darkTheme.palette.success['800'],
      900: themeOption.darkTheme.palette.success['900'],
      main: themeOption.darkTheme.palette.success.main,
      light: themeOption.darkTheme.palette.success.light,
      dark: themeOption.darkTheme.palette.success.dark,
    },
    text: {
      primary: themeOption.darkTheme.palette.text.primary,
      secondary: themeOption.darkTheme.palette.text.secondary,
      hint: themeOption.darkTheme.palette.text.hint,
      disabled: themeOption.darkTheme.palette.text.disabled,
    },
    info: {
      50: themeOption.darkTheme.palette.info['50'],
      100: themeOption.darkTheme.palette.info['100'],
      200: themeOption.darkTheme.palette.info['200'],
      300: themeOption.darkTheme.palette.info['300'],
      400: themeOption.darkTheme.palette.info['400'],
      500: themeOption.darkTheme.palette.info['500'],
      600: themeOption.darkTheme.palette.info['600'],
      700: themeOption.darkTheme.palette.info['700'],
      800: themeOption.darkTheme.palette.info['800'],
      900: themeOption.darkTheme.palette.info['900'],
      dark: themeOption.darkTheme.palette.info.dark,
      main: themeOption.darkTheme.palette.info.main,
      light: themeOption.darkTheme.palette.info.light,
    },
    grey: {
      50: themeOption.darkTheme.palette.grey['50'],
      100: themeOption.darkTheme.palette.grey['100'],
      200: themeOption.darkTheme.palette.grey['200'],
      300: themeOption.darkTheme.palette.grey['300'],
      400: themeOption.darkTheme.palette.grey['400'],
      500: themeOption.darkTheme.palette.grey['500'],
      600: themeOption.darkTheme.palette.grey['600'],
      700: themeOption.darkTheme.palette.grey['700'],
      800: themeOption.darkTheme.palette.grey['800'],
      900: themeOption.darkTheme.palette.grey['900'],
    },
    background: {
      paper: themeOption.darkTheme.palette.background.paper,
      default: themeOption.darkTheme.palette.background.default,
    },
    action: {
      disabled: themeOption.darkTheme.palette.action.disabled,
      selected: themeOption.darkTheme.palette.action.selected,
      hover: themeOption.darkTheme.palette.action.hover,
    },
    divider: themeOption.darkTheme.palette.divider.divider,
  },
  errorLight: {
    main: themeOption.darkTheme.errorLight.main,
    light: themeOption.darkTheme.errorLight.light,
    dark: themeOption.darkTheme.errorLight.dark,
    contrastText: themeOption.darkTheme.errorLight.contrastText,
  },
  successLight: {
    main: themeOption.darkTheme.successLight.main,
    light: themeOption.darkTheme.successLight.light,
    dark: themeOption.darkTheme.successLight.dark,
    contrastText: themeOption.darkTheme.successLight.contrastText,
  },
  warningLight: {
    main: themeOption.darkTheme.warningLight.main,
    light: themeOption.darkTheme.warningLight.light,
    dark: themeOption.darkTheme.warningLight.dark,
    contrastText: themeOption.darkTheme.warningLight.contrastText,
  },
  menu: {
    dark: themeOption.darkTheme.menu.dark,
    main: themeOption.darkTheme.menu.main,
    light: themeOption.darkTheme.menu.light,
    selected: themeOption.darkTheme.menu.selected,
    submenu: themeOption.darkTheme.menu.submenu,
  },
  neutral: {
    black: themeOption.darkTheme.neutral.black,
    white: themeOption.darkTheme.neutral.white,
    noChangeBlack: themeOption.darkTheme.neutral.noChangeBlack,
    noChangeWhite: themeOption.darkTheme.neutral.noChangeWhite,
    grey900: themeOption.darkTheme.neutral.grey900,
    grey800: themeOption.darkTheme.neutral.grey800,
    grey700: themeOption.darkTheme.neutral.grey700,
    grey600: themeOption.darkTheme.neutral.grey600,
    grey500: themeOption.darkTheme.neutral.grey500,
    grey400: themeOption.darkTheme.neutral.grey400,
    grey300: themeOption.darkTheme.neutral.grey300,
    grey200: themeOption.darkTheme.neutral.grey200,
    grey100: themeOption.darkTheme.neutral.grey100,
    grey50: themeOption.darkTheme.neutral.grey50,
  },
  tableErrorBG: {
    dark: themeOption.darkTheme.tableErrorBG.dark,
    main: themeOption.darkTheme.tableErrorBG.main,
    light: themeOption.darkTheme.tableErrorBG.light,
  },
  tableSuccessBG: {
    icon: themeOption.darkTheme.tableSuccessBG.icon,
    dark: themeOption.darkTheme.tableSuccessBG.dark,
    main: themeOption.darkTheme.tableSuccessBG.main,
    light: themeOption.darkTheme.tableSuccessBG.light,
  },
  tableWarningBG: {
    icon: themeOption.darkTheme.tableWarningBG.icon,
    dark: themeOption.darkTheme.tableWarningBG.dark,
    main: themeOption.darkTheme.tableWarningBG.main,
    light: themeOption.darkTheme.tableWarningBG.light,
  },
  tableDefaultBG: {
    dark: themeOption.darkTheme.tableDefaultBG.dark,
    main: themeOption.darkTheme.tableDefaultBG.main,
    light: themeOption.darkTheme.tableDefaultBG.light,
  },
  textStyles: {
    heading1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['0'],
      fontSize: themeOption.typography.fontSize['6'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['1'],
      fontSize: themeOption.typography.fontSize['5'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['1'],
      fontSize: themeOption.typography.fontSize['5'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading2medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading3: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['2'],
      fontSize: themeOption.typography.fontSize['4'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading3: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    subheading3medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    heading4: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['3'],
      fontSize: themeOption.typography.fontSize['3'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1MonoMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1MonoBold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body1bold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['4'],
      fontSize: themeOption.typography.fontSize['2'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2medium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2MonoMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2MonoBold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    body2bold: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 700,
      lineHeight: themeOption.typography.lineHeights['5'],
      fontSize: themeOption.typography.fontSize['1'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    caption: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 400,
      lineHeight: themeOption.typography.lineHeights['6'],
      fontSize: themeOption.typography.fontSize['0'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    captionMedium: {
      fontFamily: themeOption.typography.fontFamily,
      fontWeight: 500,
      lineHeight: themeOption.typography.lineHeights['6'],
      fontSize: themeOption.typography.fontSize['0'],
      letterSpacing: +themeOption.typography.letterSpacing['0'],
      paragraphSpacing: +themeOption.typography.paragraphSpacing['0'],
      textCase: 'none',
      textDecoration: 'none',
    },
    captionBold: {
      fontSize: themeOption.typography.fontSize['0'],
      lineHeight: themeOption.typography.lineHeights['6'],
      fontWeight: 700,
      fontStyle: 'normal',
      fontFeatureSettings: 'normal',
    },
  },
  typography: {
    fontFamily: themeOption.typography.fontFamily,
  },
}

interface ICommonWrapperProps {
  theme?: 'light' | 'dark'
  lightThemeOptions?: Partial<ThemeOptions>
  darkThemeOptions?: Partial<ThemeOptions>
}

export const CommonWrapper: React.FC<ICommonWrapperProps & PropsWithChildren> = ({
  theme: initialTheme,
  lightThemeOptions: lightOptionThemeProp = {},
  darkThemeOptions: darkThemeOptionsProp = {},
  children,
}) => {
  const [theme, setTheme] = useLocalStorage<string>('theme', initialTheme || 'light')
  const fullLightTheme = createMuiTheme(mergeDeepRight(lightThemeOptions, lightOptionThemeProp))
  const fullDarkTheme = createMuiTheme(mergeDeepRight(darkThemeOptions, darkThemeOptionsProp))

  return (
    <StylesProvider injectFirst>
      <CommonWrapperContext.Provider value={{ theme, setTheme, tokens }}>
        <ThemeProvider theme={theme === 'dark' ? fullDarkTheme : fullLightTheme}>
          {children}
        </ThemeProvider>
      </CommonWrapperContext.Provider>
    </StylesProvider>
  )
}

export const useChangeCurrentTheme = () => {
  const { theme, setTheme } = useContext(CommonWrapperContext)
  return useCallback(() => setTheme(theme === 'light' ? 'dark' : 'light'), [theme, setTheme])
}

export const useGetCurrentTheme = () => {
  const { theme } = useContext(CommonWrapperContext)
  return theme
}

export const useTokens = () => {
  const { tokens } = useContext(CommonWrapperContext)
  return tokens
}
