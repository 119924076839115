import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconHelmet32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M26.5937 20.7644V18.769C26.5937 16.4896 25.8472 14.323 24.435 12.5036C23.1814 10.8886 21.4481 9.64996 19.5129 8.97553C19.4591 7.87723 18.5348 7 17.4061 7H14.5936C13.465 7 12.5407 7.87723 12.4868 8.97553C10.5516 9.64996 8.81838 10.8886 7.56479 12.5036C6.15252 14.323 5.40603 16.4896 5.40603 18.769V20.7644C4 20.7644 3.99999 20.7644 4 22.882C4.00001 24.9996 3.99999 24.9996 6.10916 24.9996H25.8906C28.0002 24.9996 27.9998 25.081 28 22.9227C28.0002 20.7644 28 20.7644 26.5937 20.7644ZM6.8123 18.769C6.8123 16.2725 7.8954 13.9355 9.6717 12.2681V14.6152H11.078V11.1822C11.521 10.9037 11.991 10.6608 12.4842 10.4586V15.9998H13.8905V9.48911V9.48344V9.07688C13.8905 8.69515 14.2059 8.38458 14.5936 8.38458H17.4061C17.7938 8.38458 18.1093 8.69515 18.1093 9.07688V9.48325V9.48916V15.9998H19.5155V10.4586C20.0088 10.6608 20.4787 10.9038 20.9218 11.1822V14.6152H22.3281V12.2681C24.1044 13.9355 25.1875 16.2725 25.1875 18.769V20.7644L6.8123 20.8458V18.769ZM25.8906 23.615H6.10916C5.72146 23.615 5.40603 23.615 5.40603 22.9227C5.40603 22.2304 5.72146 22.2304 6.10916 22.2304H25.8906C26.2783 22.2304 26.5937 22.2304 26.5937 22.9227C26.5937 23.615 26.2783 23.615 25.8906 23.615Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconHelmet32
