import { makeStyles } from '@material-ui/core'

export const usePeriodPickerStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    width: 280,
  },
  disableContent: {
    '& $content': {
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        cursor: 'default',
      },
    },
  },
  content: {
    margin: '0 16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '30px',
    cursor: 'pointer',
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  contentText: {
    marginRight: '4px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    fontWeight: theme.textStyles.body1MonoMedium.fontWeight,
    fontSize: theme.textStyles.body1MonoMedium.fontSize,
    lineHeight: theme.textStyles.body1MonoMedium.lineHeight,
  },
  icon: {
    borderRadius: '30px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))
