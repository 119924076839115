import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconViewMapping24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9 2H18.1C18.5 2 19 2.4 19 2.9V11.4037C18.3549 11.2259 17.6858 11.1041 17 11.0438V4H3V18H5.82275L6.16277 18.7904C6.34308 19.2095 6.5525 19.6133 6.78851 20H1.9C1.4 20 1 19.5 1 19.1V2.9C1 2.4 1.4 2 1.9 2ZM19 13.4952C18.3654 13.2778 17.6953 13.1272 17 13.053V15.3973C18.0844 15.7917 18.8571 16.8087 18.8571 18C18.8571 18.7852 18.5215 19.4946 17.9821 20H18.1C18.5 20 19 19.5 19 19.1V13.4952ZM14.0178 20H9.2571C8.73762 19.3979 8.31183 18.7249 7.99998 18H13.1428C13.1428 18.7852 13.4785 19.4946 14.0178 20ZM15 15.3973C14.6303 15.5318 14.2968 15.7386 14.0178 16H9.2571C9.95595 15.1899 10.8244 14.508 11.8131 14H15V15.3973ZM14.2857 18C14.2857 18.9222 15.0514 19.6667 16 19.6667C16.9485 19.6667 17.7143 18.9222 17.7143 18C17.7143 17.441 17.4329 16.9473 17 16.6451V18H14.2857ZM7 15.6687C6.92732 15.7777 6.8568 15.8881 6.78851 16H5V14H7V15.6687ZM15 11.0438C13.7104 11.1572 12.4801 11.4879 11.3565 12H9V10H15V11.0438ZM5 6H7V8H5V6ZM9 6H15V8H9V6ZM7 10H5V12H7V10Z"
        fill="currentColor"
      />
      <path
        d="M16 13C12.3657 13 9.25714 15.0778 8 18C9.25714 20.9222 12.3657 23 16 23C19.6343 23 22.7429 20.9222 24 18C22.7429 15.0778 19.6343 13 16 13ZM16 20.7778C14.4229 20.7778 13.1429 19.5333 13.1429 18C13.1429 16.4667 14.4229 15.2222 16 15.2222C17.5771 15.2222 18.8571 16.4667 18.8571 18C18.8571 19.5333 17.5771 20.7778 16 20.7778ZM17.7143 18C17.7143 18.9222 16.9486 19.6667 16 19.6667C15.0514 19.6667 14.2857 18.9222 14.2857 18C14.2857 17.0778 15.0514 16.3333 16 16.3333C16.9486 16.3333 17.7143 17.0778 17.7143 18Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconViewMapping24
