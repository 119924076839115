// Check here https://codepen.io/whazam/pen/oOWxPK
import React, { useCallback } from 'react'

import { RegexPattern } from '../../constants/Regex'
import { Input } from '../Input'
import { formatNumberString } from './utils'

import { FixedTextFieldProps } from './types'

export const FixedTextField: React.FC<FixedTextFieldProps> = (props) => {
  const onKeyPress = useCallback(
    (e) => {
      const REGEXP = props.allowNegativeNumbers
        ? RegexPattern.numberDotCommaHyphen
        : RegexPattern.numberDotComma

      const fieldValue = e.target.value
      const fieldHasCommaOrDot = fieldValue.includes('.') || fieldValue.includes(',')
      const keyIsCommaOrDot = ['.', ','].includes(e.key)
      const fieldHasNumbers = /^\d+$/.test(fieldValue)
      const fieldHasHyphen = fieldValue.includes('-')
      const keyIsHyphen = e.key === '-'

      if (props.allowNegativeNumbers) {
        const hyphenCondition =
          (fieldHasHyphen && keyIsHyphen) || (!fieldHasHyphen && fieldHasNumbers && keyIsHyphen)

        if (hyphenCondition) {
          e.preventDefault()
        }
      }

      if (!REGEXP.test(e.key) || (keyIsCommaOrDot && fieldHasCommaOrDot)) {
        e.preventDefault()
        e.target.value = formatNumberString(fieldValue)
      }
    },
    [props.allowNegativeNumbers],
  )

  return (
    <Input
      {...props}
      type="text"
      onKeyPress={props.type === 'number' ? onKeyPress : undefined}
      onChange={(e) => {
        const fieldValue = e.target.value
        if (props.regex) {
          e.target.value = fieldValue.replace(props.regex, '')
        }
        props.onChange?.(e)
      }}
      onWheel={
        props.type === 'number'
          ? (e) => {
              e.preventDefault()
              e.stopPropagation()
            }
          : undefined
      }
    />
  )
}

export default FixedTextField
