export enum PERMISSION {
  // admin
  // доступ ко всему функционалу проекта
  ADMIN = 'admin',

  // dictionaries
  // доступ к АРМу
  DICTIONARY__TAB__ROUTE = 'dictionary.tab.route',
  // доступ к просмотру справочников
  DICTIONARY__VIEW__READ = 'dictionary.view.read',
  // доступ на редактирование справочных значений
  DICTIONARY__VIEW__EDIT = 'dictionary.view.edit',

  // opio
  // доступ к вкладке
  OPIO__TAB__ROUTE = 'opio.tab.route',
  // доступ к просмотру заданий
  OPIO__TASK__READ = 'opio.task.read',
  // доступ к созданию задания
  OPIO__TASK__CREATE = 'opio.task.create',
  // доступ к редактированию задания
  OPIO__TASK__EDIT = 'opio.task.edit',
  // доступ к удалению задания
  OPIO__TASK__DELETE = 'opio.task.delete',

  // laboratory
  // доступ к вкладке
  LABORATORY__TAB__ROUTE = 'laboratory.tab.route',

  // test_results
  // доступ к вкладке
  TEST_RESULTS__TAB__ROUTE = 'test_results.tab.route',

  // shipment
  // доступ к вкладке
  SHIPMENT__TAB__ROUTE = 'shipment.tab.route',

  // master_ugv
  // доступ к вкладке
  MASTER_UGV__TAB__ROUTE = 'master_ugv.tab.route',
  // доступ к запуску заданий
  MASTER_UGV__TASK__START = 'master_ugv.task.start',
  // доступ к созданию задания
  MASTER_UGV__TASK__CREATE = 'master_ugv.task.create',
  // доступ к просмотру заданий
  MASTER_UGV__TASK__READ = 'master_ugv.task.read',
  // доступ к редактированию заданий
  MASTER_UGV__TASK__EDIT = 'master_ugv.task.edit',
  // доступ к удалению заданий
  MASTER_UGV__TASK__DELETE = 'master_ugv.task.delete',
  // доступ к созданию заявки в лабораторию
  MASTER_UGV__REQUEST__CREATE = 'master_ugv.request.create',
  // доступ к просмотру заяков в лабораторию
  MASTER_UGV__REQUEST__READ = 'master_ugv.request.read',
  // доступ к созданию бирки в лабораторию
  MASTER_UGV__SAMPLE__CREATE = 'master_ugv.sample.create',
  // доступ к привязке бирки к образцу
  MASTER_UGV__SAMPLE__BIND = 'master_ugv.sample.bind',
  // доступ к просмотру бирки в лабораторию
  MASTER_UGV__SAMPLE__READ = 'master_ugv.sample.read',

  // master_uop
  // доступ к вкладке
  MASTER_UOP__TAB__ROUTE = 'master_uop.tab.route',
  // доступ к запуску заданий
  MASTER_UOP__TASK__START = 'master_uop.task.start',
  // доступ к созданию задания
  MASTER_UOP__TASK__CREATE = 'master_uop.task.create',
  // доступ к просмотру заданий
  MASTER_UOP__TASK__READ = 'master_uop.task.read',
  // доступ к редактированию заданий
  MASTER_UOP__TASK__EDIT = 'master_uop.task.edit',
  // доступ к удалению заданий
  MASTER_UOP__TASK__DELETE = 'master_uop.task.delete',
  // доступ к созданию заявки в лабораторию
  MASTER_UOP__REQUEST__CREATE = 'master_uop.request.create',
  // доступ к просмотру заяков в лабораторию
  MASTER_UOP__REQUEST__READ = 'master_uop.request.read',
  // доступ к созданию бирки в лабораторию
  MASTER_UOP__SAMPLE__CREATE = 'master_uop.sample.create',
  // доступ к привязке бирки к образцу
  MASTER_UOP__SAMPLE__BIND = 'master_uop.sample.bind',
  // доступ к просмотру бирки в лабораторию
  MASTER_UOP__SAMPLE__READ = 'master_uop.sample.read',

  // master_cki
  // доступ к вкладке
  MASTER_CKI__TAB__ROUTE = 'master_cki.tab.route',
  // доступ к запуску заданий
  MASTER_CKI__TASK__START = 'master_cki.task.start',
  // доступ к созданию задания
  MASTER_CKI__TASK__CREATE = 'master_cki.task.create',
  // доступ к просмотру заданий
  MASTER_CKI__TASK__READ = 'master_cki.task.read',
  // доступ к редактированию заданий
  MASTER_CKI__TASK__EDIT = 'master_cki.task.edit',
  // доступ к удалению заданий
  MASTER_CKI__TASK__DELETE = 'master_cki.task.delete',
  // доступ к созданию заявки в лабораторию
  MASTER_CKI__REQUEST__CREATE = 'master_cki.request.create',
  // доступ к просмотру заяков в лабораторию
  MASTER_CKI__REQUEST__READ = 'master_cki.request.read',
  // доступ к созданию бирки в лабораторию
  MASTER_CKI__SAMPLE__CREATE = 'master_cki.sample.create',
  // доступ к привязке бирки к образцу
  MASTER_CKI__SAMPLE__BIND = 'master_cki.sample.bind',
  // доступ к просмотру бирки в лабораторию
  MASTER_CKI__SAMPLE__READ = 'master_cki.sample.read',

  // master_gvc
  // доступ к вкладке
  MASTER_GVC__TAB__ROUTE = 'master_gvc.tab.route',
  // доступ к запуску заданий
  MASTER_GVC__TASK__START = 'master_gvc.task.start',
  // доступ к созданию задания
  MASTER_GVC__TASK__CREATE = 'master_gvc.task.create',
  // доступ к просмотру заданий
  MASTER_GVC__TASK__READ = 'master_gvc.task.read',
  // доступ к редактированию заданий
  MASTER_GVC__TASK__EDIT = 'master_gvc.task.edit',
  // доступ к удалению заданий
  MASTER_GVC__TASK__DELETE = 'master_gvc.task.delete',

  // otk
  // доступ к вкладке ОТК ЦКИ
  OTK_CKI__TAB__ROUTE = 'otk_cki.tab.route',
  // доступ к вкладке ОТК СПЦ
  OTK_SPC__TAB__ROUTE = 'otk_spc.tab.route',

  // reports
  // доступ к вкладке
  REPORTS__TAB__ROUTE = 'reports.tab.route',

  // wiredrawer
  // доступ к вкладке
  WIREDRAWER__TAB__ROUTE = 'wiredrawer.tab.route',
  // доступ к привязке бирки к образцу
  WIREDRAWER__SAMPLE__BIND = 'wiredrawer.sample.bind',

  // warehouse
  // доступ к вкладке
  WAREHOUSE__TAB__ROUTE = 'warehouse.tab.route',
  // доступ к складам
  WAREHOUSE__ALL__READ = 'warehouse.all.read',

  // extension
  // доступ к вкладке
  EXTENSION__TAB__ROUTE = 'extension.tab.route',

  // weigher_sgp
  // доступ к вкладке
  WEIGHER_SGP__TAB__ROUTE = 'weigher_sgp.tab.route',

  // downtime
  // доступ к вкладке
  DOWNTIME__TAB__ROUTE = 'downtime.tab.route',
}
