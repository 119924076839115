import { ProductAlias, SumWeightField, WarehouseAliasEnum } from '../../interfaces/enums/Warehouse'
import { WarehouseData } from '../../interfaces/Warehouse'

const defaultWireAttributes = [
  'steel_grade',
  'diameter',
  'aggregate',
  'barcode',
  'swim_number',
  'statusOTK',
]

const defaultFibAttributes = [
  'coil_no',
  'steel_grade',
  'diameter',
  'aggregate',
  'barcode',
  'swim_number',
  'statusOTK',
]

const defaultNailAttributes = ['nail_type', 'nail_standard', 'aggregate', 'barcode']

const WAREHOUSE: Record<WarehouseAliasEnum, WarehouseData> = {
  [WarehouseAliasEnum.WAREHOUSE_UGV_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV_INPUT]: {
    productAlias: ProductAlias.WIRE_ROD,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV_VIRTUAL_INPUT]: {
    productAlias: ProductAlias.WIRE_ROD,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV2_INPUT]: {
    productAlias: ProductAlias.WIRE_ROD,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV2_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UPP_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_UPP_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB1_OUTPUT]: {
    productAlias: ProductAlias.WIRE_ROSETTE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultFibAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB2_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB2_OUTPUT]: {
    productAlias: ProductAlias.WIRE_ROSETTE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultFibAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB3_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FIB3_OUTPUT]: {
    productAlias: ProductAlias.WIRE_ROSETTE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultFibAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_EBNER_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_EBNER_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_DANIELLI_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_DANIELLI_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_UEF_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UEF_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_UKP_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UKP_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_PRESSING_MACHINE_1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_PRESSING_MACHINE_1_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_PRESSING_MACHINE_2_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_PRESSING_MACHINE_2_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_ROLLING_MACHINE_1_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_ROLLING_MACHINE_1_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_ROLLING_MACHINE_2_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_ROLLING_MACHINE_2_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_SHARPENING_MACHINES_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_SHARPENING_MACHINES_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_CTO_LINES_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_CTO_LINES_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_COATING_LINES_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_COATING_LINES_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_NAIL_PACKING_LINE_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_NAIL_PACKING_LINE_OUTPUT]: {
    productAlias: ProductAlias.PALLET_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_NAIL_PACKING_MULTIPALLET_LINE_OUTPUT]: {
    productAlias: ProductAlias.PALLET_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_WASHING_MACHINES_INPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_WASHING_MACHINES_OUTPUT]: {
    productAlias: ProductAlias.FASTENING_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_CKI_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_CKI_OUTPUT]: {
    productAlias: ProductAlias.PALLET_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode'],
  },
  [WarehouseAliasEnum.WAREHOUSE_GVC_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_GVC_OUTPUT]: {
    productAlias: ProductAlias.PALLET_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode'],
  },
  [WarehouseAliasEnum.WAREHOUSE_SPC_GVC_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV_UTO_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV_UOP_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_SPC_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultWireAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_PACKING_LINE_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_PACKING_LINE_OUTPUT]: {
    productAlias: ProductAlias.PALLET_PRODUCTS,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['barcode'],
  },
  [WarehouseAliasEnum.WAREHOUSE_WIRE_NAILING_MACHINE_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_WIRE_NAILING_MACHINE_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_1_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_1_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_2_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_2_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_3_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_3_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_4_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_4_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_5_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_5_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_6_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_6_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_7_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_7_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_8_INPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_POLISHING_DRUM_8_OUTPUT]: {
    productAlias: ProductAlias.NAIL,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: defaultNailAttributes,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_1_1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_1_1_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_1_2_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_1_2_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_2_1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_2_1_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_2_2_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_2_2_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_3_1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_3_1_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_3_2_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_EBNER_3_2_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_DANIELLI_1_1_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_FURNACE_DANIELLI_1_1_OUTPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['aggregate', 'barcode', 'statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_GVC_VIRTUAL_INPUT]: {
    productAlias: ProductAlias.WIRE,
    sumWeightField: SumWeightField.WEIGHT,
    attributes: ['statusOTK'],
  },
  [WarehouseAliasEnum.WAREHOUSE_UGV_RESIDUALS]: {
    productAlias: ProductAlias.RESIDUALS,
    sumWeightField: SumWeightField.WEIGHT,
  },
  [WarehouseAliasEnum.WAREHOUSE_UOP_RESIDUALS]: {
    productAlias: ProductAlias.RESIDUALS,
    sumWeightField: SumWeightField.WEIGHT,
  },
}

export default WAREHOUSE
