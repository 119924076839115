import { TableColumn } from '../../../../ui-kit/components'
import { NotAddNode } from './hooks'

export const COLUMNS: TableColumn<NotAddNode>[] = [
  { name: 'shop', title: 'Цех' },
  { name: 'area', title: 'Участок' },
  { name: 'section', title: 'Подучасток' },
  { name: 'aggregate', title: 'Агрегат', width: 250 },
  { name: 'controls', title: ' ', width: 1 },
]
