import { makeStyles } from '@material-ui/core'

export const useSortableItemsStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
})

export const useSortableItemStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '4px 0px',
  },
  main: {
    flexGrow: 1,
    margin: '4px 0px',
    color: '#001729',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  actions: {
    display: 'flex',
  },
  action: {
    margin: '0 4px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
})

export const useTableModalStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 24,
  },
})
