import React, { useContext } from 'react'

import { KeycloakInstance, KeycloakProfile } from 'keycloak-js'

export interface PassportState {
  user: KeycloakProfile
  token: string | null
  expires: number | null
  refreshExpires: number | null
  dateCreateToken: number | null
  authenticated: boolean | null
  exists: boolean
}

export const KeyCloakContext = React.createContext<KeycloakInstance | null>(null)

export const useKeyCloak = () => {
  const keyCloakCtx = useContext(KeyCloakContext)
  return keyCloakCtx
}

export const useKeyCloakLogout = () => {
  const keyCloak = useKeyCloak()
  return () => keyCloak?.logout()
}

export const TokenInfoContext = React.createContext<PassportState | null>(null)

export const useTokenInfo = () => {
  return useContext(TokenInfoContext)
}

export const useKeyCloakUser = () => {
  const tokenInfo = useTokenInfo()
  return tokenInfo?.user
}

export const useKeyCloakUserName = () => {
  const keyCloakUser = useKeyCloakUser()
  if (keyCloakUser?.lastName && keyCloakUser.firstName) {
    {
      return `${keyCloakUser.lastName} ${keyCloakUser.firstName}`
    }
    if (keyCloakUser?.firstName) {
      return keyCloakUser?.firstName
    }
    return ''
  }
  return ''
}
