import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

import { useUpdatedValues } from '../../hooks/useUpdatedValues'
// import { PseudoInput } from '../PseudoInput'
import { defaultShiftLength } from './helpers/shifts'
import { CalendarPanel } from './subcomponents/CalendarPanel'
import { DatePickerInput } from './subcomponents/DatePickerInput'
import { ClickAwayListener } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'date-fns'

import { DatePickerType } from './types'

import { useDatePickerStyles } from './styles'

export var DatePicker: DatePickerType = ({
  id,
  level = 'day',
  type = 'date',
  name,
  valueFrom: outerValueFrom,
  valueTo: outerValueTo,
  enabledFrom,
  enabledTo,
  enabledHourFrom,
  enabledHourTo,
  enabledMinuteFrom,
  enabledMinuteTo,
  value: externalValue,
  onPeriodChange: outerOnPeriodChange,
  onChange: outerOnChange,
  className,
  shiftFrom: outerShiftFrom,
  shiftTo: outerShiftTo,
  shiftLength = defaultShiftLength,
  disableChange,
  withPortal,
  colored,
  pseudo,
  pseudoChildren,
  disableChangesOnBlur = false,
  isOpenOnFocus = false,
  isHideYear = false,
  ...restInputProps
}) => {
  var styles = useDatePickerStyles()
  var withPeriod = useMemo(() => type === 'period' || type === 'shift', [type])
  var withTime = useMemo(() => type === 'time' || type === 'seconds', [type])
  var withSeconds = useMemo(() => type === 'seconds', [type])
  var withShift = useMemo(() => type === 'shift', [type])
  var [isOpen, setOpen] = useState(false)
  var [inputRef, setInputRef] = useState<null | HTMLInputElement>(null)
  var [calendarRef, setCalendarRef] = useState<null | HTMLDivElement>(null)
  var [toggle, setToggle] = useState(false)

  id = useMemo(
    () => `DatePicker-${id?.toString() ?? performance.now().toString().split('.').join('')}`,
    [id],
  )
  var {
    value: { valueFrom, valueTo },
    onChange: innerOnPeriodChange,
  } = useUpdatedValues(
    useMemo(
      () => ({ valueFrom: outerValueFrom, valueTo: outerValueTo }),

      [outerValueFrom, outerValueTo],
    ),
    useCallback((a, b) => isEqual(a.valueFrom, b.valueFrom) && isEqual(a.valueTo, b.valueTo), []),
  )

  var {
    value: { shiftFrom, shiftTo },
    onChange: innerShiftChange,
  } = useUpdatedValues(
    useMemo(
      () => ({
        shiftFrom: outerShiftFrom,
        shiftTo: outerShiftTo,
      }),
      [outerShiftFrom, outerShiftTo],
    ),
  )

  var { value, onChange: innerOnChange } = useUpdatedValues(externalValue as any)

  var onChange = useCallback(
    (date: Date) => {
      innerOnChange(date)
      if (outerOnChange) {
        outerOnChange(date)
      }
    },
    [innerOnChange, outerOnChange],
  )

  var onPeriodChange = useCallback(
    (valueFrom?: Date, valueTo?: Date, shiftFrom?: number, shiftTo?: number) => {
      if (outerOnPeriodChange) {
        outerOnPeriodChange(valueFrom, valueTo, shiftFrom, shiftTo)
      }
      if (valueFrom || valueTo) {
        innerOnPeriodChange({ valueFrom, valueTo })
      }
      if (shiftFrom || shiftTo) {
        innerShiftChange({ shiftFrom, shiftTo })
      }
    },
    [innerOnPeriodChange, innerShiftChange, outerOnPeriodChange],
  )

  var outerValue = useMemo(() => {
    return value && new Date(value)
  }, [value, toggle])

  var handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  var handleFocus = useCallback(() => {
    setOpen(true)
  }, [])

  var handleReset = useCallback(() => {
    setToggle((s) => !s)
    setOpen(false)
  }, [])

  useEffect(() => {
    if (pseudo) {
      handleClose()
    }
  }, [handleClose, pseudo])

  var { styles: popperStyles, attributes } = usePopper(inputRef, calendarRef, {
    placement: 'bottom-start',
  })

  var handleSetValues = useCallback(
    (isBlur?: boolean) => (date, date2, shiftFrom, shiftTo) => {
      if (isOpen && isBlur) {
        return
      }
      inputRef?.blur()
      if (withPeriod && onPeriodChange) {
        if (withShift) {
          onPeriodChange(
            date || undefined,
            date2 || undefined,
            shiftFrom || undefined,
            shiftTo || undefined,
          )
        } else {
          onPeriodChange(date || undefined, date2 || undefined)
        }
        setToggle((s) => !s)
      } else if (onChange) {
        onChange(date)
      }
      setOpen(false)
    },
    [inputRef, isOpen, onChange, onPeriodChange, withPeriod, withShift],
  )

  var renderCalendarPanel = () => (
    <CalendarPanel
      type={type}
      level={level}
      ref={setCalendarRef}
      withPeriod={withPeriod}
      valueFrom={valueFrom}
      valueTo={valueTo}
      enabledHourFrom={enabledHourFrom}
      enabledHourTo={enabledHourTo}
      enabledMinuteFrom={enabledMinuteFrom}
      enabledMinuteTo={enabledMinuteTo}
      shiftFrom={shiftFrom}
      shiftTo={shiftTo}
      shiftLength={shiftLength}
      value={value}
      onClose={handleClose}
      onReset={handleReset}
      disableChange={disableChange}
      disableChangesOnBlur={disableChangesOnBlur}
      onChange={onChange}
      onPeriodChange={onPeriodChange}
      withTime={withTime}
      enabledTo={enabledTo}
      enabledFrom={enabledFrom}
      style={popperStyles.popper}
      withShift={withShift}
      withSeconds={withSeconds}
      isOpenOnFocus={isOpenOnFocus}
      isHideYear={isHideYear}
      {...attributes.popper}
    />
  )

  var renderDatepicker = () => (
    <div
      className={clsx(
        styles.root,
        className,
        restInputProps.disabled && styles.disabled,
        isOpen && styles.isOpen,
      )}
      id={id as any}
    >
      {name && ['date', 'time', 'seconds'].includes(type) && (
        <input type="hidden" name={name} value={value?.toISOString()} />
      )}
      {name && ['period', 'shift'].includes(type) && (
        <>
          <input type="hidden" name={`${name}-from`} value={valueFrom?.toISOString()} />
          <input type="hidden" name={`${name}-to`} value={valueTo?.toISOString()} />
        </>
      )}
      {name && type === 'shift' && (
        <>
          <input type="hidden" name={`${name}-shift-from`} value={shiftFrom} />
          <input type="hidden" name={`${name}-shift-to`} value={shiftTo} />
        </>
      )}
      <DatePickerInput
        level={level}
        ref={setInputRef}
        showTime={withTime}
        value={outerValue}
        onChange={onChange}
        enabledFrom={enabledFrom}
        enabledTo={enabledTo}
        enabledHourFrom={enabledHourFrom}
        enabledHourTo={enabledHourTo}
        enabledMinuteFrom={enabledMinuteFrom}
        enabledMinuteTo={enabledMinuteTo}
        onFocus={handleFocus}
        withPeriod={withPeriod}
        valueFrom={valueFrom}
        valueTo={valueTo}
        shiftFrom={shiftFrom}
        shiftTo={shiftTo}
        shiftLength={shiftLength}
        withShift={withShift}
        withSeconds={withSeconds}
        isOpenOnFocus={isOpenOnFocus}
        onEnterKeyDown={handleSetValues(false)}
        onTabKeyDown={handleSetValues(false)}
        onBlur={handleSetValues(true)}
        colored={colored}
        isHideYear={isHideYear}
        {...restInputProps}
      />
      {isOpen &&
        (!withPortal ? (
          <>{renderCalendarPanel()}</>
        ) : (
          ReactDOM.createPortal(
            <>{renderCalendarPanel()}</>,
            document.getElementById('root') as HTMLElement,
          )
        ))}
    </div>
  )

  return pseudo ? (
    // <PseudoInput label={withTime ? 'Дата и время' : 'Дата'}>{pseudoChildren}</PseudoInput>
    <ClickAwayListener onClickAway={handleClose}>{renderDatepicker()}</ClickAwayListener>
  ) : isOpenOnFocus ? (
    <ClickAwayListener onClickAway={handleClose}>{renderDatepicker()}</ClickAwayListener>
  ) : (
    <>{renderDatepicker()}</>
  )
}
