import React, { FC } from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const FilterIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6V8H21V6H3ZM10 18.0002H14V16.0002H10V18.0002ZM18.0002 13.0002H6.00016V11.0002H18.0002V13.0002Z"
      />
    </SvgIcon>
  )
}
