import makeStyles from '@material-ui/core/styles/makeStyles'

interface St {
  theme?: string
  isManyColumns?: boolean
}

interface Colors {
  [k: string]: string | Colors
}

/**
 * Подготавливаем стиль по цветовому сету
 */

interface IGetByColors {
  rawRowColors?: (string | undefined)[]
  highlightedBackground?: string
  highlighted?: boolean
  checked?: boolean
}

export const getByColors = ({
  rawRowColors,
  highlightedBackground,
  highlighted,
  checked,
}: IGetByColors): Colors => {
  if (!rawRowColors) {
    return {}
  }

  return {
    background: `linear-gradient(to right, ${rawRowColors[0]} 5px, ${
      highlighted ? highlightedBackground : rawRowColors[1]
    } 5px) !important`,
    '&:hover': {
      background: `linear-gradient(to right, ${rawRowColors[0]} 5px, ${
        checked ? rawRowColors[0] : rawRowColors[2]
      } 5px) !important`,
    },
    '&.Row_highlighted': {
      background: `linear-gradient(to right, ${rawRowColors[0]} 5px, #E8F5FF 5px)`,
    },
  }
}

export const useTableStyles = makeStyles((theme) => ({
  container: {
    overflow: 'unset',
    '& td': {
      height: 'inherit',
      boxSizing: 'border-box',
      fontSize: theme.textStyles.body1medium.fontSize,
      lineHeight: theme.textStyles.body1medium.lineHeight,
    },
    '& td, & th': {
      color: theme.palette.text.primary,
      paddingTop: 4,
      paddingBottom: 4,
      borderBottom: ({ theme }: St) =>
        theme === 'light' ? '1px solid rgba(0,23,41,0.12)' : '1px solid #66747E',
    },
    '& td, & td.Cell_align_left, & th, & th.Cell_align_left': {
      paddingLeft: 8,
      paddingRight: ({ isManyColumns }: St) => (isManyColumns ? 50 : 90),
      minWidth: ({ isManyColumns }: St) => (isManyColumns ? 70 : 94),
    },
    '& td.Cell_align_right, & th.Cell_align_right': {
      paddingRight: 32,
      paddingLeft: ({ isManyColumns }: St) => (isManyColumns ? 50 : 90),
      minWidth: ({ isManyColumns }: St) => (isManyColumns ? 48 : 70),
    },
  },
  base: {
    '& tr': {
      height: 56,
    },
    '& thead': {
      '& .Check__Cell': {
        textOverflow: 'clip',
      },
      '& .Check__Cell_narrowRows': {
        paddingLeft: 30,
      },
      '& tr th': {
        color: theme.palette.text.secondary,
        verticalAlign: 'middle',
        whiteSpace: 'wrap', // Данный стиль надо менять внутри ячейки в диве
        borderBottom: ({ theme }: St) =>
          theme === 'light' ? '1px solid rgba(0,23,41,0.12)' : '1px solid #66747E',
      },
      // могут возникнуть проблемы с этим
      '& tr:last-child': {
        display: 'none',
      },
    },
    '& tbody': {
      '& tr': {
        '&:hover': {
          cursor: 'pointer',
          background: theme.palette.background.default,
        },
        '&.Row__Hover:hover': {
          '& td:last-child .ControlsBlock': {
            display: 'flex',
            backgroundColor: theme.palette.background.default,
          },
        },
        '&.Row__Sticky': {
          height: 18,
          '& td': {
            position: 'sticky',
            top: 56,
            padding: 7,
            color: 'rgba(0, 23, 41, 0.6)',
            backgroundColor: '#fafafa',
            zIndex: 100,
          },
        },
        '&.Row__highlighted': {
          background: theme.palette.secondary.light,
          '&.Row__green': getByColors({
            rawRowColors: [
              theme.tableSuccessBG.dark,
              theme.tableSuccessBG.light,
              theme.tableSuccessBG.main,
            ],
            highlightedBackground: theme.palette.secondary.light,
            highlighted: true,
          }),
          '&.Row__red': getByColors({
            rawRowColors: [
              theme.tableErrorBG.dark,
              theme.tableErrorBG.light,
              theme.tableErrorBG.main,
            ],
            highlightedBackground: theme.palette.secondary.light,
            highlighted: true,
          }),
          '&.Row__yellow': getByColors({
            rawRowColors: [
              theme.tableWarningBG.dark,
              theme.tableWarningBG.light,
              theme.tableWarningBG.main,
            ],
            highlightedBackground: theme.palette.secondary.light,
            highlighted: true,
          }),
          '& .Cell__green': {
            backgroundColor: theme.tableSuccessBG.dark,
          },
          '& .Cell__red': {
            backgroundColor: theme.tableErrorBG.dark,
          },
          '& .Cell__yellow': {
            backgroundColor: theme.tableWarningBG.dark,
          },
        },
        '&:hover .Cell__green': {
          backgroundColor: theme.tableSuccessBG.main,
        },
        '&:hover .Cell__red': {
          backgroundColor: theme.tableErrorBG.main,
        },
        '&:hover .Cell__yellow': {
          backgroundColor: theme.tableWarningBG.main,
        },
        '&.Row__Checked': {
          background: theme.palette.secondary.light,
          '&.Row__green': getByColors({
            rawRowColors: [
              theme.tableSuccessBG.dark,
              theme.tableSuccessBG.light,
              theme.tableSuccessBG.main,
            ],
            checked: true,
          }),
          '&.Row__red': getByColors({
            rawRowColors: [
              theme.tableErrorBG.dark,
              theme.tableErrorBG.light,
              theme.tableErrorBG.main,
            ],
            checked: true,
          }),
          '&.Row__yellow': getByColors({
            rawRowColors: [
              theme.tableWarningBG.dark,
              theme.tableWarningBG.light,
              theme.tableWarningBG.main,
            ],
            checked: true,
          }),
          '&.Row__Hover:hover': {
            '& td:last-child .ControlsBlock': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
        '&.Row__Highlighted': {
          background: theme.palette.secondary.light,
          '&.Row__green': getByColors({
            rawRowColors: [
              theme.tableSuccessBG.dark,
              theme.tableSuccessBG.light,
              theme.tableSuccessBG.main,
            ],
            checked: true,
          }),
          '&.Row__red': getByColors({
            rawRowColors: [
              theme.tableErrorBG.dark,
              theme.tableErrorBG.light,
              theme.tableErrorBG.main,
            ],
            checked: true,
          }),
          '&.Row__yellow': getByColors({
            rawRowColors: [
              theme.tableWarningBG.dark,
              theme.tableWarningBG.light,
              theme.tableWarningBG.main,
            ],
            checked: true,
          }),
          '&.Row__Hover:hover': {
            '& td:last-child .ControlsBlock': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
        '& .Check__Cell': {
          textOverflow: 'clip',
        },
        '&.Row__green': getByColors({
          rawRowColors: [
            theme.tableSuccessBG.dark,
            theme.tableSuccessBG.light,
            theme.tableSuccessBG.main,
          ],
        }),
        '&.Row__red': getByColors({
          rawRowColors: [
            theme.tableErrorBG.dark,
            theme.tableErrorBG.light,
            theme.tableErrorBG.main,
          ],
        }),
        '&.Row__yellow': getByColors({
          rawRowColors: [
            theme.tableWarningBG.dark,
            theme.tableWarningBG.light,
            theme.tableWarningBG.main,
          ],
        }),
        '& td, & th': {
          fontSize: theme.textStyles.body1.fontSize,
          fontWeight: theme.textStyles.body1medium.fontWeight,
          fontVariantNumeric: 'tabular-nums lining-nums',
          '&.Cell__green': {
            backgroundColor: theme.tableSuccessBG.light,
          },
          '&.Cell__red': {
            backgroundColor: theme.tableErrorBG.light,
          },
          '&.Cell__yellow': {
            backgroundColor: theme.tableWarningBG.light,
          },
        },
        '&.expanded': {
          backgroundColor: theme.neutral.grey100,
          borderBottom: 'none',
          '& td': {
            backgroundColor: theme.neutral.grey100,
            borderBottom: 'none',
          },
        },
      },
      '& tr.Row__highlighted.Row__Hover:hover': {
        '& td:last-child .ControlsBlock': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    '& td:first-child, & th:first-child': {
      paddingLeft: 24,
    },
    '&.Base_rows_narrow thead tr, &.Base_rows_super_narrow thead tr, &.Base_rows_ultra_narrow thead tr': {
      height: 40,
    },
    '&.Base_rows_narrow tbody tr': {
      height: 40,
      '& td': {
        paddingTop: 7,
        paddingBottom: 7,
      },
      '& .selectionCheckbox': {
        padding: '7px 9px',
        margin: '-7px 0',
      },
      '& .tableButton': {
        margin: '-3px 0',
      },
    },
    '&.Base_rows_super_narrow tbody tr': {
      height: 32,
      '& td': {
        lineHeight: '20px',
        paddingTop: 3,
        paddingBottom: 4,
      },
    },
    '&.Base_rows_ultra_narrow tbody tr': {
      height: 28,
      '& td': {
        lineHeight: '27px',
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .Check__Cell span': {
        padding: 0,
      },
    },
    '&.Base_columns_narrow, &.Base_columns_super_narrow': {
      '& td, th': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '& td, & td.Cell_align_left, & th, & th.Cell_align_left, & td.Cell_align_right, & th.Cell_align_right': {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    '&.Base_columns_narrow': {
      '& td:first-child, th:first-child': {
        paddingLeft: 24,
      },
      '& td:last-child, th:last-child': {
        paddingRight: 24,
      },
    },
    '&.Base_columns_super_narrow': {
      '& td:first-child, th:first-child': {
        paddingLeft: 8,
      },
      '& td:last-child, th:last-child': {
        paddingRight: 8,
      },
    },
    '& .Check__Cell_stickyCheckColumn': {
      position: 'sticky',
      left: 0,
      zIndex: 1,
    },
  },
  scrollbar: {
    '& > .ps__rail-y': {
      zIndex: 100,
    },
  },
}))

export const useTableExpanderStyles = makeStyles((theme) => ({
  expander: {
    color: theme.palette.text.primary,
  },
}))

export const useTableModuleStyles = makeStyles((theme) => ({
  Cell: {
    color: theme.neutral.grey700,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    lineHeight: '18px !important',
    '& > div': {
      whiteSpace: 'normal',
    },
  },

  Cell_center: {
    '& .[class], .[class] *': {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },

  LastHiddenCell: {
    position: 'sticky',
    right: 0,
    overflow: 'unset !important',
  },

  ControlsBlock: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',
    height: '100%',
    padding: '0 24px 0 6px',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    '& .active': {
      display: 'flex',
    },
  },

  active: {
    display: 'flex',
  },

  SortIcon: {
    padding: 6,
  },

  SortWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  NoDataCell: {
    textAlign: 'center',
    padding: '40px !important',
    fontSize: 17,
  },

  Message: {
    fontSize: 17,
  },

  PagingWrapper: {
    padding: '8px 12px 12px 22px',
    borderTop: '1px solid rgba(0, 23, 41, 0.16)',
    justifyContent: 'flex-end',
    '& p': {
      marginLeft: 'auto',
    },
  },
  Table__expander: {
    transition: 'all 0.15s ease-in !important',
  },
}))

export const useHeaderStyles = makeStyles((theme) => ({
  withoutBorder: {
    '& th': {
      borderRight: 'inherit !important',
    },
  },
  narrowHeader: {
    height: 40,
  },
  fixed: {
    height: 56,
    top: 0,
    zIndex: 10,
    position: 'sticky',
    '& th': {
      position: 'sticky',
      top: 0,
      background: theme.tableDefaultBG.main,
      zIndex: 10,
    },
  },
}))
