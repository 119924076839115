import { TableReducers } from '../../../../../interfaces/TableReducers'
import {
  createTableReducer,
  generateDefaultTableState,
  TableState,
} from '../../../../../ui-kit/components'
import ViewTableManager from '../../../../../ui-kit/managers/ViewTableManager'
import { COLUMNS, reducerKey } from '../constants'
import { Reducer } from 'redux'

const initialState: TableState = generateDefaultTableState(COLUMNS)
const state = ViewTableManager.merge(reducerKey, initialState)
const tableReducer = createTableReducer<TableState>(reducerKey, state)

const virutalWarehouseTableReducers: TableReducers<typeof reducerKey> = {
  [reducerKey]: (tableReducer as unknown) as Reducer<TableState>,
}

export default virutalWarehouseTableReducers
