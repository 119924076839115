import { makeStyles } from '@material-ui/core'

export const useMeasureUnitStyles = makeStyles((theme) => ({
  root: {
    width: 36,
    height: 36,
    cursor: 'pointer',
  },
  wrapper: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '100px',
    border: '1px solid transparent',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {
    '& $wrapper': {
      backgroundColor: theme.palette.primary.main,
      color: theme.neutral.noChangeWhite,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  disabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '& $wrapper': {
      color: theme.palette.text.disabled,
    },
  },
}))
