import React, { FC, useContext } from 'react'

import NavigationContext from '../../context/NavigationContext'
import { INavigationItem } from '../../types'
import clsx from 'clsx'

import { useNavigationItemStyles } from './styles'

export const NavigationItem: FC<{
  item: INavigationItem
}> = ({ item }): JSX.Element => {
  const { renderLink, setSelectedFirstLevel } = useContext(NavigationContext)
  const classes = useNavigationItemStyles()
  // eslint-disable-next-line no-restricted-globals
  const selectedItem = item.href && location.pathname.startsWith(item.href)
  const isItemVisible = item.isShow === undefined || item.isShow

  const handleClick = () => {
    if (!item.disabled) {
      item.onClick?.()

      if ('href' in item) {
        return
      }

      setSelectedFirstLevel?.(null)
    }
  }

  if (!isItemVisible) {
    return <></>
  }

  const itemContent = (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.root, {
          [classes.selected]: selectedItem,
          [classes.disabled]: item.disabled,
        })}
        onClick={handleClick}
      >
        {item.title}
      </div>
    </div>
  )

  if (item.external) {
    return (
      <div className={classes.wrapper}>
        <a
          className={clsx(classes.root, { [classes.selected]: selectedItem })}
          href={item.href}
          target={item.target}
        >
          {item.title}
        </a>
      </div>
    )
  }

  if (item.href || item.onClick) {
    return renderLink({
      id: item.id,
      href: item.href,
      onClick: item.onClick,
      children: <>{itemContent}</>,
    })
  }

  return itemContent
}
