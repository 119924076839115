import React, { PropsWithChildren, useState } from 'react'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { DragIndicatorSvgIcon } from '../../Icon'
import { PinnedSvgIcon } from '../../Icon'
import { UnPinnedSvgIcon } from '../../Icon'
import { VisibleSvgIcon } from '../../Icon'
import { InVisibleSvgIcon } from '../../Icon'
import { Input } from '../../Input'
import { Modal } from '../../Modal'

import { TableModalItem, TableModalProps } from './types'

import { useSortableItemsStyles, useSortableItemStyles, useTableModalStyles } from './styles'

const SortableItems = SortableContainer<{
  items: TableModalItem[]
  onItemsChange: (items: TableModalItem[]) => void
  disabled?: boolean
}>(({ items, onItemsChange, disabled }) => {
  const styles = useSortableItemsStyles()
  return (
    <div className={styles.root}>
      {items.map((item, idx) => (
        <SortableItem
          index={idx}
          key={item.name}
          disabled={disabled}
          pinned={item.pinned}
          visible={item.visible}
          onPinnedToggle={() =>
            onItemsChange(
              items.map((innerItem) => ({
                ...innerItem,
                pinned: innerItem.name === item.name ? !innerItem.pinned : innerItem.pinned,
              })),
            )
          }
          onVisibleToggle={() =>
            onItemsChange(
              items.map((innerItem) => ({
                ...innerItem,
                visible: innerItem.name === item.name ? !innerItem.visible : innerItem.visible,
              })),
            )
          }
        >
          {item.name}
        </SortableItem>
      ))}
    </div>
  )
})

const SortableHandler = SortableHandle(() => {
  return <DragIndicatorSvgIcon />
})

const SortableItem = SortableElement<
  PropsWithChildren & {
    pinned?: boolean
    visible?: boolean
    onPinnedToggle?: () => void
    onVisibleToggle?: () => void
  }
>(({ children, pinned, visible, onPinnedToggle, onVisibleToggle }) => {
  const styles = useSortableItemStyles()
  return (
    <div className={styles.root}>
      <SortableHandler />
      <div className={styles.main}>{children}</div>
      <div className={styles.actions}>
        <div className={styles.action} onClick={onVisibleToggle}>
          {visible ? <VisibleSvgIcon /> : <InVisibleSvgIcon />}
        </div>
        <div className={styles.action} onClick={onPinnedToggle}>
          {pinned ? <PinnedSvgIcon /> : <UnPinnedSvgIcon />}
        </div>
      </div>
    </div>
  )
})

export const TableModal: React.FC<TableModalProps> = ({ items, onItemsChange, ...props }) => {
  const styles = useTableModalStyles()
  const [search, setSearch] = useState('')
  return (
    <Modal {...props}>
      <Input
        placeholder="Найти"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.root}
        color="primary"
        variant="outlined"
      />
      <SortableItems
        onItemsChange={onItemsChange}
        useDragHandle
        disabled={Boolean(search)}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (!search) {
            onItemsChange(arrayMove(items, oldIndex, newIndex))
          }
        }}
        items={
          search
            ? items.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
            : items
        }
      />
    </Modal>
  )
}
