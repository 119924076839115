import React, { useContext } from 'react'

import { PenSvgIcon } from '../../../../Icon'
import { SortAscendingSvgIcon } from '../../../../Icon'
import { SortDescendingSvgIcon } from '../../../../Icon'
import { SortContext } from '../context'
import { ISortContext, SortItem as ISortItem } from '../typings'
import { hashRenderTitle } from '../utils/helpers'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

export interface SortItemProps {
  onEdit: () => void
  onDelete: () => void
  onChangeSortMethod: (item: ISortItem) => void
  item: ISortItem
}

function SortItem({ item, onDelete, onEdit, onChangeSortMethod }: SortItemProps) {
  const { header } = useContext<ISortContext>(SortContext)

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={6}>
        <Typography>{hashRenderTitle(item, header)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onEdit}>
          <PenSvgIcon fill="#DADADA" />
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={() => onChangeSortMethod(item)}>
          {item.method === 'ASC' ? <SortAscendingSvgIcon /> : <SortDescendingSvgIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onDelete}>
          <CloseIcon style={{ color: '#DADADA' }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default SortItem
