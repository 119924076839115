import React from 'react'

import { Block } from '../../../../interfaces/AdditionTable'
import { Attributes } from '../../../../interfaces/Warehouse'
import { convertTonsToKilos } from '../../../../utils/convertKilograms'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'
import { round } from 'lodash'

import styles from '../styles.module.scss'

type TProps = {
  highlighted: Attributes[]
}

function getWeight(highlighted: Attributes): string {
  if (highlighted.zone === 'Зона возврата') return 'remaining_weight'

  return 'weight'
}

const CardWire = React.forwardRef<HTMLDivElement, TProps>(({ highlighted }, ref) => {
  const { barcode } = highlighted[0]
  const referenceData = [
    {
      title: '',
      data: {
        left: [
          { name: 'diameter', title: 'Диаметр, мм' },
          { name: 'swim_number', title: '№ Плавки' },
          { name: 'steel_grade', title: 'Марка стали' },
          { name: getWeight(highlighted[0]), title: 'Вес, кг' },
          { name: 'coil_number', title: '№ Мотка' },
          { name: 'startStoreTime', title: 'Дата' },
        ],
      },
    },
  ]

  function getDescription(name: string, value: string) {
    if (name === 'startStoreTime') return new Date(value).toLocaleDateString()
    if (name === 'weight') return String(round(convertTonsToKilos(Number(value))))

    return value
  }

  const renderBlock = (data?: Block[]) => {
    if (!data) return null
    return data
      .map(({ name, title }) => {
        const newDescription: string =
          // @ts-ignore
          highlighted[0][name] !== undefined ? getDescription(name, highlighted[0][name]) : '____'
        return { title, description: newDescription }
      })
      .map(({ title, description }) => (
        <Box style={{ paddingBottom: '3px' }} justifyContent="space-between" key={title}>
          <Typography style={{ maxWidth: '320px' }} variant="Subheading1">
            {title}
          </Typography>
          <Typography variant="Subheading1">{description}</Typography>
        </Box>
      ))
  }

  return (
    <div className="print-block-wire" ref={ref}>
      <Box
        flexDirection="row"
        style={{
          position: 'relative',
        }}
      >
        {referenceData.map(({ title, data }, index) => (
          <div
            style={{
              padding: '30px 86px 29px 57px',
              position: 'relative',
              width: '340,1px',
              height: '415,7px',
            }}
            key={`title${index}`}
          >
            <Typography variant="Caption-Medium">{title}</Typography>
            <Box justifyContent="space-between">
              {Object.values(data).map((value, i) => (
                <Box
                  style={{
                    width: '400px',
                    paddingRight: '10px',
                    marginRight: '10px',
                  }}
                  flexDirection="column"
                  key={i}
                >
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    marginTop="-45px"
                    marginBottom="5px"
                  >
                    <Typography variant="Heading1">Катанка</Typography>
                  </Box>

                  {renderBlock(value)}
                </Box>
              ))}
            </Box>
          </div>
        ))}

        <div className={styles.barcodeContainerWireOne}>
          <Barcode value={barcode} height={60} width={2.3} fontSize={30} />
        </div>

        <div className={styles.qrContainerWireOne}>
          <QRCode value={String(barcode)} size={130} />
        </div>
      </Box>
    </div>
  )
})

export default CardWire
