import { makeStyles } from '@material-ui/core'

export const useCommonCalendarStyles = makeStyles((theme) => ({
  root: {
    width: 312,
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    padding: '8px 16px 10px 16px',
    borderRadius: '4px',
    marginTop: 8,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    zIndex: 99999,
  },
  withTime: {
    width: 424,
  },
  withSeconds: {
    width: 473,
  },
  withTimeSlot: {
    display: 'grid',
    gridTemplateColumns: 'auto 116px',
    gridTemplateRows: '52px auto',
    columnGap: 18,
  },
  withSecondsSlot: {
    display: 'grid',
    gridTemplateColumns: 'auto 135px',
    gridTemplateRows: '52px auto',
    columnGap: 18,
  },
  calendarPanel: {
    gridColumn: '1/2',
    gridRow: '2/3',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  timePanel: {
    gridRow: '1/3',
    position: 'relative',
  },
  month: {},
  footer: {
    margin: '0 -16px',
  },
  period: {
    display: 'grid',
    gridTemplateRows: '16px 30px',
    gap: '8px',
    padding: '16px 0',
  },
  periodTitle: {
    color: theme.palette.text.hint,
    fontSize: theme.textStyles.caption.fontSize,
    lineHeight: theme.textStyles.caption.lineHeight,
  },
  periodSelectors: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto) 1fr',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.text.primary,
  },
}))
