enum DICTIONARY_WORK_STATUS {
  OPEN = 'open',
  CLOSE = 'close',
  NO_OPEN = 'no-open',
  UNBIND = 'unbind',
  APPROVE = 'approve',
  ACTUAL = 'actual',
  ALL = 'all',
}

export default DICTIONARY_WORK_STATUS
