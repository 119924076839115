// import opioReducers from 'pages/OPIO/components/ContentTable/redux/reducer';
// import shiftTableReducers from 'components/ShiftTable/redux/reducer';
import otkShiftTableReducers from '../containers/OtkContent/containers/OtkTable/redux/reducer'
import inputWarehouseTableReducers from '../containers/WarehouseInputTable/redux/reducer'
import outputWarehouseTableReducers from '../containers/WarehouseOutputTable/redux/reducer'
import { TableReducers } from '../interfaces/TableReducers'
import virutalWarehouseTableReducers from '../pages/Warehouse copy/containers/VirtualWarehouse/redux/reducer'
import { ReducerKey } from '../ui-kit/frontend-components/WarehouseContainer/constants'
import { warehouseTableReducers } from '../ui-kit/frontend-components/WarehouseContainer/redux/reducer'
import taskFilterReducer from './taskFilter/reducer'

// import labSamplesTableReducers from 'pages/Laboratory/components/LabSamplesTable/redux/reducer';
// import labRequestsTableReducers from 'pages/Laboratory/components/LabRequestsTable/redux/reducer';
// import virtualZoneTableReducers from 'components/VirtualZone/redux/reducer';
// import residualsWarehouseTableReducers from 'pages/Wiredrawer/components/WarehouseResiduals/redux/reducer';
// import wiredrawerOutputWarehouseTableReducers from 'pages/Wiredrawer/components/OutputWarehouseTable/redux/reducer';
// import wiredrawerInputWarehouseTableReducers from 'pages/Wiredrawer/components/WarehouseInput/redux/reducer';
// import downtimeTabTableReducers from '../ui-kit/frontend-components/DowntimeTable/redux/reducer';
// import cancelOperationReducers from 'pages/Administrator/redux/reducer';
// import { galvanizedWireTableReducer } from 'pages/TestResults/components/TestResultsTable/components/GalvanizedWireTable/redux';
// import { nonGalvanizedWireTableReducer } from 'pages/TestResults/components/TestResultsTable/components/NonGalvanizedWireTable/redux';
// import { coldFormedRolledWireTableReducer } from 'pages/TestResults/components/TestResultsTable/components/ColdFormedRolledWireTable/redux';
// import { periodicProfileWireTableReducer } from 'pages/TestResults/components/TestResultsTable/components/PeriodicProfileWireTable/redux';
// import { scaleLubricationReducer } from 'pages/TestResults/components/TestResultsTable/components/ScaleLubricationTable/redux';
// import { reducerCurrentRequestManagerReducer } from 'pages/Pc4Request/components/CurrentRequestTable/redux';

export const staticReducers = {
  // ...opioReducers,
  // ...shiftTableReducers,
  ...otkShiftTableReducers,
  ...virutalWarehouseTableReducers,
  ...inputWarehouseTableReducers,
  ...outputWarehouseTableReducers,
  ...{ ...((warehouseTableReducers() as unknown) as TableReducers<ReducerKey>) },
  // ...labSamplesTableReducers,
  // ...labRequestsTableReducers,
  // ...virtualZoneTableReducers,
  // ...residualsWarehouseTableReducers,
  // ...wiredrawerOutputWarehouseTableReducers,
  // ...wiredrawerInputWarehouseTableReducers,
  // ...downtimeTabTableReducers,
  // ...galvanizedWireTableReducer,
  // ...nonGalvanizedWireTableReducer,
  // ...coldFormedRolledWireTableReducer,
  // ...periodicProfileWireTableReducer,
  // ...cancelOperationReducers,
  // ...scaleLubricationReducer,
  // ...reducerCurrentRequestManagerReducer,
  taskFilter: taskFilterReducer,
}

export type StaticReducers = typeof staticReducers
