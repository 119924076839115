import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  IconClose24,
  Pagination,
  Tooltip,
} from '../../../../../../ui-kit/components'
import ButtonLoader from '../../../../../../ui-kit/frontend-components/ButtonLoader'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import Table from '../../../../../../ui-kit/frontend-components/Table'
import usePageable from '../../../../../../ui-kit/hooks/usePageable'
import { COLUMNS } from '../../constants'
import { useRemoveNode } from '../../hooks'
import { makeStyles } from '@material-ui/core'
import {
  AllNodes,
  useDictionaryNodes,
} from 'pages/Dictionaries/components/EditDictionaries/hooks/useDictionaryNodes'

type AllNode = AllNodes[number]

const useStyles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loaderWrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

export const NodesToRemove = (): ReactElement => {
  const classes = useStyles()

  const { dictionariesNodes, request } = useDictionaryNodes()

  const { items, totalPages, currentPage, setCurrentPage } = usePageable({
    items: dictionariesNodes,
    countOnPage: 20,
  })

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const [nodeId, setNodeId] = useState<number | null>(null)

  const { removeNode, isLoading } = useRemoveNode({ onSuccess: request.execute })

  const handleRemoveNode = useCallback(async () => {
    if (nodeId !== null) {
      await removeNode(nodeId)
      setIsRemoveModalOpen(false)
      setNodeId(null)
    }
  }, [nodeId, removeNode])

  const columnsConfig = useMemo(
    () =>
      COLUMNS.map((item) => {
        const name = item.name as keyof AllNode & 'controls'
        if (name === 'controls') {
          return {
            ...item,
            render: (row: AllNode) => {
              return (
                <Tooltip title="Удалить из отображения">
                  <span>
                    <IconButton
                      variant="primary"
                      onClick={() => {
                        setIsRemoveModalOpen(true)
                        setNodeId(row.id)
                      }}
                    >
                      <IconClose24 />
                    </IconButton>
                  </span>
                </Tooltip>
              )
            },
          }
        }

        return {
          ...item,
          render: (row: AllNode) => {
            return row?.[name] ?? '-'
          },
        }
      }),
    [removeNode],
  )

  const TableMemo = useMemo(
    () => (
      <Table
        narrowColumns
        fixHeader
        noDataMessage="Нет данных"
        style={{
          root: {
            style: {
              flexGrow: 1,
            },
          },
        }}
        enableScroll
        enableColumnResizing
        columns={columnsConfig}
        rows={items}
      />
    ),
    [items, columnsConfig],
  )

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={isRemoveModalOpen}
        onClose={() => {
          setIsRemoveModalOpen(false)
          setNodeId(null)
        }}
        title="Удалить из отображения?"
        actions={[
          <ButtonLoader
            key={1}
            progressLoading={isLoading}
            disabled={isLoading || nodeId === null}
            variant="contained"
            color="primary"
            onClick={handleRemoveNode}
          >
            Удалить
          </ButtonLoader>,
          <Button
            key={2}
            onClick={() => setIsRemoveModalOpen(false)}
            variant="contained"
            color="secondary"
          >
            Отменить
          </Button>,
        ]}
      />
      <Card className={classes.flexGrow}>
        <Box className={classes.flexGrow} spacing={2} paddingX={2} paddingY={2}>
          <LoaderWrapper
            isLoad={request.status === 'pending'}
            rootClassName={classes.loaderWrapperContainer}
            contentClassName={classes.loaderWrapper}
          >
            {TableMemo}
          </LoaderWrapper>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
          />
        </Box>
      </Card>
    </>
  )
}
