import React from 'react'

import { CustomCheckBoxIcon } from './icons/CustomCheckBoxIcon'
import { CustomCheckBoxOutlineBlankIcon } from './icons/CustomCheckBoxOutlineBlankIcon'
import { CustomIndeterminateCheckBoxIcon } from './icons/CustomIndeterminateCheckBoxIcon'
import { Checkbox as CheckboxMU } from '@material-ui/core'

import { CheckboxProps } from './types'

import { useCheckboxStyles } from './styles'

export const Checkbox: React.FC<CheckboxProps> = ({ color = 'default', ...props }) => {
  const classes = useCheckboxStyles({
    size: props.size,
    disabled: props.disabled,
    checked: props.checked,
    indeterminate: props.indeterminate,
  })
  return (
    <CheckboxMU
      icon={<CustomCheckBoxOutlineBlankIcon />}
      checkedIcon={<CustomCheckBoxIcon htmlColor={'#FFFFFF'} />}
      indeterminateIcon={<CustomIndeterminateCheckBoxIcon />}
      classes={{
        colorSecondary: classes.colorSecondary,
        checked: classes.checked,
        root: classes.root,
        indeterminate: classes.indeterminate,
        disabled: classes.disabled,
      }}
      className={classes[color]}
      {...props}
    />
  )
}
