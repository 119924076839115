import { useEffect, useState } from 'react'

import useAsync from '../../../../../ui-kit/hooks/useAsync'
import usePageable from '../../../../../ui-kit/hooks/usePageable'
import { ResponseNotAddNodes } from 'interfaces/responses/Dictionary'
// import { useAlert } from 'react-alert';
import service from 'services/service'

export type NotAddNode = ResponseNotAddNodes['content'][number]

export const useNotAddNodes = () => {
  // const alert = useAlert();

  const [nodes, setNodes] = useState<NotAddNode[]>([])

  const request = useAsync(service.getNotAddNodes)

  useEffect(() => {
    if (request.status === 'success' && request.value) {
      setNodes(request.value.content)
    }
  }, [request.status, request.value])

  useEffect(() => {
    if (request.status === 'error' && request.error) {
      // alert.error(request.error);
    }
  }, [request.status, request.error])

  const { items, totalPages, currentPage, setCurrentPage } = usePageable({
    items: nodes,
    countOnPage: 20,
  })

  return {
    nodes: items,
    pageable: {
      totalPages,
      currentPage,
      setCurrentPage,
    },
    setNodes,
    request,
  }
}
