export interface Favorites {
  userName: string
  favorites: string[]
}

interface FavoriteItems {
  userFavorites: Favorites | null
  otherUsersFavorites: Favorites[] | null
}

/**
 * Метод получает избранные элементы для текущего пользователя и других пользователей из локального хранилища.
 * Возвращаемый объект содержит два свойства: userFavorites и otherUsersFavorites.
 * - userFavorites: список избранных элементов для текущего пользователя.
 * - otherUsersFavorites: список избранных элементов для всех остальных пользователей.
 *
 * @returns {FavoriteItems} Объект, содержащий избранные элементы текущего пользователя и других пользователей.
 */
export const getFavoriteItems = (): FavoriteItems => {
  const newFavorites = localStorage.getItem('newFavorites')
  const userName = getUserName()
  const parsedFavorites: Favorites[] = newFavorites ? JSON.parse(newFavorites) : []

  const userFavorites = parsedFavorites.find((item) => item.userName === userName) || null
  const otherUsersFavorites = parsedFavorites.filter((item) => item.userName !== userName)

  return {
    userFavorites,
    otherUsersFavorites,
  }
}

export const getUserName = (): string => {
  const userName = localStorage.getItem('user_name') || 'all'
  localStorage.setItem('user_name', userName)
  return userName
}

/**
 * Проверяет, является ли переданный элемент (value: href || id) избранным для текущего пользователя.
 *
 * @param {string} value - Значение элемента, которое необходимо проверить на наличие в избранном.
 * @returns {boolean} Возвращает true, если элемент присутствует в избранном, и false в противном случае.
 */
export const checkInFavorite = (value: string): boolean => {
  const { userFavorites } = getFavoriteItems()
  return userFavorites ? userFavorites.favorites.includes(value) : false
}

/**
 * Рекурсивно обходит объект (obj), находит все объекты, свойство 'href' или 'id' которых соответствует переданному значению (favTitle),
 * и добавляет их в массив (items).
 *
 * @param {object} obj - Объект для обхода.
 * @param {string} favTitle - Значение 'href' или 'id', которое ищется в объекте.
 * @param {Array} [items=[]] - Массив, в который добавляются найденные объекты.
 * @returns {Array} Возвращает массив найденных объектов.
 */
export function getItemsForFavorites(obj, favTitle, items = []) {
  const getProp = (o, title, acc) => {
    Object.entries(o).forEach(([prop, value]) => {
      if (prop === 'icon') {
        return
      }

      if (typeof value === 'object') {
        getProp(value, title, acc)
      } else if ((prop === 'href' || prop === 'id') && value === title) {
        acc.push(o)
      }
    })

    return acc
  }

  return getProp(obj, favTitle, items)
}

/**
 * Функция для фильтрации массива объектов, оставляя только уникальные объекты по заданному ключу.
 * @param arr Массив объектов, который нужно отфильтровать.
 * @param key Ключ, по которому будут определены уникальные объекты.
 * @returns Новый массив с уникальными объектами.
 */
export function uniqueBy<T>(arr: T[], key: keyof T): T[] {
  // Создаем пустой объект для хранения уникальных значений ключа
  const uniqueKeys: { [k: string]: boolean } = {}

  // Фильтруем исходный массив
  const uniqueArr = arr.filter((item) => {
    const itemKey = String(item[key])

    // Если значение ключа еще не встречалось, сохраняем его в объекте и добавляем элемент в результирующий массив
    if (!uniqueKeys[itemKey]) {
      uniqueKeys[itemKey] = true
      return true
    }

    // Если значение ключа уже встречалось, пропускаем элемент
    return false
  })

  return uniqueArr
}
