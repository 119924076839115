import React from 'react'

import { CardDataInterfaceAttributes } from '../../../../interfaces/TaskMills'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'

type TProps = {
  highlighted: CardDataInterfaceAttributes
}

const CardCkiWhiteSharpeningMachine = React.forwardRef<HTMLDivElement, TProps>(
  ({ highlighted }, ref) => {
    return (
      <div className="print-block print-block_nails print-block_rolling-machine" ref={ref}>
        <Box flexDirection="column" width={600}>
          <Box alignSelf="center">
            <Barcode value={highlighted.barcode} />
          </Box>
          <Box flexDirection="column">
            <Box border={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography>ТУ на изделие</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography variant="Body1-Medium">ТУ 16-40-.....-55798700-2006</Typography>
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography>Типоразмер изделия</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography>{highlighted.nail_standard}</Typography>
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography>№ контейнера после сверла</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography variant="Subheading3-Medium"></Typography>
              </Box>
            </Box>
            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box padding="2px 5px" borderRight={1} flexShrink={1} flexBasis={300}>
                <Typography>Агрегат</Typography>
              </Box>
              <Box padding="2px 10px" flexShrink={1} flexBasis={300}>
                <Typography>{highlighted.aggregate}</Typography>
              </Box>
            </Box>

            <Box borderBottom={1} borderRight={1} borderLeft={1}>
              <Box borderRight={1} flexShrink={1} flexBasis={300}>
                <Box flexDirection="column" width="100%">
                  <Box borderBottom={1} padding="2px 5px" justifyContent="center">
                    <Typography variant="Body1-Medium">Дата</Typography>
                  </Box>
                  <Box borderBottom={1}>
                    <Box width="50%" padding="2px 5px" borderRight={1}>
                      Начат:
                    </Box>
                  </Box>
                  <Box width="50%" padding="2px 5px" borderRight={1}>
                    Закончен:
                  </Box>
                </Box>
              </Box>
              <Box flexBasis={300}>
                <Box flexShrink={1} width="50%" flexDirection="column">
                  <Box
                    justifyContent="center"
                    padding="2px 10px"
                    borderRight={1}
                    borderBottom={1}
                    flexShrink={1}
                  >
                    <Typography>ФИО</Typography>
                  </Box>
                  <Box borderBottom={1} borderRight={1} padding="2px 10px" flexBasis={27}></Box>
                  <Box borderRight={1} padding="2px 10px" flexBasis={27}></Box>
                </Box>
                <Box width="50%" flexDirection="column">
                  <Box padding="2px 10px" borderBottom={1} flexShrink={1}>
                    <Typography>Выработка, кг</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    )
  },
)

CardCkiWhiteSharpeningMachine.displayName = 'CardCkiWhiteSharpeningMachine'

export default CardCkiWhiteSharpeningMachine
