import { makeStyles } from '@material-ui/core'

export const useQuartersCalendarStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '4px',
    padding: '4px 0 10px 0',
  },
}))
