import React, { MouseEvent, useCallback, useContext, useEffect } from 'react'

import { ContextMenuItem } from '../../ContextMenu'
import { useContextMenu } from '../../ContextMenu/hooks'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useRowStyleProps } from '../utils/StyledProps'
import { Table as RGTable } from '@devexpress/dx-react-grid'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'

interface TTableRowAdditionalProps {
  highlighted?: boolean
  onHoverGetRow?: (event: any) => any
  onClickGetRow?: (event: any) => any
  isRowsBgAlternation?: boolean
  contextMenu?: ContextMenuItem[]

  [x: string]: any
}

type TableRowProps = RGTable.DataRowProps & StyledProps & TTableRowAdditionalProps

export const TableRow: React.FC<TableRowProps> = (props) => {
  // eslint-disable-next-line max-len
  const {
    highlighted,
    selectByRowClick,
    onHoverGetRow,
    onClickGetRow,
    onShow,
    contextMenu,
    ...other
  } = props // Без other в итоговом return в консоль сыпятся ошибки о highlighted и selectByRowClick
  const context = useContext(CustomFieldsCtx)
  const { setContextMenu } = useContextMenu()
  const customStyle = context.style
  const hasCheckedElement = context.hasCheckedElement
  const onHoverControls = context.onHoverControls
  const checked = hasCheckedElement
    ? hasCheckedElement(props.row, context.rowsSelection?.checked || [])
    : Boolean(context.rowsSelection?.checked?.includes(props.row))
  const [className, style] = useRowStyleProps(
    props.row,
    props,
    highlighted ? customStyle?.selectedRow : customStyle?.row,
    props.isRowsBgAlternation,
    checked,
    onHoverControls,
  )

  const getRowOnHover = (row) => {
    if (onHoverGetRow) {
      onHoverGetRow(row)
    }
  }

  const getRowOnClick = (row) => {
    if (onClickGetRow) {
      onClickGetRow(row)
    }
  }

  const handleContextMenu = useCallback(
    (event: MouseEvent<HTMLDivElement, MouseEvent>, row) => {
      event.preventDefault()
      const { clientX, clientY } = event
      if (setContextMenu) {
        setContextMenu(contextMenu as ContextMenuItem[], [clientX, clientY], row)
      }
    },
    [setContextMenu, contextMenu],
  )
  useEffect(() => {
    onShow?.(props.row)
  }, [onShow, props.row])

  return (
    <DevexpressTable.Row
      onMouseOver={() => getRowOnHover(props.row)}
      onClick={() => getRowOnClick(props.row)}
      onContextMenu={(e) => handleContextMenu(e, props.row)}
      {...other}
      className={className}
      style={style}
    />
  )
}
