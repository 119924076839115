import React from 'react'

import { RowsSelection, SpanOrientation, TableColumn, TableProps, TableStyle } from '../types'
import { TableBandHeader } from '@devexpress/dx-react-grid'

export interface ContextCustomFields<R> {
  readonly columnBands?: TableBandHeader.ColumnBands[]
  readonly rows: ReadonlyArray<R>
  readonly onButtonClick?: (row: R, column: TableColumn<R>) => void
  readonly onInputChange?: (row: R, column: TableColumn<R>, value: string) => void
  readonly onCheckboxChange?: (row: R, column: TableColumn<R>, value: boolean) => void
  readonly style?: TableStyle<R>
  readonly rowsSelection?: RowsSelection<R>
  readonly checkEnabledRows: ReadonlyArray<R> // Ряды, у которых есть чекбокс
  readonly checkableRows: ReadonlyArray<R> // Ряды, у которых есть не-readonly чекбокс
  readonly checkedRows: Readonly<Set<R>> // Ряды с отмеченным чекбоксом
  readonly alwaysCheckedRows: ReadonlyArray<R> // Ряды, у которых чекбокс всегда отмечен (он readonly)
  readonly highlightedRows: Readonly<Set<R>> // Выделенные ряды
  readonly spans: Readonly<Map<R, RowCellsSpanData<R>>> | null
  readonly narrowRows?: boolean
  readonly narrowColumns?: boolean
  readonly superNarrowRows?: boolean
  readonly ultraNarrowRows?: boolean
  readonly superNarrowColumns?: boolean
  readonly onHoverControls?: boolean
  readonly fixHeader?: boolean
  readonly scrollbarProps?: Readonly<TableProps<R>['scrollbarProps']>
  readonly isManyColumns: boolean
  readonly hasCheckedElement?: (row: R, rows: R[]) => boolean // Функция сравнения row в checkedRows
  readonly indexCheckedElement?: (row: R, rows: R[]) => number // Функция нахождения индекса row в rows
}

const initialContextValues: ContextCustomFields<unknown> = {
  rows: [],
  checkEnabledRows: [],
  checkableRows: [],
  checkedRows: new Set(),
  alwaysCheckedRows: [],
  highlightedRows: new Set(),
  spans: null,
  isManyColumns: false,
}

export const CustomFieldsCtx = React.createContext<ContextCustomFields<unknown>>(
  initialContextValues,
)

export type CellSpanData = ReadonlyArray<SpanData> | null

export type RowCellsSpanData<R> = Readonly<Map<TableColumn<R>, CellSpanData>>

export interface SpanData {
  readonly orientation: SpanOrientation
  readonly span: number
}
