import React from 'react'

import { ErrorSvgIcon } from '../Icon'
import { Alert as MUAlert, AlertTitle as MUAlertTitle } from '@material-ui/lab'
import { AlertProps as MUAlertProps } from '@material-ui/lab'

import { useAlertStyles, useAlertTitleStyles } from './styles'

const getIcon = (
  severity: MUAlertProps['severity'],
  icon: React.ReactNode | false,
): JSX.Element | React.ReactNode | false => {
  if (icon !== undefined) {
    return icon
  }
  return severity === 'warning' || severity === 'error' ? <ErrorSvgIcon /> : false
}

export const Alert: React.FC<MUAlertProps> = (props: MUAlertProps): JSX.Element => {
  const { children, title, severity, icon } = props
  const classes = { alert: useAlertStyles(props), alertTitle: useAlertTitleStyles() }
  const alertIcon = getIcon(severity, icon)

  return (
    <MUAlert {...props} classes={classes.alert} icon={alertIcon}>
      {title && <MUAlertTitle classes={classes.alertTitle}>{title}</MUAlertTitle>}
      <div style={{ padding: alertIcon ? '4px 0 0 0' : 0 }}>{children}</div>
    </MUAlert>
  )
}
