import { createContext, ReactElement } from 'react'

export interface ContextMenuItem {
  title: string
  icon?: ReactElement
  onClick: (row: any) => void
  disabled?: (row: any) => boolean
}

export interface ContextMenuModel {
  setContextMenu?: (items: ContextMenuItem[], position: number[], row: any) => void
}

export const ContextMenu = createContext<ContextMenuModel>({})
