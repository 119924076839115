import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconLightningOutline24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M10.5588 2.5L6.5 12.6471H10.5588L9.88235 20.0882L18 9.26471H13.2647L16.6471 2.5H10.5588Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default IconLightningOutline24
