import { makeStyles } from '@material-ui/core'

export const useTypographyStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'PT Root UI',
  },
  Heading1: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontWeight: 'bold',
  },
  Subheading1: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  Heading2: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 'bold',
  },
  Subheading2: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  ['Subheading2-Medium']: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 500,
  },
  Heading3: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
  },
  Subheading3: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  ['Subheading3-Medium']: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
  },
  Heading4: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 'bold',
  },
  Body1: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
  },
  ['Body1-Medium']: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 500,
  },
  ['Body1Mono-Medium']: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['Body1Mono-Bold']: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 700,
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['Body1-Bold']: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 'bold',
  },
  Body2: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
  ['Body2-Medium']: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 500,
  },
  ['Body2Mono-Medium']: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['Body2Mono-Bold']: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['Body2-Bold']: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 'bold',
  },
  Caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  ['Caption-Medium']: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
  },
  ['Caption-Bold']: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
  },
  CaptionMono: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['CaptionMono-Medium']: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  ['CaptionMono-Bold']: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    fontVariantNumeric: 'tabular-nums lining-nums',
  },
  /**
   * Deprecated
   * Все варианты снизу устаревающие, их необходимо выпилить из проекта
   * В новой версии их быть не должно
   */
  h1: {
    fontWeight: 'bold',
    fontSize: '3.375rem',
    lineHeight: '4.5rem',
  },
  h2: {
    fontWeight: 'bold',
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  h4: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  subtitle1: {
    fontSize: '2.25rem',
    lineHeight: '3rem',
  },
  subtitle2: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 500,
  },
  body2: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: 500,
  },
  button: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    textTransform: 'none',
  },
  primary: {
    color: theme.palette.text.primary,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
  },
  hint: {
    color: theme.palette.text.hint,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  inherit: {
    color: 'inherit',
  },
}))
