import { TEventReason } from '../interfaces/Warehouse'
import dayjs from 'dayjs'
import { ComplexDictionaries, ComplexDictionaryName, DictionaryValue } from 'features'
import { ExtraTemplateAttributes, Template } from 'features/templates'
import { DateFilterType } from 'interfaces/enums/Warehouse'
import { FilterDate } from 'interfaces/Filter'
import { LabTestResult } from 'interfaces/lab-results'
import { Koch as KochType } from 'interfaces/MillsContent'
import { TSection } from 'interfaces/OPIO'
import { Unit } from 'interfaces/OutputWarehouseTable'
import { ReportProps } from 'interfaces/Reports'
import {
  DependentDictionary,
  DictionaryFilter,
  ResponesUpdateIsAvailable,
  ResponseAllNodes,
  ResponseAllTypesInfo,
  ResponseAllWithNodes,
  ResponseDictionariesNames,
  ResponseDictionariesValues,
  ResponseDictionary,
  ResponseDictionaryInfo,
  ResponseNotAddNodes,
} from 'interfaces/responses/Dictionary'
import { ResponseCUDDowntime, ResponseDowntimes, ResponseNote } from 'interfaces/responses/Downtime'
import { GenealogyTreeResponse } from 'interfaces/responses/Genealony'
import { InvoiceRequest, InvoiceResponse, InvoicesResponse } from 'interfaces/responses/Invoice'
import { ResponseMasterTasks } from 'interfaces/responses/Master'
import {
  GlobalSearchProduct,
  GlobalSearchProductsByShopResponse,
  ProductFieldName,
  ProductType,
  SearchProduct,
} from 'interfaces/responses/Product'
import { ResponseTasks } from 'interfaces/responses/ShiftWithTasks'
import { ResponseShop, ResponseShopSection } from 'interfaces/responses/Shop'
import { WarehouseAlias, WarehouseData } from 'interfaces/Warehouse'
import { WireAttributes } from 'interfaces/WeigherSGP'
import fileDownload from 'js-file-download'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import DICTIONARY_WORK_STATUS from 'shared/enums/DictionaryWorkStatus'
import getFilterFullDate from 'utils/getFilterFullDate'
import getServerDateTime from 'utils/getServerDateTime'
import getValidDiameter from 'utils/getValidDiameter'

type ResponseSetTaskOPIO = {
  status: string // TODO: Проверить, всегда ли приходит status и приходит ли
  alias: string
  attributes: {
    aggregate: string
    appointment: string
    beg_diameter: string
    direction: string
    end_diameter: string
    end_period: string
    limit: string
    no_those_cards: string
    note: string
    one_weight: string
    operator: string
    order_code: string
    fact_weight?: string
    plan_weight: string
    start_period: string
    steel_grade: string
    times_resistance: string
    tu_gost: string
    status?: string
  }
  childs: []
  id: number
  type: string
}

export type ResponseWarehouseOutputAttributes = {
  appointment: string
  operator: string
  aggregate: string
  statusOTK: string
  zone_id: number
  diameter: string
  startStoreTime: string
  weight: string
  net_weight?: string
  swim_number: string
  steel_grade: string
  grease_type: string
  type: string
  coil_no: string
  barcode: string
  linkedLabRequestBarcodes: string[]
  id: number
}

export type ResponseWarehouseOutput = {
  id: number
  type: string
  alias: string
  attributes: ResponseWarehouseOutputAttributes
  childs: []
}

export type ResponseWarehouseResidualsAttributes = {
  finishStoreTime: string | null
  zone: {
    id: number
    type: string
    alias: string
    attributes: {
      name: string
    }
    childs: Array<unknown>
  }
  startStoreTime: string
  name: string
  weight: string
  linkedLabRequestBarcodes: []
  aggregate: string
}

export type ResponseWarehouseResiduals = {
  id: number
  type: string
  alias: string
  attributes: ResponseWarehouseResidualsAttributes
  childs: []
}

type ResponseWireKnotWinderZone = {
  node: {
    id: number
    type: string
    alias: string
    attributes: {
      name: string
    }
    childs: []
  }
  unit: {
    id: number
    type: string
    alias: string
    attributes: {
      diameter: string
      weight: string
      appointment: string
      swim_number: string
      steel_grade: string
      coil_no: string
      operator: string
      aggregate: string
    }
    childs: []
  }
  startDateTime: string | null
  finishDateTime: string | null
}

type ResponseWireKnotTask = {
  node: {
    id: number
    type: string
    alias: string
    attributes: {
      note: string
      tu_gost: string
      fact: string
      one_weight: string
      times_resistance: string
      start_period: string
      appointment: string
      operator: string
      beg_diameter: string
      aggregate: string
      end_period: string
      order_code: string
      plan_weight: string
      no_those_cards: string
      fact_weight: string
      limit: string
      steel_grade: string
      end_diameter: string
      status: string
      direction: string
    }
    childs: []
  }
  unit: {
    id: number
    type: string
    alias: null
    attributes: {
      through_number: string
      barcode: string
    }
    childs: []
  }
  startDateTime: string | null
  finishDateTime: string | null
}

type ResponseWireKnotStop = [
  ResponseWireKnotWinderZone,
  ResponseWireKnotWinderZone,
  ResponseWireKnotTask,
]

type Request = {
  method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  url: string
  body: string | FormData
}

type TLoginOut = () => void
class Service {
  token = ''

  loginOut: TLoginOut = () => null

  setToken = (token = '') => {
    this.token = token
  }

  setLoginOutHandler = (loginOut: TLoginOut) => {
    this.loginOut = loginOut
  }

  // eslint-disable-next-line consistent-return
  errorMessageHandler = (res: Response, responseContent: any) => {
    if (!res.ok) {
      const messageType = typeof responseContent.message
      if (messageType !== 'undefined' && messageType === 'string') {
        throw new Error(`Ошибка: ${responseContent.message}`)
      } else {
        throw new Error(`Ошибка: ${JSON.stringify(responseContent)}`)
      }
    }
  }

  // eslint-disable-next-line consistent-return
  errorFullMessageHandler = (res: Response, responseContent: any) => {
    if (!res.ok) {
      const messageType = typeof responseContent.message
      if (!messageType) {
        throw new Error(`Ошибка ${responseContent}`)
      } else {
        throw new Error(JSON.stringify(responseContent))
      }
    }
  }

  // eslint-disable-next-line consistent-return
  errorStatusHandler = (res: Response) => {
    if (res.status === 401) {
      return this.loginOut()
    }
    if (res.status === 504) {
      throw new Error('Ошибка: некорректный ответ сервера')
    }
  }

  getResource = async <T = any>(url: string) => {
    try {
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${this.token}`, 'request-source': 'WEB' },
      })
      this.errorStatusHandler(response)

      const responseContent: T = await response.json()

      this.errorMessageHandler(response, responseContent)

      return responseContent
    } catch (error) {
      throw error.message
    }
  }

  downloadResource = async (url: string, body: any) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
          'request-source': 'WEB',
        },
        body: JSON.stringify(body),
      })
      let decodeFileName: string | null = ''
      this.errorStatusHandler(response)
      if (response.status === 200) {
        const blob = await response.blob()

        const decodedContentDisposition = response.headers.get('content-disposition')

        if (decodedContentDisposition?.includes('UTF-8')) {
          decodeFileName = decodeURI(
            decodedContentDisposition?.split('=')?.[1].replace(`UTF-8''`, '') || '',
          )
        } else {
          decodeFileName = decodedContentDisposition?.split('=')?.[1] || ''
        }

        fileDownload(blob, decodeFileName || 'report.xlsx')
      } else {
        const responseContent = await response.json()
        this.errorMessageHandler(response, responseContent)
      }
    } catch (error) {
      throw error.message
    }
  }

  setResource = async <R = any>(
    url: string,
    body?: unknown,
    download?: boolean,
    fileName = 'filename.xlsx',
  ) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
          'request-source': 'WEB',
        },
        body: body ? JSON.stringify(body) : undefined,
      })

      if (download) {
        const blob = await response.blob()
        fileDownload(blob, fileName)
      }

      this.errorStatusHandler(response)

      let responseContent: R = {} as any

      // 204 - ломает json(), т.к. контента в body нет
      if (response.status !== 204) {
        responseContent = await response.json()
      }

      this.errorMessageHandler(response, responseContent)

      return responseContent
    } catch (error) {
      throw error.message
    }
  }

  putResource = async <R = any>(url: string, body: unknown) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
          'request-source': 'WEB',
        },
        body: JSON.stringify(body),
      })
      this.errorStatusHandler(response)

      const responseContent: R = await response.json()

      this.errorMessageHandler(response, responseContent)

      return responseContent
    } catch (error) {
      throw error.message
    }
  }

  patchResource = async <R = any>(url: string, body: unknown) => {
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
          'request-source': 'WEB',
        },
        body: JSON.stringify(body),
      })

      this.errorStatusHandler(response)

      const responseContent: R = await response.json()

      this.errorMessageHandler(response, responseContent)

      return responseContent
    } catch (error) {
      throw error.message
    }
  }

  deleteResource = async <R = any>(url: string, body?: unknown) => {
    try {
      const requestData: RequestInit = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${this.token}`,
          'request-source': 'WEB',
        },
      }

      if (body) {
        requestData.body = JSON.stringify(body)
      }

      const response = await fetch(url, requestData)

      let responseContent: R = {} as any
      // 204 - ломает json(), т.к. контента в body нет
      if (response.status !== 204) {
        responseContent = await response.json()
      }

      this.errorMessageHandler(response, responseContent)

      return responseContent
    } catch (error) {
      throw error.message
    }
  }

  request = async <Res = unknown>({ url, body, method = 'POST' }: Request) => {
    const defaultHeader: HeadersInit = {}
    if (typeof body === 'string') {
      defaultHeader['Content-Type'] = 'application/json;charset=utf-8'
    }

    const response = await fetch(url, {
      method,
      headers: {
        ...defaultHeader,
        'request-source': 'WEB',
        Authorization: `Basic ${this.token}`,
      },
      body,
    })

    this.errorStatusHandler(response)

    const responseContent: Res = await response.json()

    this.errorFullMessageHandler(response, responseContent)

    return responseContent
  }

  // Геттеры
  getTableColumns = (alias: string) => {
    return this.getResource<{ content: [{ name: string; alias: string }] }>(
      `/api/unit/attribute/all/${alias}`,
    )
    // else return new Promise<{content: [{name: '', alias: ''}]}>(function (fulfilled, rejected) {
    //   fulfilled({"content": [{'name': '', 'alias': ""}]}) })
  }

  getAllEmptyBarcodes = (page) => {
    return this.getResource<{
      content: [
        {
          id: number
          type: string
          alias: string
          attributes: { date_create_barcode: string; barcode: string }
        },
      ]
      totalPages: number
    }>(`/api/barcode/by-operation/find-all-empty-barcode?page=${page}&size=20&sort=id`)
  }

  getMills = (section: TSection) => {
    return this.getResource<{ content: KochType[] }>(`/api/unit/aggregate/${section}`)
  }

  getAggregate = (id: number) => {
    return this.getResource(`/api/aggregate/${id}`)
  }

  getDate = () => {
    return this.getResource('/api/unit/current/shift/wire_drawing')
  }

  getTasks(aggregate: string) {
    return this.getResource(`/api/unit/task/search?aggregate=${aggregate}`)
  }

  getIdentification(barcode: string | number) {
    return this.getResource(`/api/unit/wire_rod/search?barcode=${barcode}`)
  }

  getZoneForUnit = (id: number) => {
    return this.getResource(`/api/unit/place/${id}`)
  }

  getShift(shift: '-1' | '0' | '1') {
    return this.setResource(`/api/shift/current/area/ugv?relShift=${shift}`)
  }

  getShiftTask(shift: '-1' | '0' | '1', masterName: string) {
    return this.setResource(`/api/shift/current/area/${masterName}?relShift=${shift}`)
  }

  getTaskByShiftId(shiftId: string) {
    return this.getResource(`/api/unit/process/task/${shiftId}`)
  }

  getTaskByShiftIdUOP(
    shiftId: string,
    subsection: 'fib1' | 'fib2' | 'fib3' | 'uef' | 'ebner' | 'danielli',
  ) {
    return this.getResource(`/api/unit/process/task/${shiftId}/${subsection}`)
  }

  getTaskByShiftIdUGV(shiftId: string, subsection: 'ugv1' | 'ugv2' | 'upp') {
    return this.getResource<{
      content: ResponseMasterTasks[]
    }>(`/api/unit/process/task/${shiftId}/${subsection}`)
  }

  getWarehouseOutput = (
    aggregateId: number,
    page: number,
    sectionName: 'ukp' | 'upp' | 'ugv2' | 'ugv',
  ) => {
    return this.getResource<{ content: ResponseWarehouseOutput[]; totalPages: number }>(
      `/api/unit/store/warehouse/${sectionName}/output/aggregate/${aggregateId}?size=15&page=${
        page - 1
      }`,
    )
  }

  getWarehouseResiduals = (aggregateId: number, page: number) => {
    return this.getResource<{ content: ResponseWarehouseResiduals[]; totalPages: number }>(
      `/api/unit/store/warehouse/ugv/residuals/aggregate/${aggregateId}?size=15&page=${page - 1}`,
    )
  }

  getWarehouseInput = (aggregateId: number, page: number) => {
    return this.getResource<{ content: Unit[]; totalPages: number }>(
      `/api/unit/store/warehouse/ugv_virtual/input/aggregate/${aggregateId}?size=15&page=${
        page - 1
      }`,
    )
  }

  getWarehouseZones = (workshopName: 'warehouse.inplast.output' | 'warehouse.inplast.virtual') => {
    const fullness = true
    return this.getResource(`/api/warehouse/info/alias/${workshopName}/zones?fullness=${fullness}`)
  }

  getWarehouseZone = (
    workshopName: 'warehouse.inplast.output' | 'warehouse.inplast.virtual',
    id: string | number | null,
  ) => {
    console.log(id)
    const productTypeAlias = 'pipe'
    return this.getResource(
      // `/api/warehouse/all-product-warehouse/actual/product-type-alias/${productTypeAlias}/warehouse-alias/${workshopName}`,
      `/api/warehouse/all-product-warehouse-zone/actual/zone-id/${id}`,
    )
  }

  //TODO уточнить
  getWarehouseZoneAll = (
    workshopName: 'warehouse.inplast.output' | 'warehouse.inplast.virtual',
    // zoneId: string | number | null
  ) => {
    const productTypeAlias = 'pipe'
    return this.getResource(
      // `/api/warehouse/all-product-warehouse/actual/zone-id/${zoneId}`,
      `/api/warehouse/all-product-warehouse/actual/product-type-alias/${productTypeAlias}/warehouse-alias/${workshopName}`,
    )
  }

  getAccompanyingCards = (id: number) => {
    return this.getResource(`/api/unit/card/task/${id}`)
  }

  getAccompanyingCardsByShift = (shiftId: number, subSection?: string) => {
    return this.getResource(`/api/unit/card/shift/${shiftId}/${subSection}`)
  }

  getBarcodeImg = (code: string | number) => {
    return this.getResource(`/api/process/qr-code/${code}`)
  }

  getShops = () => {
    return this.getResource<{ content: ResponseShop[] }>('/api/shops/')
  }

  getShopSections = (id: number, deep: 1 | 2 = 2) => {
    return this.getResource<{ content: ResponseShopSection[] }>(
      `/api/shops/${id}/sections/?deep=${deep}`,
    )
  }

  getWarehousesSections = (id: number) => {
    return this.getResource<{ content: ResponseShopSection[] }>(`/api/shops/${id}/warehouses`)
  }

  getServerTime = () => {
    return this.getResource(`/api/infrastructure/time`)
  }

  getVirtualWarehouse = (page: number) => {
    const startDate = new Date(dayjs.unix(0).valueOf())
    const startFilterDate = getFilterFullDate(startDate)
    const endDate = new Date()
    const endFilterDate = getFilterFullDate(endDate)
    const startDateTime = `startDateTime=${startFilterDate},startDateTime=${endFilterDate}`
    return this.getResource(
      `/api/unit/store/wire_rod/advancedSearch?page=${
        page - 1
      }&size=60&sort=startDateTime,desc&filter=(operations=(unit=target,nodeParentAlias=warehouse.ugv_virtual.input,${startDateTime},finishDateTime=isNull)calculations=(sum=weight))`,
    )
  }

  getWarehouseWithFilter = <Attributes>({
    zoneName,
    page,
    warehouseData,
    attributes,
    dateFilterType = DateFilterType.CURRENT,
    currentDateTime = getServerDateTime(),
    startDate = new Date(dayjs.unix(0).valueOf()),
    endDate = new Date(dayjs().add(4, 'hour').valueOf()),
    standards,
  }: {
    zoneName: WarehouseAlias
    page: number
    warehouseData: WarehouseData
    attributes: Attributes
    dateFilterType?: DateFilterType
    currentDateTime?: FilterDate | dayjs.Dayjs
    startDate?: FilterDate
    endDate?: FilterDate
    standards?: string[]
  }) => {
    let datetimeFilter = 'finishDateTime=isNotNull'
    const barcodesString = standards
      ?.reduce((acc, standard) => {
        return `${acc},nail_standard=${String(standard)}`
      }, ``)
      .substr(1)

    const standardsQuery = barcodesString ? `(attributes=(${barcodesString}))` : ''

    switch (dateFilterType) {
      case DateFilterType.CURRENT: {
        const currentFilterDate = getFilterFullDate(currentDateTime) as string
        datetimeFilter = `currentDateTime=${currentFilterDate}`
        break
      }
      case DateFilterType.START: {
        const startFilterDate = getFilterFullDate(startDate)
        const endFilterDate = getFilterFullDate(endDate)
        datetimeFilter = `startDateTime=${startFilterDate},startDateTime=${endFilterDate},finishDateTime=isNull`
        break
      }
      case DateFilterType.FINISH: {
        const startFilterDate = getFilterFullDate(startDate)
        const endFilterDate = getFilterFullDate(endDate)
        datetimeFilter = `finishDateTime=${startFilterDate},finishDateTime=${endFilterDate}`
        break
      }
      case DateFilterType.FINISH_IS_NULL: {
        datetimeFilter = 'finishDateTime=isNull'
        break
      }
      default:
        break
    }

    const actualParameters = omitBy(attributes as any, isEmpty)
    const attributesParams = Object.entries(actualParameters)
      .reduce((acc, [key, value]) => {
        if (value !== '')
          return `${acc},${key}=${key === 'diameter' ? getValidDiameter(value) : value}`
        return acc
      }, '')
      .slice(1)

    const filterQuery = attributesParams.length !== 0 ? `attributes=(${attributesParams})` : ''
    return this.getResource(
      `/api/unit/store/${warehouseData.productAlias}/advancedSearch?page=${
        page - 1
      }&size=15&sort=startDateTime,desc&filter=${standardsQuery}${filterQuery}operations=(unit=target,nodeParentAlias=${zoneName},${datetimeFilter})calculations=(sum=${
        warehouseData.sumWeightField
      }))`,
    )
  }

  getVirtualWarehouseSection = (shopName: string, page: number) => {
    return this.getResource(
      `/api/unit/store/warehouse/${shopName}_virtual/input/units?page=${
        page - 1
      }&size=15&sort=startDateTime,desc`,
    )
  }

  getAllWarehouseUnits = (section: string, page?: number) => {
    const pageQuery = page ? `?page=${page - 1}&size=15&sort=startDateTime,desc` : ''
    return this.getResource(`/api/unit/store/warehouse/${section}/output/units${pageQuery}`)
  }

  getAllWarehouseUnitsOutputGvc = (section: string, page: number) => {
    return this.getResource(
      `/api/unit/store/warehouse/${section}/output/units?page=${page}&size=15&sort=startDateTime,desc`,
    )
  }

  getAllUgvWarehouseInputUnits = () => {
    return this.getResource(`/api/unit/store/warehouse/ugv_virtual/input/aggregate`)
  }

  getLabSamples = ({
    page,
    shopId,
    cardId,
  }: {
    page: number
    shopId: string | number
    cardId: string
  }) => {
    const params = {
      shopId: `${shopId}`,
      cardBarcode: `${cardId}`,
    }
    return this.getResource(
      `/api/labs/samples?page=${page - 1}&size=15&sort=id,desc&${new URLSearchParams({
        ...omitBy(params, isEmpty),
      })}`,
    )
  }

  getSamplesRequest = (reqId = 0) => {
    return this.getResource(`/api/labs/samples?requestId=${reqId}`)
  }

  getLabRequest = ({ page, shopId }: { page: number; shopId: string | number }) => {
    const params = {
      shopId: `${shopId}`,
    }
    return this.getResource(
      `/api/labs/requests?page=${page - 1}&size=15&sort=id,desc&${new URLSearchParams({
        ...omitBy(params, isEmpty),
      })}`,
    )
  }

  getLabProbs = () => {
    return this.getResource(`/api/labs/probes`)
  }

  getNailType = () => {
    return this.getResource('/api/unit/attribute/nail/nail_type')
  }

  getNailStandard = () => {
    return this.getResource('/api/unit/attribute/nail/nail_standard')
  }

  getLabProbForShops = (shopId: string) => {
    return this.getResource(`/api/labs/probes/find-by-shop-id/${shopId}`)
  }

  getLabForCard = (cardId: string) => {
    return this.getResource(`/api/unit/task/${cardId}`)
  }

  getWiresRodName = () => {
    return this.getResource('/api/unit/attribute/wire_rod/name')
  }

  getUnitOnSpcForGvc = () => {
    return this.getResource('/api/unit/store/warehouse/spc_gvc/output/units')
  }

  getPrintOperations = (shiftId: number, pcName: string) => {
    return this.getResource(
      `/api/weight/cards/shift/${shiftId}${pcName ? `?pcName=${pcName}` : ''}`,
    )
  }

  getActualPrintOperations = (unitId: number) => {
    return this.getResource(`/api/weight/cards/actual/unit/${unitId}`)
  }

  getWireByCardBarcode = (barcode: string) => {
    return this.getResource(`/api/unit/wire/card/${barcode}`)
  }

  getWarehouseInputUnits = (section: string) => {
    return this.getResource(`/api/unit/store/warehouse/${section}/input/units`)
  }

  getDowntimeByAggregate = ({ kochId, page }: { kochId?: number; page?: number }) => {
    if (typeof kochId === 'number') {
      return this.getResource(`/api/aggregate/downtime/search?aggregateId=${kochId}&page=0&size=15`)
    }
    return this.getResource(`/api/aggregate/downtime/search?page=${Number(page) - 1}&size=15`)
  }

  getDowntimeNote = () => {
    return this.getResource<ResponseNote>('/api/unit/attribute/downtime/note')
  }

  getDowntime = ({
    section,
    page,
    startDate,
    endDate,
    showFinished = false,
    nodeId,
  }: {
    section: string
    page: number
    startDate: FilterDate
    endDate: FilterDate | dayjs.Dayjs
    showFinished?: boolean
    nodeId?: number
  }) => {
    let datetimeFilter = ''

    if (startDate || endDate) {
      const startFilterDate = getFilterFullDate(startDate ?? new Date(0))
      const endFilterDate = getFilterFullDate(endDate ?? getServerDateTime())
      datetimeFilter = `startDateTime=${startFilterDate},startDateTime=${endFilterDate}`
    }

    if (showFinished) datetimeFilter = `${datetimeFilter},finishDateTime=isNotNull`

    if (datetimeFilter && (startDate || endDate)) datetimeFilter = `,${datetimeFilter}`

    const nodeIdParam = nodeId ? `nodeId=${nodeId},` : ''

    return this.getResource<ResponseDowntimes>(
      `/api/unit/store/downtime/advancedSearch?page=${page}&size=15&sort=startDateTime,desc&filter=(operations=(unit=target,${nodeIdParam}nodeParentAlias=${section}${datetimeFilter})`,
    )
  }

  getZincLogs = ({ sectionId, page }: { sectionId: number; page: number }) => {
    return this.getResource(
      `/api/unit/journal/zinc/advancedSearch?page=${
        page - 1
      }&size=15&sort=startDateTime,desc&filter=(operations=(unit=target,nodeId=${sectionId}))`,
    )
  }

  getOutputUnitsResults = (
    section: string,
    filter: {
      swim_number: string
      aggregate: string
      steel_grade: string
      startDate: Date | undefined
      statusOTK: string
    },
    page: number,
  ) => {
    const attributesParams = Object.entries(filter)
      .reduce((acc, [key, value]) => {
        if (value !== '' && key !== 'startDate') return `${acc},${key}=${value}`
        return acc
      }, '')
      .slice(1)

    const filterQuery = attributesParams.length !== 0 ? `attributes=(${attributesParams})` : ''
    const startDateValue =
      typeof filter.startDate !== 'undefined'
        ? filter.startDate.toLocaleString('ru-Ru').split(',').join('')
        : 'isNotNull'

    const dateQuery =
      typeof filter.startDate !== 'undefined'
        ? `,startDateTime=${new Date().toLocaleString('ru-Ru').split(',').join('')}`
        : ''
    return this.getResource(
      `/api/labs/wire/labResultOwners?page=${page}&size=20&sort=startDateTime,desc&filter=(${filterQuery}operations=(unit=target,nodeParentAlias=warehouse.${section}.output,startDateTime=${startDateValue}${dateQuery},finishDateTime=isNull))`,
    )
  }

  getOutputUnitsChild = (
    section: string,
    searchValue: {
      swim_number: string
      aggregate: string
      steel_grade: string
      startDate: string
      endDate: string
    },
    page: number,
  ) => {
    const attributesParams = Object.entries(searchValue)
      .reduce((acc, [key, value]) => {
        if (value !== '' && key !== 'startDate' && key !== 'endDate')
          return `${acc},${key}=${value}`
        return acc
      }, '')
      .slice(1)

    const dateQuery = searchValue.startDate.length
      ? `startDateTime=${searchValue.startDate},startDateTime=${searchValue.endDate}`
      : 'startDateTime=isNotNull'

    const filterQuery = attributesParams.length !== 0 ? `attributes=(${attributesParams})` : ''
    return this.getResource(
      `/api/labs/wire/labResultSlaves?page=${page}&size=20&sort=startDateTime,desc&filter=(${filterQuery}operations=(unit=target,nodeParentAlias=warehouse.${section}.output,${dateQuery},finishDateTime=isNull))`,
    )
  }

  getTask = (barcode: string) => {
    return this.getResource(`/api/shift/card/${barcode}/task`)
  }

  getGlobalSearchProduct = (spcNumber: number) => {
    return this.getResource<GlobalSearchProduct>(`/api/unit/product/globalSearch/spc/${spcNumber}`)
  }

  getProductFieldsNames = (unitType: ProductType) => {
    return this.getResource<{ content: ProductFieldName[] }>(
      `/api/unit/attribute/all-info/${unitType}`,
    )
  }

  // Сеттеры

  createCard(count: number) {
    return this.setResource<[]>(`api/barcode/create/card`, {
      barcode_pattern: 'YY_MM_DD_09NUM',
      barcode_generate_range: 'CURRENT_YEAR',
      count_create_barcode: count,
    })
  }

  setIdentify(
    nodeId: number,
    unitIds: number[],
    aggregateAlias: TSection,
    outputWarehouseAlias: string,
    eventReason: string,
  ) {
    return this.setResource('/api/process/perform/place-unit-on-aggregate', {
      processName: 'aggregateKnotUnit',
      processType: 'start',
      eventReason,
      nodeId,
      unitIds,
      additionalAttributes: {
        knotAliasLike: 'unwinding',
        aggregateAlias,
        unitTypeAlias: 'wire_rod',
      },
      accompanyingOperations: [
        {
          processName: 'warehouseUnit',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          additionalAttributes: { outputWarehouseAlias },
        },
      ],
    })
  }

  createResiduals(rawMaterialId: string, weight: number) {
    return this.setResource('/api/residuals/create', {
      materialId: rawMaterialId,
      warehouse: 'warehouse.ugv.residuals',
      weight,
    })
  }

  setTaskMillStart(unitId: number, startStatus: DictionaryValue) {
    return this.setResource('/api/process/perform/start-task-on-aggregate', {
      processName: 'aggregateTask',
      processType: 'start',
      nodeId: null,
      unitIds: [unitId],
      additionalAttributes: {
        'task.status': startStatus,
      },
      accompanyingOperations: [],
    })
  }

  setCreateAdditionalSpc(count: number, taskId: number) {
    return this.setResource('/api/process/perform/create-additional-spc', {
      count,
      taskId,
    })
  }

  setTaskStatus(unitId: number, status: DictionaryValue) {
    return this.patchResource('/api/unit/', {
      id: unitId,
      type: 'task',
      alias: 'task',
      attributes: {
        status,
      },
    })
  }

  editTaskMill(body: unknown) {
    return this.patchResource(`/api/unit/`, body)
  }

  setWireKnotStart(
    nodeId: number,
    cardId: number,
    attributes: unknown,
    startDateTime: Date,
    winderIdUnit?: number,
    unwinderId?: number,
  ) {
    return this.setResource('/api/process/perform/start-production-unit-on-aggregate', {
      processName: 'aggregateProductionUnit',
      processType: 'start',
      nodeId,
      unitIds: [],
      startDateTime: `${startDateTime.toLocaleString('ru-Ru').split(',').join('')}`,
      additionalAttributes: {
        knotAliasLike: 'winder',
        selectedInputKnot: unwinderId,
      },
      accompanyingOperations: [
        {
          processName: 'cardUnit',
          processType: 'start',
          nodeId: cardId,
          additionalAttributes: {
            selectedInputKnot: unwinderId,
          },
          unitIds: [],
        },
      ],
      accompanyingUnits: [
        {
          id: winderIdUnit,
          type: 'wire',
          alias: 'wire',
          attributes,
        },
      ],
    })
  }

  setTheoreticalWeight(nodeId: number, weight: number) {
    return this.patchResource('/api/unit/', {
      id: nodeId,
      type: 'wire',
      attributes: {
        weight: weight.toString(),
      },
    })
  }

  setProductWeight(unitId: number, weight: number) {
    return this.setResource('/api/weight/product/', {
      id: unitId,
      type: 'wire',
      attributes: {
        weight: weight.toString(),
      },
    })
  }

  setProductAttributes(unitId: number, attributes: WireAttributes) {
    return this.patchResource('/api/unit/', {
      id: unitId,
      type: 'wire',
      attributes,
    })
  }

  setWireKnotStop(
    nodeId: number,
    unwinderId: number,
    zoneId: number | undefined | null = null,
    weight: number,
    wireType: DictionaryValue,
    greaseType: DictionaryValue,
    finishDateTime: Date,
    outputWarehouseAlias: string,
    coilNumber: string,
    winderIdUnit?: number,
    eventReason?: string,
  ) {
    return this.setResource<{ content: ResponseWireKnotStop }>(
      '/api/process/perform/complete-production-unit-on-aggregate',
      {
        processName: 'aggregateProductionUnit',
        processType: 'close',
        finishDateTime: getFilterFullDate(finishDateTime),
        eventReason,
        nodeId,
        unitIds: [],
        additionalAttributes: {
          unitTypeAlias: 'wire',
          knotAliasLike: 'winder',
          warehouseAlias: outputWarehouseAlias,
          weight: weight.toString(),
          type: wireType,
          grease_type: greaseType,
          winderIdUnit,
          coil_no: coilNumber,
          selectedInputKnot: unwinderId,
        },
        accompanyingOperations: [
          {
            processName: 'warehouseUnit',
            processType: 'start',
            nodeId: zoneId,
            unitIds: [],
            additionalAttributes: {
              warehouseAlias: outputWarehouseAlias,
            },
          },
        ],
      },
    )
  }

  closeKnotWireRod = (
    nodeId: number,
    remainingWeight: string,
    weight: number,
    warehouseAlias: string,
    zoneId?: number | null,
    eventReason?: string,
  ) => {
    return this.setResource('/api/process/perform/pull-off-unit-from-aggregate', {
      processName: 'aggregateKnotUnit',
      processType: 'close',
      nodeId,
      eventReason,
      unitIds: [],
      additionalAttributes: {
        productKnotAliasLike: 'winder',
        unitTypeAlias: 'wire_rod',
        weight,
        remaining_weight: remainingWeight,
      },
      accompanyingOperations: [
        {
          processName: 'warehouseUnit',
          processType: 'start',
          nodeId: zoneId,
          unitIds: [],
          additionalAttributes: {
            warehouseAlias,
          },
        },
      ],
    })
  }

  setUnit = (body: unknown) => {
    return this.setResource(`/api/unit/`, body)
  }

  getShiftId = (body: { shiftDate: string; areaAlias: string; shopId: number }) => {
    return this.setResource<{ content: number }>(`/api/shift/shop`, body)
  }

  setShiftWithTasks = (id: string | number, data: string) => {
    return this.setResource<{ content: ResponseTasks }>(`/api/shift/shiftWithTasks`, {
      sectionId: id.toString(),
      shiftDate: data,
    })
  }

  setShiftWithTasksPeriod = (id: string | number, dateFrom?: string) => {
    return this.setResource<{ content: ResponseTasks[] }>(`/api/shift/shiftWithTasks/from-to`, {
      sectionId: id.toString(),
      from: dateFrom,
    })
  }

  setTasksOPIO = (id: number | undefined, attributes: unknown) => {
    return this.setResource<{ content: ResponseSetTaskOPIO }>(`/api/shift/${id}/task`, {
      id: 0,
      type: 'task',
      alias: 'task',
      attributes,
    })
  }

  switchShiftTask = ({
    shiftId,
    taskId,
    attributes,
  }: {
    shiftId: number
    taskId: number
    attributes: unknown
  }) => {
    return this.patchResource(`/api/shift/edit-and-replace-task/${shiftId}`, {
      id: taskId,
      type: 'task',
      alias: 'task',
      attributes,
    })
  }

  removeTask = (idSection: number, idTask?: number) => {
    return this.deleteResource(`/api/shift/${idSection}/task/${idTask}`)
  }

  removeTasks = (tasksIds: number[]) => {
    return this.deleteResource(`/api/shift/task/delete-list-new-task`, tasksIds)
  }

  editTask = (idSection: number, idTask: number, attributes: unknown) => {
    return this.patchResource(`/api/shift/${idSection}/task`, {
      id: idTask,
      type: 'task',
      alias: 'task',
      attributes,
    })
  }

  setInventoryWireRod = (
    wireRodIds: Array<number>,
    status: DictionaryValue,
    unitType: 'wire_rod' | 'wire',
  ) => {
    return this.patchResource('api/unit/store/inventory', {
      productIds: wireRodIds,
      statusAttrAlias: 'status_inventory',
      statusAttrValue: status,
      unitTypeAlias: unitType,
    })
  }

  setLabCard = ({ value, probe, shop }: { value: number; probe: number; shop: string }) => {
    return this.setResource('/api/labs/samples', {
      count: value,
      probe,
      shop: Number(shop),
    })
  }

  linkLabToProduct = ({ labCardId, cardId }: { labCardId: string; cardId: number | undefined }) => {
    return this.setResource(`/api/labs/samples/${labCardId}/fill-attributes`, {
      barcode: cardId,
    })
  }

  editGreaseType = (id: number, greaseType: DictionaryValue) => {
    return this.patchResource('api/unit/', {
      id,
      type: 'wire',
      attributes: {
        grease_type: greaseType,
      },
    })
  }

  replaceUnitOnZone = ({
    zoneId,
    wiresId,
    status,
    eventReason = null,
  }: {
    zoneId: number | null
    wiresId: Array<number> | null
    status: DictionaryValue
    eventReason?: TEventReason | null
  }) => {
    return this.setResource('/api/process/perform/replace-unit-between-zones', {
      processName: 'warehouseUnit',
      processType: 'start',
      eventReason,
      nodeId: zoneId,
      unitIds: wiresId,
      additionalAttributes: {
        warehouseAlias: 'warehouse.ugv.input',
        'wire_rod.status': status,
      },
      accompanyingOperations: [
        {
          processName: 'warehouseUnit',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          additionalAttributes: {},
        },
      ],
    })
  }

  replaceUnitOnZoneOutputGvc = ({
    zoneId,
    wiresId,
    eventReason,
  }: {
    zoneId: number | null
    wiresId: Array<number> | null
    eventReason?: TEventReason | null
  }) => {
    return this.setResource('/api/process/perform/replace-unit-between-zones', {
      processName: 'warehouseUnit',
      processType: 'start',
      eventReason,
      nodeId: zoneId,
      unitIds: wiresId,
      additionalAttributes: {},
      accompanyingOperations: [
        {
          processName: 'warehouseUnit',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          additionalAttributes: {},
        },
      ],
    })
  }

  closeUnit = (
    zoneId: number,
    unitId: Array<number>,
    invoiceNumber: string,
    eventReason?: TEventReason,
  ) => {
    return this.setResource('/api/process/perform/', {
      processName: 'warehouseUnit',
      eventReason,
      processType: 'close',
      nodeId: zoneId,
      unitIds: unitId,
      additionalAttributes: { invoice_number: invoiceNumber },
    })
  }

  addLabRequest = ({
    analyzes,
    shop,
    probe,
    program,
    experimentGoal,
    labGroup,
    labClass,
    group,
    labNote,
  }: {
    analyzes: any
    shop: string
    probe: number
    program: number
    experimentGoal: string
    labGroup: string
    labClass: string
    group: string
    labNote: string
  }) => {
    return this.setResource('/api/labs/requests', {
      analyzes,
      laboratory: 28,
      shop: Number(shop),
      system: 38,
      probe,
      program,
      experimentGoal,
      labGroup,
      labClass,
      group,
      labNote,
    })
  }

  editLabRequest = ({
    analyzes,
    reqId,
  }: {
    analyzes: Array<{ id: string; attributes: Array<string> }>
    reqId: number | undefined
  }) => {
    return this.putResource(`/api/labs/requests/${reqId}/update-analyzes`, [...analyzes])
  }

  linkSamples = ({ tagId, reqId }: { tagId: Array<string> | number; reqId: string }) => {
    return this.setResource(`/api/labs/samples/link-samples-to-request`, {
      requestId: reqId,
      sampleBarcodes: tagId,
    })
  }

  unLinkSamples = ({ tagId }: { tagId: Array<string> | number }) => {
    return this.setResource('/api/labs/samples/unlink-samples', {
      sampleBarcodes: tagId,
    })
  }

  deleteLabRequest = (id: number) => {
    return this.deleteResource(`/api/labs/requests/${id}`)
  }

  sendLabRequest = (id: number) => {
    return this.patchResource(`/api/labs/requests/${id}/send`, {})
  }

  addWireRod = (data: any) => {
    return this.setResource('/api/unit/', {
      id: 0,
      type: 'wire_rod',
      attributes: { ...data },
    })
  }

  setWireRodOnZone = ({
    wireRodId,
    zoneId,
    eventReason,
  }: {
    wireRodId: number
    zoneId: number
    eventReason?: TEventReason
  }) => {
    return this.setResource('/api/process/perform/', {
      processName: 'warehouseUnit',
      processType: 'start',
      eventReason,
      nodeId: zoneId,
      unitIds: [wireRodId],
      additionalAttributes: {},
      accompanyingOperations: [],
      accompanyingUnits: [],
    })
  }

  getLogMessages = (
    currentPage: number,
    address?: string,
    from?: string,
    messageDirection?: string,
    to?: string,
    globalSearch?: string,
    status?: string,
  ) => {
    return this.setResource(`/api-integration/message`, {
      additionalFilter: {
        address,
        from,
        messageDirection,
        to,
        globalSearch,
        status,
      },
      direction: 'DESC',
      pageNumber: currentPage,
      pageSize: 15,
      sortField: ['createdDate'],
    })
  }

  reprocessMessages = (messageId: string[]) => {
    return this.setResource(`/api-integration/message/reprocess`, messageId)
  }

  resendMessages = (messageId: string[]) => {
    return this.setResource(`/api-integration/message/resend`, messageId)
  }

  processNewMessages = (message: string, messageAddress: string) => {
    return this.setResource(`/api-integration/message/process`, {
      message: JSON.parse(message),
      messageAddress,
    })
  }

  sendNewMessages = (message: string, messageAddress: string) => {
    return this.setResource(`/api-integration/message/send`, {
      message: JSON.parse(message),
      messageAddress,
    })
  }

  startWeightingProduct = (
    unitId: number,
    brigade: number,
    wireStatus: DictionaryValue,
    pcName?: DictionaryValue,
    eventReason?: TEventReason,
  ) => {
    return this.setResource('/api/process/perform/start-weighting-product', {
      processName: 'weightCardProduct',
      processType: 'start',
      nodeId: null,
      eventReason,
      unitIds: [unitId],
      additionalAttributes: {},
      accompanyingOperations: [
        {
          processName: 'warehouseUnit',
          processType: 'start',
          nodeId: 27245,
          unitIds: [unitId],
          additionalAttributes: {
            warehouseAlias: 'warehouse.spc.output',
            'wire.status': wireStatus,
          },
          accompanyingOperations: [
            {
              processName: 'warehouseUnit',
              processType: 'close',
              nodeId: null,
              unitIds: [],
              additionalAttributes: {},
            },
          ],
        },
      ],
      accompanyingUnits: [
        {
          id: 0,
          type: 'weight_card',
          attributes: {
            brigade,
            pc_name: pcName,
          },
        },
      ],
    })
  }

  completeWeightingProduct = (
    unitId: number,
    nodeId: number,
    weightCardStatus: DictionaryValue,
  ) => {
    return this.setResource('/api/process/perform/complete-weighting-product', {
      processName: 'weightCardProduct',
      processType: 'close',
      nodeId,
      unitIds: [unitId],
      additionalAttributes: {},
      accompanyingOperations: [],
      accompanyingUnits: [
        {
          id: nodeId,
          type: 'weight_card',
          attributes: {
            status: weightCardStatus,
          },
        },
      ],
    })
  }

  handleDisassemblePallet = (palletId: string) => {
    return this.setResource(`/api/pallet/disassemble/${palletId}`, null)
  }

  createInvoice = () => {
    return this.setResource('/api/process/perform/invoice', {
      processName: 'warehouseInvoice',
      processType: 'start',
      nodeId: null,
      unitIds: [],
      startDateTime: null,
      finishDateTime: null,
      additionalAttributes: { nodeAliasLike: 'warehouse.spc_gvc.output' },
      accompanyingOperations: [
        {
          processName: 'invoiceProduct',
          processType: 'start',
          nodeId: null,
          unitIds: [],
          startDateTime: null,
          finishDateTime: null,
          additionalAttributes: {},
          accompanyingOperations: [],
          accompanyingUnits: [],
        },
        {
          processName: 'warehouseInvoice',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          startDateTime: null,
          finishDateTime: null,
          additionalAttributes: {},
          accompanyingOperations: [],
          accompanyingUnits: [],
        },
        {
          processName: 'invoiceProduct',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          startDateTime: null,
          finishDateTime: null,
          additionalAttributes: {},
          accompanyingOperations: [],
          accompanyingUnits: [],
        },
      ],
      accompanyingUnits: [{ id: 0, type: 'invoice_document', alias: null }],
    })
  }

  downloadDictionary = (
    selectedType: string,
    selectedWorkStatus: string,
    dateOper: boolean,
    dictionaryName?: string,
  ) => {
    return this.setResource(
      `/api/dictionary/xlsx/${selectedType}/${selectedWorkStatus}?date_oper=${dateOper}`,
      null,
      true,
      `${dictionaryName}.xlsx`,
    )
  }

  replaceUnitsByInvoice = (eventReason?: TEventReason) => {
    return this.setResource('/api/process/perform/replace-units-by-invoice', {
      processName: 'warehouseInvoice',
      processType: 'close',
      nodeId: null,
      unitIds: [],
      startDateTime: null,
      eventReason,
      finishDateTime: null,
      additionalAttributes: { nodeAliasLike: 'warehouse.spc_gvc.output' },
      accompanyingOperations: [
        {
          processName: 'invoiceProduct',
          processType: 'close',
          nodeId: null,
          unitIds: [],
          startDateTime: null,
          finishDateTime: null,
          additionalAttributes: {},
          accompanyingOperations: [],
          accompanyingUnits: [],
        },
        {
          processName: 'warehouseUnit',
          processType: 'start',
          nodeId: null,
          unitIds: [],
          additionalAttributes: { warehouseAlias: 'warehouse.gvc_virtual.input' },
          accompanyingOperations: [
            {
              processName: 'warehouseUnit',
              processType: 'close',
              nodeId: null,
              unitIds: [],
              additionalAttributes: {},
            },
          ],
        },
      ],
      accompanyingUnits: [{}],
    })
  }

  createNewDowntime = ({
    aggregateId,
    startDateTime,
    finishDateTime = null,
    note,
  }: {
    aggregateId: number
    startDateTime: string | null
    finishDateTime?: string | null
    note: DictionaryValue
  }) => {
    return this.setResource<ResponseCUDDowntime>('/api/process/perform/create-downtime', {
      processName: 'aggregateDowntime',
      processType: 'start',
      nodeId: aggregateId,
      unitIds: [],
      startDateTime,
      finishDateTime,
      additionalAttributes: {
        nodeAliasLike: 'aggregate',
      },
      accompanyingOperations: [],
      accompanyingUnits: [
        {
          id: 0,
          type: 'downtime',
          alias: null,
          attributes: {
            note,
          },
        },
      ],
    })
  }

  editNewDowntime = ({
    aggregateId,
    downtimeId,
    startDateTime = null,
    finishDateTime = null,
    note,
  }: {
    aggregateId: number
    downtimeId: number
    startDateTime?: string | null
    finishDateTime?: string | null
    note: DictionaryValue
  }) => {
    return this.putResource<ResponseCUDDowntime>('/api/process/perform/update-downtime', {
      processName: 'aggregateDowntime',
      processType: 'update',
      nodeId: aggregateId,
      unitIds: [downtimeId],
      startDateTime,
      finishDateTime,
      additionalAttributes: {
        nodeAliasLike: 'aggregate',
      },
      accompanyingOperations: [],
      accompanyingUnits: [
        {
          id: downtimeId,
          type: 'downtime',
          alias: null,
          attributes: {
            note,
          },
        },
      ],
    })
  }

  closeNewDowntime = ({ aggregateId, downtimeId }: { aggregateId: number; downtimeId: number }) => {
    return this.putResource<ResponseCUDDowntime>('/api/process/perform/close-downtime', {
      processName: 'aggregateDowntime',
      processType: 'close',
      nodeId: aggregateId,
      unitIds: [downtimeId],
      startDateTime: null,
      finishDateTime: null,
      additionalAttributes: {
        nodeAliasLike: 'aggregate',
      },
      accompanyingOperations: [],
      accompanyingUnits: [],
    })
  }

  addDowntime = ({
    kochId,
    kochName,
    startTime,
    endTime,
    note,
  }: {
    kochId: string
    kochName: string
    startTime: string
    endTime: string
    note: string
  }) => {
    return this.setResource('/api/aggregate/downtime', {
      alias: 'whatever_alias',
      type: 'downtime',
      attributes: {
        aggregateId: kochId,
        aggregateName: kochName,
        tm_beg: startTime,
        tm_end: endTime,
        note,
      },
    })
  }

  editDowntime = ({
    id,
    startTime,
    endTime,
    note,
  }: {
    id: number
    startTime: string
    endTime: string
    note: string
  }) => {
    return this.patchResource('/api/unit/', {
      alias: 'whatever_alias',
      type: 'downtime',
      id,
      attributes: {
        tm_beg: startTime,
        tm_end: endTime,
        note,
      },
    })
  }

  deleteDowntime = (id: number) => {
    return this.deleteResource(`/api/unit/id/${id}`)
  }

  copyShift = ({
    sectionId,
    sourceShiftDate,
    targetShiftId,
    transferBalance,
  }: {
    sectionId: number
    targetShiftId: number
    sourceShiftDate: string
    transferBalance: boolean
  }) => {
    return this.setResource(`/api/shift/copy${transferBalance ? `?transferBalance=true` : ``}`, {
      sectionId,
      sourceShiftDate,
      targetShiftId,
    })
  }

  addZinc = ({
    sectionName,
    sectionId,
    startDateTime,
    weight,
    type,
  }: {
    sectionName?: string
    sectionId: number
    startDateTime: string
    weight: string
    type: DictionaryValue
  }) => {
    return this.setResource('/api/process/perform/create-zinc', {
      processName: 'sectionZinc',
      processType: 'start',
      nodeId: sectionId,
      unitIds: [],
      startDateTime,
      finishDateTime: null,
      additionalAttributes: {
        nodeAliasLike: sectionName,
      },
      accompanyingOperations: [],
      accompanyingUnits: [
        {
          id: 0,
          type: 'zinc',
          alias: null,
          attributes: {
            weight,
            type,
          },
        },
      ],
    })
  }

  editZinc = ({
    unitId,
    sectionId,
    startDateTime,
    weight,
    type,
  }: {
    unitId: number
    sectionId: number
    startDateTime: string
    weight: string
    type: DictionaryValue
  }) => {
    return this.putResource('/api/process/perform/update-zinc', {
      processName: 'sectionZinc',
      processType: 'update',
      nodeId: sectionId,
      unitIds: [unitId],
      startDateTime,
      finishDateTime: '',
      additionalAttributes: {},
      accompanyingOperations: [],
      accompanyingUnits: [
        {
          id: unitId,
          type: 'zinc',
          alias: null,
          attributes: {
            weight,
            type,
          },
        },
      ],
    })
  }

  deleteZinc = (id: number) => {
    return this.deleteResource(`/api/unit/id/${id}`)
  }

  updateUnit = <A extends Record<string, unknown>>({
    id,
    alias,
    type,
    attributes,
  }: {
    id: number
    alias: string
    type: string
    attributes: Partial<
      {
        [Key in keyof A]: A[Key]
      }
    >
  }) => {
    return this.patchResource('/api/unit/', {
      id,
      alias,
      type,
      attributes,
    })
  }

  updateUnits = <A extends Record<string, unknown>>(
    units: Array<{
      id: number
      alias?: string | null
      type?: string | null
      attributes: A
    }>,
  ) => {
    return this.patchResource('/api/unit/list', units)
  }

  setStatusOtk = <A extends Record<string, unknown>>({
    units,
    clearDefect = false,
  }: {
    units: Array<{
      id: number
      alias?: string | null
      type?: string | null
      attributes: A
    }>
    clearDefect?: boolean
  }) => {
    return this.patchResource(`/api/unit/list/set-status-otk?clear-defect=${clearDefect}`, units)
  }

  shareSamples = (parent: number, chields: Array<number>) => {
    return this.setResource('/api/labs/samples/share-lab-results', {
      processName: 'shareLabResult',
      processType: 'start',
      nodeId: parent,
      unitIds: chields,
      additionalAttributes: {},
      accompanyingOperations: [],
    })
  }

  unshareSamples = (ids: Array<number>) => {
    return this.setResource('/api/labs/samples/unshare-lab-results', {
      processName: 'shareLabResult',
      processType: 'close',
      nodeId: null,
      unitIds: ids,
      additionalAttributes: {},
      accompanyingOperations: [],
    })
  }

  updateTaskOrderCode = (id: number, orderCode: string) => {
    return this.patchResource('/api/unit/', {
      alias: 'task',
      type: 'task',
      id,
      attributes: {
        order_code: orderCode,
      },
    })
  }

  uploadShiftFile = (sectionId: number, formData: FormData) => {
    return this.request({
      url: `/api/shift/${sectionId}/uploadFile`,
      body: formData,
    })
  }

  fibImportTask = (formData: FormData) => {
    return this.request({
      url: `/api/shift/fib/importTask`,
      body: formData,
    })
  }

  startAllShiftTasks = ({ sectionId, shiftId }: { sectionId: number; shiftId: number }) => {
    return this.setResource<{ noValid: ResponseMasterTasks[] }>(
      '/api/process/perform/start-all-new-task-on-aggregate',
      {
        sectionId,
        shiftId,
      },
    )
  }

  removeAllShiftTasks = ({ sectionId, shiftId }: { sectionId: number; shiftId: number }) => {
    return this.deleteResource('/api/process/perform/delete-all-new-task-on-aggregate', {
      sectionId,
      shiftId,
    })
  }

  getDictionariesNames = () => {
    return this.getResource<{ content: ResponseDictionariesNames }>('/api/dictionary/getAllNames')
  }

  getDictionariesValues = (dictionariesNames: string[]) => {
    return this.setResource<{ content: ResponseDictionariesValues }>(
      '/api/dictionary/list/getAllActualValues',
      dictionariesNames,
    )
  }

  getComplexDictionariesValues = (dictionariesNames: ComplexDictionaryName[]) => {
    return this.setResource<{ content: ComplexDictionaries }>(
      '/api/dictionary/list/getAllActual',
      dictionariesNames,
    )
  }

  getDictionariesInfo = () => {
    return this.getResource<{ content: ResponseDictionaryInfo[] }>(
      '/api/dictionary/getAllNames/info',
    )
  }

  getDictionary = ({
    dictionaryName,
    listFilter = [],
    page,
  }: {
    dictionaryName: string
    listFilter?: DictionaryFilter[]
    page: number
  }) => {
    return this.setResource<ResponseDictionary>(
      `/api/dictionary/getAllActualWithColumn/${dictionaryName}?page=${page}&size=15`,
      listFilter,
    )
  }

  getAllTypesInfo = () => {
    return this.getResource<ResponseAllTypesInfo>('/api/dictionary/getAllTypes/info')
  }

  getAllWithNodes = ({
    dictionaryType,
    workStatus = DICTIONARY_WORK_STATUS.OPEN,
    listFilter = [],
    page,
  }: {
    dictionaryType: string
    workStatus?: DICTIONARY_WORK_STATUS
    listFilter?: DictionaryFilter[]
    page: number
  }) => {
    return this.setResource<ResponseAllWithNodes>(
      `/api/dictionary/type/allWithNodes/${dictionaryType}/${workStatus}?page=${page}&size=15&sort=id,desc`,
      listFilter.length > 0 ? listFilter : undefined,
    )
  }

  getAllAddNodes = () => {
    return this.getResource<ResponseAllNodes>('/api/dictionary/node/all-add-nodes')
  }

  getNodesByShop = (shop: string) => {
    return this.getResource<ResponseAllNodes>(`/api/dictionary/node/nodes-by-shop/${shop}`)
  }

  getNotAddNodes = () => {
    return this.getResource<ResponseNotAddNodes>('/api/dictionary/node/generate/all-name-not-add')
  }

  addNode = (node: ResponseNotAddNodes['content'][number]) => {
    return this.setResource('/api/dictionary/node/add', node)
  }

  removeNode = (nodeId: number) => {
    return this.deleteResource(`/api/dictionary/node/delete/${nodeId}`)
  }

  getUpdateIsAvailable = (id: number) => {
    return this.getResource<ResponesUpdateIsAvailable>(`/api/dictionary/update/check/${id}`)
  }

  updateDictionaryValue = (id: number, data: Record<string, unknown>) => {
    return this.putResource<{ content: ResponseAllWithNodes['content']['content'][number] }>(
      `/api/dictionary/update/${id}`,
      data,
    )
  }

  addDictionaryValue = (
    type: string,
    value: Record<string, string | number | Date | undefined>,
    isStartNow = false,
  ) => {
    return this.setResource<{ content: ResponseAllWithNodes['content']['content'][number] }>(
      '/api/dictionary/add',
      {
        ...value,
        startDateTime: isStartNow ? null : getFilterFullDate(new Date(value.startDateTime as Date)),
        start: isStartNow,
        type,
      },
    )
  }

  deleteDictionaryValue = (id: number) => {
    return this.deleteResource(`/api/dictionary/delete/${id}`)
  }

  approveDictionaryValues = (dictionaryIds: number[]) => {
    return this.patchResource<{ content: { listIds: number[]; dateTime: string } }>(
      '/api/dictionary/list/add/approve-date',
      dictionaryIds,
    )
  }

  closeDictionaryValues = (listIds: number[], dateTime?: Date) => {
    return this.patchResource<{ content: { listIds: number[]; dateTime: string } }>(
      '/api/dictionary/list/add/close-date',
      {
        dateTime: dateTime ?? null,
        listIds,
      },
    )
  }

  bindNodeToDictionary = (dictionaryIds: number[], nodeIds: number[]) => {
    return this.setResource<{ content: { dictionaryIds: number[]; nodeIds: number[] } }>(
      '/api/dictionary/node-to-dictionary/list/binding',
      {
        dictionaryIds,
        nodeIds,
      },
    )
  }

  unbindNodeToDictionary = (dictionaryIds: number[], nodeIds: number[]) => {
    return this.deleteResource<{ content: { dictionaryIds: number[]; nodeIds: number[] } }>(
      '/api/dictionary/node-to-dictionary/list/unbinding',
      {
        dictionaryIds,
        nodeIds,
      },
    )
  }

  getDependentDictionary = (dictionaryType: string) => {
    return this.getResource<{ content: DependentDictionary[] }>(
      `/api/dictionary/get-all-actual-values/type/${dictionaryType}`,
    )
  }

  getCancelEventsList = (eventId: string) => {
    return this.setResource(`/api/event/${eventId}/cancel`, null)
  }

  setCancelOperations = (spkId: string, heatNumber: string, aggregateName: string) => {
    return this.setResource(`/api/event`, {
      spkId,
      heatNumber,
      aggregateName,
    })
  }

  setOTKStatus = ({
    areaAlias,
    productTypeAlias,
    warehouseAlias,
    status = 'Годен',
    currentShift = true,
  }: {
    areaAlias: string
    productTypeAlias: string
    warehouseAlias: string
    status?: 'Годен' | 'Негоден'
    currentShift?: boolean
  }) => {
    return this.patchResource<{ content: Record<string, number> }>(
      `/api/unit/set-status_otk/having-no-status_otk/${areaAlias}/${productTypeAlias}/${warehouseAlias}`,
      {
        statusOTK: status,
        currentShift,
      },
    )
  }

  cancelLastEvent = (eventId: number) => {
    return this.setResource(`/api/event/${eventId}/cancel`, {})
  }

  // TODO: add sort [&sort=<параметры сортировки>]
  getProductsByShop = ({
    productType,
    shopId,
    page = 0,
    size = 15,
    filter = {},
  }: {
    productType: ProductType
    shopId: number
    page?: number
    size?: number
    filter?: Record<string, string>
  }) => {
    const convertedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      return `${acc}&${key}=${value}`
    }, '')
    return this.getResource<GlobalSearchProductsByShopResponse>(
      `/api/unit/product/globalSearch/${productType}/${shopId}/search?page=${page}&size=${size}&sort=startDateTime,desc${convertedFilter}`,
    )
  }

  getProductsByInvoice = ({
    productType,
    invoice,
    page = 0,
    size = 25,
    filter = {},
  }: {
    productType: ProductType
    invoice: string
    page?: number
    size?: number
    filter?: Record<string, string>
  }) => {
    const convertedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      return `${acc}&${key}=${value}`
    }, '')
    return this.getResource<{
      content: {
        calculations: {
          operation: string
          result: number
        }[]
        data: {
          content: SearchProduct[]
          totalElements: number
          totalPages: number
        }
      }
    }>(
      `/api/unit/product/globalSearch/${productType}/search?page=${page}&size=${size}&sort=startDateTime,desc&deliveryNum=${invoice}&filter=calculations=(sum=weight)${convertedFilter}`,
    )
  }

  getGenealogyTreeByUnitId = ({ unitId, base = false }: { unitId: string; base?: boolean }) => {
    return this.getResource<GenealogyTreeResponse>(
      `/api/genealogy/production-tree/unit/${unitId}?base=${base}`,
    )
  }

  getGenealogyTreeByBarcode = ({ barcode, base = false }: { barcode: string; base?: boolean }) => {
    return this.getResource<GenealogyTreeResponse>(
      `/api/genealogy/production-tree/barcode/${barcode}?base=${base}`,
    )
  }

  preGlobalSearch = (status: string, value: string) => {
    return this.getResource(
      `/api/dictionary/global-search/type-count/status/${status}?value=${value}`,
    )
  }

  globalDictionarySearch = (status: string, value: string) => {
    return this.getResource(
      `/api/dictionary/global-search/all/status/${status}?value=${value}&page=0&size=15`,
    )
  }

  dictionarySearchByType = (type: string, value: string, page: number, pageSize: number) => {
    return this.getResource(
      `/api/dictionary/global-search/type/${type}?value=${value}&page=${page}&size=${pageSize}`,
    )
  }

  collectMultipallet = (data: {
    barcode: number
    aggregate: string
    selectedPallets: {
      unitId: number
      boxCount: number
      boxWeight: number
    }[]
  }) => {
    return this.setResource('/api/pallet/collect', data)
  }

  writeOffResiduals = (id: number | undefined, weight: string, masterName: string) => {
    return this.setResource('/api/residuals/write-off', {
      residualsId: id,
      warehouse: `warehouse.${masterName}.residuals`,
      weight: Number(weight),
    })
  }

  getTemplates = <ExtraAttr extends ExtraTemplateAttributes = ExtraTemplateAttributes>(
    place: string,
  ) => {
    return this.getResource<{ content: Template<ExtraAttr>[] }>(`/api/template/place/${place}`)
  }

  addTemplate = <ExtraAttr extends ExtraTemplateAttributes = ExtraTemplateAttributes>({
    place,
    name,
    pattern,
    ...rest
  }: ExtraAttr & {
    place: string
    name: string
    pattern: string
  }) => {
    return this.setResource<{ content: Template<ExtraAttr> }>('/api/template/add', {
      type: 'template',
      alias: null,
      attributes: {
        place,
        name,
        pattern,
        ...rest,
      },
    })
  }

  editTemplate = <ExtraAttr extends ExtraTemplateAttributes = ExtraTemplateAttributes>(
    id: number,
    attributes: ExtraAttr & {
      pattern?: string
    },
  ) => {
    return this.patchResource<{ content: Template<ExtraAttr> }>(`/api/template/update/${id}`, {
      type: 'template',
      alias: null,
      attributes,
    })
  }

  removeTemplate = (id: number) => {
    return this.deleteResource(`/api/template/delete/${id}`)
  }

  getAggregates = (shopAlias: string) => {
    return this.getResource<{ content: { id: number; value: string }[] }>(
      `/api/dictionary/aggregate/shop-alias/${shopAlias}`,
    )
  }

  getNextCoilNumber = (aggregateId: number) => {
    return this.setResource<{ content: number }>(
      `/api/aggregate/${aggregateId}/winder/nextCoilNumber`,
    )
  }

  getInvoiceNumbers = (from: Date, to: Date) => {
    const fromDate = getFilterFullDate(from)
    const toDate = getFilterFullDate(to)
    return this.getResource<{ content: string[] }>(
      `/api/unit/wire-rod/delivery-number?from=${fromDate}&to=${toDate}`,
    )
  }

  getLabTestResults = ({
    date,
    laboratorian,
    objectTypeId,
  }: {
    date: Date
    laboratorian?: string
    objectTypeId: number
  }) => {
    const dateQuery = dayjs(date).format('YYYY-MM-DD')
    const laboratorianQuery = laboratorian ? `&laboratorian=${laboratorian}` : ''

    const query = `?date=${dateQuery}&objectTypeId=${objectTypeId}${laboratorianQuery}`

    return this.getResource<{ content: LabTestResult[] }>(`/api/labTestResults/table/on${query}`)
  }

  getInvoices = ({ warehouse, filter, page, sort = 'created,desc' }: InvoiceRequest) => {
    return this.setResource<InvoicesResponse>(
      `/api/invoice/${warehouse}/list?pageNumber=${page}&sort=${sort}`,
      filter,
    )
  }

  getInvoiceProducts = (invoiceId: number) => {
    return this.getResource(`/api/invoice/${invoiceId}`)
  }

  removeInvoiceProducts = (invoiceId: number, productIds: number[]) => {
    return this.setResource(`/api/invoice/${invoiceId}/remove`, productIds)
  }

  shipInvoice = (invoiceId: number) => {
    return this.setResource<InvoiceResponse>(`/api/invoice/${invoiceId}/ship`)
  }

  downloadUopTasks = (formData: FormData) => {
    return this.request({
      url: `/api/shift/import/task/uto`,
      body: formData,
    })
  }

  getReport = (data: ReportProps) => {
    return this.downloadResource('/api/generator/export-data/xlsx', data)
  }
}

const service = new Service()

export default service
