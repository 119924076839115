// import { useAlert } from 'react-alert';
import { useCallback, useState } from 'react'

import { BindNodes } from '../types'
import service from 'services/service'

export type UseBindNodeReturn = {
  isLoading: boolean
  bindNode: (value: BindNodes) => Promise<void>
}

export const useBindNode = (onSuccess: (value: BindNodes) => void): UseBindNodeReturn => {
  // const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false)

  const bindNode = useCallback<UseBindNodeReturn['bindNode']>(
    async ({ dictionaryIds, nodeIds }) => {
      try {
        setIsLoading(true)

        const response = await service.bindNodeToDictionary(dictionaryIds, nodeIds)

        onSuccess(response.content)
      } catch (e) {
        // alert.error(String(e));
      } finally {
        setIsLoading(false)
      }
    },
    [alert, onSuccess],
  )

  return {
    isLoading,
    bindNode,
  }
}
