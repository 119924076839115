import { numberedDateByLevel } from '../../../helpers/numberedDateByLevel'
import { quarterQuarterKeys } from '../../../helpers/quarters'
import { quarterParams, useIsSelectedQuarterParams } from '../types'

export const useIsSelectedQuarter = ({
  withPeriod,
  dateFrom,
  dateTo,
  selectedDate,
  panelValue,
}: useIsSelectedQuarterParams) => (dayAsDate: Date, quarter: quarterParams) => {
  const numberedDateFrom =
    dateFrom &&
    numberedDateByLevel(
      new Date(dateFrom.getFullYear(), quarterQuarterKeys[dateFrom.getMonth()]),
      'quarter',
    )
  const numberedDateTo =
    dateTo &&
    numberedDateByLevel(
      new Date(dateTo.getFullYear(), quarterQuarterKeys[dateTo.getMonth()]),
      'quarter',
    )
  const numberedDayAsDate = numberedDateByLevel(
    new Date(dayAsDate.getFullYear(), quarterQuarterKeys[dayAsDate.getMonth()]),
    'quarter',
  )
  return Boolean(
    withPeriod
      ? (withPeriod &&
          numberedDateFrom &&
          numberedDateTo &&
          numberedDayAsDate < numberedDateTo &&
          numberedDayAsDate > numberedDateFrom) ||
          (withPeriod && numberedDateTo === numberedDayAsDate) ||
          (withPeriod && numberedDateFrom === numberedDayAsDate)
      : selectedDate && !!selectedDate?.getMonth()
      ? selectedDate?.getFullYear() === panelValue?.getFullYear() &&
        quarter.values.includes(selectedDate?.getMonth())
      : selectedDate?.getFullYear() === panelValue?.getFullYear() &&
        selectedDate?.getMonth() === dayAsDate.getMonth(),
  )
}
