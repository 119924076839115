/**
 * Приводит нативный date к dd.MM.yy
 */
export const convertNativeDateToFormatDate = (date: Date): string =>
  date.toLocaleDateString('ru-RU')

/**
 * Приводит нативный date к HH:mm
 */
export const convertNativeDateToFormatTime = (date: Date): string =>
  [pad(date.getHours()), pad(date.getMinutes())].join(':')

/**
 * Приводит дату к "название дня недели"
 */
export const getLocalizedWeekDay = (date: Date): string => {
  const weekDay = date.toLocaleDateString('ru-RU', { weekday: 'long' })

  return weekDay[0].toUpperCase() + weekDay.slice(1)
}

const pad = (n: number) => (n < 10 ? '0' + n : n)
