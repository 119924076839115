export const DICTIONARY_REDUCER_PREFIX = 'DICTIONARY-TABLE'

export type SearchTable = {
  name: string
  rows: any
  columns: any
}

export type SearchTableQuantityFound = {
  name: string
  count: string
}

export const columnsQuantityFound = [
  { name: 'name', title: 'Наименование справочника', width: '700px' },
  { name: 'count', title: 'Количество совпадений', width: '700px' },
]

export const pageSizeValues = [
  { name: '2 записи', value: 2 },
  { name: '3 записи', value: 3 },
  { name: '10 записей', value: 10 },
  { name: '20 записей', value: 20 },
]
