import React from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconExternalLink24: React.FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9888 4.06759C13.6315 4.19494 13.4336 4.51034 13.432 4.95486C13.4307 5.31671 13.5628 5.56627 13.8496 5.744L14.0629 5.8762L15.4293 5.89013L16.7957 5.9041L14.2147 8.49017C12.1354 10.5737 11.6233 11.1094 11.5798 11.2468C11.4129 11.7731 11.8094 12.3839 12.3605 12.4491C12.8042 12.5016 12.704 12.5864 15.5105 9.78588L18.0967 7.20509L18.1107 8.57139L18.1246 9.93765L18.2568 10.151C18.4346 10.4378 18.6842 10.5699 19.046 10.5685C19.504 10.5669 19.8476 10.3369 19.9516 9.9622C20.0209 9.71286 20.0136 4.83795 19.9436 4.60438C19.8745 4.37355 19.6274 4.12646 19.3965 4.05732C19.1212 3.97482 14.2228 3.98412 13.9888 4.06759ZM5.57383 4.97355C4.80646 5.10619 4.17144 5.75682 4.04284 6.54221C3.98572 6.89093 3.98572 18.038 4.04284 18.3867C4.17483 19.1928 4.80721 19.8252 5.6134 19.9572C5.96214 20.0143 17.1098 20.0143 17.4586 19.9572C18.2759 19.8233 18.9039 19.1861 19.0325 18.3601C19.0584 18.1936 19.071 17.2583 19.0633 16.0756C19.0511 14.2182 19.0441 14.0618 18.9677 13.9187C18.8527 13.7036 18.7658 13.6131 18.5874 13.5227C18.0446 13.2476 17.3732 13.5009 17.2243 14.0368C17.1946 14.1437 17.1775 14.9164 17.1774 16.1552L17.1771 18.1052H11.536H5.89488V12.4645V6.82368L7.84503 6.82341C9.13755 6.82328 9.85459 6.80671 9.97133 6.77428C10.2417 6.69923 10.4993 6.41512 10.5615 6.12347C10.6624 5.65075 10.4941 5.25444 10.1037 5.04525C9.93119 4.95279 9.8828 4.95063 7.85605 4.94393C6.71681 4.94019 5.68982 4.9535 5.57383 4.97355Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconExternalLink24
