import React from 'react'

import { shifts } from '../../helpers/shifts'
import clsx from 'clsx'

import { ShiftSelectorProps } from './types'

import { useShiftSelectorStyles } from './styles'

export const ShiftSelector: React.FC<ShiftSelectorProps> = ({
  value,
  shiftLength = 2,
  onChange,
  disabled = false,
}) => {
  const classes = useShiftSelectorStyles()
  const handleClick = (shift: number) => {
    if (!disabled) {
      onChange(shift)
    }
  }
  return (
    <div className={classes.root}>
      {shifts[shiftLength].map((shift) => (
        <div
          key={shift}
          className={clsx(classes.shift, {
            [classes.disabled]: disabled,
            [classes.selected]: value === shift && !disabled,
            [classes.selectedDisabled]: value === shift && disabled,
          })}
          onClick={() => handleClick(shift)}
        >
          {shift}
        </div>
      ))}
    </div>
  )
}
