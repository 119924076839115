import { quarterQuarterKeys } from './quarters'

export function numberedDateByLevel(date: Date, level: string): number {
  switch (level) {
    case 'day': {
      return +new Date(date.getFullYear(), date.getMonth(), date.getDate())
    }
    case 'quarter': {
      return +new Date(date.getFullYear(), quarterQuarterKeys[date.getMonth()])
    }
    case 'month': {
      return +new Date(date.getFullYear(), date.getMonth())
    }
    case 'year': {
      return +new Date(date.getFullYear())
    }
    default: {
      return +date
    }
  }
}
