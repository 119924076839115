import { TableColumn } from '../../../Table/types'

export function getInitialViews<T>(columns: TableColumn<T>[]): TableColumn<T>[] {
  return columns.map((e) => ({
    ...e,
    visible: typeof e.visible !== 'undefined' ? e.visible : true,
    pinned: typeof e.pinned !== 'undefined' ? e.pinned : false,
  }))
}

// TODO удалить это, переписать
/**
 * @param srcArr - исходный массив объектов для "склейки"
 * @param overrideArr - перезаписывающий массив объектов для "склейки"
 * @param paramName - ключ по которому происходит "склейка"
 */
export function insertAndMergeArrays(srcArr: any[], overrideArr: any[], paramName) {
  const finalArr: any[] = []
  let count = 0

  for (const item of srcArr) {
    if (!overrideArr.some((el) => el[paramName] === item[paramName])) {
      finalArr.push(item)
    } else {
      finalArr.push(overrideArr[count])
      count++
    }
  }

  return finalArr
}
