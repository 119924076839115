import React, { FC, useEffect, useRef, useState } from 'react'

import { Box } from '../Box'
import { Typography } from '../Typography'
import { getNewDate } from './helpers'
import {
  convertNativeDateToFormatDate,
  convertNativeDateToFormatTime,
  getLocalizedWeekDay,
} from './helpers'
import clsx from 'clsx'

import { ClockProps } from './types'

import { Styles } from './styles'

export const Clock: FC<ClockProps> = ({ timeDifference }) => {
  const classes = Styles()
  const intervalIdRef = useRef<NodeJS.Timeout>()
  const [date, setDate] = useState(getNewDate(timeDifference))
  const formatDate = convertNativeDateToFormatDate(date)
  const weekDay = getLocalizedWeekDay(date)
  const time = convertNativeDateToFormatTime(date)

  useEffect(() => {
    const secondsDiff = intervalIdRef.current ? 60000 : (60 - date.getSeconds()) * 1000

    intervalIdRef.current = setInterval(() => {
      setDate(getNewDate(timeDifference))
    }, secondsDiff)

    return () => {
      intervalIdRef.current && clearInterval(intervalIdRef.current)
    }
  }, [date, timeDifference])

  return (
    <Box className={classes.boxClass}>
      <Typography variant="Body2-Medium" className={clsx(classes.root, 'test-clock-date')}>
        {formatDate}, {weekDay}
      </Typography>
      <Typography variant="Heading4" className="test-clock-time">
        {time}
      </Typography>
    </Box>
  )
}
