import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  addTypography: {
    color: '#167FFB',
  },
  drawer: {
    width: '416px',
  },
  footer: {
    borderTop: 'solid 1px rgba(0, 23, 41, 0.08)',
  },
  header: {
    fontWeight: 'bold',
  },
  tooltipTitle: {
    padding: '12px',
  },
  content: {
    padding: '8px 48px',
  },
  paper: {
    overflowY: 'unset',
  },
  closeBtn: {
    position: 'absolute',
    zIndex: 100,
    left: '-52px',
    top: '-3px',
  },
  footerContainer: {
    padding: '0px 0px 12px 36px',
    width: '100%',
  },
  backdropRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  closeIcon: {
    fontSize: '30px',
    color: '#fff',
  },
})
