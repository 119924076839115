import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconHelp32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M16 28C22.5647 28 28 22.5647 28 16C28 9.44706 22.5529 4 15.9882 4C9.43529 4 4 9.44706 4 16C4 22.5647 9.44706 28 16 28ZM16 26C10.4471 26 6.01176 21.5529 6.01176 16C6.01176 10.4588 10.4353 6 15.9882 6C21.5412 6 25.9882 10.4588 26 16C26.0118 21.5529 21.5529 26 16 26ZM15.7529 18.4353C16.3529 18.4353 16.7059 18.0706 16.7059 17.6V17.4588C16.7059 16.8 17.0941 16.3882 17.9059 15.8471C19.0471 15.0941 19.8588 14.4 19.8588 13C19.8588 11.0353 18.1176 9.98824 16.1294 9.98824C14.1059 9.98824 12.7765 10.9412 12.4471 12.0235C12.3882 12.2235 12.3529 12.4118 12.3529 12.6118C12.3529 13.1294 12.7647 13.4235 13.1529 13.4235C13.8235 13.4235 13.9294 13.0588 14.3059 12.6235C14.6941 11.9765 15.2588 11.5882 16.0471 11.5882C17.1176 11.5882 17.8235 12.1882 17.8235 13.0941C17.8235 13.8941 17.3176 14.2824 16.2941 14.9882C15.4471 15.5765 14.8235 16.2 14.8235 17.3529V17.5059C14.8235 18.1294 15.1529 18.4353 15.7529 18.4353ZM15.7294 21.9529C16.4118 21.9529 17 21.4118 17 20.7294C17 20.0353 16.4235 19.5059 15.7294 19.5059C15.0353 19.5059 14.4588 20.0471 14.4588 20.7294C14.4588 21.4 15.0471 21.9529 15.7294 21.9529Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconHelp32
