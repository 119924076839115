import React from 'react'

import { Typography as MUTypography } from '@material-ui/core'
import clsx from 'clsx'

import { TypographyProps } from './types'

import { useTypographyStyles } from './styles'

const deprecatedVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'button',
  'overline',
]

export const Typography: React.FC<TypographyProps> = ({
  variant = 'Body1',
  color = 'inherit',
  ...props
}) => {
  const classes = useTypographyStyles(props)

  /**
   * Deprecated
   * Предупреждаем пользователя об устаревающих вариантах
   */
  if (deprecatedVariants.includes(variant)) {
    console.log(
      `Typography. Используемый props.variant="${variant}" deprecated. Пожалуйста обновите вариант стиля в соответствии со стилем в макете.`,
    )
  }

  return (
    <MUTypography
      {...props}
      classes={{
        root: variant
          ? color
            ? clsx(classes.root, classes[variant], classes[color])
            : clsx(classes.root, classes[variant])
          : color
          ? clsx(classes.root, classes[color])
          : classes.root,
      }}
    />
  )
}
