import getFullDate from './getFullDate'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const SERVER_TIME_ZONE = 'Asia/Yekaterinburg'

const getServerDateTime = (date = new Date()): Date => {
  const serverDate = dayjs(date).tz(SERVER_TIME_ZONE)
  const [day, month, year, hours, minutes, seconds] = getFullDate(serverDate)
  const resultDate = new Date()
  resultDate.setDate(Number(day))
  resultDate.setMonth(Number(month) - 1)
  resultDate.setFullYear(Number(year))
  resultDate.setHours(Number(hours))
  resultDate.setMinutes(Number(minutes))
  resultDate.setSeconds(Number(seconds))
  return resultDate
}

export default getServerDateTime
