import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { getLastValue } from '../../helpers/getLastLevelDate'
import { months } from '../../helpers/months'
import { panelToCalendarMapping } from '../../helpers/panelToCalendarMapping'
import { CommonCalendar } from '../CommonCalendar'
import { TimeSelector } from '../TimeSelector'
import { ClickAwayListener } from '@material-ui/core'
import { set } from 'date-fns'

import { CalendarPanelProps } from './types'

export const CalendarPanel = React.forwardRef<HTMLDivElement, CalendarPanelProps>(
  (
    {
      level,
      value,
      onChange,
      onPeriodChange,
      disableChange,
      withPeriod,
      withShift,
      withTime,
      withSeconds,
      valueTo,
      valueFrom,
      shiftFrom,
      shiftTo,
      shiftLength,
      enabledTo,
      enabledFrom,
      enabledHourFrom,
      enabledHourTo,
      enabledMinuteFrom,
      enabledMinuteTo,
      onClose,
      onReset,
      disableChangesOnBlur,
      isOpenOnFocus,
      isHideYear,
      ...props
    },
    ref,
  ) => {
    const [selectedPanel, setSelectedPanel] = useState<'day' | 'month' | 'quarter' | 'year'>('day')
    const [innerValue, innerOnChange] = useState(withPeriod ? valueFrom : value)
    const [selectedTime, setSelectedTime] = useState(new Date())
    const [innerPeriodValue, innerPeriodOnChange] = useState<{ valueFrom?: Date; valueTo?: Date }>({
      valueFrom,
      valueTo,
    })
    const [panelValue, setPanelValue] = useState((withPeriod ? valueFrom : value) || new Date())
    const [innerShiftFrom, setInnerShiftFrom] = useState(shiftFrom ?? 1)
    const [innerShiftTo, setInnerShiftTo] = useState(shiftTo ?? 1)

    useEffect(() => {
      setSelectedPanel(level)
    }, [level])

    useEffect(() => {
      setInnerShiftFrom(shiftFrom ?? 1)
      setInnerShiftTo(shiftTo ?? 1)
    }, [shiftFrom, shiftTo])

    useEffect(() => {
      if (!withPeriod) {
        if (value) {
          innerOnChange(value)
          if (withTime) {
            setSelectedTime(value)
          }
        }
      }
    }, [value, withPeriod, withTime])

    const period = useMemo(() => {
      if (!panelValue) {
        return ''
      }
      if (selectedPanel === 'day') {
        return `${months[panelValue.getMonth()]}, ${panelValue.getFullYear()}`
      }
      if (selectedPanel === 'month' || selectedPanel === 'quarter') {
        return panelValue.getFullYear()
      }
      const currentYear = panelValue.getFullYear()
      return `${currentYear - 6} — ${currentYear + 5}`
    }, [panelValue, selectedPanel])

    const handleAccept = useCallback(() => {
      if (!innerValue || !onChange) {
        return
      }

      if (withPeriod && onPeriodChange) {
        const valueFrom = innerPeriodValue.valueFrom
        const valueTo = getLastValue(level, innerPeriodValue.valueTo, valueFrom)
        const withShiftFunc = (shift) => (withShift ? shift : undefined)
        const [shiftFrom, shiftTo] = [withShiftFunc(innerShiftFrom), withShiftFunc(innerShiftTo)]
        onPeriodChange(valueFrom, valueTo, shiftFrom, shiftTo)
      }

      if (!withTime || !selectedTime) {
        onChange(innerValue)
        onClose()
        return
      }

      let newValue = set(innerValue, {
        hours: selectedTime.getHours(),
        minutes: selectedTime.getMinutes(),
      })

      if (withSeconds) {
        newValue = set(newValue, { seconds: selectedTime.getSeconds() })
      }

      onChange(newValue)
      onClose()
    }, [
      innerPeriodValue.valueFrom,
      innerPeriodValue.valueTo,
      innerShiftFrom,
      innerShiftTo,
      innerValue,
      level,
      onChange,
      onClose,
      onPeriodChange,
      selectedTime,
      withPeriod,
      withSeconds,
      withShift,
      withTime,
    ])

    const handleDecline = useCallback(() => {
      onReset()
    }, [onReset])

    const handleSelect = useCallback(
      (date: Date) => {
        if (selectedPanel === 'day') {
          innerOnChange(date)
        }

        if (!withPeriod || !(level === 'year')) {
          setPanelValue(date)
        }

        if (selectedPanel === 'month' && level === 'day') {
          setSelectedPanel('day')
        }
        if (selectedPanel === 'year' && level === 'quarter') {
          setSelectedPanel('quarter')
        }
        if (selectedPanel === 'year' && (level === 'month' || level === 'day')) {
          setSelectedPanel('month')
        }

        if (!withPeriod && !withTime && level === selectedPanel) {
          onChange && onChange(date)
          onClose()
          return
        }

        if (withPeriod) {
          innerPeriodOnChange((prev) => {
            const prevStart = prev.valueFrom
            const prevEnd = prev.valueTo

            if (!prevStart && level === selectedPanel) {
              return {
                valueFrom: date,
              }
            }
            if (prevStart && !prevEnd) {
              const valueFrom = +prevStart - +date > 0 ? date : prevStart
              const valueTo = +prevStart - +date > 0 ? prevStart : date
              return {
                valueFrom,
                valueTo,
              }
            }
            if (prevStart && prevEnd && level === selectedPanel) {
              return {
                valueFrom: date,
              }
            }
            return {}
          })
        }
      },
      [selectedPanel, level, onChange, onClose, withPeriod, withShift, onPeriodChange, withTime],
    )

    const onLeftArrowClick = useCallback(() => {
      switch (selectedPanel) {
        case 'day': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { month: prevPanel.getMonth() - 1 }),
          )
          break
        }
        case 'quarter':
        case 'month': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { year: prevPanel.getFullYear() - 1 }),
          )
          break
        }
        case 'year': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { year: prevPanel.getFullYear() - 12 }),
          )
          break
        }
      }
    }, [selectedPanel])

    const onRightArrowClick = useCallback(() => {
      switch (selectedPanel) {
        case 'day': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { month: prevPanel.getMonth() + 1 }),
          )
          break
        }
        case 'quarter':
        case 'month': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { year: prevPanel.getFullYear() + 1 }),
          )
          break
        }
        case 'year': {
          setPanelValue(
            (prevPanel) => prevPanel && set(prevPanel, { year: prevPanel.getFullYear() + 12 }),
          )
          break
        }
      }
    }, [selectedPanel])

    const onContentClick = useCallback(() => {
      switch (selectedPanel) {
        case 'day': {
          setSelectedPanel('month')
          break
        }
        case 'month': {
          setSelectedPanel('year')
          break
        }
        case 'quarter': {
          setSelectedPanel('year')
          break
        }
      }
    }, [selectedPanel])

    const CalendarComponent = panelToCalendarMapping[selectedPanel]

    const renderCalendarPanel = () => (
      <CommonCalendar
        ref={ref}
        timeSlot={
          withTime ? (
            <TimeSelector
              innerValue={innerValue}
              disabled={disableChange}
              enabledHourFrom={enabledHourFrom}
              enabledHourTo={enabledHourTo}
              enabledMinuteFrom={enabledMinuteFrom}
              enabledMinuteTo={enabledMinuteTo}
              withSeconds={withSeconds}
              value={value}
              selectedTime={selectedTime}
              onChange={setSelectedTime}
            />
          ) : undefined
        }
        period={period}
        withSeconds={withSeconds}
        onLeftClick={onLeftArrowClick}
        disableContentOfPeriodPicker={selectedPanel === 'year'}
        onContentClick={onContentClick}
        onRightClick={onRightArrowClick}
        onAccept={handleAccept}
        onDecline={handleDecline}
        disableChange={disableChange}
        showFooter={withTime || withShift || withSeconds || withPeriod}
        showShiftsSelector={withShift && selectedPanel === 'day'}
        shiftFrom={innerShiftFrom}
        shiftTo={innerShiftTo}
        shiftLength={shiftLength}
        onChangeShiftFrom={setInnerShiftFrom}
        onChangeShiftTo={setInnerShiftTo}
        level={level}
        isHideYear={isHideYear}
        selectedPanel={selectedPanel}
        {...props}
      >
        <CalendarComponent
          valueFrom={innerPeriodValue.valueFrom}
          valueTo={innerPeriodValue.valueTo}
          withPeriod={withPeriod}
          withTime={withTime}
          selectedDate={innerValue}
          disableChange={disableChange}
          onSelect={handleSelect}
          enabledFrom={enabledFrom}
          enabledTo={enabledTo}
          panelValue={panelValue}
        />
      </CommonCalendar>
    )

    return isOpenOnFocus ? (
      <>{renderCalendarPanel()}</>
    ) : (
      <ClickAwayListener onClickAway={() => (disableChangesOnBlur ? onClose() : handleAccept())}>
        {renderCalendarPanel()}
      </ClickAwayListener>
    )
  },
)
