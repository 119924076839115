import { TableReducers } from '../../../../interfaces/TableReducers'
import { createTableReducer, generateDefaultTableState, TableState } from '../../../components'
import ViewTableManager from '../../../managers/ViewTableManager'
import { COLUMNS, ReducerKey, reducerKeyPrefix } from '../constants'

export function warehouseTableReducers() {
  return Object.entries(COLUMNS).reduce((acc, [abbreviation, columns]) => {
    const key = `${reducerKeyPrefix}-${abbreviation}`
    const initialState: TableState = generateDefaultTableState(columns)
    const state = ViewTableManager.merge(key, initialState)
    const tableReducer = createTableReducer<TableState>(key, state)
    return {
      ...acc,
      [key]: tableReducer,
    }
  }, {} as TableReducers<ReducerKey>)
}
