import { makeStyles } from '@material-ui/core'

export const useDialogActionsStyles = makeStyles(() => ({
  root: {
    padding: '48px 48px 32px 48px',
    borderTop: '1px solid rgba(0, 23, 41, 0.16)',
    justifyContent: 'start',
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: 16,
    },
  },
}))
