import React, { useContext, useMemo } from 'react'

import { useMapColumnToChildren } from '../hooks/useMapColumnToChildren'
import { SpanOrientation, TableColumn } from '../types'
import { ContextCustomFields, CustomFieldsCtx } from '../utils/customFieldsCtx'
import { StyledProps, useCellStyleProps } from '../utils/StyledProps'
import { Table as RGTable } from '@devexpress/dx-react-grid'
import { Table as DevexpressTable } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'

import { useTableModuleStyles } from '../styles'

type TableCellProps = RGTable.DataCellProps & StyledProps & { column?: TableColumn<unknown> }

export const TableCell: React.FC<TableCellProps> = (props) => {
  const { row, column, children } = props
  const { checkedRows } = useContext(CustomFieldsCtx) || {}
  const { highlightedRows } = useContext(CustomFieldsCtx) || {}
  const context = useContext(CustomFieldsCtx)
  const onHoverControls = context.onHoverControls
  const s = useTableModuleStyles()
  const mappedChildren = useMapColumnToChildren(row, column, children ?? row[column.name])
  const { type, style } = column
  const columnName = props.tableColumn.column?.name
  const customClassNames = [
    { [s.Cell_center]: type },
    columnName === 'controls' ? s.LastHiddenCell : '',
  ]
  const [className, cellStyle] = useCellStyleProps(
    row,
    column,
    props,
    style?.cell,
    customClassNames,
  )
  const spanRenderResult = useSpan(row, column)
  const controlsBlockClass =
    ((checkedRows && checkedRows.has(row)) || (highlightedRows && highlightedRows.has(row))) &&
    !onHoverControls
      ? `${s.ControlsBlock} ${s.active}`
      : s.ControlsBlock
  return (
    (spanRenderResult.renderEnabled && (
      <DevexpressTable.Cell
        {...props}
        {...spanRenderResult.props}
        className={className}
        style={cellStyle}
      >
        {columnName === 'controls' && (
          <div className={clsx(controlsBlockClass, 'ControlsBlock')}>{mappedChildren}</div>
        )}
        {columnName !== 'controls' && mappedChildren}
      </DevexpressTable.Cell>
    )) ||
    null
  )
}

interface SpanProps {
  readonly rowSpan?: number
  readonly colSpan?: number
}

interface SpanRenderResult {
  readonly renderEnabled: boolean
  readonly props: SpanProps | undefined
}

function useSpan<R>(row: R, column: TableColumn<R>): SpanRenderResult {
  const context = useContext(CustomFieldsCtx) as ContextCustomFields<R>
  const spans = context.spans
  const spanDatas = useMemo(() => spans?.get(row)?.get(column), [spans, row, column])
  const renderEnabled = spanDatas !== null
  const props = useMemo(() => {
    let result: SpanProps | undefined
    if (spanDatas) {
      let colSpan = 0
      let rowSpan = 0
      for (const spanData of spanDatas) {
        if (spanData.orientation === SpanOrientation.HORIZONTAL) {
          colSpan = Math.max(spanData.span, colSpan)
        } else {
          rowSpan = Math.max(spanData.span, rowSpan)
        }
      }
      result = {
        colSpan: colSpan > 0 ? colSpan : undefined,
        rowSpan: rowSpan > 0 ? rowSpan : undefined,
      }
    }
    return result
  }, [spanDatas])
  return { renderEnabled, props }
}
