import React, { useCallback, useContext } from 'react'

import { Button } from '../../Button'
import { Checkbox } from '../../Checkbox'
import { TableColumn } from '../types'
import { ContextCustomFields, CustomFieldsCtx } from '../utils/customFieldsCtx'
import { TextField } from '@material-ui/core'

export function useMapColumnToChildren<R>(
  row: R,
  column: TableColumn<R>,
  defaultSlot?: React.ReactNode,
): React.ReactNode {
  const ctx = useContext(CustomFieldsCtx) as ContextCustomFields<R>
  const narrowRows = ctx?.narrowRows
  const handleButtonClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (ctx?.onButtonClick) {
        ctx.onButtonClick(row, column)
      }
    },
    [column, ctx, row],
  )
  const handleCheckboxClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (ctx?.onCheckboxChange) {
        ctx.onCheckboxChange(row, column, Boolean(e.target.value))
      }
    },
    [ctx, row, column],
  )
  const handleInputChange = useCallback(
    (e) => {
      e.stopPropagation()
      if (ctx?.onInputChange) {
        ctx.onInputChange(row, column, e.target.value)
      }
    },
    [ctx, row, column],
  )
  if (typeof column.render === 'function') {
    return column.render(row)
  }
  if (typeof column.type !== 'undefined') {
    switch (column.type) {
      case 'button': {
        return (
          <Button
            size={narrowRows ? 'small' : undefined}
            className="tableButton"
            onClick={handleButtonClick}
            variant="contained"
            color="primary"
          >
            {row[column.name]}
          </Button>
        )
      }
      case 'checkbox': {
        return (
          <Checkbox
            onChange={handleCheckboxClick}
            value={row[column.name]}
            className="selectionCheckbox"
          />
        )
      }
      case 'input': {
        return (
          <TextField variant="outlined" value={row[column.name]} onChange={handleInputChange} />
        )
      }
    }
  }
  const icon = column.getIcon?.(row) ?? null
  return (
    <>
      {icon}
      {defaultSlot}
    </>
  )
}
