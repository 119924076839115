import { makeStyles, RadioProps, Theme } from '@material-ui/core'

export const useRadioStylesCheck = makeStyles<Theme, RadioProps>((theme) => ({
  root: {
    padding: '4px',
    color: theme.palette.action.disabledBackground,
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill: theme.neutral.white,
    },
  },
  colorPrimary: ({ checked, disabled }) => ({
    '& .MuiIconButton-label .MuiSvgIcon-root rect': {
      fill: disabled
        ? checked
          ? theme.neutral.noChangeWhite
          : theme.palette.action.disabledBackground
        : theme.neutral.noChangeWhite,
    },
    '&$checked::after': {
      content: '""',
      position: 'absolute',
      width: '20px',
      height: '20px',
      border: '1px solid rgba(255, 255, 255, 0.4)',
      borderRadius: '50%',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.action.disabledBackground,
    },
    '&$checked:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  }),
  checked: ({ disabled }) => ({
    '& svg': {
      color: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
    },
  }),
}))
