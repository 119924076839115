import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
// import { useAlert } from 'react-alert';
import ReactToPrint from 'react-to-print'

import {
  mainDark,
  mainRed,
  modalPrintApplicationGreen,
  modalPrintApplicationPeachy,
  secondBlue,
} from '../../../../colors'
import { CardDataInterfaceAttributes } from '../../../../interfaces/TaskMills'
import service from '../../../../services/service'
import { Box } from '../../../../ui-kit/components/Box'
import { Button } from '../../../../ui-kit/components/Button'
import { Dialog } from '../../../../ui-kit/components/Dialog'
import { Input } from '../../../../ui-kit/components/Input'
import { Loader } from '../../../../ui-kit/components/Loader'
import { MenuItem } from '../../../../ui-kit/components/MenuItem'
import { Select } from '../../../../ui-kit/components/Select'
import { Typography } from '../../../../ui-kit/components/Typography'
import getPageStyle, {
  getTagSizeByColor,
} from '../../../../ui-kit/frontend-components/../../utils/getPageStyle'
import ButtonLoader from '../../../../ui-kit/frontend-components/ButtonLoader'
import CardBlue from '../../../../ui-kit/frontend-components/Card/CardBlue'
import CardBlueTag from '../../../../ui-kit/frontend-components/Card/CardBlueTag'
import CardCkiCto from '../../../../ui-kit/frontend-components/Card/CardCkiCto'
import CardCkiWhite from '../../../../ui-kit/frontend-components/Card/CardCkiWhite'
import CardCkiWhiteRollingMachine from '../../../../ui-kit/frontend-components/Card/CardCkiWhiteRollingMachine/CardCkiWhiteRollingMachine'
import CardCkiWhiteSharpeningMachine from '../../../../ui-kit/frontend-components/Card/CardCkiWhiteSharpeningMachine'
import CardCoatingLine from '../../../../ui-kit/frontend-components/Card/CardCoatingLine'
import CardGreenNails from '../../../../ui-kit/frontend-components/Card/CardGreenNails'
import CardGreenTag from '../../../../ui-kit/frontend-components/Card/CardGreenTag'
import CardNails from '../../../../ui-kit/frontend-components/Card/CardNails'
import CardPackaging from '../../../../ui-kit/frontend-components/Card/CardPackaging'
import CardRed from '../../../../ui-kit/frontend-components/Card/CardRed'
import CardRedMediumTag from '../../../../ui-kit/frontend-components/Card/CardRedMediumTag'
import CardRedTag from '../../../../ui-kit/frontend-components/Card/CardRedTag'
import CardWhite from '../../../../ui-kit/frontend-components/Card/CardWhite'
import CardWhiteBlue from '../../../../ui-kit/frontend-components/Card/CardWhiteBlue'
import CardWhiteMediumTag from '../../../../ui-kit/frontend-components/Card/CardWhiteMediumTag'
import CardWhiteTag from '../../../../ui-kit/frontend-components/Card/CardWhiteTag/CardWhiteTag'
import CardYellow from '../../../../ui-kit/frontend-components/Card/CardYellow'
import CardYellowMediumTag from '../../../../ui-kit/frontend-components/Card/CardYellowMediumTag'
import CardYellowTag from '../../../../ui-kit/frontend-components/Card/CardYellowTag'
import UserContent from '../../../../ui-kit/frontend-components/UserContent'
import Table from '../../components/Table'
import { CARDS_INTERVAL_INITIAL_STATE, CARDS_PRINT_COUNT, COLUMNS } from './constants'
import {
  Props,
  TAttributes,
  TCards,
  TCards2,
  TCardValue,
  TCardValue2,
} from './NewModalPrintCards.type'
import { groupBy } from 'lodash'

const styleNumber = {
  height: '26px',
  minWidth: '26px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

type TRows = {
  id: string
  color: string
  description: JSX.Element
}

var listId = []

function createCards(countCards, isPrinting, isAnswerGet, setIsAnswerGet) {
  if (countCards > 0 && isPrinting && !isAnswerGet) {
    service.createCard(countCards).then((value) => {
      // listId = value
      console.log('ListId')
      console.log(value)
      listId.splice(0, listId.length)
      value.forEach((elm) => {
        listId.push(elm)
      })
      setIsAnswerGet(true)
    })
  }
}

const NewModalPrintCards: FC<Props> = ({
  shiftId,
  isOpen,
  onClose,
  subSection,
  tableRows,
  setPage,
  page,
}) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const [isAnswerGet, setIsAnswerGet] = useState(false)
  const [countCards, setCountCards] = useState<number>(0)
  const [contentCard, setContentCards] = useState<{ content: TCards2[] }>({ content: [] })
  const onBeforeGetContentResolve = useRef({})

  const [checked, setChecked] = useState<TRows[]>([])

  const [cardsInterval, setCardsInterval] = useState<Record<string, number>>(
    CARDS_INTERVAL_INITIAL_STATE,
  )

  const handleChangeCardsInterval = useCallback((id: string, value: number) => {
    setCardsInterval((prev) => ({
      ...prev,
      [id]: value,
    }))
  }, [])

  const [cardValue, setCardValue] = useState<TCardValue | TCardValue2>({})
  const [loading, setLoading] = useState(true)

  const componentRef = useRef(null)

  // const alert = useAlert();

  useEffect(() => {
    setLoading(true)

    if (isOpen) {
      const loadCards = async () => {
        try {
          setLoading(false)
          // const { content }: { content: TCards[] } = await service.getAccompanyingCardsByShift(
          //   shiftId,
          //   subSection
          // );
          //TODO изменить на ответ
          const { content }: { content: TCards2[] } = { content: [] }
          for (var i = 0; i < countCards; i++) {
            content[i] = {
              id: listId[i],
              alias: null,
              type: 'card',
              attributes: { color: 'white', task_id: listId[i] },
              childs: [],
            }
          }
          console.log('content')
          console.log(content)
          setContentCards({ content })

          if (subSection === 'wire_nailing_machine') {
            setCardValue({
              wire_nailing_machine: content,
            })
            return
          }
          if (subSection === 'packing_line') {
            setCardValue({
              packing_line: content,
            })
            return
          }

          let whiteBlueStripeMediumTag

          const result = groupBy(content, 'attributes.color')

          if (
            Object.prototype.hasOwnProperty.call(result, 'white_blue_stripe') &&
            (subSection === 'ugv' || subSection === 'danielli')
          ) {
            whiteBlueStripeMediumTag = result.white_blue_stripe.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'white_blue_stripe_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy(whiteBlueStripeMediumTag, 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue

            setCardValue((prev) => {
              return { ...prev, ...groupCopyType, ...resultType }
            })
          }

          let yellowTag
          let yellowMediumTag

          if (
            Object.prototype.hasOwnProperty.call(result, 'yellow') &&
            (subSection === 'ugv' || subSection === 'danielli')
          ) {
            yellowTag = result.yellow.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'yellow_tag' },
              } as unknown) as TCardValue
            })

            yellowMediumTag = result.yellow.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'yellow_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...yellowTag, ...yellowMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue

            setCardValue((prev) => {
              return { ...prev, ...groupCopyType, ...resultType }
            })
          }

          let redTag
          let redMediumTag

          if (Object.prototype.hasOwnProperty.call(result, 'red')) {
            redTag = result.red.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'red_tag' },
              } as unknown) as TCardValue
            })

            redMediumTag = result.red.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'red_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...redTag, ...redMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue

            setCardValue((prev) => {
              return { ...prev, ...resultType, ...groupCopyType }
            })
          }
          let greenTag
          let greenMediumTag

          if (Object.prototype.hasOwnProperty.call(result, 'green')) {
            greenTag = result.green.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'green_tag' },
              } as unknown) as TCardValue
            })

            greenMediumTag = result.green.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'green_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...greenTag, ...greenMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue

            setCardValue((prev) => {
              return { ...prev, ...resultType, ...groupCopyType }
            })
          }

          let blueTag
          let blueMediumTag

          if (Object.prototype.hasOwnProperty.call(result, 'blue')) {
            blueTag = result.blue.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'blue_tag' },
              } as unknown) as TCardValue
            })

            blueMediumTag = result.blue.map((card) => {
              const taskFieldt = tableRows.find((row: { id: number }) => {
                return Number(row.id) === Number(card.attributes.task_id)
              })

              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, ...taskFieldt, color: 'blue_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...blueTag, ...blueMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue

            setCardValue((prev) => {
              return { ...prev, ...resultType, ...groupCopyType }
            })
          }

          let copyWhite
          let copyWhiteMediumTag
          if (
            Object.prototype.hasOwnProperty.call(result, 'white') &&
            (subSection === 'ugv' || subSection === 'danielli' || subSection === 'uef')
          ) {
            copyWhite = result.white.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'white_tag' },
              } as unknown) as TCardValue
            })

            copyWhiteMediumTag = result.white.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'white_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...copyWhite, ...copyWhiteMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue
            setCardValue((prev) => {
              return { ...prev, ...groupCopyType, ...resultType }
            })
          }

          // let copyWhiteMediumTag2;
          // if (
          //   Object.prototype.hasOwnProperty.call(result, 'white_medium_tag') &&
          //   (subSection === 'ugv' || subSection === 'danielli' || subSection === 'uef')
          // ) {

          //   copyWhiteMediumTag2 = result.white_medium_tag.map((card) => {
          //     return {
          //       alias: card.alias,
          //       id: card.id,
          //       type: card.type,
          //       attributes: { ...card.attributes, color: 'white_medium_tag' },
          //     } as unknown as TCardValue;
          //   });

          //   const groupCopy = groupBy(copyWhiteMediumTag2, 'attributes.color');

          //   const resultType = result as TCardValue;

          //   const groupCopyType = groupCopy as unknown as TCardValue;
          //   setCardValue((prev) => {
          //     return { ...prev, ...groupCopyType, ...resultType };
          //   });
          // }

          let copyRed
          let copyRedMediumTag

          if (
            Object.prototype.hasOwnProperty.call(result, 'red') &&
            (subSection === 'fib1' || subSection === 'fib2')
          ) {
            copyRed = result.red.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'red_tag' },
              } as unknown) as TCardValue
            })

            copyRedMediumTag = result.red.map((card) => {
              return ({
                alias: card.alias,
                id: card.id,
                type: card.type,
                attributes: { ...card.attributes, color: 'red_medium_tag' },
              } as unknown) as TCardValue
            })

            const groupCopy = groupBy([...copyRed, ...copyRedMediumTag], 'attributes.color')

            const resultType = result as TCardValue

            const groupCopyType = (groupCopy as unknown) as TCardValue
            setCardValue((prev) => {
              return { ...prev, ...groupCopyType, ...resultType }
            })
          }
        } catch (err) {
          // alert.error(String(err));
        } finally {
          setLoading(false)
        }
      }
      loadCards()
    }
  }, [isAnswerGet])

  const onCheckedChange = useCallback((row: TRows[]) => {
    const rowLength = row.length

    if (rowLength === 0 || rowLength === 5) {
      return setChecked([])
    }

    if (rowLength > 1) {
      return setChecked([row[rowLength - 1]])
    }
    if (rowLength === 1) {
      return setChecked([row[0]])
    }

    return setChecked([])
  }, [])

  // const getSelectInverval = useCallback(
  //   (key) => {
  //     const count = cardValue?.[key]?.length ?? 0;
  //     if (count === 0) return null;

  //     const items = new Array(Math.ceil(count / CARDS_PRINT_COUNT)).fill(1).map((_, index) => {
  //       const start = index * CARDS_PRINT_COUNT + 1;
  //       const end = index * CARDS_PRINT_COUNT + CARDS_PRINT_COUNT;
  //       return (
  //         <MenuItem key={index} value={index}>
  //           {start} - {end > count ? count : end}
  //         </MenuItem>
  //       );
  //     });
  //     return (
  //       <Box marginLeft={3}>
  //         <Select
  //           fullWidth
  //           label="Интервал"
  //           value={cardsInterval[key]}
  //           onChange={(e) => handleChangeCardsInterval(key, Number(e.target.value))}
  //         >
  //           {items}
  //         </Select>
  //       </Box>
  //     );
  //   },
  //   [cardsInterval, cardValue, handleChangeCardsInterval]
  // );

  const checkedCards = useMemo(() => (checked.length ? cardValue[checked[0]?.color] : []), [
    cardValue,
    checked,
  ])

  const rows = useMemo(() => {
    if (subSection === 'wire_nailing_machine') {
      return [
        {
          id: 'Cпк продукции ГвЦ',
          color: 'wire_nailing_machine',
          length: cardValue.white?.length || 0,
          description: (
            <Box alignItems="center">
              <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
                <Typography style={{ margin: '2px' }} variant="Body1">
                  {cardValue.wire_nailing_machine?.length || 0}
                </Typography>
              </div>
              {/* {getSelectInverval('wire_nailing_machine')} */}
            </Box>
          ),
        },
      ]
    }
    if (subSection === 'packing_line') {
      return [
        {
          id: 'Cпк линия упаковки',
          color: 'packing_line',
          length: cardValue.packing_line?.length || 0,
          description: (
            <Box alignItems="center">
              <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
                <Typography style={{ margin: '2px' }} variant="Body1">
                  {cardValue.packing_line?.length || 0}
                </Typography>
              </div>
              {/* {getSelectInverval('packing_line')} */}
            </Box>
          ),
        },
      ]
    }
    return [
      {
        id: 'Белая спк(формат оффисный А5)',
        color: 'white',
        length: cardValue.white?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
              <Typography style={{ margin: '2px' }} variant="Body1">
                {cardValue.white?.length || 0}
              </Typography>
            </div>
            {/* {getSelectInverval('white')} */}
          </Box>
        ),
      },
      {
        id: 'Белая спк(формат бирка 9х11)',
        color: 'white_tag',
        length: cardValue.white?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
              <Typography style={{ margin: '2px' }} variant="Body1">
                {cardValue.white?.length || 0}
              </Typography>
            </div>
            {/* {getSelectInverval('white')} */}
          </Box>
        ),
      },
      {
        id: 'Белая спк(формат бирка 9х18)',
        color: 'white_medium_tag',
        length: cardValue.white?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
              <Typography style={{ margin: '2px' }} variant="Body1">
                {cardValue.white?.length || 0}
              </Typography>
            </div>
            {/* {getSelectInverval('white')} */}
          </Box>
        ),
      },
      {
        id: 'Желтая спк(формат оффисный А5)',
        color: 'yellow',
        length: cardValue.yellow?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationPeachy }}>
              <Typography variant="Body1">{cardValue.yellow?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('yellow')} */}
          </Box>
        ),
      },
      {
        id: 'Желтая спк(формат бирка 9х11)',
        color: 'yellow_tag',
        length: cardValue.yellow?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationPeachy }}>
              <Typography variant="Body1">{cardValue.yellow?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('yellow')} */}
          </Box>
        ),
      },
      {
        id: 'Желтая спк(формат бирка 9х18)',
        color: 'yellow_medium_tag',
        length: cardValue.yellow?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationPeachy }}>
              <Typography variant="Body1">{cardValue.yellow?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('yellow')} */}
          </Box>
        ),
      },
      {
        id: 'Зеленая спк',
        color: 'green',
        length: cardValue.green?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationGreen }}>
              <Typography variant="Body1">{cardValue.green?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('green')} */}
          </Box>
        ),
      },
      {
        id: 'Зеленая спк(формат бирка 9х11)',
        color: 'green_tag',
        length: cardValue.green?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationGreen }}>
              <Typography variant="Body1">{cardValue.green?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('green')} */}
          </Box>
        ),
      },
      {
        id: 'Зеленая спк(формат бирка 9х18)',
        color: 'green_medium_tag',
        length: cardValue.green?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: modalPrintApplicationGreen }}>
              <Typography variant="Body1">{cardValue.green?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('green')} */}
          </Box>
        ),
      },
      {
        id: 'Голубая спк',
        color: 'blue',
        length: cardValue.blue?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: secondBlue }}>
              <Typography variant="Body1">{cardValue.blue?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('blue')} */}
          </Box>
        ),
      },
      {
        id: 'Голубая спк(формат бирка 9х11)',
        color: 'blue_tag',
        length: cardValue.blue?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: secondBlue }}>
              <Typography variant="Body1">{cardValue.blue?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('blue')} */}
          </Box>
        ),
      },
      {
        id: 'Голубая спк(формат бирка 9х18)',
        color: 'blue_medium_tag',
        length: cardValue.blue?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: secondBlue }}>
              <Typography variant="Body1">{cardValue.blue?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('blue')} */}
          </Box>
        ),
      },
      {
        id: 'Красная спк',
        color: 'red',
        length: cardValue.red?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: mainRed }}>
              <Typography variant="Body1">{cardValue.red?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('red')} */}
          </Box>
        ),
      },
      {
        id: 'Красная спк(формат бирка 9х11)',
        color: 'red_tag',
        length: cardValue.red?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: mainRed }}>
              <Typography variant="Body1">{cardValue.red?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('red')} */}
          </Box>
        ),
      },
      {
        id: 'Красная спк(формат бирка 9х18)',
        color: 'red_medium_tag',
        length: cardValue.red?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: mainRed }}>
              <Typography variant="Body1">{cardValue.red?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('red')} */}
          </Box>
        ),
      },
      {
        id: 'Красная с полосой спк',
        color: 'red_stripe',
        length: cardValue.red_stripe?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, backgroundColor: mainRed, border: '1px solid #000' }}>
              <Typography variant="Body1">{cardValue.red_stripe?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('red_stripe')} */}
          </Box>
        ),
      },
      {
        id: 'Бело-голубая спк',
        color: 'white_blue_stripe',
        length: cardValue.white_blue_stripe?.length || 0,
        description: (
          <Box alignItems="center">
            <div
              style={{
                ...styleNumber,
                background: `linear-gradient(to right, transparent 50%, ${secondBlue} 50%)`,
              }}
            >
              <Typography variant="Body1">{cardValue.white_blue_stripe?.length || 0}</Typography>
            </div>
            {/* {getSelectInverval('white_blue_stripe')} */}
          </Box>
        ),
      },
      {
        id: 'Бело-голубая спк(формат бирка 9х11)',
        color: 'white_blue_stripe_tag',
        length: cardValue.white_blue_stripe?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
              <Typography style={{ margin: '2px' }} variant="Body1">
                {cardValue.white_blue_stripe?.length || 0}
              </Typography>
            </div>
            {/* {getSelectInverval('white_blue_stripe')} */}
          </Box>
        ),
      },
      {
        id: 'Бело-голубая спк(формат бирка 9х18)',
        color: 'white_blue_stripe_medium_tag',
        length: cardValue.white_blue_stripe?.length || 0,
        description: (
          <Box alignItems="center">
            <div style={{ ...styleNumber, border: `1px solid ${mainDark}` }}>
              <Typography style={{ margin: '2px' }} variant="Body1">
                {cardValue.white_blue_stripe?.length || 0}
              </Typography>
            </div>
            {/* {getSelectInverval('white_blue_stripe')} */}
          </Box>
        ),
      },
    ].filter((el) => {
      return el.length !== 0
    })
  }, [
    cardValue,
    cardsInterval,
    // getSelectInverval
  ])

  const highlightedCard = useCallback((date: CardDataInterfaceAttributes[]) => {
    if (date.length !== 0) {
      return [
        Object.entries(date[0]).reduce((acc, [key, value]) => {
          const modValue = value

          return { ...acc, [key]: modValue }
        }, {} as CardDataInterfaceAttributes),
      ]
    }
    return []
  }, [])

  const getCards = useCallback(() => {
    // const isCheckedCards = typeof checkedCards !== 'undefined' && Boolean(checkedCards.length);
    const isCheckedCards = true

    if (subSection === 'coating_lines' && isCheckedCards) {
      return checkedCards.map((item) => {
        return <CardCoatingLine key={item.id} highlighted={item.attributes as TAttributes} />
      })
    }

    if (subSection === 'cto_lines' && isCheckedCards) {
      return checkedCards.map((item) => {
        return <CardCkiCto key={item.id} highlighted={item.attributes as TAttributes} />
      })
    }

    if (subSection?.includes('pressing_machine') && isCheckedCards) {
      return checkedCards.map((item) => {
        return <CardCkiWhite key={item.id} highlighted={item.attributes as TAttributes} />
      })
    }

    if (subSection?.includes('rolling_machine') && isCheckedCards) {
      return checkedCards.map((item) => {
        return (
          <CardCkiWhiteRollingMachine key={item.id} highlighted={item.attributes as TAttributes} />
        )
      })
    }

    if (subSection?.includes('sharpening_machines') && isCheckedCards) {
      return checkedCards.map((item) => {
        return (
          <CardCkiWhiteSharpeningMachine
            key={item.id}
            highlighted={item.attributes as TAttributes}
          />
        )
      })
    }

    if (subSection?.includes('wire_nailing_machine') && isCheckedCards) {
      return checkedCards.map((item) => {
        return (
          <CardNails
            key={item.id}
            highlighted={item.attributes as TAttributes}
            barcode={Number(item.id)}
          />
        )
      })
    }

    if (subSection === 'packing_line' && isCheckedCards) {
      return checkedCards.map((item) => {
        return (
          <CardPackaging
            key={item.id}
            highlighted={item.attributes as TAttributes}
            barcode={Number(item.id)}
          />
        )
      })
    }

    if (isCheckedCards) {
      return contentCard.content.map((item) => {
        switch (item.attributes.color) {
          case 'yellow':
            return (
              <CardYellow
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'yellow_tag':
            return (
              <CardYellowTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'yellow_medium_tag':
            return (
              <CardYellowMediumTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'green':
            return (
              <CardGreenNails
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'green_tag':
            return (
              <CardGreenTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'blue':
            return (
              <CardBlue
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'blue_tag':
            return (
              <CardBlueTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white':
            return (
              <CardWhite
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white_tag':
            return (
              <CardWhiteTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white_medium_tag':
            return (
              <CardWhiteMediumTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white_blue_stripe':
            return (
              <CardWhiteBlue
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'red':
            return (
              <CardRed
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'red_tag':
            return (
              <CardRedTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'red_medium_tag':
            return (
              <CardRedMediumTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'red_stripe':
            return (
              <CardRed
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white_blue_stripe_tag':
            return (
              <CardWhiteTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          case 'white_blue_stripe_medium_tag':
            return (
              <CardWhiteMediumTag
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
          default:
            return (
              <CardWhiteBlue
                key={item.id}
                highlighted={highlightedCard([item.attributes as TAttributes])}
                barcode={item.id}
              />
            )
        }
      })
    }
    return []
  }, [checkedCards, subSection, highlightedCard])

  const cards = useMemo(() => {
    const color = checked?.[0]?.color
    const start = (cardsInterval?.[color] ?? 0) * CARDS_PRINT_COUNT
    const end = start + CARDS_PRINT_COUNT
    console.log(getCards().slice(start, end))
    console.log(getCards())
    return getCards().slice(start, end)
  }, [getCards, checked, cardsInterval])

  // костыль для корректного отображения checkedRow
  // т.к. компонент сравнивает ссылки, а не row id
  // useEffect(() => {
  //   const id = checked?.[0]?.id || '';
  //   const row = rows.find((item) => item.id === id);

  //   if (row && row !== checked[0]) {
  //     setChecked([row]);
  //   }
  // }, [checked, rows]);

  // const tableRowsSelection = useMemo(
  //   () => ({
  //     checkEnabled: true,
  //     checked,
  //     onCheckedChange,
  //   }),
  //   [checked, onCheckedChange]
  // );

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      title="Печать сопроводительных карт"
      actions={[
        <ReactToPrint
          key={1}
          copyStyles
          pageStyle={getPageStyle(subSection, getTagSizeByColor(contentCard[0]?.color))}
          trigger={() => (
            createCards(countCards, isPrinting, isAnswerGet, setIsAnswerGet),
            (
              <ButtonLoader
                // progressLoading={isPrinting}
                // disabled={checked.length === 0 || isPrinting}
                progressLoading={isPrinting}
                disabled={countCards <= 0}
                variant="contained"
                color="primary"
              >
                Распечатать
              </ButtonLoader>
            )
          )}
          onBeforeGetContent={() => {
            setIsPrinting(true)
          }}
          onAfterPrint={() => {
            setIsPrinting(false)
            setPage(Number(page))
            listId.splice(0, listId.length)
          }}
          content={() => componentRef.current}
        />,
        <Button key={2} onClick={onClose} variant="contained" color="secondary">
          Отменить
        </Button>,
      ]}
    >
      <Box flexDirection="column" spacing={3}>
        <Input
          label="Количество СПК"
          onChange={(e) => {
            setIsAnswerGet(false)
            setCountCards(Number(e.target.value))
          }}
          // onChange={(e) => setIsPrinting(true)}
          autoFocus
          placeholder="шт"
          variant="outlined"
        />
        {/* {( 
        <Table
            rowsSelection={tableRowsSelection}
            rows={rows}
            columns={COLUMNS}
            noDataMessage="Запустите задание, чтобы получить сопроводительные карты"
          />
        )} */}
      </Box>

      {isAnswerGet && (
        <div className="print-all-card">
          <div ref={componentRef}>
            <Box flexDirection="column">{cards}</Box>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default NewModalPrintCards
