import { makeStyles, Theme } from '@material-ui/core/styles'

export const useTimePickerStyles = makeStyles<Theme, { withIcon: boolean }>((theme) => ({
  MuiOutlinedInput: {
    input: {
      padding: '10.5px 14px',
    },
  },
  root: ({ withIcon }) => ({
    maxWidth: 130,
    '& label': {
      fontWeight: theme.textStyles.body1medium.fontWeight,
    },
    '& > div': { padding: withIcon ? undefined : 0 },
  }),
  underline: {
    '&$focused:before': {
      borderBottom: '2px solid #167FFB !important',
    },
    '&$focused:after': {
      borderBottom: '2px solid #167FFB !important',
    },
  },
  anEnd: ({ withIcon }) => ({
    padding: '10.5px 14px !important',
    paddingRight: withIcon ? undefined : '0 !important',
    '& + div': { margin: withIcon ? undefined : 0 },
  }),
  keyboardButton: ({ withIcon }) => ({
    position: withIcon ? 'static' : 'absolute',
    inset: withIcon ? 'auto' : 0,
    padding: withIcon ? '10px 0' : 0,
    '& *': { display: withIcon ? 'block' : 'none' },
  }),
}))
