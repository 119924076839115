import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconSortAscended24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07715 18V16H21.0771V18H3.07715ZM3.07715 6H9.07715V8H3.07715V6ZM15.0771 11H3.07715V13H15.0771V11Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconSortAscended24
