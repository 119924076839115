import React, { useState } from 'react'
import { useAlert } from 'react-alert'

import { Box, Button } from '../../ui-kit/components'
import UserContent from '../../ui-kit/frontend-components/UserContent'
import { MOCK_REPORT_DATA } from './mockReport'
import service from 'services/service'

const Report: React.FC = () => {
  const alert = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async () => {
    setIsLoading(true)
    try {
      await service.getReport(MOCK_REPORT_DATA)
    } catch (e) {
      alert.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <UserContent title="Отчеты">
      <Box flexBasis={200}>
        <Button variant="contained" color="primary" onClick={handleDownload}>
          {isLoading ? 'В процессе выгрузки' : 'Выгрузить в Excel'}
        </Button>
      </Box>
    </UserContent>
  )
}

export default Report
