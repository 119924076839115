import React, { FC } from 'react'
import { useBarcode } from 'react-barcodes'

type TProps = {
  value?: string | number
  displayValue?: boolean
  height?: number
  width?: number
  fontOptions?: string
  margin?: number
  fontSize?: number
}

const Barcode: FC<TProps> = ({
  value,
  displayValue = true,
  height = 28,
  width,
  fontOptions = '',
  margin = 10,
  fontSize = 16,
}) => {
  const { inputRef } = useBarcode({
    value: String(value),
    options: {
      height,
      width: width || 1,
      fontSize,
      displayValue,
      fontOptions,
      margin,
    },
  })
  return <svg ref={inputRef} />
}

export default Barcode
