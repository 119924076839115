import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  DatePicker,
  Dialog,
  IconButton,
  IconSlab24,
  Pagination,
  PenSvgIcon,
  RemoveSvgIcon,
  ScheduleSvgIcon,
  SimpleAttributeMappingSvgIcon,
  TableColumn,
  TaskSvgIcon,
  Tooltip,
  Typography,
} from '../../../../../../ui-kit/components'
import ButtonLoader from '../../../../../../ui-kit/frontend-components/ButtonLoader'
import LoaderWrapper from '../../../../../../ui-kit/frontend-components/LoaderWrapper'
import Table from '../../../../../../ui-kit/frontend-components/Table'
import TableManager from '../../../../../../ui-kit/managers/TableManager'
import { AllWithNodes, Column, DictionaryInfo } from '../../hooks/useDictionaryValues'
import { DependentDictionaries } from '../../hooks/useGetDependentDictionaries'
import BindNodeTableModal from '../BindNodeTableModal'
import DictionaryValuesForm from '../DictionaryValuesForm'
import { useAddDictionaryValue } from './hooks/useAddDictionaryValue'
import { useApproveDictionaryValue } from './hooks/useApproveDictionaryValue'
import { useCloseDictionaryValue } from './hooks/useCloseDictionaryValue'
import { useDeleteDictionaryValue } from './hooks/useDeleteDictionaryValue'
import { DictionaryForm, useDictionaryForm } from './hooks/useDictionaryForm'
import { useUpdateDictionaryValue } from './hooks/useUpdateDictionaryValue'
import { FormControlLabel, makeStyles } from '@material-ui/core'
import { ResponseAllWithNodes } from 'interfaces/responses/Dictionary'
import getServerDateTime from 'utils/getServerDateTime'

const excludeColumnTypeNames = ['Date', 'Array']

type NodeBinding = AllWithNodes[number]['nodesBinding'][number]

type Props = {
  dictionaryInfo: DictionaryInfo
  dictionaryType: string
  rows: AllWithNodes
  columns: (Omit<TableColumn<AllWithNodes[number]>, 'type'> & Column)[]
  readOnly: boolean
  totalPages: number
  currentPage: number
  onChangeCurrentPage: (page: number) => void
  isLoading: boolean
  onDelete: (id: number) => void
  onEdit: (value: AllWithNodes[number]) => void
  onAdd: (value: AllWithNodes[number]) => void
  onRefresh: () => Promise<void>
  onClose: (id: number, dateTime: string) => void
  onApprove: (id: number, dateTime: string) => void
  manager: TableManager
  dependentDictionaries: DependentDictionaries
}

const styles = makeStyles({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  chipLabel: {
    fontWeight: 500,
  },
})

const optionalFields: (keyof AllWithNodes[number])[] = ['finishDateTime']

const datesFields = [
  'startDateTime',
  'finishDateTime',
  'setDateTime',
  'updateDateTime',
  'approveDateTime',
]

const EditTable = ({
  dictionaryInfo,
  dictionaryType,
  rows,
  columns,
  currentPage,
  readOnly,
  onChangeCurrentPage,
  totalPages,
  isLoading,
  onDelete,
  onAdd,
  onEdit,
  onRefresh,
  onClose,
  onApprove,
  manager,
  dependentDictionaries,
}: Props): JSX.Element => {
  const classes = styles()

  const [selectedRow, setSelectedRow] = useState<AllWithNodes[number] | null>(null)

  const form = useDictionaryForm(columns, selectedRow, dictionaryInfo, optionalFields)

  const [closeModalId, setCloseModalId] = useState(0)
  const [dateForClose, setDateForClose] = useState<Date>(getServerDateTime())
  const [finish, setFinish] = useState(true)
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false)
  const closeCloseModal = useCallback(() => {
    setIsCloseModalOpen(false)
    setFinish(true)
  }, [])

  useEffect(() => {
    if (isCloseModalOpen) {
      setDateForClose(getServerDateTime())
      setFinish(true)
    }
  }, [isCloseModalOpen])

  const [isFormModalOpen, setIsFormModalOpen] = useState(false)
  const closeModal = useCallback(() => {
    setSelectedRow(null)
    setIsFormModalOpen(false)
    form.reset()
  }, [form])

  const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false)
  const closeEditModal = useCallback(() => {
    setSelectedRow(null)
    setIsEditFormModalOpen(false)
  }, [])

  const [isBindModalOpen, setIsBindModalOpen] = useState(false)
  const closeBindModal = useCallback(() => {
    setSelectedRow(null)
    setIsBindModalOpen(false)
    onRefresh()
  }, [onRefresh])

  const handleUpdate = useCallback(
    (value: ResponseAllWithNodes['content']['content'][number]) => {
      onEdit(value)
      closeEditModal()
    },
    [closeEditModal, onEdit],
  )

  const { isLoading: isUpdateLoading, updateDictionaryValue } = useUpdateDictionaryValue(
    dictionaryType,
    handleUpdate,
  )

  const handleEditSubmit = useCallback(
    (data: DictionaryForm, start: boolean, finish?: boolean) => {
      if (selectedRow) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { nodesBinding, ...rest } = selectedRow
        const convertedData = data as Record<string, unknown>
        if (selectedRow.finishDateTime && !data.finishDateTime) {
          convertedData.finishDateTime = null
        }
        updateDictionaryValue({ value: selectedRow, newValue: data, start, finish })
      }
    },
    [selectedRow, updateDictionaryValue],
  )

  const handleAdd = useCallback(
    (value: ResponseAllWithNodes['content']['content'][number]) => {
      onAdd(value)
      closeModal()
    },
    [closeModal, onAdd],
  )

  const { isLoading: isAddLoading, addDictionaryValue } = useAddDictionaryValue(
    dictionaryType,
    handleAdd,
  )

  const handleAddSubmit = useCallback(
    (data: DictionaryForm, start) => {
      const formData = { ...data }
      const mainColumn = columns.find(({ main }) => main) ?? { name: '' }
      if (mainColumn.name !== 'value') {
        // @ts-ignore
        formData.value = data[mainColumn.name]
      }
      addDictionaryValue(formData, start)
    },
    [selectedRow, addDictionaryValue, columns],
  )

  const { isLoading: isDeleteLoading, deleteDictionaryValue } = useDeleteDictionaryValue(onDelete)

  const { isLoading: isApproveLoading, approveDictionaryValue } = useApproveDictionaryValue(
    onApprove,
  )

  const { isLoading: isCloseLoading, closeDictionaryValue } = useCloseDictionaryValue(onClose)

  const columnsConfig = useMemo<TableColumn<any>[]>(() => {
    const tableColumns = columns.map(({ type, ...item }) => {
      if (type === 'Date') {
        return {
          ...item,
          render: (row: any) => {
            if (!row[item.name]) return '-'
            return getServerDateTime(row[item.name]).toLocaleString('ru-RU').toString()
          },
        }
      }
      if (item.name === 'nodesBinding') {
        return {
          ...item,
          render: (row: any) => {
            const nodesBinding = row.nodesBinding.map(
              ({ id, shop, area, section }: NodeBinding) => {
                const label = [shop, area, section].filter((item) => item).join(' - ')
                return (
                  <Chip
                    key={id}
                    variant="default"
                    size="small"
                    label={<Typography className={classes.chipLabel}>{label}</Typography>}
                  />
                )
              },
            )
            return <Box spacing={1}>{nodesBinding}</Box>
          },
        }
      }
      return {
        ...item,
        render: (row: any) => {
          return row?.[item.name] ?? '-'
        },
      }
    })
    const controlsColumn: TableColumn<any> = {
      name: 'controls',
      title: ' ',
      width: 1,
      render: (row: any) => {
        return (
          <Box spacing={2}>
            {!row.approveDateTime && !readOnly && (
              <Tooltip title="Утвердить значение">
                <span>
                  <IconButton
                    variant="primary"
                    disabled={isApproveLoading}
                    onClick={() => {
                      approveDictionaryValue([row.id])
                    }}
                  >
                    <TaskSvgIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {!row.approveDateTime && !readOnly && (
              <Tooltip title="Связать значение">
                <span>
                  <IconButton
                    variant="primary"
                    onClick={() => {
                      setSelectedRow(row)
                      setIsBindModalOpen(true)
                    }}
                  >
                    <SimpleAttributeMappingSvgIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {!row.approveDateTime && !readOnly && (
              <Tooltip title="Редактировать значение">
                <span>
                  <IconButton
                    variant="primary"
                    onClick={() => {
                      const convertedRows = Object.entries(row).reduce((acc, curr) => {
                        const [key, value] = curr as [string, typeof row]
                        return {
                          ...acc,
                          [key]:
                            datesFields.includes(key) && value !== null
                              ? getServerDateTime(new Date(value))
                              : value,
                        }
                      }, {} as typeof row)
                      setSelectedRow(convertedRows)
                      setIsEditFormModalOpen(true)
                    }}
                  >
                    <PenSvgIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            <Tooltip title="Дублировать значение">
              <span>
                <IconButton
                  variant="primary"
                  onClick={() => {
                    setSelectedRow({
                      ...row,
                      startDateTime: getServerDateTime(),
                      finishDateTime: null,
                    })
                    setIsFormModalOpen(true)
                  }}
                >
                  <IconSlab24 />
                </IconButton>
              </span>
            </Tooltip>
            {row.approveDateTime && !readOnly && !row.finishDateTime && (
              <Tooltip title="Закрыть значение">
                <span>
                  <IconButton
                    variant="primary"
                    disabled={isCloseLoading}
                    onClick={() => {
                      setIsCloseModalOpen(true)
                      setCloseModalId(Number(row.id))
                    }}
                  >
                    <ScheduleSvgIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {!row.approveDateTime && !readOnly && (
              <Tooltip title="Удалить значение">
                <span>
                  <IconButton
                    variant="primary"
                    disabled={isDeleteLoading}
                    onClick={() => {
                      deleteDictionaryValue(row.id)
                    }}
                  >
                    <RemoveSvgIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        )
      },
    }
    return [...tableColumns, controlsColumn]
  }, [
    columns,
    classes,
    readOnly,
    isDeleteLoading,
    isCloseLoading,
    isApproveLoading,
    deleteDictionaryValue,
    approveDictionaryValue,
  ])

  const excludeColumnNames = useMemo(
    () =>
      columns
        .filter((column) => excludeColumnTypeNames.includes(column.type))
        .map(({ name }) => name) as (keyof typeof rows[number])[],
    [columns],
  )

  const TableMemo = useMemo(
    () => (
      <Table
        narrowColumns
        fixHeader
        noDataMessage="Нет данных"
        style={{
          root: {
            style: {
              flexGrow: 1,
              overflow: 'auto',
              maxHeight: '620px',
            },
          },
        }}
        enableColumnResizing
        columns={columnsConfig}
        rows={rows}
        withFilter
        manager={manager}
        excludeColumnNames={excludeColumnNames}
      />
    ),
    [columnsConfig, rows, manager, excludeColumnNames],
  )

  return (
    <>
      <LoaderWrapper
        rootClassName={classes.flexGrow}
        contentClassName={classes.flexGrow}
        isLoad={isLoading}
      >
        <Box className={classes.flexGrow} spacing={2}>
          <Card className={classes.flexGrow}>
            <Box className={classes.flexGrow} spacing={2} paddingX={2} paddingY={2}>
              {TableMemo}
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, page) => onChangeCurrentPage(page)}
              />
            </Box>
          </Card>
          <Box spacing={2} justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsFormModalOpen(true)
              }}
            >
              Добавить
            </Button>
          </Box>
        </Box>
      </LoaderWrapper>
      {isFormModalOpen && (
        <Dialog
          maxWidth="xs"
          onClose={closeModal}
          actions={[
            <ButtonLoader
              form="dictionary-values-form"
              progressLoading={isAddLoading}
              disabled={isAddLoading}
              type="submit"
              key={1}
              color="primary"
              variant="contained"
            >
              Добавить
            </ButtonLoader>,
            <Button onClick={closeModal} key={2} color="secondary" variant="contained">
              Отменить
            </Button>,
          ]}
          title="Добавление справочного значения"
          open={isFormModalOpen}
        >
          <DictionaryValuesForm
            onSubmit={handleAddSubmit}
            form={form}
            columns={columns}
            mode="add"
            dependentDictionaries={dependentDictionaries}
          />
        </Dialog>
      )}

      {isEditFormModalOpen && (
        <Dialog
          maxWidth="xs"
          onClose={closeEditModal}
          actions={[
            <ButtonLoader
              form="dictionary-values-form"
              progressLoading={isUpdateLoading}
              disabled={isUpdateLoading}
              type="submit"
              key={1}
              color="primary"
              variant="contained"
            >
              Обновить
            </ButtonLoader>,
            <Button onClick={closeEditModal} key={2} color="secondary" variant="contained">
              Отменить
            </Button>,
          ]}
          title="Редактирование справочного значения"
          open={isEditFormModalOpen}
        >
          <DictionaryValuesForm
            onSubmit={handleEditSubmit}
            form={form}
            columns={columns}
            mode="edit"
            dependentDictionaries={dependentDictionaries}
          />
        </Dialog>
      )}

      {isCloseModalOpen && (
        <Dialog
          maxWidth="xs"
          onClose={closeCloseModal}
          actions={[
            <ButtonLoader
              progressLoading={isCloseLoading}
              disabled={isCloseLoading}
              type="button"
              key={1}
              color="primary"
              variant="contained"
              onClick={() => {
                closeDictionaryValue([closeModalId], finish ? undefined : dateForClose)
                closeCloseModal()
              }}
            >
              Закрыть
            </ButtonLoader>,
            <Button onClick={closeCloseModal} key={2} color="secondary" variant="contained">
              Отменить
            </Button>,
          ]}
          title="Закрытие справочного значения"
          open={isCloseModalOpen}
        >
          <Box flexDirection="column" spacing={1}>
            {!finish && (
              <DatePicker
                withPortal
                type="time"
                label="Время закрытия"
                value={dateForClose}
                onChange={(date) => setDateForClose(date)}
              />
            )}
            <FormControlLabel
              value="hidden"
              style={{ marginLeft: 0, marginRight: 0 }}
              control={<Checkbox checked={finish} onChange={(e, checked) => setFinish(checked)} />}
              label="Завершить действие значения сейчас?"
            />
          </Box>
        </Dialog>
      )}

      {isBindModalOpen && selectedRow && (
        <BindNodeTableModal
          isOpen={isBindModalOpen}
          onClose={closeBindModal}
          dictionaryId={selectedRow.id}
          currentIds={selectedRow?.nodesBinding?.map(({ id }) => id) ?? []}
        />
      )}
    </>
  )
}

export default EditTable
