import dayjs from 'dayjs'

const getFullDate = (date: Date | dayjs.Dayjs): string[] => {
  if (date instanceof Date) {
    const seconds = date.getSeconds().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const house = date.getHours().toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()

    return [day, month, year, house, minutes, seconds]
  }
  return date.format('DD MM YYYY HH mm ss').split(' ')
}

export default getFullDate
