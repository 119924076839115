export const quarters = [
  { month: 0, name: '1 квартал', values: [0, 1, 2] },
  { month: 3, name: '2 квартал', values: [3, 4, 5] },
  { month: 6, name: '3 квартал', values: [6, 7, 8] },
  { month: 9, name: '4 квартал', values: [9, 10, 11] },
]

export const quarterMonthKeys = {
  1: 0,
  2: 3,
  3: 6,
  4: 9,
}

export const quarterQuarterKeys = {
  0: 0,
  1: 0,
  2: 0,
  3: 3,
  4: 3,
  5: 3,
  6: 6,
  7: 6,
  8: 6,
  9: 9,
  10: 9,
  11: 9,
}
