import { FilterItem, FilterType } from './typings'
import { withoutValueRegex } from './utils/enums'
import { Item } from './utils/helpers'
import * as yup from 'yup'
import Reference from 'yup/es/Reference'

type ItemMethod = { label: string; value: FilterType }

export interface ICreateFilter {
  label: Item
  method: ItemMethod
  value: string | number
  secondValue?: number | string
  values?: string[]
}

const initialValues: ICreateFilter = {
  label: { label: '', value: '' },
  method: { label: '', value: '' as FilterItem['method'] },
  value: '',
  secondValue: '',
  values: [],
}

const labels = {
  label: 'Название столбца',
  method: 'Способ фильтрации',
  value: 'Значение от',
  secondValue: 'Значение до',
}

const validationFiltersSchema = yup.object().shape({
  label: yup.object().shape({
    value: yup.string().required('Выберите параметр из списка.'),
  }),
  method: yup.object().shape({
    value: yup.string().required('Выберите параметр из списка.'),
  }),
  value: yup.mixed().when('method', {
    is: (v) => v.value && v.value.includes('date'),
    then: yup.date().when('method', {
      is: (v) => withoutValueRegex.exec(v.value),
      then: yup.date().notRequired(),
      otherwise: yup.date().required('Обязательно поле.').typeError('Введите или выберите дату.'),
    }),
    otherwise: yup.mixed().when('method', {
      is: (v) => v.value && v.value.includes('string'),
      then: yup.string().when('method', {
        is: (v) => withoutValueRegex.exec(v.value),
        then: yup.string().notRequired(),
        otherwise: yup.string().trim().required('Обязательно поле.'),
      }),
      otherwise: yup.number().when('method', {
        is: (v) => withoutValueRegex.exec(v.value),
        then: yup.number().notRequired(),
        otherwise: yup.number().required('Обязательно поле.').typeError('Введите число.'),
      }),
    }),
  }),
  values: yup.mixed().when('method', {
    is: (v) => v.value && v.value.includes('custom'),
    then: yup.array().min(1),
  }),
  secondValue: yup.mixed().when('method', {
    is: (v) => v.value && v.value.includes('between'),
    then: yup.mixed().when('method', {
      is: (v) => v.value && v.value.includes('date'),
      then: yup
        .date()
        .min(yup.ref('value'), '"Значение до" не может быть раньше "Значение после".')
        .required('Обязательно поле.')
        .typeError('Введите или выберите дату.'),
      otherwise: yup
        .number()
        .min(
          yup.ref<number>('value') as Reference<number>,
          '"Значение после" должно быть больше "Значение до".',
        )
        .required('Обязательно поле.')
        .typeError('Введите число.'),
    }),
  }),
})

export { initialValues, labels, validationFiltersSchema }
