import React from 'react'

import { ClosedEyeSvgIcon, EyeSvgIcon, PinSvgIcon } from '../../../../Icon/icons_old'
import { TableColumn } from '../../../../Table/types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

export interface ViewItemProps<T extends unknown> {
  item: TableColumn<T>
  pinned?: boolean
  visible?: boolean
  onPinnedToggle?: () => void
  onVisibleToggle?: () => void
}

function ViewItem<T extends unknown>({
  item,
  onPinnedToggle,
  onVisibleToggle,
}: ViewItemProps<T>): JSX.Element {
  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={8}>
        <Typography>{item.title}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onVisibleToggle}>
          {item.visible ? <EyeSvgIcon color="primary" /> : <ClosedEyeSvgIcon color="disabled" />}
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onPinnedToggle}>
          {item.pinned ? <PinSvgIcon color="primary" /> : <PinSvgIcon color="disabled" />}
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ViewItem
