import { useCallback } from 'react'

import { normalizeDateToDay } from './normalizeDateToDay'
import { isAfter, isBefore } from 'date-fns'

export const useIsDisabled = (enabledFrom?: Date, enabledTo?: Date) =>
  useCallback(
    (date: Date) => {
      const normalizedEnabledFrom = enabledFrom && normalizeDateToDay(enabledFrom)
      const normalizedEnabledTo = enabledTo && normalizeDateToDay(enabledTo)
      const normalizedDate = normalizeDateToDay(date)
      if (
        (normalizedEnabledFrom && isBefore(normalizedDate, normalizedEnabledFrom)) ||
        (normalizedEnabledTo && isAfter(normalizedDate, normalizedEnabledTo))
      ) {
        return true
      }
      return false
    },
    [enabledFrom, enabledTo],
  )
