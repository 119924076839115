import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconArrowBack32: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M26.6666 14.6667H10.4399L17.8933 7.21333L15.9999 5.33333L5.33325 16L15.9999 26.6667L17.8799 24.7867L10.4399 17.3333H26.6666V14.6667Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconArrowBack32
