import React, { FC } from 'react'

import { CloseSvgIcon } from '../Icon'
import { ModalProps } from '../Table/TableModal/types'
import { Drawer, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { makeModalStylesForAnchor } from './styles'

const useModalStylesForLeftAnchor = makeModalStylesForAnchor('left')
const useModalStylesForRightAnchor = makeModalStylesForAnchor('right')

export const Modal: FC<ModalProps> = ({
  isOpen,
  modalName,
  children,
  withMenu,
  withOpenMenu,
  disableBackdrop,
  level = 1,
  classes,
  ...props
}) => {
  const modalClassesForLeftAnchor = useModalStylesForLeftAnchor()
  const modalClassesForRightAnchor = useModalStylesForRightAnchor()
  const modalClasses =
    props.anchor === 'right' ? modalClassesForRightAnchor : modalClassesForLeftAnchor
  const { modal: modalClassFromProps, paper: paperClassFromProps, ...restClasses } = classes ?? {}
  return (
    <Drawer
      {...props}
      open={isOpen}
      classes={{
        modal: clsx(modalClasses.modal, modalClassFromProps),
        paper: clsx(
          modalClasses.paper,
          {
            [modalClasses.paperLevel1]: level === 1,
            [modalClasses.paperLevel2]: level === 2,
            [modalClasses.paperLevel3]: level === 3,
            [modalClasses.withMenu]: withMenu,
            [modalClasses.withOpenMenu]: withOpenMenu,
            [modalClasses.disableBackdrop]: disableBackdrop,
          },
          paperClassFromProps,
        ),
        ...restClasses,
      }}
    >
      <CloseSvgIcon
        onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}
        className={modalClasses.closeIcon}
      />
      {modalName && <Typography className={modalClasses.title}>{modalName}</Typography>}
      <div className={modalClasses.children}>{children}</div>
    </Drawer>
  )
}
