import { SET_DATE, SET_START_TIME } from './constants'
import { taskFilterInitialState } from './initialState'
import { Reducer } from 'redux'

import { TaskFilterAction, TaskFilterInitialState } from './types'

export const taskFilterReducer: Reducer<TaskFilterInitialState, TaskFilterAction> = (
  state = taskFilterInitialState,
  action,
) => {
  switch (action.type) {
    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      }
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      }
    default:
      return state
  }
}

export default taskFilterReducer
