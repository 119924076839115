export const mainWhite = '#fff'
export const mainBlue = '#167ffb'
export const secondBlue = '#d0e9ff'
export const mainDark = '#000000'
export const mainGray = 'rgba(0, 23, 41, 0.75)'
export const mainGreen = '#47dc68'
export const mainRed = '#FF0000'

export const userMenuBlue = '#0b3461'
export const userMenuDarkBlue = '#112542'
export const userMenuBlueHover = '#005291'

export const userContentGray = '#f7f8f8'

export const userDateDark = '#001729'

export const printGreen = '#d8fbdb'

export const modalPrintBlue = '#167ffb'
export const modalGray = 'rgba(0, 0, 0, 0.25)'

export const tableControlButtonBlue = '#0060d1'

export const tableResultBlack = '#001729'

export const galvanizerBlack = '#001729'

export const fullnessGray = '#f2f3f4'
export const fullnessOrange = '#ffc30f'
export const fullnessRed = '#ff7a7a'

export const kalilshickBlack = '#001729'

export const modalPrintApplicationGreen = '#BFF5C5'
export const modalPrintApplicationPeachy = '#FFE9BF'
