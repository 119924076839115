import React from 'react'
import { AlertComponentPropsWithStyle } from 'react-alert'

import { Alert } from '../../ui-kit/components/Alert'
import { Snackbar } from '../../ui-kit/components/Snackbar'

const TITLES = {
  error: 'Oшибка',
  success: 'Успешно',
  info: 'Инфо',
}

export const AlertTemplate: React.FC<AlertComponentPropsWithStyle> = ({ options, message }) => {
  const title = options.type ? TITLES[options.type] : ''
  return (
    <Snackbar open anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <Alert style={{ whiteSpace: 'nowrap' }} title={title} severity={options.type}>
        <div>{message}</div>
      </Alert>
    </Snackbar>
  )
}

export default AlertTemplate
