import { makeStyles, Theme } from '@material-ui/core'

import { ExtendedDialogProps } from './types'

export const useDialogStyles = makeStyles<Theme, ExtendedDialogProps>((theme) => ({
  icon: (props) => ({
    position: 'absolute',
    right: props.position === 'right' ? undefined : -40,
    left: props.position === 'right' ? -40 : undefined,
    top: 0,
    cursor: 'pointer',
    color: theme.palette.background.paper,
    '&:hover': {
      opacity: 0.8,
    },
  }),
  root: {
    position: 'relative',
    '@global': {
      '.MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
  },
  paper: (props) => {
    switch (props.position) {
      case 'right':
        return {
          borderRadius: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          maxHeight: '100%',
          margin: 0,
          overflow: 'initial',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
        }
      case 'bottom':
        return {
          borderRadius: 0,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          maxWidth: '100%',
          margin: 0,
          overflow: 'initial',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
        }
      default:
        return {
          overflow: 'initial',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
        }
    }
  },
}))
