import React, { FC } from 'react'

import { Typography } from '../Typography'

import { PseudoInputProps } from './types'

import { usePseudoInputStyles } from './styles'

export const PseudoInput: FC<PseudoInputProps> = ({
  label,
  children,
  size = 'm',
  longLabel = false,
}) => {
  const classes = usePseudoInputStyles({ label, children, size, longLabel })
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.text} variant="Body1-Medium">
        {children}
      </Typography>
    </div>
  )
}
