import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconCoins24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M8.41009 8.31555C12.607 8.31555 15.743 6.64842 15.743 5.15774C15.743 3.6672 12.607 2 8.41009 2C4.21322 2 1.07722 3.6672 1.07722 5.15774C1.07722 6.64842 4.21315 8.31555 8.41009 8.31555Z"
        fill="currentColor"
      />
      <path
        d="M8.41009 20.9472C9.02266 20.9472 9.61225 20.9114 10.1731 20.8462C9.70033 20.2357 9.30939 19.5583 9.01651 18.8307C8.81528 18.8376 8.61335 18.8423 8.41002 18.8423C6.0119 18.8423 3.74083 18.3648 2.01536 17.4978C1.67561 17.3272 1.36297 17.1438 1.07722 16.9496V17.7895C1.07722 19.28 4.21315 20.9472 8.41009 20.9472Z"
        fill="currentColor"
      />
      <path
        d="M8.41009 16.7367C8.4316 16.7367 8.45276 16.7363 8.47421 16.7363C8.43125 16.3912 8.40883 16.0398 8.40883 15.6833C8.40883 15.3269 8.43118 14.9756 8.47421 14.6309C8.45276 14.6309 8.43153 14.6317 8.41009 14.6317C6.01197 14.6317 3.74097 14.1542 2.01543 13.2872C1.67561 13.1166 1.3629 12.9332 1.07715 12.7391V13.5789C1.07722 15.0695 4.21315 16.7367 8.41009 16.7367Z"
        fill="currentColor"
      />
      <path
        d="M8.41009 12.526C8.61796 12.526 8.82303 12.5218 9.02552 12.5138C9.35074 11.7121 9.79519 10.9715 10.3371 10.3148C9.70969 10.3843 9.06492 10.4211 8.41009 10.4211C6.01197 10.4211 3.74097 9.94365 2.01543 9.07672C1.67561 8.90595 1.3629 8.72263 1.07715 8.52849V9.36834C1.07722 10.859 4.21315 12.526 8.41009 12.526Z"
        fill="currentColor"
      />
      <path
        d="M11.5533 12.1938C11.1 12.8786 10.7761 13.6569 10.6167 14.4933C10.5432 14.8788 10.5044 15.2765 10.5044 15.6833C10.5044 15.9891 10.5266 16.2897 10.5686 16.584C10.6717 17.307 10.8971 17.9906 11.223 18.6134C11.565 19.2669 12.0174 19.8533 12.5555 20.347C13.6736 21.3732 15.1607 22 16.7908 22C20.2571 22 23.0771 19.1663 23.0771 15.6833C23.0771 12.2001 20.2571 9.36651 16.7908 9.36651C16.4325 9.36651 16.0812 9.39726 15.7391 9.45537C13.9965 9.75148 12.4956 10.7705 11.5533 12.1938Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconCoins24
