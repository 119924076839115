import React, { CSSProperties, useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import clsx, { ClassValue } from 'clsx'

import { getByColors } from '../styles'
import { RowStyleProps, StyleProps, TableColumn } from '../types'

// TODO: по-моему дич какая то которая дублирует RowStyleProps<R>
type StyledProps = { color?: (row: any) => 'green' | 'red' | undefined; className?: string; style?: CSSProperties }

const useStyleProps = (styledProps: StyledProps, styleProps?: StyleProps, customClassValues?: ClassValue[]): [string, CSSProperties] => {
    const propsClassName = styledProps.className
    const rootClassName = styleProps?.className
    const className = useMemo(() => clsx([...(customClassValues || []), rootClassName, propsClassName]), [propsClassName, rootClassName, customClassValues])

    const propsStyle = styledProps.style
    const rootStyle = styleProps?.style
    const style = useMemo(() => ({ ...propsStyle, ...rootStyle }), [propsStyle, rootStyle])
    return [className, style]
}

const useRowStyleProps = <R extends { controls?: React.ReactNode }>(
    row: R,
    styledProps: StyledProps,
    styleProps?: RowStyleProps<R>,
    isRowsBgAlternation?: boolean,
    isChecked?: boolean,
    onHoverControls?: boolean,
): [string, CSSProperties] => {
    const rowColor = styleProps?.color
    const rowColorClassName = typeof rowColor === 'function' ? rowColor(row) : rowColor

    const rowColors = styleProps?.colors
    const rowColors2 = typeof rowColors === 'function' ? rowColors(row) : rowColors
    const rowsBgAlternationStyle = (theme) => ({
        rowsBgAlternation: {
            backgroundColor: theme.palette.background.default,
        },
    })
    const styles = (theme) => ({
        color: {
            ...getByColors({ rawRowColors: rowColors2 }),
            '&.Row__highlighted': getByColors({ rawRowColors: rowColors2, highlightedBackground: theme.palette.secondary.light, highlighted: true }),
        },
        rowsBgAlternation: {
            backgroundColor: theme.palette.background.default,
        },
    })
    const currentStyle = makeStyles(styles)()
    const rawRowColorClassNames2 = useMemo(() => rowColors2 && currentStyle, [rowColors2])
    const cnWow = rawRowColorClassNames2 ? rawRowColorClassNames2['color'] : makeStyles(rowsBgAlternationStyle)()

    const rawRowClassName = styleProps?.className
    const rowClassName = typeof rawRowClassName === 'function' ? rawRowClassName(row) : rawRowClassName
    const propsClassName = styledProps.className
    const withSticky = !styleProps?.sticky?.call ? false : styleProps.sticky(row)
    const withControls = row?.hasOwnProperty('controls')
    const className = clsx(
        [rowClassName, propsClassName, cnWow],
        { [`Row__${rowColorClassName}`]: rowColorClassName, ['Row__Sticky']: withSticky, ['Row__Checked']: isChecked, ['Row__Hover']: withControls && onHoverControls },
        isRowsBgAlternation && rawRowColorClassNames2?.rowsBgAlternation,
    )

    const rawRowStyle = styleProps?.style
    const rowStyle = typeof rawRowStyle === 'function' ? rawRowStyle(row) : rawRowStyle
    const propsStyle = styledProps.style
    const style = { ...propsStyle, ...rowStyle, ...(isRowsBgAlternation ? { isRowsBgAlternation: '' } : {}) }
    return [className, style]
}

/**
 * Скопировал предыдущую функцию, но ничего с собой поделать не могу.
 * Сливать две логики в одну - порочная практика.
 */
const useCellStyleProps = <R>(row: R, column: TableColumn<unknown>, styledProps: StyledProps, styleProps?: RowStyleProps<R>, customClassValues?: ClassValue[]): [string, CSSProperties] => {
    const color = typeof styleProps?.color === 'function' ? styleProps?.color(row) : styleProps?.color
    const rawRowClassName = styleProps?.className
    const rowClassName = typeof rawRowClassName === 'function' ? rawRowClassName(row) : rawRowClassName
    const propsClassName = styledProps.className
    const className = useMemo(() => clsx([...(customClassValues || []), rowClassName, propsClassName, { [`Cell_align_${column.align}`]: column.align, [`Cell__${color}`]: color }]), [
        customClassValues,
        rowClassName,
        propsClassName,
        column.align,
        color,
    ])

    const rawRowStyle = styleProps?.style
    const rowStyle = typeof rawRowStyle === 'function' ? rawRowStyle(row) : rawRowStyle
    const propsStyle = styledProps.style
    const style = useMemo(() => ({ ...propsStyle, ...rowStyle }), [rowStyle, propsStyle])
    return [className, style]
}

export { type StyledProps, useStyleProps, useRowStyleProps, useCellStyleProps }
