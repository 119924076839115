import React, { PropsWithChildren, useContext, useMemo } from 'react'

import { Checkbox } from '../../Checkbox'
import { useGetCurrentTheme } from '../../CommonWrapper'
import { CustomFieldsCtx } from '../utils/customFieldsCtx'
import { TableCell } from './TableCell'
import { TableSelection } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'

import { useTableStyles } from '../styles'

const EMPTY_ARRAY = []
const EMPTY_SET = new Set()
const COLUMN = { name: '' }

export const TableSelectionHeaderCell: React.FC<
  TableSelection.HeaderCellProps & PropsWithChildren
> = (props) => {
  const context = useContext(CustomFieldsCtx)
  const rowsSelection = context.rowsSelection
  const checkEnabled = rowsSelection?.checkEnabled
  const isStickyCheckHeader = rowsSelection?.isStickyCheckColumn
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { allSelected, someSelected, ...other } = props // Без other в итоговом return в консоль сыпятся ошибки о allSelected и someSelected
  const checkedRows = context.checkedRows || EMPTY_SET
  const checkEnabledRows = context.checkEnabledRows || EMPTY_ARRAY
  const checkableRows = context.checkableRows || EMPTY_ARRAY
  const hasCheckedElement = context.hasCheckedElement
  const theme = useGetCurrentTheme()
  const classes = useTableStyles({ theme })

  const equalFunction = hasCheckedElement
    ? (row) => hasCheckedElement(row, Array.from(checkedRows))
    : (row) => checkedRows.has(row)

  const [allChecked, someChecked] = useMemo(() => {
    const checkedRowsCount = checkedRows.size
    const rowsCount = checkEnabledRows.length
    const all =
      rowsCount > 0 &&
      rowsCount === checkedRowsCount &&
      !checkEnabledRows.find((row) => !equalFunction(row))
    const some = !all && checkedRows.size && checkableRows.find((row) => equalFunction(row))
    return [all, !!some]
  }, [checkedRows, checkEnabledRows, checkableRows])
  const onClick = () => {
    const onCheckedChange = rowsSelection?.onCheckedChange
    if (onCheckedChange) {
      const newState = !allChecked && !someChecked
      onCheckedChange((context.alwaysCheckedRows || []).concat(newState ? checkableRows : []))
    }
  }
  return (
    <TableCell
      value={null}
      row={{}}
      column={COLUMN}
      {...other}
      className={clsx(
        classes.base,
        checkEnabled && 'Check__Cell',
        checkEnabled && isStickyCheckHeader && 'Check__Cell_stickyCheckColumn',
        context.narrowRows && 'Check__Cell_narrowRows',
      )}
    >
      {checkEnabled && checkableRows.length > 0 && !rowsSelection?.singleCheck && (
        <Checkbox checked={allChecked} indeterminate={someChecked} onClick={onClick} />
      )}
      {props.children}
    </TableCell>
  )
}
