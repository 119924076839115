import React from 'react'

import { useGetCurrentTheme } from '../../components/CommonWrapper'
import { Typography } from '../../components/Typography/Typography'
import { TooltipProps } from '../Navigation'
import { Tooltip as MUITooltip } from '@material-ui/core'
import clsx from 'clsx'

import { tooltipStyled } from './styles'

function getTitle(str: string): JSX.Element {
  return (
    <Typography className="title" variant="Body2-Bold">
      {str}
    </Typography>
  )
}

function getDescription(str: string): JSX.Element {
  return (
    <Typography className="description" variant="Body2-Medium">
      {str}
    </Typography>
  )
}

function getListItems(list: string[]) {
  return (
    <div className="list">
      {list.map((item, index) => (
        <Typography variant="Body2-Medium">
          <li key={String(index)}> {item} </li>
        </Typography>
      ))}
    </div>
  )
}

export const NewTooltip: React.FC<TooltipProps> = (props) => {
  const { title, description, list } = props
  const nonContent = !title && !description && (!list || list?.length)
  const style = tooltipStyled({ nonContent })

  const theme = useGetCurrentTheme()

  return (
    <div>
      <MUITooltip
        {...props}
        classes={{
          ...props.classes,
          tooltip: clsx(style.tooltip, {
            [style.light]: theme === 'light',
            [style.dark]: theme === 'dark',
          }),
        }}
        title={
          <>
            {title && !description && !list && getDescription(title)}
            {title && (description || list) && getTitle(title)}
            {description && getDescription(description)}
            {list && (
              <div>
                <ul className={style.ul}>{getListItems(list)}</ul>
              </div>
            )}
          </>
        }
        children={props.children}
      />
    </div>
  )
}
