import React, { useMemo, useState } from 'react'

import { Day } from '../Day'
import { normalizeDateToYear } from './helpers/normalizeDateToYear'
import { useIsDisabledYear } from './helpers/useIsDisabledYear'
import { useIsEndYear } from './helpers/useIsEndYear'
import { useIsMidYear } from './helpers/useIsMidYear'
import { useIsSelectedYear } from './helpers/useIsSelectedYear'
import { useIsStartYear } from './helpers/useIsStartYear'

import { YearsCalendarProps } from './types'

import { useYearsCalendarStyles } from './styles'

export const YearsCalendar: React.FC<YearsCalendarProps> = ({
  panelValue,
  disableChange,
  withPeriod,
  valueFrom,
  valueTo,
  enabledFrom,
  enabledTo,
  selectedDate,
  onSelect,
}) => {
  const today = useMemo(() => new Date(), [])
  const startYear = useMemo(() => (panelValue ?? new Date()).getFullYear() - 6, [panelValue])
  const dateFrom = useMemo(() => valueFrom && normalizeDateToYear(valueFrom), [valueFrom])
  const dateTo = useMemo(() => valueTo && normalizeDateToYear(valueTo), [valueTo])
  const [innerCurrentHover, setCurrentHover] = useState<null | Date>(null)
  const currentHover = useMemo(() => (!valueFrom || !valueTo ? innerCurrentHover : null), [
    innerCurrentHover,
    valueFrom,
    valueTo,
  ])
  const dateCurrentHover = useMemo(
    () =>
      currentHover && new Date(currentHover.getFullYear(), currentHover.getMonth(), 1, 0, 0, 0, 0),
    [currentHover],
  )
  const styles = useYearsCalendarStyles()

  const isSelectedYear = useIsSelectedYear({
    dateCurrentHover,
    withPeriod,
    dateFrom,
    dateTo,
    selectedDate,
  })
  const isStartYear = useIsStartYear({ dateCurrentHover, withPeriod, dateFrom })
  const isMidYear = useIsMidYear({ dateCurrentHover, withPeriod, dateFrom, dateTo })
  const isEndYear = useIsEndYear({ dateCurrentHover, withPeriod, dateTo, dateFrom })
  const isDisabled = useIsDisabledYear(enabledFrom, enabledTo)

  return (
    <div className={styles.root}>
      {new Array(12).fill(null).map((_, idx) => {
        const currentYear = idx + startYear
        const dayAsDate = new Date(currentYear, 0, 1, 0, 0, 0, 0)
        return (
          <Day
            onClick={() => onSelect && onSelect(dayAsDate)}
            onHover={() => withPeriod && setCurrentHover(dayAsDate)}
            isHoverState={Boolean(dateCurrentHover)}
            selected={isSelectedYear(dayAsDate)}
            disabled={disableChange || isDisabled(currentYear)}
            today={today.getFullYear() === currentYear}
            start={isStartYear(dayAsDate)}
            mid={isMidYear(dayAsDate)}
            end={isEndYear(dayAsDate)}
            key={currentYear}
            type="year"
          >
            {currentYear}
          </Day>
        )
      })}
    </div>
  )
}
