import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconFullscreenExit24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8H8V5H10V10H5V8ZM8 16H5V14H10V19H8V16ZM14 19H16V16H19V14H14V19ZM16 5V8H19V10H14V5H16Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconFullscreenExit24
