import React, { FC } from 'react'

import { Box } from '../../components/Box'
import { Button } from '../../components/Button'
import { ButtonProps, CircularProgress, CircularProgressProps } from '@material-ui/core'

type TButton = {
  progressLoading: boolean
  progressColor?: CircularProgressProps['color']
} & ButtonProps

const ButtonLoader: FC<TButton> = ({
  progressLoading,
  progressColor = 'secondary',
  children,
  ...props
}) => {
  return (
    <Button {...props}>
      <Box alignItems="center">
        {progressLoading && (
          <CircularProgress style={{ marginRight: '8px' }} size={22} color={progressColor} />
        )}
        {children}
      </Box>
    </Button>
  )
}

export default ButtonLoader
