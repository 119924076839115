import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconData24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M7.40201 10.1488L7.36029 10.4877L7.12274 10.7165C6.94945 10.8835 6.71882 10.9844 6.46582 10.9844C5.94234 10.9844 5.51562 10.5577 5.51562 10.0342C5.51562 9.5107 5.94234 9.08398 6.46582 9.08398C6.9893 9.08398 7.41602 9.5107 7.41602 10.0342C7.41602 10.0357 7.41587 10.0428 7.41349 10.0624C7.41227 10.0724 7.41082 10.083 7.40875 10.098L7.40847 10.1001C7.40661 10.1135 7.40422 10.1309 7.40201 10.1488ZM9.43854 11.5143L9.67609 11.2854C9.84938 11.1185 10.08 11.0176 10.333 11.0176C10.4725 11.0176 10.6081 11.0516 10.7384 11.114L11.0416 11.2591L11.1868 11.5624C11.2492 11.6927 11.2832 11.8282 11.2832 11.9678C11.2832 12.4913 10.8565 12.918 10.333 12.918C9.80953 12.918 9.38281 12.4913 9.38281 11.9678C9.38281 11.9662 9.38295 11.9592 9.38534 11.9395C9.38656 11.9296 9.38801 11.9189 9.39007 11.9039C9.39197 11.8902 9.3945 11.872 9.39682 11.8531L9.43854 11.5143ZM13.7948 8.95439L13.4916 8.80922L13.3464 8.50594C13.284 8.37569 13.25 8.24011 13.25 8.10059C13.25 7.57711 13.6767 7.15039 14.2002 7.15039C14.394 7.15039 14.5738 7.20898 14.7252 7.31014L14.998 7.49234L15.1009 7.82058C15.1339 7.92588 15.1504 8.016 15.1504 8.10059C15.1504 8.62406 14.7237 9.05078 14.2002 9.05078C14.0607 9.05078 13.9251 9.01674 13.7948 8.95439ZM17.5423 5.99065L17.2696 5.80844L17.1667 5.4802C17.1337 5.3749 17.1172 5.28479 17.1172 5.2002C17.1172 4.67672 17.5439 4.25 18.0674 4.25C18.5909 4.25 19.0176 4.67672 19.0176 5.2002C19.0176 5.72367 18.5909 6.15039 18.0674 6.15039C17.8736 6.15039 17.6938 6.0918 17.5423 5.99065ZM19.0176 19.2832V19.4082L19.0764 19.5185L19.1999 19.75H17.1172V19.2832V9.08398H19.0176V19.2832ZM5.33333 19.75L5.4568 19.5185L5.51562 19.4082V19.2832V13.918H7.41602V19.2832V19.75H5.33333ZM9.38281 19.75V19.2832V15.8516H11.2832V19.2832V19.75H9.38281ZM13.25 19.75V19.2832V11.9844H15.1504V19.2832V19.75H13.25Z"
        stroke="currentColor"
      />
    </svg>
  )
}

export default IconData24
