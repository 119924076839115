import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardBlueNails = React.forwardRef<HTMLDivElement, CardProps>(
  ({ highlighted, barcode }, ref) => {
    const referenceData: ReferenceDataType[] = [
      {
        title: highlighted?.[0]?.appointment ?? '',
        data: {
          left: [
            { name: 'end_diameter', title: 'Диаметр, мм' },
            { name: 'steel_grade', title: 'Марка стали' },
            { name: 'aggregate', title: '№ стана/катушки' },
            { name: '', title: 'Масса, тн' },
            { name: 'date', title: 'Дата' },
            { name: '', title: '№ плавки' },
          ],
          right: [
            { name: '', title: 'Оператор' },
            { name: '', title: 'Тип смазки' },
            { name: 'brigade', title: 'Бригада' },
          ],
        },
      },
    ]

    const renderBlock = (data?: Block[]): React.ReactNode => {
      if (!data) return null
      return data
        .map(({ name, title }) => {
          // @ts-ignore
          const newDescription: string =
            // @ts-ignore
            highlighted[0][name] !== undefined ? highlighted[0][name] : '____'
          return { title, description: newDescription }
        })
        .map(({ title, description }) => (
          <Box style={{ paddingBottom: '6px' }} justifyContent="space-between" key={title}>
            <Typography style={{ maxWidth: '120px' }} variant="Caption">
              {title}
            </Typography>
            <Typography variant="Caption">{description}</Typography>
          </Box>
        ))
    }

    return (
      <div className="print-block print-block_blue" ref={ref}>
        <Box
          flexDirection="column"
          style={{
            position: 'relative',
          }}
        >
          {referenceData.map(({ title, data }, index) => (
            <div key={`title${index}`} style={{ padding: '24px 85px 15px 57px' }}>
              <Box style={{ paddingBottom: '8px' }} spacing={2}>
                <Typography variant="Caption">Назначение</Typography>
                <Typography variant="Caption-Medium">{title}</Typography>
              </Box>
              <Box justifyContent="space-between">
                {Object.values(data).map((value, i) => (
                  <Box
                    style={{
                      width: '230px',
                      paddingRight: '10px',
                      marginRight: '10px',
                      borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                    }}
                    flexDirection="column"
                    key={i}
                  >
                    {renderBlock(value)}
                  </Box>
                ))}
              </Box>
            </div>
          ))}

          <div className={styles.barcodeContainer}>
            <Barcode value={barcode} />
          </div>
          <div className={styles.qrCodeContainer}>
            <QRCode value={String(barcode)} />
          </div>
        </Box>
      </div>
    )
  },
)

export default CardBlueNails
