import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Zone } from '../../../../interfaces/Fullness'
// import { useAlert } from 'react-alert';
import { Attributes, WarehouseRowsData } from '../../../../interfaces/Warehouse'
import service from '../../../../services/service'
import {
  Box,
  Button,
  Dialog,
  Input,
  MenuItem,
  Select,
  Typography,
} from '../../../../ui-kit/components'
import ButtonLoader from '../../../../ui-kit/frontend-components/ButtonLoader'
import useAsync from '../../../../ui-kit/hooks/useAsync'
// import { DictionariesContext, DictionaryValue } from 'features';
import { sortBy } from 'lodash'

type TProps = {
  isOpen: boolean
  setIsOpen: (status: boolean) => void
}

const ModalSearch: FC<TProps> = ({ isOpen, setIsOpen }) => {
  // const { getDictionary } = useContext(DictionariesContext);

  const [barcode, setBarcode] = useState('')
  const [zoneName, setZoneName] = useState('')
  const [isWarehouse, setIsWarehouse] = useState(true)
  const [wireRod, setWireRod] = useState<WarehouseRowsData<Attributes>[]>([])
  const [zoneId, setZoneId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  // const alert = useAlert();

  const getWireRodSearch = async () => {
    try {
      const {
        content,
      }: { content: WarehouseRowsData<Attributes>[] } = await service.getIdentification(barcode)
      if (content.length !== 0) {
        const {
          content: unitZone,
        }: {
          content: { attributes: { name: string; warehouse?: any } }
        } = await service.getZoneForUnit(content[0].id)
        setZoneName(unitZone.attributes.name)
        setIsWarehouse(Boolean(unitZone.attributes.warehouse))
      }

      if (content.length === 0) {
        setError(true)
      }
      setWireRod(content)
    } catch (e) {
      setError(true)
    }
  }

  const setUnitOnZone = async () => {
    // setIsLoading(true);
    // try {
    //   const status = getDictionary('status.wire_rod.ugv.spc').find(
    //     ({ value }) => value === 'Принято на ТСД'
    //   ) as DictionaryValue;
    //   await service.replaceUnitOnZone({
    //     zoneId: Number(zoneId),
    //     wiresId: [wireRod[0]?.id],
    //     status,
    //   });
    //   alert.success('Успешно');
    // } catch (e) {
    //   setError(true);
    // } finally {
    //   setIsOpen(false);
    //   setIsLoading(false);
    // }
  }

  const getZone = useCallback(() => service.getWarehouseZones('warehouse.inplast.output'), [])

  const { value, status, execute } = useAsync<{ content: Zone[] }>(getZone, false, false)

  useEffect(() => {
    if (isOpen) {
      execute()
    }
  }, [isOpen])

  const sortedZone = sortBy(value?.content, ['attributes.groupName'])

  return (
    <Dialog
      maxWidth="xs"
      onClose={() => {
        setIsOpen(false)
      }}
      title="Поиск катанки"
      actions={[
        <ButtonLoader
          progressLoading={isLoading}
          disabled={zoneId === ''}
          onClick={setUnitOnZone}
          key={1}
          color="primary"
          variant="contained"
        >
          Переместить
        </ButtonLoader>,
        <Button onClick={() => setIsOpen(false)} key={2} color="secondary" variant="contained">
          Отменить
        </Button>,
      ]}
      open={isOpen}
    >
      <Box flexDirection="column" spacing={3}>
        <Typography>Необходимо считать или ввести вручную штрихкод катанки</Typography>
        <Box spacing={2} alignItems="flex-start">
          <Input
            onChange={(e) => setBarcode(e.target.value)}
            label="Ввести штрихкод"
            error={error}
            helperText={error && 'Катанка не найдена'}
          />
          <Button onClick={getWireRodSearch} color="primary" variant="contained">
            Найти
          </Button>
        </Box>
        {wireRod[0]?.attributes?.barcode && (
          <Box flexDirection="column" spacing={2}>
            <Box spacing={1} alignItems="center">
              <Typography variant="Body1">
                Катанка {wireRod[0].attributes.barcode} находится на: <span>{zoneName || '-'}</span>
              </Typography>
            </Box>
            {isWarehouse && (
              <Box flexDirection="column" spacing={1}>
                <Typography>Переместить на:</Typography>
                {status === 'success' && (
                  <Select onChange={(e) => setZoneId(e.target.value as string)} fullWidth>
                    {sortedZone?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          <Box flexBasis="100%" justifyContent="space-between" spacing={4}>
                            <Typography>
                              {item.attributes.groupName} {item.attributes.name}
                            </Typography>
                            <Box>
                              <Typography variant="Body1-Bold">
                                {item.attributes.fullness} / {item.attributes.capacity || '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default ModalSearch
