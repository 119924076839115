import { AsyncReducers } from './reducerManager'
import { staticReducers } from './staticReducers'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

const middlewares = [thunk]

const configureStore = () => {
  const store = createStore(
    combineReducers(staticReducers),
    compose(
      applyMiddleware(...middlewares),
      // eslint-disable-next-line no-underscore-dangle
      (window as any)?.__REDUX_DEVTOOLS_EXTENSION__?.({ trace: true }) || ((f: any) => f),
    ),
  )

  type Store = typeof store & {
    asyncReducers: AsyncReducers
  }

  const storeWithAsyncReducers: Store = {
    ...store,
    asyncReducers: {},
  }

  return storeWithAsyncReducers
}

export const store = configureStore()
