import { makeStyles } from '@material-ui/core'

export const useTabStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'inherit',
    fontWeight: theme.textStyles.body1medium.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.textStyles.body1medium.fontSize,
    padding: '6px 0',
    minWidth: 40,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  selected: {
    '& span:first-child': {
      fontWeight: theme.textStyles.body1bold.fontWeight,
    },
    color: theme.palette.text.primary,
  },
  wrapper: {
    fontWeight: theme.textStyles.body1medium.fontWeight,
    fontSize: theme.textStyles.body1medium.fontSize,
    lineHeight: theme.textStyles.body1medium.lineHeight,
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    color: theme.palette.text.primary,
    '&:hover': {
      color: '#167FFB',
    },
  },
  number: {
    background: '#E8F5FF',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    color: '#167FFB',
    fontSize: theme.textStyles.caption.fontSize,
    lineHeight: theme.textStyles.caption.lineHeight,
    marginLeft: '8px',
  },
}))
