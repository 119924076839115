import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconListSheets24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M10.1602 15.4463C10.1602 16.4128 9.37665 17.1963 8.41016 17.1963C7.44366 17.1963 6.66016 16.4128 6.66016 15.4463C6.66016 14.4798 7.44366 13.6963 8.41016 13.6963C9.37665 13.6963 10.1602 14.4798 10.1602 15.4463Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1273 13.3208L13.3973 20.0508C12.463 20.9851 11.2726 21.6214 9.97661 21.8792C8.68066 22.137 7.33737 22.0047 6.11661 21.499C4.89585 20.9934 3.85245 20.1371 3.11835 19.0384C2.38425 17.9398 1.99243 16.6481 1.99243 15.3267C1.99243 14.0054 2.38426 12.7137 3.11836 11.6151C3.39137 11.2065 3.70718 10.8314 4.05898 10.4954L10.6357 3.91868L10.6413 3.92429C11.2058 3.36952 11.8649 2.91896 12.5883 2.59429C13.3918 2.23362 14.2588 2.03542 15.1392 2.01109C16.0197 1.98677 16.8963 2.1368 17.7185 2.45256C18.5408 2.76831 19.2925 3.24356 19.9303 3.85094C20.5682 4.45833 21.0796 5.18586 21.4353 5.99167C21.7909 6.79749 21.9836 7.66567 22.0024 8.54626C22.0212 9.42685 21.8656 10.3025 21.5447 11.1227C21.2238 11.9429 20.7438 12.6916 20.1324 13.3257L20.1273 13.3208ZM19.1864 12.1404L12.3366 18.9901C11.6121 19.7147 10.689 20.2081 9.68398 20.408C8.679 20.6079 7.63731 20.5053 6.69064 20.1132C5.74397 19.7211 4.93483 19.057 4.36556 18.2051C3.79628 17.3531 3.49243 16.3514 3.49243 15.3267C3.49243 14.3021 3.79628 13.3004 4.36556 12.4484C4.57865 12.1295 4.82534 11.837 5.10027 11.5751C5.55985 11.1374 6.09832 10.7856 6.69064 10.5403C7.63731 10.1482 8.679 10.0456 9.68398 10.2455C10.7058 10.4487 11.611 10.9021 12.3241 11.6152L12.8544 12.1455L19.4471 5.55307C19.6919 5.87508 19.8988 6.22544 20.0629 6.59728C20.3387 7.22215 20.4882 7.89539 20.5027 8.57825C20.5173 9.26111 20.3967 9.9401 20.1478 10.5762C19.9229 11.1511 19.5971 11.6808 19.1864 12.1404ZM18.3757 4.50309C18.0085 4.23533 17.6069 4.01648 17.1808 3.85286C16.5432 3.608 15.8634 3.49166 15.1807 3.51052C14.4979 3.52938 13.8256 3.68308 13.2025 3.96277C12.5794 4.24245 12.0177 4.6426 11.5498 5.1402L11.5425 5.13325L7.99529 8.68041C8.65319 8.6133 9.32096 8.64387 9.97662 8.77429C11.0134 8.98053 11.9871 9.40064 12.8225 10.0561L18.3757 4.50309Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconListSheets24
