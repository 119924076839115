import React, { FC } from 'react'

import { IIconsProps } from '../types'

import { useIconStyles } from '../styles'

export const IconHelmet24: FC<IIconsProps> = ({ color = 'inherit', htmlColor }) => {
  const classes = useIconStyles({ htmlColor })
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlColor ? classes.userColor : classes[color]}
    >
      <path
        d="M19.2832 15.0635V13.6408C19.2832 12.0157 18.77 10.471 17.799 9.17385C16.9372 8.0224 15.7456 7.13932 14.4151 6.65848C14.3781 5.87543 13.7427 5.25 12.9667 5.25H11.0331C10.2572 5.25 9.62174 5.87543 9.58471 6.65848C8.25425 7.13932 7.06263 8.0224 6.20079 9.17385C5.22986 10.471 4.71665 12.0157 4.71665 13.6408V15.0635C3.75 15.0635 3.74999 15.0635 3.75 16.5733C3.75001 18.083 3.74999 18.083 5.20005 18.083H18.7998C20.2502 18.083 20.2498 18.1411 20.25 16.6023C20.2502 15.0635 20.25 15.0635 19.2832 15.0635ZM5.68345 13.6408C5.68345 11.861 6.42809 10.1947 7.64929 9.00597V10.6794H8.6161V8.23177C8.92071 8.03322 9.24378 7.86001 9.5829 7.71585V11.6665H10.5497V7.02465V7.0206V6.73074C10.5497 6.45858 10.7666 6.23716 11.0331 6.23716H12.9667C13.2333 6.23716 13.4501 6.45858 13.4501 6.73074V7.02047V7.02468V11.6665H14.4169V7.71585C14.7561 7.86004 15.0791 8.03326 15.3837 8.23177V10.6794H16.3505V9.00597C17.5718 10.1947 18.3164 11.861 18.3164 13.6408V15.0635L5.68345 15.1216V13.6408ZM18.7998 17.0959H5.20005C4.9335 17.0959 4.71665 17.0959 4.71665 16.6023C4.71665 16.1087 4.9335 16.1087 5.20005 16.1087H18.7998C19.0663 16.1087 19.2832 16.1087 19.2832 16.6023C19.2832 17.0959 19.0663 17.0959 18.7998 17.0959Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconHelmet24
