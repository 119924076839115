import React, { PropsWithChildren } from 'react'

import { ArrowDownSvgIcon } from '../../../Icon'
import LeftDatePickerItemSvgIcon from '../../../Icon/DatePicker/LeftDatePickerItemSvgIcon'
import RightDatePickerItemSvgIcon from '../../../Icon/DatePicker/RightDatePickerItemSvgIcon'
import clsx from 'clsx'

import { PeriodPickerProps } from './types'

import { usePeriodPickerStyles } from './styles'

export const PeriodPicker: React.FC<PeriodPickerProps & PropsWithChildren> = ({
  disableContent,
  onContentClick,
  onLeftClick,
  onRightClick,
  children,
  isHideYear,
  selectedPanel,
}) => {
  const styles = usePeriodPickerStyles()
  const handleClick =
    disableContent || (isHideYear && selectedPanel === 'month') ? undefined : onContentClick
  return (
    <div className={clsx(styles.root, disableContent && styles.disableContent)}>
      <LeftDatePickerItemSvgIcon
        data-testid="left-panel-icon"
        className={styles.icon}
        onClick={onLeftClick}
      />
      <div data-testid="level-switcher" className={styles.content} onClick={handleClick}>
        <div className={styles.contentText}>{children}</div>
        {!disableContent && !(isHideYear && selectedPanel === 'month') && (
          <ArrowDownSvgIcon color="primary" />
        )}
      </div>
      <RightDatePickerItemSvgIcon
        data-testid="right-panel-icon"
        className={styles.icon}
        onClick={onRightClick}
      />
    </div>
  )
}
