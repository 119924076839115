import React, { createContext, FC, useState } from 'react'

export type UserNavigationContextValue = {
  isOpen: boolean
  changeIsOpen: (value: boolean) => void
}

export const UserNavigationContext = createContext<UserNavigationContextValue>({
  isOpen: true,
  changeIsOpen: () => null,
})

export const UserNavigationProvider: FC = (children) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <UserNavigationContext.Provider
      value={{
        isOpen,
        changeIsOpen: setIsOpen,
      }}
    >
      {/* {{children}} */}
    </UserNavigationContext.Provider>
  )
}
