import React, { FC } from 'react'

import { INavigationItem } from '../../types'
import { CollapsibleNavigationItem } from '../CollapsibleNavigationItem'
import { NavigationItem } from '../NavigationItem'

export const NavigationItems: FC<{
  item: INavigationItem
}> = ({ item }) => {
  const items = item.items || []

  return (
    <div>
      {items.map((subItem) => {
        if ('items' in subItem) {
          return <CollapsibleNavigationItem item={subItem} key={subItem.title} />
        }

        const isItemVisible = subItem.isShow === undefined || subItem.isShow

        if (!isItemVisible) {
          return null
        }

        const itemTitle = subItem.title || subItem.id

        return <NavigationItem item={{ ...subItem, title: itemTitle }} key={itemTitle} />
      })}
    </div>
  )
}
