import React, { FC } from 'react'

import { Typography } from '../Typography'
import { Pagination as MUIPagination, PaginationItem, PaginationProps } from '@material-ui/lab'

import { usePaginationStyles } from './styles'

export const Pagination: FC<PaginationProps> = (props: PaginationProps) => {
  const classes = usePaginationStyles()
  return (
    <Typography variant="Body1-Medium">
      <MUIPagination
        {...props}
        className={classes.root}
        renderItem={styleItemsAndGetThem(classes)}
      />
    </Typography>
  )
}

const styleItemsAndGetThem = (classes) => (item) => (
  <PaginationItem {...item} classes={setStyles(classes)} />
)
const setStyles = (classes) => {
  return {
    page: classes.page,
    icon: classes.icon,
  }
}
