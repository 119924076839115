import React from 'react'

import { Block, ReferenceDataType } from '../../../../interfaces/AdditionTable'
import { CardProps } from '../../../../interfaces/Card'
import { Box } from '../../../components/Box'
import { Typography } from '../../../components/Typography'
import Barcode from '../../Barcode'
import QRCode from '../../QRCode'

import styles from '../styles.module.scss'

const CardRed = React.forwardRef<HTMLDivElement, CardProps>(({ highlighted, barcode }, ref) => {
  const referenceData: ReferenceDataType[] = [
    {
      title: '',
      data: {
        left: [
          { name: 'aggregate', title: '№ нити' },
          { name: 'symbol', title: 'Условное обозначение' },
          { name: '', title: '№ розетты' },
          { name: 'steel_grade', title: 'Марка стали' },
          { name: 'swim_number', title: '№ плавки' },
          { name: 'brigade', title: 'Бригада' },
          { name: 'order_code', title: 'Шифр заказа' },
        ],
        right: [
          { name: 'beg_diameter', title: 'Диаметер' },
          { name: 'tu_gost', title: 'ТУ, ГОСТ' },
          { name: '', title: 'Предел прочности Н/мм2' },
          { name: '', title: 'Плотность цинка Н/мм2' },
          { name: 'date', title: 'Дата изготовления' },
          { name: '', title: 'Штамп ОТК' },
        ],
      },
    },
    {
      title: '',
      data: {
        left: [
          { name: 'aggregate', title: '№ нити' },
          { name: 'symbol', title: 'Условное обозначение' },
          { name: '', title: '№ розетты' },
          { name: 'steel_grade', title: 'Марка стали' },
          { name: 'swim_number', title: '№ плавки' },
          { name: 'brigade', title: 'Бригада' },
          { name: 'order_code', title: 'Шифр заказа' },
        ],
        right: [
          { name: 'beg_diameter', title: 'Диаметер' },
          { name: 'tu_gost', title: 'ТУ, ГОСТ' },
          { name: '', title: 'Предел прочности Н/мм2' },
          { name: '', title: 'Плотность цинка Н/мм2' },
          { name: 'date', title: 'Дата изготовления' },
          { name: '', title: 'Штамп ОТК' },
        ],
      },
    },
  ]
  const renderBlock = (data?: Block[]) => {
    if (!data) return null
    return data
      .map(({ name, title }) => {
        const newDescription: string =
          // @ts-ignore
          highlighted[0][name] !== undefined ? highlighted[0][name] : '____'
        return { title, description: newDescription }
      })

      .map(({ title, description }) => (
        <Box style={{ paddingBottom: '6px' }} justifyContent="space-between" key={title}>
          <Typography style={{ maxWidth: '120px' }} variant="Caption">
            {title}
          </Typography>
          <Typography variant="Caption">{description}</Typography>
        </Box>
      ))
  }

  return (
    <div className="print-block print-block_red" ref={ref}>
      <Box
        flexDirection="column"
        style={{
          position: 'relative',
        }}
        paddingBottom="40px"
      >
        {referenceData.map(({ title, data }, index) => (
          <div style={{ padding: '28px 85px 15px 57px' }} key={`title${index}`}>
            <Typography style={{ paddingBottom: '8px' }} variant="Caption-Medium">
              {title}
            </Typography>
            <Box justifyContent="space-between">
              {Object.values(data).map((value, i) => (
                <Box
                  style={{
                    width: '230px',
                    paddingRight: '10px',
                    marginRight: '10px',
                    borderRight: '1px solid rgba(0, 23, 41, 0.12)',
                  }}
                  flexDirection="column"
                  key={i}
                >
                  {renderBlock(value)}
                </Box>
              ))}
            </Box>
          </div>
        ))}

        <div className={styles.line} />

        <div className={styles.barcodeContainer}>
          <Barcode value={barcode} />
        </div>
        <div className={styles.barcodeContainerTwo}>
          <Barcode value={barcode} />
        </div>
        <div className={styles.qrCodeContainer}>
          <QRCode value={String(barcode)} />
        </div>
        <div className={styles.qrCodeContainerTwo}>
          <QRCode value={String(barcode)} />
        </div>
      </Box>
    </div>
  )
})

export default CardRed
