import { ModalProps } from '../Table/TableModal/types'
import { makeStyles, Theme } from '@material-ui/core'

const MENU_COLLAPSED_WIDTH = 57
const MENU_EXPANDED_WIDTH = 256

const PAPER_WIDTH = 316
const PAPER_HORIZONTAL_PADDING = 48

const DRAWER_WIDTH = PAPER_WIDTH + PAPER_HORIZONTAL_PADDING * 2

const PAPER_HORIZONTAL_SPACING_LVL_2 = DRAWER_WIDTH
const PAPER_HORIZONTAL_SPACING_LVL_2_WITH_COLLAPSED_MENU =
  PAPER_HORIZONTAL_SPACING_LVL_2 + MENU_COLLAPSED_WIDTH
const PAPER_HORIZONTAL_SPACING_LVL_2_WITH_EXPANDED_MENU =
  PAPER_HORIZONTAL_SPACING_LVL_2 + MENU_EXPANDED_WIDTH
const PAPER_HORIZONTAL_SPACING_LVL_3 = DRAWER_WIDTH * 2
const PAPER_HORIZONTAL_SPACING_LVL_3_WITH_COLLAPSED_MENU =
  PAPER_HORIZONTAL_SPACING_LVL_3 + MENU_COLLAPSED_WIDTH
const PAPER_HORIZONTAL_SPACING_LVL_3_WITH_EXPANDED_MENU =
  PAPER_HORIZONTAL_SPACING_LVL_3 + MENU_EXPANDED_WIDTH

const CLOSE_ICON_HORIZONTAL_SPACING = 16
const CLOSE_ICON_HORIZONTAL_POSITION = DRAWER_WIDTH + CLOSE_ICON_HORIZONTAL_SPACING
const CLOSE_ICON_HORIZONTAL_POSITION_WITH_COLLAPSED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION + MENU_COLLAPSED_WIDTH
const CLOSE_ICON_HORIZONTAL_POSITION_WITH_EXPANDED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION + MENU_EXPANDED_WIDTH
const CLOSE_ICON_HORIZONTAL_POSITION_LVL2 = DRAWER_WIDTH * 2 + CLOSE_ICON_HORIZONTAL_SPACING
const CLOSE_ICON_HORIZONTAL_POSITION_LVL2_WITH_COLLAPSED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION_LVL2 + MENU_COLLAPSED_WIDTH
const CLOSE_ICON_HORIZONTAL_POSITION_LVL2_WITH_EXPANDED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION_LVL2 + MENU_EXPANDED_WIDTH
const CLOSE_ICON_HORIZONTAL_POSITION_LVL3 = DRAWER_WIDTH * 3 + CLOSE_ICON_HORIZONTAL_SPACING
const CLOSE_ICON_HORIZONTAL_POSITION_LVL3_WITH_COLLAPSED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION_LVL3 + MENU_COLLAPSED_WIDTH
const CLOSE_ICON_HORIZONTAL_POSITION_LVL3_WITH_EXPANDED_MENU =
  CLOSE_ICON_HORIZONTAL_POSITION_LVL3 + MENU_EXPANDED_WIDTH

export const makeModalStylesForAnchor = (
  anchor: Required<ModalProps>['anchor'],
): ReturnType<typeof makeStyles> => {
  const createHorizontalSpacingStyles = (dimension: number) => {
    switch (anchor) {
      case 'right':
        return {
          left: 'auto',
          right: `${dimension}px`,
        }
      case 'left':
      default:
        return {
          left: `${dimension}px`,
          right: 'auto',
        }
    }
  }
  return makeStyles<Theme, ModalProps>((theme) => ({
    paper: {
      padding: `${PAPER_HORIZONTAL_PADDING}px`,
      minWidth: PAPER_WIDTH,
      width: PAPER_WIDTH,
      position: 'absolute',
      boxShadow: 'none',
      borderRight: '1px solid rgba(0, 23, 41, 0.12)',
      backgroundColor: theme.palette.background.paper,
      height: '100%',
    },
    paperLevel1: {
      '&$withMenu': {
        ...createHorizontalSpacingStyles(MENU_COLLAPSED_WIDTH),
      },
      '&$withOpenMenu': {
        ...createHorizontalSpacingStyles(MENU_EXPANDED_WIDTH),
      },
    },
    paperLevel2: {
      zIndex: 1298,
      ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_2),
      '&$withMenu': {
        ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_2_WITH_COLLAPSED_MENU),
      },
      '&$withOpenMenu': {
        ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_2_WITH_EXPANDED_MENU),
      },
    },
    paperLevel3: {
      zIndex: 1299,
      ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_3),
      '&$withMenu': {
        ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_3_WITH_COLLAPSED_MENU),
      },
      '&$withOpenMenu': {
        ...createHorizontalSpacingStyles(PAPER_HORIZONTAL_SPACING_LVL_3_WITH_EXPANDED_MENU),
      },
    },
    disableBackdrop: {
      '& .MuiBackdrop-root': {
        display: 'none',
      },
    },
    children: {
      paddingBottom: 45,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexGrow: 1,
    },
    modal: {
      '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.25)',
      },
    },
    withMenu: {
      width: PAPER_WIDTH,
    },
    withOpenMenu: {
      width: PAPER_WIDTH,
    },
    closeIcon: {
      top: 0,
      position: 'fixed',
      ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION),
      color: theme.palette.background.paper,
      zIndex: 1400,
      cursor: 'pointer',
      '$withMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_WITH_COLLAPSED_MENU),
      },
      '$withOpenMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_WITH_EXPANDED_MENU),
      },
      '$paperLevel2 &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL2),
      },
      '$paperLevel2$withMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL2_WITH_COLLAPSED_MENU),
      },
      '$paperLevel2$withOpenMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL2_WITH_EXPANDED_MENU),
      },
      '$paperLevel3 &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL3),
      },
      '$paperLevel3$withMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL3_WITH_COLLAPSED_MENU),
      },
      '$paperLevel3$withOpenMenu &': {
        ...createHorizontalSpacingStyles(CLOSE_ICON_HORIZONTAL_POSITION_LVL3_WITH_EXPANDED_MENU),
      },
    },
    title: {
      color: theme.palette.text.primary,
      marginBottom: '24px',
      fontSize: theme.textStyles.heading4.fontSize,
      lineHeight: theme.textStyles.heading4.lineHeight,
      fontWeight: theme.textStyles.heading4.fontWeight,
    },
  }))
}
