import { useCallback, useEffect, useState } from 'react'

import { TableColumn } from '../../../../../ui-kit/components'
import { FilterItem } from '../../../../../ui-kit/components/TableToolbar/components/Filter/typings'
import useAsync from '../../../../../ui-kit/hooks/useAsync'
import { getReducerKey } from '../utils'
// import { useAlert } from 'react-alert';
import {
  DictionaryContent,
  DictionaryFilter,
  ResponseDictionary,
} from 'interfaces/responses/Dictionary'
import { Request } from 'interfaces/responses/Request'
import service from 'services/service'
import { useStoreSelector } from 'store/hooks'

export type DictionaryColumn = TableColumn<DictionaryContent>

export type Dictionary = {
  columns: DictionaryColumn[]
  content: DictionaryContent[]
} | null

export type UseDictionaryParams = {
  immediateExecute?: boolean
  dictionaryName: string
}

export type UseDictionaryReturn = {
  dictionary: Dictionary
  setDictionary: (value: Dictionary) => void
  request: Request<ResponseDictionary>
  pageable: {
    currentPage: number
    totalPages: number
    changeTotalPages: (totalPages: number) => void
  }
}

export const convertColumns = (
  columns: ResponseDictionary['columnName'],
  columnOrder: ResponseDictionary['columnOrder'],
): DictionaryColumn[] =>
  Object.entries(columns)
    .map(
      ([key, value]) =>
        ({
          name: key,
          title: value,
          dataType: 'custom' as const,
          customMethods: [
            {
              label: 'Содержит',
              value: 'string-have',
            },
          ],
        } as DictionaryColumn),
    )
    .sort((a, b) => columnOrder[a.name] - columnOrder[b.name])

const sortItems = (items: DictionaryContent[]) => items.sort((a, b) => b.id - a.id)

export const convertFilters = (filters: FilterItem[]): DictionaryFilter[] =>
  filters.map(({ label, value = '' }) => ({
    columnName: label,
    value,
  }))

export const useDictionary = ({
  immediateExecute = false,
  dictionaryName,
}: UseDictionaryParams): UseDictionaryReturn => {
  // const alert = useAlert();

  const [dictionary, setDictionary] = useState<Dictionary>(null)

  const [totalPages, setTotalPages] = useState(0)

  const reducerKey = getReducerKey(dictionaryName)

  const currentPage = useStoreSelector((state) => state?.[reducerKey]?.params?.page ?? 0)

  const filters = useStoreSelector((state) => state?.[reducerKey]?.params?.filter ?? [])

  const getDictionary = useCallback(
    () =>
      service.getDictionary({
        dictionaryName,
        listFilter: convertFilters(filters),
        page: currentPage,
      }),
    [dictionaryName, currentPage, filters],
  )

  const request = useAsync(getDictionary, immediateExecute)

  useEffect(() => {
    if (request.value && request.status === 'success') {
      setTotalPages(request.value.content.totalPages)
      setDictionary({
        columns: convertColumns(request.value.columnName, request.value.columnOrder),
        content: sortItems(request.value.content.content),
      })
    }
  }, [request.value, request.status])

  useEffect(() => {
    if (request.error) {
      setDictionary(null)
      // alert.error(request.error);
    }
  }, [request.error])

  return {
    dictionary,
    setDictionary,
    request,
    pageable: {
      currentPage,
      totalPages,
      changeTotalPages: setTotalPages,
    },
  }
}
