import getZoneNumber from './getZoneNumber'
import { get } from 'lodash'

const zoneComparator = <T>(path: string, arrLength: number) => {
  return (a: T, b: T): number => {
    const aNumber = getZoneNumber(get(a, path), arrLength)
    const bNumber = getZoneNumber(get(b, path), arrLength)
    return Number(aNumber) - Number(bNumber)
  }
}

export default zoneComparator
