import { createContext } from 'react'

import { IFilterContext } from './typings'
import { noop } from 'lodash'

export const FilterContext = createContext<IFilterContext>({
  visible: false,
  onClose: noop,
  setTab: noop,
  filter: [],
  setFilter: noop,
  header: [],
  initHint: true,
  setInitHint: noop,
})
