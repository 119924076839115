import React, { createContext, Dispatch, SetStateAction } from 'react'

import { INavigationItem, RenderLinkProps } from '../types'

interface INavigationContext {
  isOpen: boolean
  renderLink: (props: RenderLinkProps) => JSX.Element
  selectedFirstLevel: string | null
  setSecondLevel: Dispatch<SetStateAction<INavigationItem | null>>
  setSelectedFirstLevel: Dispatch<SetStateAction<string | null>>
  useFavorites?: boolean
  onChangeFavorites?: () => void
  onOpenChange?: (open: boolean) => void
  selectedListItemId?: string | null
  setSelectedListItemId?: Dispatch<SetStateAction<string | null>>
  pathname: string
}

const initialContextValues: INavigationContext = {
  isOpen: false,
  renderLink: () => <></>,
  selectedFirstLevel: null,
  setSecondLevel: () => null,
  setSelectedFirstLevel: () => null,
  pathname: '',
}

const NavigationContext = createContext<INavigationContext>(initialContextValues)

export default NavigationContext
